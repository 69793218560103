import {
    IAlternativePortionsByRunnerByUid,
    IPortionsByRunnerByUid,
    IKitchenMenuAlternativePortions,
    IKitchenMenuPortions
} from "../../App/interfaces/IKitchenMenuPortions";

import { IDietaryUsageStats } from "../../App/interfaces/IDietaryUsageStats";
import { IReportsState } from "../../App/interfaces/IReportsState";
import { reportsInitialState } from "./reportsInitialState";
import { IJoggerEdit } from "../../App/interfaces/IJoggerEdit";

import {
    IMenuItemUsageStats,
    MenuItemUsageStat
} from "../../App/interfaces/IMenuItemUsageStats";

export enum TypeKeys {
    SET_PORTIONS_DATE = 'REPORTS/SET_PORTIONS_DATE',

    CALC_DIETARY_STATS_FOR_RANGE_REQ = 'REPORTS/CALC_DIETARY_STATS_FOR_RANGE_REQ',
    CALC_DIETARY_STATS_FOR_RANGE_RES = 'REPORTS/CALC_DIETARY_STATS_FOR_RANGE_RES',

    CALC_MENU_ITEM_STATS_REQ = 'REPORTS/CALC_MENU_ITEM_STATS_REQ',
    CALC_MENU_ITEM_STATS_RES = 'REPORTS/CALC_MENU_ITEM_STATS_RES',

    CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_REQ',
    CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_RES',

    CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_REQ',
    CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_RES',

    CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_REQ',
    CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_RES',

    CALC_TEA_SIDE_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_TEA_SIDE_JOGGERS_FOR_RANGE_REQ',
    CALC_TEA_SIDE_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_TEA_SIDE_JOGGERS_FOR_RANGE_RES',

    CALC_MAIN_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_MAIN_JOGGERS_FOR_RANGE_REQ',
    CALC_MAIN_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_MAIN_JOGGERS_FOR_RANGE_RES',

    CALC_TEA_JOGGERS_FOR_RANGE_REQ = 'REPORTS/CALC_TEA_JOGGERS_FOR_RANGE_REQ',
    CALC_TEA_JOGGERS_FOR_RANGE_RES = 'REPORTS/CALC_TEA_JOGGERS_FOR_RANGE_RES',

    CALC_LUNCH_DESSERT_JOGGERS_REQ = 'REPORTS/CALC_LUNCH_DESSERT_JOGGERS_REQ',
    CALC_LUNCH_DESSERT_JOGGERS_RES = 'REPORTS/CALC_LUNCH_DESSERT_JOGGERS_RES',

    CALC_TEA_DESSERT_JOGGERS_REQ = 'REPORTS/CALC_TEA_DESSERT_JOGGERS_REQ',
    CALC_TEA_DESSERT_JOGGERS_RES = 'REPORTS/CALC_TEA_DESSERT_JOGGERS_RES',

    CALC_LUNCH_SIDE_JOGGERS_REQ = 'REPORTS/CALC_LUNCH_SIDE_JOGGERS_REQ',
    CALC_LUNCH_SIDE_JOGGERS_RES = 'REPORTS/CALC_LUNCH_SIDE_JOGGERS_RES',

    CALC_TEA_SIDE_JOGGERS_REQ = 'REPORTS/CALC_TEA_SIDE_JOGGERS_REQ',
    CALC_TEA_SIDE_JOGGERS_RES = 'REPORTS/CALC_TEA_SIDE_JOGGERS_RES',

    CALC_MAIN_JOGGERS_REQ = 'REPORTS/CALC_MAIN_JOGGERS_REQ',
    CALC_MAIN_JOGGERS_RES = 'REPORTS/CALC_MAIN_JOGGERS_RES',

    CALC_TEA_JOGGERS_REQ = 'REPORTS/CALC_TEA_JOGGERS_REQ',
    CALC_TEA_JOGGERS_RES = 'REPORTS/CALC_TEA_JOGGERS_RES',

    EDIT_LUNCH_DESSERT_JOGGER_REQ = 'REPORTS/EDIT_LUNCH_DESSERT_JOGGER_REQ',
    EDIT_TEA_DESSERT_JOGGER_REQ = 'REPORTS/EDIT_TEA_DESSERT_JOGGER_REQ',
    EDIT_LUNCH_SIDE_JOGGER_REQ = 'REPORTS/EDIT_LUNCH_SIDE_JOGGER_REQ',
    EDIT_TEA_SIDE_JOGGER_REQ = 'REPORTS/EDIT_TEA_SIDE_JOGGER_REQ',
    EDIT_MAIN_JOGGER_REQ = 'REPORTS/EDIT_MAIN_JOGGER_REQ',
    EDIT_TEA_JOGGER_REQ = 'REPORTS/EDIT_TEA_JOGGER_REQ',

    REG_SIGNATURE_REPORTS_EVENTS_REQ = 'REPORTS/REG_SIGNATURE_REPORTS_EVENTS_REQ',
    REG_DELIVERY_REPORTS_EVENTS_RES = 'REPORTS/REG_DELIVERY_REPORTS_EVENTS_RES',
    REG_FOODCHECK_REPORTS_EVENTS_RES = 'REPORTS/REG_FOODCHECK_REPORTS_EVENTS_RES',
    REG_DIARYCHECK_REPORTS_EVENTS_RES = 'REPORTS/REG_DIARYCHECK_REPORTS_EVENTS_RES',
    REG_PREREQUISITE_REPORTS_EVENTS_RES = 'REPORTS/REG_PREREQUISITE_REPORTS_EVENTS_RES',
    UREG_SIGNATURE_REPORTS_EVENTS_REQ = 'REPORTS/UREG_SIGNATURE_REPORTS_EVENTS_REQ',
};

export interface ISetPortionsDate {
    type: TypeKeys.SET_PORTIONS_DATE;
    payload: Date,
}

export interface ICalcDietaryStatsReq {
    type: TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
    };
}

export interface ICalcDietaryStatsRes {
    type: TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_RES;
    payload: {
        stats: IDietaryUsageStats[];
    };
}

export interface ICalcMenuItemStatsReq {
    type: TypeKeys.CALC_MENU_ITEM_STATS_REQ;
    payload: {
        itemType: MenuItemUsageStat;
    };
}

export interface ICalcMenuItemStatsRes {
    type: TypeKeys.CALC_MENU_ITEM_STATS_RES;
    payload: {
        itemType: MenuItemUsageStat;
        stats: IMenuItemUsageStats[];
    };
}

export interface ICalcMainJoggersForRangeReq {
    type: TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcMainJoggersForRangeRes {
    type: TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaJoggersForRangeReq {
    type: TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcTeaJoggersForRangeRes {
    type: TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcLunchDessertJoggersReq {
    type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcLunchDessertJoggersRes {
    type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcLunchDessertJoggersForRangeReq {
    type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcLunchDessertJoggersForRangeRes {
    type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaDessertJoggersReq {
    type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}
export interface ICalcTeaDessertJoggersRes {
    type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaDessertJoggersForRangeReq {
    type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}
export interface ICalcTeaDessertJoggersForRangeRes {
    type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}



export interface ICalcLunchSideJoggersReq {
    type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcLunchSideJoggersRes {
    type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcLunchSideJoggersForRangeReq {
    type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcLunchSideJoggersForRangeRes {
    type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaSideJoggersReq {
    type: TypeKeys.CALC_TEA_SIDE_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}
export interface ICalcTeaSideJoggersRes {
    type: TypeKeys.CALC_TEA_SIDE_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaSideJoggersForRangeReq {
    type: TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_REQ;
    payload: {
        theStartDate: number;
        theEndDate: number;
        filterOnSelectedRunner: boolean;
    };
}
export interface ICalcTeaSideJoggersForRangeRes {
    type: TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}



export interface ICalcMainJoggersReq {
    type: TypeKeys.CALC_MAIN_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcMainJoggersRes {
    type: TypeKeys.CALC_MAIN_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface ICalcTeaJoggersReq {
    type: TypeKeys.CALC_TEA_JOGGERS_REQ;
    payload: {
        theStartDate: number;
        filterOnSelectedRunner: boolean;
    };
}

export interface ICalcTeaJoggersRes {
    type: TypeKeys.CALC_TEA_JOGGERS_RES;
    payload: {
        alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[];
        portionsByRunnerByUid: IPortionsByRunnerByUid[];
    };
}

export interface IEditLunchDessertJoggersReq {
    type: TypeKeys.EDIT_LUNCH_DESSERT_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}


export interface IEditTeaDessertJoggersReq {
    type: TypeKeys.EDIT_TEA_DESSERT_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}


export interface IEditLunchSideJoggersReq {
    type: TypeKeys.EDIT_LUNCH_SIDE_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}


export interface IEditTeaSideJoggersReq {
    type: TypeKeys.EDIT_TEA_SIDE_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}

export interface IEditMainJoggersReq {
    type: TypeKeys.EDIT_MAIN_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}

export interface IEditTeaJoggersReq {
    type: TypeKeys.EDIT_TEA_JOGGER_REQ;
    payload: {
        color: string;
        isAlternative: boolean;
        uid: string;
        id: string;
        code: string;
        meals: number;
    };
}

export interface IUnRegSignatureReportsEventsReq {
    type: TypeKeys.UREG_SIGNATURE_REPORTS_EVENTS_REQ;
}

export interface IRegSignatureReportsEventsReq {
    type: TypeKeys.REG_SIGNATURE_REPORTS_EVENTS_REQ;
}

export interface IRegDeliveryReportsEventsRes {
    type: TypeKeys.REG_DELIVERY_REPORTS_EVENTS_RES;
    payload: {
        key: string;
        storage: string;
    };
}

export interface IRegFoodcheckReportsEventsRes {
    type: TypeKeys.REG_FOODCHECK_REPORTS_EVENTS_RES;
    payload: {
        key: string;
        storage: string;
    };
}

export interface IRegDiarycheckReportsEventsRes {
    type: TypeKeys.REG_DIARYCHECK_REPORTS_EVENTS_RES;
    payload: {
        key: string;
        storage: string;
    };
}

export interface IRegPrerequisiteReportsEventsRes {
    type: TypeKeys.REG_PREREQUISITE_REPORTS_EVENTS_RES;
    payload: {
        key: string;
        storage: string;
    };
}

export const actions = {
    setPortionsDate(payload: Date): ISetPortionsDate {
        const action: ISetPortionsDate = { type: TypeKeys.SET_PORTIONS_DATE, payload };
        return action;
    },

    calcLunchDessertJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcLunchDessertJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcLunchDessertJoggersReq = { type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_REQ, payload };
        return action;
    },
    calcLunchDessertJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcLunchDessertJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcLunchDessertJoggersRes = { type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_RES, payload };
        return action;
    },
    calcLunchDessertJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcLunchDessertJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcLunchDessertJoggersForRangeReq = { type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcLunchDessertJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcLunchDessertJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcLunchDessertJoggersForRangeRes = { type: TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcTeaDessertJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcTeaDessertJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaDessertJoggersReq = { type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_REQ, payload };
        return action;
    },
    calcTeaDessertJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaDessertJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaDessertJoggersRes = { type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_RES, payload };
        return action;
    },
    calcTeaDessertJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcTeaDessertJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaDessertJoggersForRangeReq = { type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcTeaDessertJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaDessertJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaDessertJoggersForRangeRes = { type: TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcLunchSideJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcLunchSideJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcLunchSideJoggersReq = { type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_REQ, payload };
        return action;
    },
    calcLunchSideJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcLunchSideJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcLunchSideJoggersRes = { type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_RES, payload };
        return action;
    },
    calcLunchSideJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcLunchSideJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcLunchSideJoggersForRangeReq = { type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcLunchSideJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcLunchSideJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcLunchSideJoggersForRangeRes = { type: TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcTeaSideJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcTeaSideJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaSideJoggersReq = { type: TypeKeys.CALC_TEA_SIDE_JOGGERS_REQ, payload };
        return action;
    },
    calcTeaSideJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaSideJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaSideJoggersRes = { type: TypeKeys.CALC_TEA_SIDE_JOGGERS_RES, payload };
        return action;
    },
    calcTeaSideJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcTeaSideJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaSideJoggersForRangeReq = { type: TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcTeaSideJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaSideJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaSideJoggersForRangeRes = { type: TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcMainJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcMainJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcMainJoggersReq = { type: TypeKeys.CALC_MAIN_JOGGERS_REQ, payload };
        return action;
    },
    calcMainJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcMainJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcMainJoggersRes = { type: TypeKeys.CALC_MAIN_JOGGERS_RES, payload };
        return action;
    },
    calcMainJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcMainJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcMainJoggersForRangeReq = { type: TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcMainJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcMainJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcMainJoggersForRangeRes = { type: TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcTeaJoggersReq(theStartDate: number, filterOnSelectedRunner: boolean): ICalcTeaJoggersReq {
        const payload = {
            theStartDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaJoggersReq = { type: TypeKeys.CALC_TEA_JOGGERS_REQ, payload };
        return action;
    },
    calcTeaJoggersRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaJoggersRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaJoggersRes = { type: TypeKeys.CALC_TEA_JOGGERS_RES, payload };
        return action;
    },
    calcTeaJoggersForRangeReq(theStartDate: number, theEndDate: number, filterOnSelectedRunner: boolean): ICalcTeaJoggersForRangeReq {
        const payload = {
            theStartDate,
            theEndDate,
            filterOnSelectedRunner,
        }
        const action: ICalcTeaJoggersForRangeReq = { type: TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcTeaJoggersForRangeRes(alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[], portionsByRunnerByUid: IPortionsByRunnerByUid[]): ICalcTeaJoggersForRangeRes {
        const payload = {
            portionsByRunnerByUid,
            alternativesByRunnerByUid
        }
        const action: ICalcTeaJoggersForRangeRes = { type: TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_RES, payload };
        return action;
    },

    calcDietaryStatsForDateRangeReq(theStartDate: number, theEndDate: number): ICalcDietaryStatsReq {
        const payload = {
            theStartDate,
            theEndDate
        }
        const action: ICalcDietaryStatsReq = { type: TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_REQ, payload };
        return action;
    },
    calcDietaryStatsForDateRangeRes(stats: IDietaryUsageStats[]): ICalcDietaryStatsRes {
        const payload = {
            stats
        }
        const action: ICalcDietaryStatsRes = { type: TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_RES, payload };
        return action;
    },

    calcMenuItemStatsReq(itemType: MenuItemUsageStat): ICalcMenuItemStatsReq {
        const payload = {
            itemType,
        }
        const action: ICalcMenuItemStatsReq = { type: TypeKeys.CALC_MENU_ITEM_STATS_REQ, payload };
        return action;
    },
    calcMenuItemStatsRes(itemType: MenuItemUsageStat, stats: IMenuItemUsageStats[]): ICalcMenuItemStatsRes {
        const payload = {
            itemType,
            stats
        }
        const action: ICalcMenuItemStatsRes = { type: TypeKeys.CALC_MENU_ITEM_STATS_RES, payload };
        return action;
    },

    editLunchDessertJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditLunchDessertJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditLunchDessertJoggersReq = { type: TypeKeys.EDIT_LUNCH_DESSERT_JOGGER_REQ, payload };
        return action;
    },
    editTeaDessertJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditTeaDessertJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditTeaDessertJoggersReq = { type: TypeKeys.EDIT_TEA_DESSERT_JOGGER_REQ, payload };
        return action;
    },

    editLunchSideJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditLunchSideJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditLunchSideJoggersReq = { type: TypeKeys.EDIT_LUNCH_SIDE_JOGGER_REQ, payload };
        return action;
    },
    editTeaSideJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditTeaSideJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditTeaSideJoggersReq = { type: TypeKeys.EDIT_TEA_SIDE_JOGGER_REQ, payload };
        return action;
    },

    editMainJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditMainJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditMainJoggersReq = { type: TypeKeys.EDIT_MAIN_JOGGER_REQ, payload };
        return action;
    },
    editTeaJoggersReq(color: string, isAlternative: boolean, uid: string, id: string, code: string, meals: number): IEditTeaJoggersReq {
        const payload = {
            color,
            isAlternative,
            uid,
            id,
            code,
            meals,
        }
        const action: IEditTeaJoggersReq = { type: TypeKeys.EDIT_TEA_JOGGER_REQ, payload };
        return action;
    },

    uregSignatureReportsEventsReq(): IUnRegSignatureReportsEventsReq {
        const action: IUnRegSignatureReportsEventsReq = { type: TypeKeys.UREG_SIGNATURE_REPORTS_EVENTS_REQ };
        return action;
    },

    regSignatureReportsEventsReq(): IRegSignatureReportsEventsReq {
        const action: IRegSignatureReportsEventsReq = { type: TypeKeys.REG_SIGNATURE_REPORTS_EVENTS_REQ };
        return action;
    },

    regDeliveryReportsEventsRes(payload: {
        key: string;
        storage: string;
    }): IRegDeliveryReportsEventsRes {
        const action: IRegDeliveryReportsEventsRes = { type: TypeKeys.REG_DELIVERY_REPORTS_EVENTS_RES, payload };
        return action;
    },

    regFoodcheckReportsEventsRes(payload: {
        key: string;
        storage: string;
    }): IRegFoodcheckReportsEventsRes {
        const action: IRegFoodcheckReportsEventsRes = { type: TypeKeys.REG_FOODCHECK_REPORTS_EVENTS_RES, payload };
        return action;
    },

    regDiarycheckReportsEventsRes(payload: {
        key: string;
        storage: string;
    }): IRegDiarycheckReportsEventsRes {
        const action: IRegDiarycheckReportsEventsRes = { type: TypeKeys.REG_DIARYCHECK_REPORTS_EVENTS_RES, payload };
        return action;
    },

    regPrerequisiteReportsEventsRes(payload: {
        key: string;
        storage: string;
    }): IRegPrerequisiteReportsEventsRes {
        const action: IRegPrerequisiteReportsEventsRes = { type: TypeKeys.REG_PREREQUISITE_REPORTS_EVENTS_RES, payload };
        return action;
    },

};//    Actions

// Action Types
type TActionTypes =
    ISetPortionsDate |
    ICalcLunchDessertJoggersRes |
    ICalcLunchDessertJoggersForRangeRes |
    ICalcTeaDessertJoggersRes |
    ICalcTeaDessertJoggersForRangeRes |

    ICalcLunchSideJoggersRes |
    ICalcLunchSideJoggersForRangeRes |
    ICalcTeaSideJoggersRes |
    ICalcTeaSideJoggersForRangeRes |

    ICalcMainJoggersRes |
    ICalcMainJoggersForRangeRes |
    ICalcTeaJoggersRes |
    ICalcTeaJoggersForRangeRes |
    ICalcDietaryStatsRes |
    ICalcMenuItemStatsRes |
    IEditTeaJoggersReq |
    IEditMainJoggersReq |
    IEditLunchDessertJoggersReq |
    IEditTeaDessertJoggersReq |

    IEditLunchSideJoggersReq |
    IEditTeaSideJoggersReq |

    IRegDeliveryReportsEventsRes |
    IRegFoodcheckReportsEventsRes |
    IRegDiarycheckReportsEventsRes |
    IRegPrerequisiteReportsEventsRes;

// Action Reducers
const reducers = {
    setPortionsDate(state: IReportsState, action: ISetPortionsDate): IReportsState {
        const result: IReportsState = {
            ...state,
            portionsDate: action.payload
        };
        return result;
    },

    calcLunchDessertJoggersRes(state: IReportsState, action: ICalcLunchDessertJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            lunchDessertJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcLunchDessertJoggersForRangeRes(state: IReportsState, action: ICalcLunchDessertJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            lunchDessertJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcTeaDessertJoggersRes(state: IReportsState, action: ICalcTeaDessertJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaDessertJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcTeaDessertJoggersForRangeRes(state: IReportsState, action: ICalcTeaDessertJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaDessertJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },


    calcLunchSideJoggersRes(state: IReportsState, action: ICalcLunchSideJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            lunchSideJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcLunchSideJoggersForRangeRes(state: IReportsState, action: ICalcLunchSideJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            lunchSideJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcTeaSideJoggersRes(state: IReportsState, action: ICalcTeaSideJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaSideJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcTeaSideJoggersForRangeRes(state: IReportsState, action: ICalcTeaSideJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaSideJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },


    calcMainJoggersRes(state: IReportsState, action: ICalcMainJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            mainJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcMainJoggersForRangeRes(state: IReportsState, action: ICalcMainJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            mainJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },


    calcTeaJoggersRes(state: IReportsState, action: ICalcTeaJoggersRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaJoggerEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcTeaJoggersForRangeRes(state: IReportsState, action: ICalcTeaJoggersForRangeRes): IReportsState {
        const updatedJoggerEdit: IJoggerEdit = {
            alternativesByRunnerByUid: action.payload.alternativesByRunnerByUid,
            portionsByRunnerByUid: action.payload.portionsByRunnerByUid
        }

        const result: IReportsState = {
            ...state,
            teaJoggerForRangeEdit: updatedJoggerEdit,
        };

        return result;
    },

    calcDietaryStatsForDateRangeRes(state: IReportsState, action: ICalcDietaryStatsRes): IReportsState {
        const result: IReportsState = {
            ...state,
            dietaryUsageStats: action.payload.stats,
        };

        return result;
    },

    calcMenuItemStatsForDateRangeRes(state: IReportsState, action: ICalcMenuItemStatsRes): IReportsState {
        const {
            itemType,
            stats
        } = action.payload;

        switch (itemType) {
            case MenuItemUsageStat.Ingredients: {
                const result: IReportsState = {
                    ...state,
                    allergenUsageStats: stats,
                    selectedMenuItemUsageStats: MenuItemUsageStat.Ingredients,
                };
                return result;
            }

            case MenuItemUsageStat.Desserts: {
                const result: IReportsState = {
                    ...state,
                    dessertsUsageStats: stats,
                    selectedMenuItemUsageStats: MenuItemUsageStat.Desserts,
                };
                return result;
            }

            case MenuItemUsageStat.Lunches: {
                const result: IReportsState = {
                    ...state,
                    lunchesUsageStats: stats,
                    selectedMenuItemUsageStats: MenuItemUsageStat.Lunches,
                };
                return result;
            }

            case MenuItemUsageStat.Sides: {
                const result: IReportsState = {
                    ...state,
                    sidesUsageStats: stats,
                    selectedMenuItemUsageStats: MenuItemUsageStat.Sides,
                };
                return result;
            }

            case MenuItemUsageStat.Teas: {
                const result: IReportsState = {
                    ...state,
                    teasUsageStats: stats,
                    selectedMenuItemUsageStats: MenuItemUsageStat.Teas,
                };
                return result;
            }

            default:
                return state;
        }
    },

    editLunchDessertJoggersReq(state: IReportsState, action: IEditLunchDessertJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editLunchDessertJoggerAlternatives(state, action);
        }

        return editLunchDessertJoggers(state, action);
    },

    editTeaDessertJoggersReq(state: IReportsState, action: IEditTeaDessertJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editTeaDessertJoggerAlternatives(state, action);
        }

        return editTeaDessertJoggers(state, action);
    },

    editLunchSideJoggersReq(state: IReportsState, action: IEditLunchSideJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editLunchSideJoggerAlternatives(state, action);
        }

        return editLunchSideJoggers(state, action);
    },

    editTeaSideJoggersReq(state: IReportsState, action: IEditTeaSideJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editTeaSideJoggerAlternatives(state, action);
        }

        return editTeaSideJoggers(state, action);
    },

    editMainJoggersReq(state: IReportsState, action: IEditMainJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editMainJoggerAlternatives(state, action);
        }

        return editMainJoggers(state, action);
    },

    editTeaJoggersReq(state: IReportsState, action: IEditTeaJoggersReq): IReportsState {
        const { isAlternative } = action.payload;

        if (isAlternative) {
            return editTeaJoggerAlternatives(state, action);
        }

        return editTeaJoggers(state, action);
    },

    appDeliveryReportsEventsRes(state: IReportsState, action: IRegDeliveryReportsEventsRes): IReportsState {

        const result: IReportsState = {
            ...state,
            deliveryReportsStorageMap: {
                ...state.deliveryReportsStorageMap,
                [action.payload.key]: action.payload.storage
            }
        };
        return result;
    },

    appFoodcheckReportsEventsRes(state: IReportsState, action: IRegFoodcheckReportsEventsRes): IReportsState {

        const result: IReportsState = {
            ...state,
            foodcheckReportsStorageMap: {
                ...state.foodcheckReportsStorageMap,
                [action.payload.key]: action.payload.storage
            }
        };
        return result;
    },

    appDiarycheckReportsEventsRes(state: IReportsState, action: IRegDiarycheckReportsEventsRes): IReportsState {

        const result: IReportsState = {
            ...state,
            diarycheckReportsStorageMap: {
                ...state.diarycheckReportsStorageMap,
                [action.payload.key]: action.payload.storage
            }
        };
        return result;
    },

    appPrerequisiteReportsEventsRes(state: IReportsState, action: IRegPrerequisiteReportsEventsRes): IReportsState {

        const result: IReportsState = {
            ...state,
            prerequisiteReportsStorageMap: {
                ...state.prerequisiteReportsStorageMap,
                [action.payload.key]: action.payload.storage
            }
        };
        return result;
    },

};  //  Reducers

const editLunchDessertJoggerAlternatives = (state: IReportsState, action: IEditLunchDessertJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.lunchDessertJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.lunchDessertJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.lunchDessertJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.lunchDessertJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                lunchDessertJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editLunchDessertJoggers = (state: IReportsState, action: IEditLunchDessertJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.lunchDessertJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.lunchDessertJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.lunchDessertJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.lunchDessertJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                lunchDessertJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaDessertJoggers = (state: IReportsState, action: IEditTeaDessertJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.teaDessertJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaDessertJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.teaDessertJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.teaDessertJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                teaDessertJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaDessertJoggerAlternatives = (state: IReportsState, action: IEditTeaDessertJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.teaDessertJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaDessertJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.teaDessertJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.teaDessertJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                teaDessertJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};




const editLunchSideJoggerAlternatives = (state: IReportsState, action: IEditLunchSideJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.lunchSideJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.lunchSideJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.lunchSideJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.lunchSideJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                lunchSideJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editLunchSideJoggers = (state: IReportsState, action: IEditLunchSideJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.lunchSideJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.lunchSideJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.lunchSideJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.lunchSideJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                lunchSideJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaSideJoggers = (state: IReportsState, action: IEditTeaSideJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.teaSideJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaSideJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.teaSideJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.teaSideJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                teaSideJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaSideJoggerAlternatives = (state: IReportsState, action: IEditTeaSideJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.teaSideJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaSideJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.teaSideJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.teaSideJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                teaSideJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};





const editMainJoggerAlternatives = (state: IReportsState, action: IEditMainJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.mainJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.mainJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.mainJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.mainJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                mainJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editMainJoggers = (state: IReportsState, action: IEditMainJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.mainJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.mainJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.mainJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.mainJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                mainJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaJoggerAlternatives = (state: IReportsState, action: IEditTeaJoggersReq): IReportsState => {
    const { color, uid, id, code } = action.payload;
    const toEdit: IAlternativePortionsByRunnerByUid | undefined = state.teaJoggerEdit.alternativesByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaJoggerEdit.alternativesByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuAlternativePortions[] = toEdit.alternativePortionsByUid[uid];
        const portion: IKitchenMenuAlternativePortions | undefined = portions.find((p) => p.id === id && p.code === code);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuAlternativePortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuAlternativePortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedAlternativesByRunnerByUid: IAlternativePortionsByRunnerByUid = {
                ...toEdit,
                alternativePortionsByUid: {
                    ...toEdit.alternativePortionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allAlterns: IAlternativePortionsByRunnerByUid[] = [...state.teaJoggerEdit.alternativesByRunnerByUid];
            allAlterns[updateI] = updatedAlternativesByRunnerByUid;

            const joggerEdit = {
                ...state.teaJoggerEdit,
                alternativesByRunnerByUid: allAlterns,
            };

            const result: IReportsState = {
                ...state,
                teaJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const editTeaJoggers = (state: IReportsState, action: IEditTeaJoggersReq): IReportsState => {
    const { color, uid, id } = action.payload;
    const toEdit: IPortionsByRunnerByUid | undefined = state.teaJoggerEdit.portionsByRunnerByUid.find((i) => i.color === color);
    if (toEdit) {
        const updateI: number = state.teaJoggerEdit.portionsByRunnerByUid.indexOf(toEdit);
        const portions: IKitchenMenuPortions[] = toEdit.portionsByUid[uid];
        const portion: IKitchenMenuPortions | undefined = portions.find((p) => p.id === id);
        if (portion) {
            const k: number = portions.indexOf(portion);
            const updatedPortion: IKitchenMenuPortions = {
                ...portion,
                meals: action.payload.meals,
                total: action.payload.meals,
            };

            const updatedPortions: IKitchenMenuPortions[] = [...portions];
            updatedPortions[k] = updatedPortion;

            const updatedPortionsByRunnerByUid: IPortionsByRunnerByUid = {
                ...toEdit,
                portionsByUid: {
                    ...toEdit.portionsByUid,
                    [action.payload.uid]: updatedPortions
                }

            }

            const allPorts: IPortionsByRunnerByUid[] = [...state.teaJoggerEdit.portionsByRunnerByUid];
            allPorts[updateI] = updatedPortionsByRunnerByUid;

            const joggerEdit = {
                ...state.teaJoggerEdit,
                portionsByRunnerByUid: allPorts,
            };

            const result: IReportsState = {
                ...state,
                teaJoggerEdit: joggerEdit,
            }

            return result;
        }
    }

    return state;
};

const reducer = (state: IReportsState = reportsInitialState(), action: TActionTypes): IReportsState => {
    switch (action.type) {
        case TypeKeys.SET_PORTIONS_DATE:
            return reducers.setPortionsDate(state, action);

        case TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_RES:
            return reducers.calcDietaryStatsForDateRangeRes(state, action);

        case TypeKeys.CALC_MENU_ITEM_STATS_RES:
            return reducers.calcMenuItemStatsForDateRangeRes(state, action);

        case TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_RES:
            return reducers.calcLunchDessertJoggersRes(state, action);

        case TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_RES:
            return reducers.calcLunchDessertJoggersForRangeRes(state, action);

        case TypeKeys.CALC_TEA_DESSERT_JOGGERS_RES:
            return reducers.calcTeaDessertJoggersRes(state, action);

        case TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_RES:
            return reducers.calcTeaDessertJoggersForRangeRes(state, action);


        case TypeKeys.CALC_LUNCH_SIDE_JOGGERS_RES:
            return reducers.calcLunchSideJoggersRes(state, action);

        case TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_RES:
            return reducers.calcLunchSideJoggersForRangeRes(state, action);

        case TypeKeys.CALC_TEA_SIDE_JOGGERS_RES:
            return reducers.calcTeaSideJoggersRes(state, action);

        case TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_RES:
            return reducers.calcTeaSideJoggersForRangeRes(state, action);

            
        case TypeKeys.CALC_MAIN_JOGGERS_RES:
            return reducers.calcMainJoggersRes(state, action);

        case TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_RES:
            return reducers.calcMainJoggersForRangeRes(state, action);

        case TypeKeys.CALC_TEA_JOGGERS_RES:
            return reducers.calcTeaJoggersRes(state, action);

        case TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_RES:
            return reducers.calcTeaJoggersForRangeRes(state, action);

        case TypeKeys.EDIT_LUNCH_DESSERT_JOGGER_REQ:
            return reducers.editLunchDessertJoggersReq(state, action);

        case TypeKeys.EDIT_TEA_DESSERT_JOGGER_REQ:
            return reducers.editTeaDessertJoggersReq(state, action);

        case TypeKeys.EDIT_LUNCH_SIDE_JOGGER_REQ:
            return reducers.editLunchSideJoggersReq(state, action);

        case TypeKeys.EDIT_TEA_SIDE_JOGGER_REQ:
            return reducers.editTeaSideJoggersReq(state, action);

        case TypeKeys.EDIT_MAIN_JOGGER_REQ:
            return reducers.editMainJoggersReq(state, action);

        case TypeKeys.EDIT_TEA_JOGGER_REQ:
            return reducers.editTeaJoggersReq(state, action);

        case TypeKeys.REG_DELIVERY_REPORTS_EVENTS_RES:
            return reducers.appDeliveryReportsEventsRes(state, action);

        case TypeKeys.REG_FOODCHECK_REPORTS_EVENTS_RES:
            return reducers.appFoodcheckReportsEventsRes(state, action);

        case TypeKeys.REG_DIARYCHECK_REPORTS_EVENTS_RES:
            return reducers.appDiarycheckReportsEventsRes(state, action);

        case TypeKeys.REG_PREREQUISITE_REPORTS_EVENTS_RES:
            return reducers.appPrerequisiteReportsEventsRes(state, action);

        default:
            return state;
    }
};

export default reducer;