import {
    db,
} from '../../Storage';

import { IMenuDataByCycleMap, IMenuDataMap, IMenuData, IMenuDataSession } from '../../App/interfaces/IMenuData';

export const MENU_DATA_BY_CYCLE_PATH: string = `data/food/menu_data_by_cycle`;

const addEmptySidesAndDesserts = (map: IMenuDataMap): void => {
    if (map) {
        for (const m in map) {
            if (m && map[m]) {
                const menu: IMenuData = map[m];
                if (menu) {
                    const lunch: IMenuDataSession = menu.lunch;
                    if (lunch) {
                        if (lunch.sides === undefined) {
                            lunch.sides = [];
                        }
                        if (lunch.desserts === undefined) {
                            lunch.desserts = [];
                        }
                    }
                    const tea: IMenuDataSession = menu.tea;
                    if (tea) {
                        if (tea.sides === undefined) {
                            tea.sides = [];
                        }
                        if (tea.desserts === undefined) {
                            tea.desserts = [];
                        }
                    }
                }
            }
        }
    }
};

export const loadMenuDataByCycle = async (): Promise<Readonly<IMenuDataByCycleMap>> => {
    const snapshot = await db.child(MENU_DATA_BY_CYCLE_PATH).once('value');
    if (snapshot) {
        const values = snapshot.val();
        if (values) {
            for (const c in values) {
                if (c && values[c]) {
                    addEmptySidesAndDesserts(values[c]);
                }
            }
            return values;
        }
    }

    return {};
};
