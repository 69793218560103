import {
    call,
    delay,
    fork,
    put,
    PutEffect,
    race,
    select,
    take,
} from 'redux-saga/effects';

import {
    EventChannel,
    eventChannel
} from 'redux-saga';

import { format, getTime, parseFormat, N_DATE_TIME_STORAGE_KEY_FORMAT, startOfDay } from '../../Common/utils/dateFunctions';

import cloneDeep from 'lodash/cloneDeep';

import firebase from 'firebase/compat/app';
import 'firebase/auth';

import {
    actions,
    TypeKeys
} from '../store';

import {
    ISentConfirmationDates,
    IAppState,
} from '../../App/interfaces/IAppState';

import { IDietaryCode } from '../../App/interfaces/IDietaryCodes';

import {
    auth,
    db,
    fs,
} from '../../Storage';

import {
    getDayLunchOrder,
    getDayTeaOrder,
    getSelectedUserUid,
    getLocalState as appLocalState,
    getOrderForUserAndStartDate,
} from '../../App/store/appSelectors';

import {
    getDietaryCode,
    getLocalState as menuLocalState
} from '../../App/store/menuSelectors';
import { IMenuState } from '../../App/interfaces/IMenuState';

import {
    IDayOrder, IOrder
} from '../../App/interfaces/IDayOrder';

import {
    IUser,
} from '../../Auth/interfaces/IUser';

import { IRunner, IRunnerMembers } from '../../App/interfaces/IRunners';

import {
    confirmationAddedChannel,
    confirmationUpdateChannel,
    usersUpdateChannel,
} from '../../App/sagas/channels';

import {
    getOrderKeyForDate,
    nextWeekDayN,
    N_DATE_TIME_STAMP_FORMAT,
    startOfDateAsNumberN
} from '../../Common/utils/dateFunctions';

import { IDietaryCodesOrder } from '../interfaces/IDietaryCodesOrder';
import { IFeatureMap, FeaturesPath } from '../interfaces/IFeature';

import {
    UserNotesPath,
    IUserNotesMap
} from '../interfaces/IUserNotes';


import { loadUserInfo } from '../../Auth/sagas/authSaga';

import { uploadFromCache } from '../../Storage/offlineStore';
import { getState } from '../../Store/store';
import { IState } from '../../Store/state';
import { ISignatureInfo, SignatureEventType, SignatureEventName } from '../../Common/interfaces/ISignatureInfo';
import { SIGNATURES_FOODCHECK_PATH, SIGNATURES_DELIVERY_PATH, SIGNATURES_CHECKS_PATH, SIGNATURES_PREREQUISITE_PATH } from '../../App/utils/saveSignature';
import { isAdmin } from '../../Auth/store';


function* processChannelAction(action: any):  Generator<PutEffect<any>, void, unknown> {
    yield put(action);
}

const removedDeleteDietaries = (codes: IDietaryCodesOrder, state: IMenuState): void => {
    if (codes) {
        for (const code in codes) {
            if (code) {
                const dc: IDietaryCode = getDietaryCode(code, state);
                if (dc === undefined || dc.deleted) {
                    console.log(`removedDeleteDietaries ${code}`);
                    delete codes[code];
                }
            }
        }
    }
};

const fixUndefinedSpecials = (order: IDayOrder) => {
    if (order && order.dessertSpecial === undefined) {
        order.dessertSpecial = {};
    }
    if (order && order.special === undefined) {
        order.special = {};
    }
    return order;
}

const updateAmmendedReason = (order: Readonly<IOrder>) => {
    const theDate = new Date();
    const ammendDate = format(theDate, N_DATE_TIME_STAMP_FORMAT);
    const ammendReason = `EYC Copied orders`;
    order.lunch.ammendReason = ammendReason;
    order.lunch.ammendDate = ammendDate;
    order.tea.ammendReason = ammendReason;
    order.tea.ammendDate = ammendDate;
    return order;
};

const copyWeeksOrders = async (uid: string, from: Date, to: Date, days: number, state: IState): Promise<void> => {
    let date: Date = from;
    let newDate: Date = startOfDay(to);

    for (let day: number = 0; day < days; day++) {
        const order: Readonly<IOrder> | undefined = getOrderForUserAndStartDate(uid, startOfDateAsNumberN(date), state.app);
        if (order) {
            let cloned = cloneDeep(order);
            removedDeleteDietaries(cloned.lunch.special, state.menu);
            removedDeleteDietaries(cloned.tea.special, state.menu);
            removedDeleteDietaries(cloned.lunch.dessertSpecial, state.menu);
            removedDeleteDietaries(cloned.tea.dessertSpecial, state.menu);
            delete cloned.tea.ammendDate;
            delete cloned.tea.ammendReason;
            delete cloned.lunch.ammendDate;
            delete cloned.lunch.ammendReason;

            fixUndefinedSpecials(cloned.tea);
            fixUndefinedSpecials(cloned.lunch);

            if (isAdmin(state)) {
                cloned = updateAmmendedReason(cloned);
            }

            const key: string = getOrderKeyForDate(newDate);
            const orderDate = newDate.valueOf();
            const updated = new Date().valueOf();
            
            const newOrder = {
                lunch: cloned.lunch,
                tea: cloned.tea,
                uid: cloned.uid,
                dateId: key,
                orderDate,
                updated,
            };

            const ref = fs.collection(`orders`).where('dateId', '==', key).where(`uid`, `==`, uid).limit(1);
            const snapshot = await ref.get();
            if (snapshot.empty === true) {
                const id = await fs.collection(`orders`).doc().id;
                await fs.collection(`orders`).doc(id).set(newOrder);
            }
            else {
                const doc = snapshot.docs[0];
                await doc.ref.update(newOrder);
            }

        }
        date = nextWeekDayN(date);
        newDate = nextWeekDayN(newDate);
    }
};

export function* appCopyWeeksOrders() {
    while (true) {
        const { payload } = yield take(TypeKeys.COPY_WEEK_ORDERS_REQ);
        const state: IState = yield select(getState);
        const fromDate: Date = payload.fromDate;
        const toDate: Date = payload.toDate;
        const days: number = payload.days;
        const uid: string | undefined = getSelectedUserUid(state.app);
        if (uid !== undefined) {
            try {
                yield call(copyWeeksOrders, uid, fromDate, toDate, days, state);
                yield put(actions.copyWeeksOrdersRes());
            } catch (error) {
                //  Failure
                yield put(actions.appErr(error));
            }
        }
        else {
            yield put(actions.appErr(new Error(`No user selected`)));
        }
    }
};

const writeFirestoreOrder = async (uid: string, startDate: Date, lunchOrder: IDayOrder, teaOrder: IDayOrder): Promise<void> => {
    const key: string = getOrderKeyForDate(startDate);

    fixUndefinedSpecials(lunchOrder);
    fixUndefinedSpecials(teaOrder);

    const dayOrder: any = {
        lunch: lunchOrder,
        tea: teaOrder
    };
    
    if (teaOrder.ammendDate === undefined) {
        dayOrder.tea.ammendDate = null;
        dayOrder.tea.ammendReason = null;
    }
    
    if (lunchOrder.ammendDate === undefined) {
        dayOrder.lunch.ammendDate = null;
        dayOrder.lunch.ammendReason = null;
    }

    const orderDate = startDate.valueOf();
    const updated = new Date().valueOf();

    const order = {
        lunch: dayOrder.lunch,
        tea: dayOrder.tea,
        uid,
        dateId: key,
        orderDate,
        updated,
    }

    const ref = fs.collection(`orders`).where('dateId', '==', key).where(`uid`, `==`, uid).limit(1);
    const snapshot = await ref.get();
    if (snapshot.empty === true) {
        const id = await fs.collection(`orders`).doc().id;
        await fs.collection(`orders`).doc(id).set(order);
    }
    else {
        const doc = snapshot.docs[0];
        await doc.ref.update(order);
    }
};

const updateDessertIfNew = (uid: string, startDate: number, dayOrderLunch: IDayOrder, dayOrderTea: IDayOrder, state: IAppState): void => {
    const existingOrder: boolean = getOrderForUserAndStartDate(uid, startDate, state) !== undefined;

    if (!existingOrder) {
        dayOrderLunch.dessert = dayOrderLunch.normalMeals;
        dayOrderLunch.dessertSpecial = dayOrderLunch.special;
        dayOrderTea.dessert = dayOrderTea.normalMeals;
        dayOrderTea.dessertSpecial = dayOrderTea.special;
    }
};

export function* appSaveAmendmentOrderForDay() {
    while (true) {
        const { payload } = yield take(TypeKeys.SAVE_AMMEND_ORDER_FOR_DAY_REQ);
        const startDate: Date = payload.startDate;
        const state: IAppState = yield select(appLocalState);
        const uid: string | undefined = getSelectedUserUid(state);
        if (uid !== undefined) {
            const theDate: Date = new Date();
            const lunch: IDayOrder = {
                ...getDayLunchOrder(state),
                ammendDate: format(theDate, N_DATE_TIME_STAMP_FORMAT)
            };
            const tea: IDayOrder = {
                ...getDayTeaOrder(state),
                ammendDate: format(theDate, N_DATE_TIME_STAMP_FORMAT)
            };

            updateDessertIfNew(uid, startOfDateAsNumberN(startDate), lunch, tea, state);

            try {
                yield call(writeFirestoreOrder, uid, startDate, lunch, tea);
                yield put(actions.updateCurrentDayOrdersAndTimeStamp({ uid, startDate, dayOrderLunch: lunch, dayOrderTea: tea }));
            } catch (error) {
                //  Failure
                yield put(actions.appErr(error));
            }
        }
        else {
            yield put(actions.appErr(new Error(`No user selected`)));
        }
    }
}

export function* appSaveOrderForDay() {
    while (true) {
        const { payload } = yield take(TypeKeys.SAVE_ORDER_FOR_DAY_REQ);
        const state: IAppState = yield select(appLocalState);
        const startDate: Date = payload.startDate;
        const uid: string | undefined = getSelectedUserUid(state);
        if (uid !== undefined) {
            const lunch: IDayOrder = getDayLunchOrder(state);
            const tea: IDayOrder = getDayTeaOrder(state);

            lunch.dessert = lunch.normalMeals;
            lunch.dessertSpecial = lunch.special;
            tea.dessert = tea.normalMeals;
            tea.dessertSpecial = tea.special;

            try {
                yield call(writeFirestoreOrder, uid, startDate, lunch, tea);
                yield put(actions.updateCurrentDayOrdersAndTimeStamp({ uid, startDate, dayOrderLunch: lunch, dayOrderTea: tea }));
            } catch (error) {
                //  Failure
                yield put(actions.appErr(error));
            }
        }
        else {
            yield put(actions.appErr(new Error(`No user selected`)));
        }
    }
}

const loadAllUsers = (): Promise<ReadonlyArray<IUser>> => {
    return new Promise((resolve, reject) => {
        db.child('users').once('value')
            .then((snapshot) => {
                const users = snapshot.val();
                const result: IUser[] = [];
                for (const uid in users) {
                    if (uid && users[uid]) {
                        const user: IUser = {
                            accr: users[uid].accr,
                            active: users[uid].active,
                            admin: users[uid].admin,
                            email: users[uid].email,
                            lunchCostPence: users[uid].lunchCostPence,
                            name: users[uid].name,
                            orderByOn: users[uid].orderByOn,
                            orgs: users[uid].orgs,
                            teaCostPence: users[uid].teaCostPence,
                            uid,
                            userName: users[uid].userName,
                            userType: users[uid].userType
                        };
                        result.push(user);
                    }
                }
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            })
    });
}

export function* appLoadAllUsers() {
    while (true) {
        yield take(TypeKeys.LOAD_ALL_USERS_REQ);

        try {
            const users: ReadonlyArray<IUser> = yield call(loadAllUsers);
            yield put(actions.allUsersRes(users));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

export const getUserConsent = (uid: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        db.child(`consent/${uid}`).once('value')
            .then((snapshot) => {
                const result = snapshot.val();
                if (result) {
                    resolve(result);
                }
                else {
                    resolve(false);
                }
            })
            .catch((error) => {
                reject(error);
            })
    });
};

export function* appLoadUser() {
    while (true) {
        yield take(TypeKeys.LOAD_USER_REQ);

        try {
            const userInfo: firebase.User | null = auth.getLoggedInUser();
            if (userInfo !== null) {
                const user: Readonly<IUser> = yield call(loadUserInfo, userInfo.uid);
                yield put(actions.loadUserRes(user));
            }
            else {
                yield put(actions.appErr(new Error(`No user logged in`)));
            }
        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}



const FLASH_MESSAGE_INTERVAL_MS: number = 1200;

export function* appToggleUserOrderUpdateEvent() {
    while (true) {
        try {
            yield race({
                updated: take(TypeKeys.REG_ALL_ORDERS_RES)
            });

            const menuState: IMenuState = yield select(menuLocalState);
            if (menuState.menuLoaded) {
                yield put(actions.toggleUserOrderUpdateEvent(true));
                yield delay(FLASH_MESSAGE_INTERVAL_MS);
                yield put(actions.toggleUserOrderUpdateEvent(false));
                yield delay(FLASH_MESSAGE_INTERVAL_MS);
                yield put(actions.toggleUserOrderUpdateEvent(true));
                yield delay(FLASH_MESSAGE_INTERVAL_MS);
                yield put(actions.toggleUserOrderUpdateEvent(false));
                yield delay(FLASH_MESSAGE_INTERVAL_MS);
                yield put(actions.toggleUserOrderUpdateEvent(true));
                yield delay(FLASH_MESSAGE_INTERVAL_MS);
                yield put(actions.toggleUserOrderUpdateEvent(false));
            }
        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

export const loadAllRunners = async (): Promise<ReadonlyArray<IRunner>> => {
    const snapshot: firebase.database.DataSnapshot = await db.child('runners').once('value');
    const values = snapshot.val();
    const result: IRunner[] = [];
    for (const color in values) {
        if (color) {
            const members: IRunnerMembers = values[color].members !== undefined ? values[color].members : {};
            const runner: IRunner = {
                active: values[color].active,
                color,
                members,
                order: values[color].order
            }
            result.push(runner);
        }
    }
    return result;
};

export function* appLoadAllRunners() {
    while (true) {
        yield take(TypeKeys.LOAD_ALL_RUNNERS_REQ);

        try {
            const runners: ReadonlyArray<IRunner> = yield call(loadAllRunners);
            yield put(actions.runnersRes(runners));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

/// Create a user added events
export function* appUsersUpdatedSaga() {
    while (true) {
        yield take(TypeKeys.REG_ALL_USERS_UPDATE_REQ);

        const channel: EventChannel<unknown> = yield call(usersUpdateChannel);

        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_ALL_USERS_UPDATE_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
        finally {
            // tslint:disable-next-line:no-console
            // console.log(`appUsersUpdatedSaga channel finished`);
        }
    }
}


const loadAllConfirmations = async (): Promise<Readonly<ISentConfirmationDates>> => {
    const snapshot: firebase.database.DataSnapshot = await db.child('confirmations').once('value');
    const value = snapshot.val();
    if (value) {
        return value;
    }
    return {};
};

export function* appLoadAllConfirmations() {
    while (true) {
        yield take(TypeKeys.LOAD_CONFIRMATION_REQ);

        try {
            const confirmations: Readonly<ISentConfirmationDates> = yield call(loadAllConfirmations);
            yield put(actions.appConfirmationRes(confirmations));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

export function* appConfirmationsUpdatedSaga() {
    while (true) {
        yield take(TypeKeys.REG_CONFIRMATION_EVENTS_REQ);

        const channel: EventChannel<unknown> = yield call(confirmationUpdateChannel);

        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_CONFIRMATION_EVENTS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
        finally {
            // tslint:disable-next-line:no-console
            // console.log(`appConfirmationsUpdatedSaga channel finished`);
        }
    }
}

export function* appConfirmationsAddedSaga() {
    while (true) {//    share update request
        yield take(TypeKeys.REG_CONFIRMATION_EVENTS_REQ);

        const channel: EventChannel<unknown> = yield call(confirmationAddedChannel);

        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_CONFIRMATION_EVENTS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
        finally {
            // tslint:disable-next-line:no-console
            // console.log(`appConfirmationsUpdatedSaga channel finished`);
        }
    }
}

const loadFeatures = async (): Promise<Readonly<IFeatureMap>> => {
    const snapshot = await db.child(FeaturesPath).once('value');
    const data: IFeatureMap = snapshot.val();
    return data;
};

export function* appLoadFeatures() {
    while (true) {
        yield take(TypeKeys.FEATURES_REQ);
        try {
            const features: Readonly<IFeatureMap> = yield call(loadFeatures);
            yield put(actions.featuresRes(features));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

const loadUserNotes = async (): Promise<Readonly<IUserNotesMap>> => {
    const snapshot = await db.child(UserNotesPath).once('value');
    const data: IUserNotesMap = snapshot.val();
    return data;
};

export function* appLoadUserNotes() {
    while (true) {
        yield take(TypeKeys.USER_NOTES_REQ);
        try {
            const userNotes:Readonly<IUserNotesMap> = yield call(loadUserNotes);
            yield put(actions.userNotesRes(userNotes));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}


export function* appUploadCachedConfirmations() {
    while (true) {
        yield take(TypeKeys.SET_LOGGED_IN_USER);
        try {
            yield call(uploadFromCache);
        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}

export function signaturesChannel(refId: string, eventName: SignatureEventName) {
    const ref = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    const signatures: ISignatureInfo[] = [];
                    const dates = event.val();
                    for (const date in dates) {
                        const info = dates[date];
                        const signature: ISignatureInfo = {
                            url: info.url,
                            uid: event.key,
                            dateTime: date,
                            dateValue: getTime(parseFormat(date, N_DATE_TIME_STORAGE_KEY_FORMAT)),
                            data: info.data,
                        }

                        signatures.push(signature);
                    }
                    const eventType: SignatureEventType = `update`;
                    emit(
                        {
                            payload: {
                                key: event.key,
                                eventName,
                                eventType,
                                signatures: signatures.sort((a: ISignatureInfo, b: ISignatureInfo) => a.dateValue - b.dateValue),
                            },
                            type: TypeKeys.REG_SIGNATURE_EVENTS_RES,
                        }
                    );
                }
            }
        });

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    const signatures: ISignatureInfo[] = [];
                    const dates = event.val();
                    for (const date in dates) {
                        const info = dates[date];
                        const signature: ISignatureInfo = {
                            url: info.url,
                            uid: event.key,
                            dateTime: date,
                            dateValue: getTime(parseFormat(date, N_DATE_TIME_STORAGE_KEY_FORMAT)),
                            data: info.data,
                        }

                        signatures.push(signature);
                    }
                    const eventType: SignatureEventType = `added`;
                    emit(
                        {
                            payload: {
                                key: event.key,
                                eventName,
                                eventType,
                                signatures: signatures.sort((a: ISignatureInfo, b: ISignatureInfo) => a.dateValue - b.dateValue),
                            },
                            type: TypeKeys.REG_SIGNATURE_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

export function* appSignaturesSaga() {
    while (true) {

        yield take(TypeKeys.REG_SIGNATURE_EVENTS_REQ);

        const foodcheck: EventChannel<unknown> = yield call(signaturesChannel, SIGNATURES_FOODCHECK_PATH, `foodcheck`);
        const delivery: EventChannel<unknown> = yield call(signaturesChannel, SIGNATURES_DELIVERY_PATH, `deliverycheck`);
        const diary: EventChannel<unknown> = yield call(signaturesChannel, SIGNATURES_CHECKS_PATH, `diarycheck`);
        const prerequisite: EventChannel<unknown> = yield call(signaturesChannel, SIGNATURES_PREREQUISITE_PATH, `prerequisitecheck`);

        try {
            while (true) {
                const { cancel, actionFood, actionDelivery, actionDiary, actionPrerequisites } = yield race({
                    actionFood: take(foodcheck),
                    actionDelivery: take(delivery),
                    actionDiary: take(diary),
                    actionPrerequisites: take(prerequisite),
                    cancel: take(TypeKeys.UREG_SIGNATURE_EVENTS_REQ)
                });
                if (cancel) {
                    foodcheck.close();
                    delivery.close();
                    diary.close();
                    break;
                }
                if (actionFood) {
                    yield fork(processChannelAction, actionFood);
                }
                else if (actionDelivery) {
                    yield fork(processChannelAction, actionDelivery);
                }
                else if (actionDiary) {
                    yield fork(processChannelAction, actionDiary);
                }
                else if (actionPrerequisites) {
                    yield fork(processChannelAction, actionPrerequisites);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
    }
}

;