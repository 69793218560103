import { IMenuItemIdToIsJoggerGramsMap } from "../../App/interfaces/IAllMenuData";
import {
    IKitchenMenuPortions,
    IPortionsByRunnerByUid,
    IPortionsByUid,
    IAlternativePortionsByRunnerByUid,
    IKitchenMenuAlternativePortions,
    IAlternativePortionsByUid,
    IPortionsWithAlternatives
} from "../../App/interfaces/IKitchenMenuPortions";

import {
    IPortionRange,
} from "../../App/interfaces/IPortionRules";

export type ruleIdForItem = (id: string) => string | undefined;
export type portionRangesForRuleId = (id: string) => IPortionRange[];
export type portionRangeForId = (id: string) => Readonly<IPortionRange> | undefined;
export type joggerPortionGramsForId = (id: string) => boolean;

export const getPortionsByRunnerByUid = (alwaysAdd: boolean, runnerColors: ReadonlyArray<string>, portions: IPortionsWithAlternatives[]): IPortionsByRunnerByUid[] => {
    const allPortions: IKitchenMenuPortions[] = [];
    for (const dp of portions) {
        allPortions.push(...dp.portions);
    }

    const portionsByRunnerByUid: IPortionsByRunnerByUid[] = [];
    for (const color of runnerColors) {

        const byColor: IKitchenMenuPortions[] = allPortions.filter((s) => s.runner === color);
        const byUid: IPortionsByUid = {};
        for (const p of byColor) {
            if (byUid[p.uid]) {
                byUid[p.uid].push(p);
            }
            else {
                byUid[p.uid] = [p];
            }
        }

        const add: boolean = alwaysAdd || Object.keys(byUid).length > 0;

        if (add) {
            portionsByRunnerByUid.push({
                color,
                portionsByUid: byUid,
            });
        }
    }

    return portionsByRunnerByUid;
};

export const getAltPortionsByRunnerByUid = (alwaysAdd: boolean, runnerColors: ReadonlyArray<string>, portions: IPortionsWithAlternatives[]): IAlternativePortionsByRunnerByUid[] => {
    const allPortions: IKitchenMenuAlternativePortions[] = [];
    for (const dp of portions) {
        allPortions.push(...dp.alternativePortions);
    }

    const portionsByRunnerByUid: IAlternativePortionsByRunnerByUid[] = [];

    for (const color of runnerColors) {

        const byColor: IKitchenMenuAlternativePortions[] = allPortions.filter((s) => s.runner === color);
        const byUid: IAlternativePortionsByUid = {};
        for (const p of byColor) {
            if (byUid[p.uid]) {
                byUid[p.uid].push(p);
            }
            else {
                byUid[p.uid] = [p];
            }
        }

        const add: boolean = alwaysAdd || Object.keys(byUid).length > 0;

        if (add) {
            portionsByRunnerByUid.push({
                alternativePortionsByUid: byUid,
                color,
            });
        }
    }

    return portionsByRunnerByUid;
};

export const getPortionDisplayValuesAsString = (values: IPortionDisplayValues, isJoggerPortionGramsForId: Readonly<IMenuItemIdToIsJoggerGramsMap>): string => {
    if (values.portions.length) {
        return `${values.meals} * ${values.item} = ${values.portions.join(' + ')}`;
    }
    const isGramms: boolean = isJoggerPortionGramsForId[values.id];
    if (isGramms) {
        return `${(values.meals/1000).toFixed(3)}kg ${values.item}`
    }
    return `${values.meals} * ${values.item}`;
};

export const getColorDisplayValuesAsString = (values: IPortionDisplayValues): string => {
    return `${values.item}`;
};

export interface IPortionDisplayValues {
    id: string;
    uid: string;
    code: string;
    item: string;
    meals: number;
    portions: string[];
}

export interface IItemToRanges {
    [itemId: string]: string[];
}

export interface IItemToCounts {
    [itemId: string]: number;
}

export interface IRangesAndCounts {
    ranges: IItemToRanges;
    counts: IItemToCounts;
}
