import { 
    IMenuExtra ,
    IMenuExtraMap, 
} from '../../App/interfaces/IMenuExtra';

import { IMenuMeal } from '../../App/interfaces/IMenuMeal';

import { 
    IMenuAllergen, 
    IMenuAllergenMap 
} from '../../App/interfaces/IMenuAllergen';

import { IMealAllergens } from '../../App/interfaces/IMeal';

export const getAllergensForItem = (item: IMenuExtra, menuAllergens: Readonly<IMenuAllergenMap>, result: IMealAllergens) : void => {
    const allergens: string[] = [];
    for (const alg of item.allergens) {
        if (alg) {
            const menuAllergen: IMenuAllergen | undefined = menuAllergens[alg];
            if (menuAllergen) {
                allergens.push(menuAllergen.item);
            }
        }
    }

    if (allergens.length !== 0) {
        result[item.item] = allergens
    }
}

export const getAllergensFromMenu = (
    normal: IMenuMeal | undefined, 
    sides: string[] | undefined,
    desserts: string[] | undefined,
    menuSides: Readonly<IMenuExtraMap>, 
    menuDesserts: Readonly<IMenuExtraMap>, 
    menuAllergens: Readonly<IMenuAllergenMap>): IMealAllergens => {
    
    const result: IMealAllergens = {};
    if (normal) {
        getAllergensForItem(normal, menuAllergens, result);
    }

    if (sides) {
        for (const s of sides) {
            const item: IMenuExtra = menuSides[s];
            getAllergensForItem(item, menuAllergens, result);
        }
    }
    
    if (desserts) {
        for (const d of desserts) {
            const item: IMenuExtra = menuDesserts[d];
            getAllergensForItem(item, menuAllergens, result);
        }
    }
    
    return result;
}