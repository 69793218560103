export interface IRunnerMembers { // uid: true
    [key: string]: boolean;
}

export interface IRunner {
    active: boolean;
    color: string;
    members: IRunnerMembers;
    order: number;
}

export interface IRunners { 
    [key: string]: IRunner;
}

export interface IUidToRunnerColor { 
    [key: string]: string;
}

export interface IRunnerColorToUid { 
    [key: string]: string;
}
export const RUNNER_NONE = 'none';
export const isRunnerNone = (color: string): boolean => color === RUNNER_NONE;