import { toDate } from '../../Common/utils/dateFunctions';

import {
    getAllUserUids,
    getOrders,
    getUidRunner,
} from './appSelectors';

import { getTotalCount } from './utils';

import { startOfDateAsNumberN, endOfDateAsNumberN } from '../../Common/utils/dateFunctions';
import { IUidToRunnerColor, RUNNER_NONE } from '../../App/interfaces/IRunners';
import { IState } from '../../Store/state';
import { IDeletedCodesMap } from '../../App/interfaces/IDietaryCodes';
import { getDeletedDietary } from './menuSelectors';
import { IOrderMap } from '../interfaces/IDayOrder';
export const filterOrders = (fromDate: number, toDate: number, allUserOrders: IOrderMap, deletedDietary: Readonly<IDeletedCodesMap>, uids: ReadonlyArray<string>) => {
    const filtered: IOrderMap = {};
    for (const o in allUserOrders) {
        const order = allUserOrders[o];
        if (uids.includes(order.uid)) {
            if (order.orderDate >= fromDate && order.orderDate <= toDate) {
                if (getTotalCount(order.lunch, deletedDietary) > 0 || getTotalCount(order.tea, deletedDietary) > 0) {
                    filtered[o] = order;
                }
            }
        }
    }
    return filtered;
};

export const loadAllOrdersForDateRange = (from: number, to: number, state: IState): Readonly<IOrderMap> => {
    const fromStartOfDay: number = startOfDateAsNumberN(toDate(from));
    const toEndOfDay: number = endOfDateAsNumberN(toDate(to));
    const deletedDietary = getDeletedDietary(state.menu);
    const uids: ReadonlyArray<string> = getAllUserUids(state.app);
    const filtered: IOrderMap = filterOrders(fromStartOfDay, toEndOfDay, getOrders(state.app), deletedDietary, uids);
    return filtered;
};

export const loadAllRunnersWithOrdersForDateRange = (from: number, to: number, state: IState): ReadonlyArray<string> => {
    const fromStartOfDay: number = startOfDateAsNumberN(toDate(from));
    const toEndOfDay: number = endOfDateAsNumberN(toDate(to));
    const orders: Readonly<IOrderMap> = getOrders(state.app);
    const uids: ReadonlyArray<string> = getAllUserUids(state.app);
    const filtered = filterOrders(fromStartOfDay, toEndOfDay, orders, getDeletedDietary(state.menu), uids);
    const runnersToUids: IUidToRunnerColor = getUidRunner(state.app);
    const result: { [uid: string]: string } = {};
    for (const k in filtered) {
        const o = filtered[k];
        if (runnersToUids[o.uid] !== undefined && runnersToUids[o.uid] !== RUNNER_NONE) {
            result[runnersToUids[o.uid]] = runnersToUids[o.uid];
        }
    }
    return Object.keys(result);
};

