import { 
    IDayOrder,
} from '../../App/interfaces/IDayOrder';

import { 
    IDietaryCodesOrder 
} from '../../App/interfaces/IDietaryCodesOrder';

import { IUser } from '../../Auth/interfaces/IUser';

import { IDeletedCodesMap } from '../../App/interfaces/IDietaryCodes';

export const compareUserName = (a: IUser, b: IUser): number => {
    return a.name.localeCompare(b.name);
};


export const hasSpecials = (dayOrder: Readonly<IDayOrder>): boolean => {
    if (dayOrder.special && Object.keys(dayOrder.special).length > 0) {
        return true;
    }
    if (dayOrder.dessertSpecial && Object.keys(dayOrder.dessertSpecial).length > 0) {
        return true;
    }
    return false;
}


export const getTotalSpecialCount = (dayOrder: Readonly<IDayOrder>, deletedDietary: Readonly<IDeletedCodesMap>) : number => {
    let specialCount: number = 0;
    if (hasSpecials(dayOrder)) {
        const special: IDietaryCodesOrder = dayOrder.special;
        for (const key in special) {
            const deleted = deletedDietary[key] !== undefined ? true : false;
            if (key && deleted === false) {
                specialCount = specialCount + special[key];
            }
        }
    }

    return specialCount;
};

export const getSpecialCount = (dayOrder: Readonly<IDayOrder>) : string[] => {
    const result: string[] = [];
    if (hasSpecials(dayOrder)) {
        const special: IDietaryCodesOrder = dayOrder.special;
        for (const key in special) {
            if (key) {
                result.push(`${key}:${special[key]}`);
            }
        }
    }

    return result;
};

export const getTotalSpecialDessertCount = (dayOrder: Readonly<IDayOrder>, deletedDietary: Readonly<IDeletedCodesMap>) : number => {
    let specialCount: number = 0;
    if (hasSpecials(dayOrder)) {
        const special: IDietaryCodesOrder = dayOrder.dessertSpecial;
        for (const key in special) {
            const deleted = deletedDietary[key] !== undefined ? true : false;
            if (key && deleted === false) {
                specialCount = specialCount + special[key];
            }
        }
    }

    return specialCount;
};

export const getTotalCount = (dayOrder: Readonly<IDayOrder>, deletedDietary: Readonly<IDeletedCodesMap>) : number => {
    const specialCount: number = getTotalSpecialCount(dayOrder, deletedDietary);
    return specialCount + dayOrder.normalMeals;
};


// export const addSpecials = (s1: IDietaryCodesOrder | undefined, s2: IDietaryCodesOrder | undefined ): IDietaryCodesOrder => {
//     let result: IDietaryCodesOrder = {};

//     if (s1) {
//         result = {...s1};
//     }
//     if (s2) {
//         for (const code in s2) {
//             if (code) {
//                 let total: number = s2[code];
//                 const resCode: number | undefined = result[code];
//                 if (resCode) {
//                     total = total + resCode;
//                 }
//                 result[code] = total;
//             }
//         }
//     }

//     return result;
// };

// export const sumNewOrder = (o1: Readonly<IOrder>, o2: Readonly<IOrder>) : Readonly<IOrder> => {
//     const result: IOrder = {
//         lunch: {...defaultDayOrder},
//         tea: {...defaultDayOrder},
//         dateId: o1.dateId,
//         uid: o1.uid,
//         id: o1.id,
//         updated: o1.updated,
//         orderDate: o1.orderDate
//     };

//     result.lunch.normalMeals =  o1.lunch.normalMeals + o2.lunch.normalMeals;
//     result.lunch.special = addSpecials(o1.lunch.special, o2.lunch.special);
//     result.lunch.dessert =  o1.lunch.dessert + o2.lunch.dessert;
//     result.lunch.dessertSpecial = addSpecials(o1.lunch.dessertSpecial, o2.lunch.dessertSpecial);
    
//     result.tea.normalMeals =  o1.tea.normalMeals + o2.tea.normalMeals;
//     result.tea.special = addSpecials(o1.tea.special, o2.tea.special);
//     result.tea.dessert =  o1.tea.dessert + o2.tea.dessert;
//     result.tea.dessertSpecial = addSpecials(o1.tea.dessertSpecial, o2.tea.dessertSpecial);

//     return result;
// };
