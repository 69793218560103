import 'firebase/auth';

import { EventChannel, eventChannel } from 'redux-saga';

import { 
    call, 
    put,
    race, 
    take
} from 'redux-saga/effects';

import {
    actions,
    TypeKeys
} from '../store';

import {
   db 
} from '../../Storage';

import { IUser } from '../interfaces/IUser';

export const loadUserInfo = (uid: string): Promise<Readonly<IUser>> => {
    return new Promise((resolve, reject) => {
        db.child(`users/${uid}`).once('value')
        .then( (snapshot) => {
            const user = snapshot.val();
            if (user) {
                const result: IUser = {
                    accr: user.accr,
                    active: user.active,
                    admin: user.admin,
                    email: user.email,
                    lunchCostPence: user.lunchCostPence,
                    name: user.name,
                    orderByOn: user.orderByOn,
                    orgs: user.orgs,
                    teaCostPence: user.teaCostPence,
                    uid: snapshot.key as string,
                    userName: user.userName,
                    userType: user.userType
                }
                resolve(result);
            }
            else {
                reject(new Error(`Load User: No user for with UID ${uid}`));    
            }
        })
        .catch ( (error) => {
            reject(error);
        })
    });
};

export function firebaseConnectedChannel() {
    return eventChannel(emit => {
        db.child('.info/connected').on('value', snap => {
            if (snap) {
                if (snap.val() === true) {
                    // tslint:disable-next-line:no-console
                    // console.log('connected');
                    emit(
                        { 
                            payload: true,
                            type: TypeKeys.SET_CONNECTION_STATE, 
                        }
                    );
               }
                else {
                    // tslint:disable-next-line:no-console
                    // console.log('disconnected');
                    emit(
                        { 
                            payload: false,
                            type: TypeKeys.SET_CONNECTION_STATE, 
                        }
                    );
                }
            }
        });
        
        const unsubscribe = () => {
            // tslint:disable-next-line:no-console
            // console.log(`firebaseConnectedChannel unsubscribe`);
            db.child('.info/connected').off('value');
        }
        return unsubscribe;
    });
}

export function*   authConnectionStateSaga(){
    while (true) {
        yield take(TypeKeys.REG_CONNECTION_STATE);
        const channel: EventChannel<unknown> =   yield call(firebaseConnectedChannel);
        while (true) {
            try {
                const { cancel, action } = yield race ({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_CONNECTION_STATE)
                });
    
                if (cancel){
                    channel.close();
                    break;
                }
                else if (action){
                    yield put(action);
                }
            }
            catch (error){
                yield put(actions.authErr(error))
            }
        }
    }
}