export interface IFirebaseConfigKeys {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    messagingSenderId: string;
    projectId: string;
    storageBucket: string;
    appId: string;
    measurementId:string;
}

export const config: IFirebaseConfigKeys = {
	apiKey: "AIzaSyBE-HIPPQphPHwbXlrdVpQpJWww4prCx7g",
  authDomain: "eycp-43857.firebaseapp.com",
  databaseURL: "https://eycp-43857-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eycp-43857",
  storageBucket: "eycp-43857.appspot.com",
  messagingSenderId: "671688063970",
  appId: "1:671688063970:web:8034204df969c457d6d7bb",
  measurementId: "G-EXNY22SL03"
}

//  https://firebase.googleblog.com/2016/07/deploy-to-multiple-environments-with.html
//  firebase use development
