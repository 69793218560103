import {
    db,
} from '../../Storage';

import {
    IMenuCycleMap,
    IMenuCycleDefinition,
    IAllMenuCycleMap,
    IMenuCycleDefinitionMap,
    IMenuCycle,
    CurrentCyclePastMenus
} from '../../App/interfaces/IMenuCycle';
import { N_DATE_FORMAT, N_MENU_DATE_STR_FORMAT, getMondayOrNextIfWeekendN, parseFormat, startOfDateAsNumberN } from '../../Common/utils/dateFunctions';
import { format, addDays, addWeeks, isBefore } from '../../Common/utils/dateFunctions';

export const CURRENT_MENU_CYCLE_PATH: string = `data/food/current_menu_cycle`;
export const MENU_CYCLES_PATH: string = `data/food/menu_cycles`;

export const getCurrentCycle = async (): Promise<string> => {
    const snapshot = await db.child(`${CURRENT_MENU_CYCLE_PATH}/id`).once('value');
    const cycle: string = snapshot.val();
    return cycle;
};

export const loadCycleMap = async (): Promise<Readonly<IMenuCycleDefinitionMap>> => {
    const snapshot = await db.child(MENU_CYCLES_PATH).once('value');
    const cycleMap: IMenuCycleDefinitionMap = snapshot.val();
    return cycleMap;
};

export const loadAllMenuCycles = async (): Promise<Readonly<IAllMenuCycleMap>> => {
    const cycleMap: IMenuCycleDefinitionMap = await loadCycleMap();
    const currentCycle: string = await getCurrentCycle();

    const WEEKS_IN_CYCLE: number = 4;
    const DAYS_IN_WEEK: number = 5;
    const MAX_MENUS: number = 20;    //   20 menus, 5 per week, 4 weeks per cycle
    const result: IAllMenuCycleMap = {};

    const monday: Date = getMondayOrNextIfWeekendN();
    let gotCurrentCycleOffset: boolean = false;
    let currentCycleOffset: number = 0;
    let offset: number = 0;
    for (const k in cycleMap) {
        if (k) {
            const cycles: IMenuCycleMap = {};
            const pastMenus: IMenuCycleMap = {};
            const v: IMenuCycleDefinition = cycleMap[k];
            const CYCLES: number = 6;
            let reachedEndDate: boolean = false;
            let curMenuId: number = 0;
            for (let c: number = 0; c < CYCLES && !reachedEndDate; c++) {
                for (let w: number = 0; w < WEEKS_IN_CYCLE && !reachedEndDate; w++) {
                    for (let d: number = 0; d < DAYS_IN_WEEK && !reachedEndDate; d++) {
                        let date: Date = parseFormat(v.startDate, N_DATE_FORMAT);
                        const weeks: number = (c * 4) + w;
                        date = addWeeks(addDays(date, d), weeks);
                        const dateFmt: string = format(date, N_DATE_FORMAT);
                        if (k === currentCycle) {
                            if (isBefore(date, monday) === false) {
                                if (!gotCurrentCycleOffset) {
                                    gotCurrentCycleOffset = true;
                                    currentCycleOffset = offset % MAX_MENUS;
                                }
                                const cycle: IMenuCycle = {
                                    cycleId: v.id,
                                    date: dateFmt,
                                    dateStr: format(date, N_MENU_DATE_STR_FORMAT),
                                    menuId: curMenuId,
                                    dateVal: startOfDateAsNumberN(date),
                                    offset: currentCycleOffset,
                                }
                                cycles[cycle.date] = cycle;
                            }
                            else {
                                const cycle: IMenuCycle = {
                                    cycleId: v.id,
                                    date: dateFmt,
                                    dateStr: format(date, N_MENU_DATE_STR_FORMAT),
                                    menuId: curMenuId,
                                    dateVal: startOfDateAsNumberN(date),
                                    offset: 0,
                                }
                                pastMenus[cycle.date] = cycle;
                            }  
                            ++offset;
                        }
                        else {
                            const cycle: IMenuCycle = {
                                cycleId: v.id,
                                date: dateFmt,
                                dateStr: format(date, N_MENU_DATE_STR_FORMAT),
                                menuId: curMenuId,
                                dateVal: startOfDateAsNumberN(date),
                                offset: 0,
                            }
                            cycles[cycle.date] = cycle;
                        }
                        curMenuId = curMenuId + 1;
                        if (curMenuId >= MAX_MENUS) {
                            curMenuId = 0;
                        }
                        if (v.endDate === dateFmt) {
                            reachedEndDate = true;
                        }
                    }
                }
            }
            result[v.id] = cycles;
            if (Object.keys(pastMenus).length > 0) {
                result[CurrentCyclePastMenus] = pastMenus;
            }
        }
    }
    return result;
};
