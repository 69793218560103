export const mergeToUniqueArray = (arr1: ReadonlyArray<string|number>, arr2: ReadonlyArray<string|number>): ReadonlyArray<string|number> => {
    const array: ReadonlyArray<string|number> = [...arr1, ...arr2].filter((v, i, a) => a.indexOf(v) === i);
    return array;
};

export const getDistinct = (input: ReadonlyArray<string|number>): ReadonlyArray<string|number> => {
    const array: ReadonlyArray<string|number> = input.filter((x, i, a) => a.indexOf(x) === i);
    return array;
};

export function splitArrayIntoChunks<T>(array: ReadonlyArray<T>, chunkSize: number): T[][] {
    const result: T[][] = [];

    for (let i: number = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}