export interface IFeature {
    [feature: string]: boolean;
}

export interface IFeatureMap {
    [uid: string]: IFeature;
}

export const ALL_FEATURES = ['hideCD','hideNotes','hideTeaOrderForm','noDessert', 'noJogger'] as const;
export const ALL_FEATURES_DESCRIPTIONS = ['Hide CD','Hide Notes', 'Hide Tea Order Form', 'No Dessert', 'No Jogger'] as const;

export enum FeatureId {
    HideCD,
    HideNotes,
    HideTeaOrderForm,
    NoDessert,
    NoJogger
}

export const FeaturesPath = `features`;