import { IDietaryCodesOrder } from '../../App/interfaces/IDietaryCodesOrder';

export interface IDayOrder {
    ammendDate: string | undefined;
    ammendReason: string | undefined;
    normalMeals: number;
    note: string;
    special: IDietaryCodesOrder;
    dessert: number;
    dessertSpecial: IDietaryCodesOrder;
}

export const defaultDayOrder: IDayOrder = {
    ammendDate: undefined,
    ammendReason: undefined,
    dessert: 0,
    dessertSpecial: {},
    normalMeals: 0,
    note: '',
    special: {},
};

export interface IOrder {
    id: string;
    updated: number;
    orderDate: number;
    uid: string;
    dateId: string;
    lunch: IDayOrder;
    tea: IDayOrder;
}
export interface IOrderMap {
    [id: string]: IOrder;
}


export type ChangeType = 'added' | 'removed' | 'modified';
export interface IOrderPayload {
    order: IOrder;
    type: ChangeType;
}