import { 
    IPortionRange, 
    IRangeCountMap 
} from '../../App/interfaces/IPortionRules';

const portionRangeCompare = (pr1: IPortionRange, pr2: IPortionRange): number => {
    return pr2.to - pr1.to;
};

const updatePortionRangeCount = (q: number, id: string, portions: IRangeCountMap): void => {
    if (portions[id]) {
        portions[id] = portions[id] + q;
    }
    else {
        portions[id] = q;
    }
};

export const calcPortionRangeCounts = (amount: number, ranges: IPortionRange[]): IRangeCountMap => {
    const sorted: IPortionRange[] = ranges.slice().sort(portionRangeCompare);
    const portionRangeCounts: IRangeCountMap = {};
    let remainder: number = amount;
    for (const pr of sorted) {
        const q: number = Math.trunc(remainder / pr.to);
        if (q > 0) {
            updatePortionRangeCount(q, pr.id, portionRangeCounts);
        }
        remainder = Math.trunc(remainder % pr.to);
        if (remainder === 0) {
            break;
        }

        if (remainder >= pr.from) {
            updatePortionRangeCount(1, pr.id, portionRangeCounts);
            break;
        }
    }
    return portionRangeCounts;
};