import { createSelector } from 'reselect';
import memoize from 'memoize-one';
import isArrayLike from 'lodash/isArrayLike';
import { toDate, N_DATE_FORMAT, parseFormat, dateAsNumberN } from '../../Common/utils/dateFunctions';

import { menuInitialState } from './menuInitialState';
import { IMenuState } from '../../App/interfaces/IMenuState';
import { IState } from '../../Store/state';
import { getState } from '../../Store/store';
import {
    IPortionRuleMap,
    IPortionRule,
    IPortionRange,
    IPortionRangeMap, IRangeCountMap
} from '../../App/interfaces/IPortionRules';
import {
    IMenuExtra,
    IMenuExtraMap
} from '../../App/interfaces/IMenuExtra';
import {
    IMenuDataSession,
    IMenuDataByCycleMap,
    IMenuDataMap,
    IMenuData
} from '../../App/interfaces/IMenuData';
import {
    IAlternativeMap,
    IAlternative
} from '../../App/interfaces/IAlternatives';
import {
    IAllMenuCycleMap,
    IMenuCycleDefinitionMap,
    IMenuCycleDefinition,
    IMenuCycleMap,
    IMenuCycle,
    CurrentCyclePastMenus
} from '../../App/interfaces/IMenuCycle';
import {
    IMenuMealMap,
    IMenuMeal
} from '../../App/interfaces/IMenuMeal';
import {
    IMenuAllergenMap,
    IMenuAllergen
} from '../../App/interfaces/IMenuAllergen';
import {
    IMenu,
    IMenuMap,
    IMenuMapByCycle
} from '../../App/interfaces/IMenu';

import {
    IDietaryCodesMap,
    IDietaryCode,
    DietaryCodesAllUsers,
    IDeletedCodesMap
} from '../../App/interfaces/IDietaryCodes';
import { ALL_FEATURES, FeatureId, IFeature } from '../../App/interfaces/IFeature';
import { getFeatureFlag } from '../../App/utils/getFeatureFlag';
import { hideCD, isDietaryForUser } from '../../App/utils/makeOrder';
import { IAlternativeMealDataMap, IAlternativeMealData, IAlternativeMealDataColourDataMap } from '../../App/interfaces/IAlternativeMealData';
import { getOrderKeyForDate } from '../../Common/utils/dateFunctions';
import { IMenuItemIdToIsJoggerGramsMap, IMenuItemIdToLabelMap } from '../../App/interfaces/IAllMenuData';
import { IKitchenMenuAlternativePortions, IKitchenMenuPortions } from '../../App/interfaces/IKitchenMenuPortions';
import { IPortionDisplayValues, IRangesAndCounts } from '../../App/utils/joggerTable';
import { calcPortionRangeCounts } from '../../App/utils/calcPortions';

const isPropertyArray = (prop: string, i: Readonly<IMenuExtra> | undefined): boolean => {
    if (i && prop === `allergens`) {
        return isArrayLike(i[`allergens`]);
    }
    return false;
}

export const getLocalState = (state: IState = getState()): IMenuState => {
    if (state && state.menu) {
        return state.menu;
    }
    return menuInitialState();
};

export const getDietaryCodesIds = (state: IMenuState): ReadonlyArray<string> => {
    return state.dietaryCodesIds;
};

export const getDeletedDietary = (state: IMenuState): Readonly<IDeletedCodesMap> => {
    return state.deletedDietary;
};

export const getAllDietaryCodes = (state: IMenuState): Readonly<IDietaryCodesMap> => {
    return state.dietaryCodes;
};

export const getIsDietaryCodeForAllUsers = (code: string, state: IMenuState): boolean => {
    const dc: Readonly<IDietaryCode> = getDietaryCode(code, state);
    if (dc) {
        if (dc.users.indexOf(DietaryCodesAllUsers) !== -1) {
            return true;
        }
    }
    return false;
};

export const getDietaryCode = (code: string, state: IMenuState): Readonly<IDietaryCode> => {
    return state.dietaryCodes[code];
};

// const getFeaturesFromState = (state: IState) => getFeatures(state.app);
// const getDietaryCodesIdsFromState = (state: IState) => getDietaryCodesIds(state.menu);
// const getSelectedUserUidFromState = (state: IState) => getSelectedUserUid(state.app);
// const getAllDietaryCodesFromState = (state: IState) => getAllDietaryCodes(state.menu);

// export const getFeatureForSelectedUidFromState = createSelector(
//     [getSelectedUserUidFromState, getFeaturesFromState],
//     (uid, features) => {
//         if (uid && features && features[uid]) {
//             return features[uid];
//         }
//         return undefined;
//     }
// );

const getUid = (_: IMenuState, uid: string | undefined): string | undefined => uid;
const getFeature = (_: IMenuState, __: string | undefined, feature: Readonly<IFeature> | undefined): Readonly<IFeature> | undefined => feature;

export const getDietariesMapForOrdersAndUid = createSelector(
    [getDietaryCodesIds, getUid, getFeature, getAllDietaryCodes],
    (ids, uid, feature, codesMap) => {
        const map: IDietaryCodesMap = {};
        if (uid) {
            const hideCDFlag: boolean = getFeatureFlag(feature, ALL_FEATURES[FeatureId.HideCD]);
            const allDietaries: Array<IDietaryCode> = [];
            for (const code of ids) {
                const dc = codesMap[code];
                if (dc && !hideCD(dc.code, hideCDFlag) && isDietaryForUser(dc.users, uid) && dc.deleted === false) {
                    allDietaries.push(dc);
                }
            }
            allDietaries.forEach((dc) => {
                map[dc.code] = dc;
            });
        }
        return map;
    }
);


// export const getDietariesMapForOrdersAndUid = createSelector(
//     [getDietaryCodesIdsFromState, getSelectedUserUidFromState, getFeatureForSelectedUidFromState, getAllDietaryCodesFromState],
//     (ids, uid, features, codesMap) => {

//         const map: IDietaryCodesMap = {};
//         if (uid) {
//             const hideCDFlag: boolean = getFeatureFlag(features, ALL_FEATURES[FeatureId.HideCD]);
//             const allDietaries: Array<IDietaryCode> = [];
//             for (const code of ids) {
//                 const dc = codesMap[code];
//                 if (dc && !hideCD(dc.code, hideCDFlag) && isDietaryForUser(dc.users, uid) && dc.deleted === false) {
//                     allDietaries.push(dc);
//                 }
//             }
//             allDietaries.forEach((dc) => {
//                 map[dc.code] = dc;
//             });
//         }
//         return map;
//     }
// );

export const getPortionRules = (state: IMenuState): Readonly<IPortionRuleMap> => {
    return state.portionRules;
};

export const isItemInRule = (itemId: string, rule: Readonly<IPortionRule> | undefined): boolean => {
    if (rule && rule.itemIds && rule.itemIds.length > 0) {
        if (rule.itemIds.indexOf(itemId) !== -1) {
            return true;
        }
    }
    return false;
};

export const isItemInAnyRules = (itemId: string, portionRules: Readonly<IPortionRuleMap>): boolean => {
    for (const ruleId in portionRules) {
        if (ruleId) {
            if (isItemInRule(itemId, portionRules[ruleId])) {
                return true;
            }
        }
    }
    return false;
}

export const getRuleIdForItem = (itemId: string, state: IMenuState): string | undefined => {
    for (const ruleId in state.portionRules) {
        if (ruleId) {
            if (isItemInRule(itemId, state.portionRules[ruleId])) {
                return ruleId;
            }
        }
    }
    return undefined;
}

export const doesRangeExist = (range: IPortionRange, state: IMenuState): boolean => {
    if (state.portionRanges) {
        for (const rK in state.portionRanges) {
            if (rK) {
                const r: IPortionRange = state.portionRanges[rK];
                if (r.from === range.from && r.to === range.to && r.portionAsString.length === range.portionAsString.length) {
                    for (let i: number = 0; i < r.portionAsString.length; i++) {
                        if (r.portionAsString[i].localeCompare(range.portionAsString[i]) !== 0) {
                            return false;
                        }
                    }
                    return true;
                }
            }
        }
    }
    return false;
}

export const getPortionRanges = (state: IMenuState): Readonly<IPortionRangeMap> => {
    return state.portionRanges;
};

export const getPortionRangeForId = (rangeId: string, state: IMenuState): Readonly<IPortionRange> | undefined => {
    const range: IPortionRange | undefined = state.portionRanges[rangeId];
    return range;
};

export const getPortionRangesForRuleId = (ruleId: string, state: IMenuState): IPortionRange[] => {
    const ranges: IPortionRange[] = [];
    const rule: Readonly<IPortionRule> | undefined = state.portionRules[ruleId];
    if (rule && rule.rangeIds && rule.rangeIds.length > 0) {
        for (const rId of rule.rangeIds) {
            const range: IPortionRange | undefined = state.portionRanges[rId];
            if (range) {
                ranges.push(range);
            }
        }
    }
    return ranges;
};

export const getAllMenuAlternativeMealDataMap = (state: IMenuState): Readonly<IAlternativeMealDataMap> => {
    return state.alternativeMealData;
};

export const getMenuAlternativeMealDataForId = (id: string, state: IMenuState): Readonly<IAlternativeMealData> | undefined => {
    if (state.alternativeMealData && state.alternativeMealData[id]) {
        return state.alternativeMealData[id];
    }
    return undefined;
};

export const getAlternativeMealDisplayColours = createSelector(
    [getAllMenuAlternativeMealDataMap],
    (alternativeMealData) => {
        const result: IAlternativeMealDataColourDataMap = {};
        for (const i in alternativeMealData) {
            if (alternativeMealData[i] !== undefined) {
                result[i] = alternativeMealData[i].display;
            }
        }
        return result;
    }
);

export const getAlternativeMealPrintColours = createSelector(
    [getAllMenuAlternativeMealDataMap],
    (alternativeMealData) => {
        const result: IAlternativeMealDataColourDataMap = {};
        for (const i in alternativeMealData) {
            if (alternativeMealData[i] !== undefined) {
                result[i] = alternativeMealData[i].print;
            }
        }
        return result;
    }
);

export const getAllMenuAlternativesMap = (state: IMenuState): Readonly<IAlternativeMap> => {
    return state.alternatives;
};

export const getAllMenuCycleMap = (state: IMenuState): IAllMenuCycleMap => {
    return state.allMenuCycles;
};

export const getMenuDataByCycleMap = (state: IMenuState): IMenuDataByCycleMap => {
    return state.menuDataByCycle;
};

export const getAllMenuTeas = (state: IMenuState): IMenuMealMap => {
    return state.menuTeas;
};
export const getMenuTeaForId = (id: string, state: IMenuState): IMenuMeal | undefined => {
    return state.menuTeas[id];
};

export const getAllMenuLunches = (state: IMenuState): IMenuMealMap => {
    return state.menuLunches;
};
export const getMenuLunchForId = (id: string, state: IMenuState): IMenuMeal | undefined => {
    return state.menuLunches[id];
};

export const getAllMenuAllergens = (state: IMenuState): Readonly<IMenuAllergenMap> => {
    return state.menuAllergens;
};
export const getMenuAllergensForId = (id: string, state: IMenuState): IMenuAllergen | undefined => {
    return state.menuAllergens[id];
};

export const getAllMenuSides = (state: IMenuState): IMenuExtraMap => {
    return state.menuSides;
};
export const getMenuSidesForId = (id: string, state: IMenuState): IMenuExtra | undefined => {
    return state.menuSides[id];
};

export const getAllMenuDesserts = (state: IMenuState): IMenuExtraMap => {
    return state.menuDesserts;
};
export const getMenuDessertsForId = (id: string, state: IMenuState): IMenuExtra | undefined => {
    return state.menuDesserts[id];
};

const getMenuAlternativeMapForItems = (menuItems: IMenuExtraMap, alternativeMap: IAlternativeMap): IAlternativeMap => {
    const result: IAlternativeMap = {};
    for (const key in alternativeMap) {
        const alt: IAlternative = alternativeMap[key];
        if (alt !== undefined && menuItems[alt.itemId] !== undefined) {
            result[key] = alt;
        }
    }
    return result;
};

export const getUnusedLunchMenuAlternativeItems = createSelector(
    [getAllMenuLunches, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const result: IMenuExtraMap = {};
        for (const i in menuItems) {
            if (menuAlternativesMap[i] === undefined) {
                result[i] = menuItems[i];
            }
        }
        return result;
    }
);
export const getLunchMenuAlternativeItemsIdArray = createSelector(
    [getAllMenuLunches, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const alternativeMap: IAlternativeMap = getMenuAlternativeMapForItems(menuItems, menuAlternativesMap);
        const ids: Array<string> = Object.values(alternativeMap).sort((a: IAlternative, b: IAlternative) => {
            return menuItems[a.itemId].item.localeCompare(menuItems[b.itemId].item);
        }).map((alt) => alt.itemId.toString());
        return ids;
    }
);
export const getUnusedDessertMenuAlternativeItems = createSelector(
    [getAllMenuDesserts, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const result: IMenuExtraMap = {};
        for (const i in menuItems) {
            if (menuAlternativesMap[i] === undefined) {
                result[i] = menuItems[i];
            }
        }
        return result;
    }
);
export const getDessertMenuAlternativeItemsIdArray = createSelector(
    [getAllMenuDesserts, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const alternativeMap: IAlternativeMap = getMenuAlternativeMapForItems(menuItems, menuAlternativesMap);
        const ids: Array<string> = Object.values(alternativeMap).sort((a: IAlternative, b: IAlternative) => {
            return menuItems[a.itemId].item.localeCompare(menuItems[b.itemId].item);
        }).map((alt) => alt.itemId.toString());
        return ids;
    }
);

export const getUnusedTeaMenuAlternativeItems = createSelector(
    [getAllMenuTeas, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const result: IMenuExtraMap = {};
        for (const i in menuItems) {
            if (menuAlternativesMap[i] === undefined) {
                result[i] = menuItems[i];
            }
        }
        return result;
    }
);
export const getTeaMenuAlternativeItemsIdArray = createSelector(
    [getAllMenuTeas, getAllMenuAlternativesMap],
    (menuItems, menuAlternativesMap) => {
        const alternativeMap: IAlternativeMap = getMenuAlternativeMapForItems(menuItems, menuAlternativesMap);
        const ids: Array<string> = Object.values(alternativeMap).sort((a: IAlternative, b: IAlternative) => {
            return menuItems[a.itemId].item.localeCompare(menuItems[b.itemId].item);
        }).map((alt) => alt.itemId.toString());
        return ids;
    }
);

export const getAllergenForId = (id: string, state: IMenuState): string | undefined => {
    const i: IMenuAllergen | undefined = getMenuAllergensForId(id, state);
    if (i) {
        return i.item;
    }
    return undefined;
};

export const getDessertForId = (id: string, state: IMenuState): string | undefined => {
    const i: IMenuExtra | undefined = getMenuDessertsForId(id, state);
    if (i) {
        return i.item;
    }
    return undefined;
};
export const getSideForId = (id: string, state: IMenuState): string | undefined => {
    const i: IMenuExtra | undefined = getMenuSidesForId(id, state);
    if (i) {
        return i.item;
    }
    return undefined;
};
export const getLunchForId = (id: string, state: IMenuState): string | undefined => {
    const i: IMenuExtra | undefined = getMenuLunchForId(id, state);
    if (i) {
        return i.item;
    }
    return undefined;
};
export const getTeaForId = (id: string, state: IMenuState): string | undefined => {
    const i: IMenuExtra | undefined = getMenuTeaForId(id, state);
    if (i) {
        return i.item;
    }
    return undefined;
};

export const getItemLabelForIdMap = (state: IMenuState): Readonly<IMenuItemIdToLabelMap> => {
    return state.menuItemIdToLabel;
};

export const getItemJoggerIsGramsForIdMap = (state: IMenuState): Readonly<IMenuItemIdToIsJoggerGramsMap> => {
    return state.isJoggerMultiplierGramsMap;
};

//  This when in non edit mode will be the data for the current cycle
//  If an menu cycle has been chosen to edit then it will be data for that cycle.
//      If a customer then it will always be the current cycle as they have no way of selecting the 'edit cycle'
export const getAllMenuDataForCurrentCycle = (state: IMenuState): IMenuDataMap => {
    const menuData: IMenuDataMap | undefined = state.menuDataByCycle[getEditMenuCycleId(state)];
    return menuData;
};

//  This when in non edit mode will be the data for the current cycle
//  If an menu cycle has been chosen to edit then it will be data for that cycle.
//      If a customer then it will always be the current cycle as they have no way of selecting the 'edit cycle'
export const getMenuCycleDataForCurrentCycle = (state: IMenuState): IMenuCycleMap => {
    const menuCycleData: IMenuCycleMap | undefined = state.allMenuCycles[getEditMenuCycleId(state)];
    return menuCycleData;
};


export const getMenuDataForIdAndCurrentCycle = (id: number, state: IMenuState): IMenuData | undefined => {
    const menuData: IMenuDataMap | undefined = getAllMenuDataForCurrentCycle(state);
    return menuData !== undefined ? menuData[id] : undefined;
};

export const getMenuEditData = (state: IMenuState): IMenuData | undefined => {
    return state.menuEditData;
};

const getAllergensForMealItems = (
    n: IMenuExtra | undefined,
    desserts: string[] | undefined,
    sides: string[] | undefined,
    state: IMenuState

): string[] => {

    const allergens: string[] = [];

    if (n && isPropertyArray('allergens', n)) {
        allergens.push(...n.allergens);
    }
    if (desserts && isArrayLike(desserts)) {
        for (const i of desserts) {
            const d: IMenuExtra | undefined = getMenuDessertsForId(i, state);
            if (d && isPropertyArray('allergens', d)) {
                allergens.push(...d.allergens);
            }
        }
    }
    if (sides && isArrayLike(sides)) {
        for (const i of sides) {
            const s: IMenuExtra | undefined = getMenuSidesForId(i, state);
            if (s && isPropertyArray('allergens', s)) {
                allergens.push(...s.allergens);
            }
        }
    }

    return allergens.filter((k, i, a) => a.indexOf(k) === i);
};

export const getAllAllergensForMeal = (meal: Readonly<IMenuDataSession>, state: IMenuState, func: (id: string, state: IMenuState) => IMenuExtra | undefined) => {
    const n: IMenuExtra | undefined = func(meal.normal, state);
    return getAllergensForMealItems(n, meal.desserts, meal.sides, state);
};

export const getLunchMenuDataAllergensForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.lunch) {
        return getAllAllergensForMeal(menuData.lunch, state, getMenuLunchForId);
    }
    return [];
};

export const getTeaMenuDataAllergensForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.tea) {
        return getAllAllergensForMeal(menuData.tea, state, getMenuTeaForId);
    }
    return [];
};

export const getLunchMenuDataDessertsForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.lunch && menuData.lunch.desserts) {
        return menuData.lunch.desserts;
    }
    return [];
};

export const getTeaMenuDataDessertsForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.tea && menuData.tea.desserts) {
        return menuData.tea.desserts;
    }
    return [];
};

export const getLunchMenuDataSidesForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.lunch && menuData.lunch.sides) {
        return menuData.lunch.sides;
    }
    return [];
};

export const getTeaMenuDataSidesForIdAndCurrentCycle = (id: number, state: IMenuState): string[] => {
    const menuData: IMenuData | undefined = getMenuDataForIdAndCurrentCycle(id, state);
    if (menuData && menuData.tea && menuData.tea.sides) {
        return menuData.tea.sides;
    }
    return [];
};

export const hasMenuLoaded = (state: IMenuState): boolean => {
    return state.menuLoaded;
}

export const hasMenuAtStartupLoaded = (state: IMenuState): boolean => {
    return state.menuAtStartupLoaded;
}

export const getPortion = (id: string, state: IMenuState): Readonly<IMenuExtra> | undefined => {

    if (state.menuLunches[id]) {
        return state.menuLunches[id];
    }
    else if (state.menuTeas[id]) {
        return state.menuTeas[id];
    }
    else if (state.menuSides[id]) {
        return state.menuSides[id];
    }
    return state.menuDesserts[id];
}

export const getEditMenuCycleId = (state: IMenuState): string => {
    if (state.editMenuCycle) {
        return state.editMenuCycle;
    }
    return state.currentMenuCycle;
};

export const getCurrentMenuCycleId = (state: IMenuState): string => {
    return state.currentMenuCycle;
};

export const getCurrentMenuCycleOffset = (state: IMenuState): number => {
    const menuCycleMap: IMenuCycleMap | undefined = state.allMenuCycles[getCurrentMenuCycleId(state)];
    if (menuCycleMap) {
        for (const m in menuCycleMap) {
            if (m && menuCycleMap[m]) {
                return menuCycleMap[m].offset;
            }
        }
    }
    return 0;
};

export const getMenuCycleDefinitionMap = (state: IMenuState): IMenuCycleDefinitionMap => {
    return state.menuCycleDefinitionMap;
};

export const getMenuCycleDefinitionMapEdit = (state: IMenuState): IMenuCycleDefinitionMap => {
    return state.menuCycleDefinitionMapEdit;
};

export const getMenuCycleDefinition = (cycleId: string, state: IMenuState): IMenuCycleDefinition | undefined => {
    return state.menuCycleDefinitionMap[cycleId];
};


export const getDisplayMenusByCycle = (state: IMenuState): Readonly<IMenuMapByCycle> => {
    return state.displayMenusByCycle;
};

const getCycleDateIsIn = (date: number, cycleMap: IMenuCycleDefinitionMap): string | undefined => {
    for (const cId in cycleMap) {
        const start = dateAsNumberN(parseFormat(cycleMap[cId].startDate, N_DATE_FORMAT));
        const end = dateAsNumberN(parseFormat(cycleMap[cId].endDate, N_DATE_FORMAT));
        if (date >= start && date <= end) {
            return cId;
        }
    }

    return undefined;
}

export const getDisplayMenuForDateStateLess = memoize((
    date: number,
    menuCycleDefinitionMap: IMenuCycleDefinitionMap,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    displayMenusByCycle: Readonly<IMenuMapByCycle>,
    currentMenuCycle: string
): Readonly<IMenu> | undefined => {
    const cId: string | undefined = getCycleDateIsIn(date, menuCycleDefinitionMap);
    if (cId !== undefined) {
        const key: string = getOrderKeyForDate(date);
        const menuCycleMap: IMenuCycleMap | undefined = allMenuCycles[cId];
        const pastMenus: IMenuCycleMap | undefined = allMenuCycles[CurrentCyclePastMenus];
        if (menuCycleMap) {
            const cycle: IMenuCycle | undefined = menuCycleMap[key];
            if (cycle) {
                const menuDataMap: IMenuMap = displayMenusByCycle[cId];
                if (menuDataMap) {
                    const menu: IMenu | undefined = menuDataMap[cycle.menuId];
                    return menu;
                }
            }
            else if (pastMenus !== undefined && cId === currentMenuCycle) {
                const cycle: IMenuCycle | undefined = pastMenus[key];
                if (cycle) {
                    const menuDataMap: IMenuMap = displayMenusByCycle[cId];
                    if (menuDataMap) {
                        const menu: IMenu | undefined = menuDataMap[cycle.menuId];
                        return menu;
                    }
                }
            }
        }
    }

    return undefined;
});

export const getDisplayMenuForDate = (date: number, state: IMenuState): Readonly<IMenu> | undefined => {
    return getDisplayMenuForDateStateLess(date, state.menuCycleDefinitionMap, state.allMenuCycles, state.displayMenusByCycle, state.currentMenuCycle);
};

export const getCurrentMenuCycles = memoize((fromDate: number, menuCycleMap: IMenuCycleMap): IMenuCycle[] => {
    const menuCycles: IMenuCycle[] = [];
    for (const k in menuCycleMap) {
        if (k && menuCycleMap[k]) {
            const cycle: IMenuCycle | undefined = menuCycleMap[k];
            if (cycle && cycle.dateVal >= fromDate) {
                menuCycles.push(cycle);
            }
        }
    }

    const compareMenuId = (a: IMenuCycle, b: IMenuCycle): number => {
        return a.menuId - b.menuId;
    };
    const compareDateVal = (a: IMenuCycle, b: IMenuCycle): number => {
        return a.dateVal - b.dateVal;
    };

    const compareDateThenMenuId = (a: IMenuCycle, b: IMenuCycle): number => {
        return compareDateVal(a, b) || compareMenuId(a, b);
    };

    return menuCycles.sort(compareDateThenMenuId);
});

export const getDateForCycleAndMenuId = (cId: string, fromDate: number, menuId: number, state: IMenuState): Date | undefined => {
    const menuCycleMap: IMenuCycleMap | undefined = state.allMenuCycles[cId];
    if (menuCycleMap) {
        const menuCycles: IMenuCycle[] = getCurrentMenuCycles(fromDate, menuCycleMap);
        for (let i: number = 0; i < menuCycles.length; ++i) {
            const cycle: IMenuCycle = menuCycles[i];
            if (cycle.menuId === menuId) {
                return toDate(cycle.dateVal);
            }
        }
    }
    return undefined;
};

export const getDateForCurrentCycleAndMenuId = (fromDate: number, menuId: number, state: IMenuState): Date | undefined => {
    return getDateForCycleAndMenuId(state.currentMenuCycle, fromDate, menuId, state);
};

export const getAllBroswingMenuDataForCurrentCycleArray = createSelector(
    [getAllMenuDataForCurrentCycle, getCurrentMenuCycleOffset, getMenuCycleDataForCurrentCycle],
    (menuData, offset, menuCycleData) => {
        if (menuData) {
            const sortedCycleData = Object.values(menuCycleData).sort((a: IMenuCycle, b: IMenuCycle) => a.dateVal - b.dateVal);
            let array: IMenuData[] = Object.values(menuData);
            array = array.sort((a, b) => a.id - b.id);
            const removed: IMenuData[] = array.splice(0, offset);
            array.push(...removed);
            const result: IMenuData[] = [];
            for (const cd of sortedCycleData) {
                const f = array.find((md) => md.id === cd.menuId);
                if (f !== undefined && (result.find((r) => r.id === f.id) === undefined)) {
                    f.dateVal = cd.dateVal;
                    result.push(f);
                }
            }
            return result;
        }
        return [];
    }
);

// const getSelectedDayOrdersFromState = (state: IState): Readonly<ISelectOrdersForUidAndDate> | undefined => state.app.selectedDayOrders;
// const getMenuCycleDefinitionMapFromState = (state: IState) => getMenuCycleDefinitionMap(state.menu);
// const getAllMenuCycleMapFromState = (state: IState) => getAllMenuCycleMap(state.menu);
// const getMenuDataByCycleMapFromState = (state: IState) => getMenuDataByCycleMap(state.menu);
// const getAllMenuDessertsFromState = (state: IState) => getAllMenuDesserts(state.menu);
// const getAllMenuLunchesFromState = (state: IState) => getAllMenuLunches(state.menu);
// const getAllMenuSidesFromState = (state: IState) => getAllMenuSides(state.menu);
// const getAllMenuTeasFromState = (state: IState) => getAllMenuTeas(state.menu);

// export const getDietariesForUidAndOrderDate = createSelector(
//     [
//         getSelectedDayOrdersFromState,
//         getMenuCycleDefinitionMapFromState,
//         getAllMenuCycleMapFromState,
//         getDietariesMapForOrdersAndUid,
//         getMenuDataByCycleMapFromState,
//         getAllMenuDessertsFromState,
//         getAllMenuLunchesFromState,
//         getAllMenuSidesFromState,
//         getAllMenuTeasFromState,
//         getFeatureForSelectedUidFromState
//     ],
//     (
//         selectedDayOrders,
//         menuCycleDefinitionMap,
//         allMenuCycles,
//         allDietaries,
//         menuDataByCycle,
//         menuDesserts,
//         menuLunches,
//         menuSides,
//         menuTeas,
//         features
//     ) => {
//         if (selectedDayOrders) {
//             const noTeaFlag: boolean = getFeatureFlag(features, ALL_FEATURES[FeatureId.HideTeaOrderForm]);
//             const noDessertFlag: boolean = getFeatureFlag(features, ALL_FEATURES[FeatureId.NoDessert]);
//             const theDateKey: string = getOrderKeyForDate(selectedDayOrders.startDate);
//             const extras: ReadonlyArray<IMenuExtra> = getAllExtras(theDateKey, noTeaFlag, noDessertFlag, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuLunches, menuTeas, menuSides, menuDesserts);
//             const allergens = getAllergens(extras);
//             const dcMap = getDietariesForAllergens(allDietaries, allergens);
//             return dcMap;
//         }
//         return {};
//     }
// );

const calcPortionRanges = (p: IKitchenMenuPortions, state: IMenuState): string[] => {
    const ruleId: string | undefined = getRuleIdForItem(p.id, state);
    if (ruleId) {
        const ranges: IPortionRange[] = getPortionRangesForRuleId(ruleId, state);
        const count: IRangeCountMap = calcPortionRangeCounts(p.meals, ranges);
        const portions: string[] = [];
        for (const rId in count) {
            if (rId) {
                const range: IPortionRange | undefined = getPortionRangeForId(rId, state);
                if (range && range.portionAsString) {
                    const num: number = count[rId];
                    for (let n: number = 0; n < num; n++) {
                        portions.push(...range.portionAsString);
                    }
                }
            }
        }
        return portions;
    }
    return [];
}

const getMenuState = (state: IState) => state.menu;

export function makeGetPortionRangesAndCounts() {
    const portionsPassThrough = (state: IState, portions: ReadonlyArray<IKitchenMenuPortions>) => portions;
    return createSelector(
        [getMenuState, portionsPassThrough],
        (state, portions): IRangesAndCounts => {
            const result: IRangesAndCounts = {
                ranges: {},
                counts: {},
            }

            if (portions.length > 0) {
                portions.forEach((p) => {
                    const ranges: string[] = calcPortionRanges(p, state);
                    if (ranges.length > 0) {
                        if (result.ranges[p.id]) {
                            result.ranges[p.id].push(...ranges);
                        }
                        else {
                            result.ranges[p.id] = ranges;
                        }
                    }
                    else {
                        if (result.counts[p.id]) {
                            result.counts[p.id] += p.meals;
                        }
                        else {
                            result.counts[p.id] = p.meals;
                        }
                    }
                });
            }

            return result;
        });
}

const getPortionRangesAsStrings = (count: IRangeCountMap, state: IMenuState): string[] => {
    const portions: string[] = [];
    for (const rId in count) {
        if (rId) {
            const range: IPortionRange | undefined = getPortionRangeForId(rId, state);
            if (range && range.portionAsString) {
                const num: number = count[rId];
                for (let n: number = 0; n < num; n++) {
                    portions.push(range.portionAsString.join(' + '));
                }
            }
        }
    }
    return portions;
};

export function makeGetPortionDisplayValues() {
    const portionsPassThrough = (state: IState, portions: IKitchenMenuPortions[]) => portions;
    return createSelector(
        [getMenuState, portionsPassThrough],
        (state, portions): IPortionDisplayValues[] => {
            const total: IPortionDisplayValues[] = [];
            for (const p of portions) {
                if (p.meals === 0) {
                    continue;
                }
                const result: IPortionDisplayValues = {
                    id: p.id,
                    uid: p.uid,
                    code: '',
                    item: '',
                    meals: 0,
                    portions: [],
                }
                const ruleId: string | undefined = getRuleIdForItem(p.id, state);
                if (ruleId) {
                    const ranges: IPortionRange[] = getPortionRangesForRuleId(ruleId, state);
                    const count: IRangeCountMap = calcPortionRangeCounts(p.meals, ranges);
                    result.portions = getPortionRangesAsStrings(count, state);
                }
                result.item = p.item;
                result.meals = p.meals;
                total.push(result);
            }
            return total;
        });
}

export function makeGetAltPortionDisplayValues() {
    const portionsPassThrough = (state: IState, portions: IKitchenMenuAlternativePortions[]) => portions;
    return createSelector(
        [getMenuState, portionsPassThrough],
        (state, portions): IPortionDisplayValues[] => {
            const total: IPortionDisplayValues[] = [];
            for (const p of portions) {
                if (p.meals === 0) {
                    continue;
                }
                const result: IPortionDisplayValues = {
                    id: p.id,
                    uid: p.uid,
                    code: p.code,
                    item: '',
                    meals: 0,
                    portions: [],
                }
                const ruleId: string | undefined = getRuleIdForItem(p.id, state);
                if (ruleId) {
                    const ranges: IPortionRange[] = getPortionRangesForRuleId(ruleId, state);
                    const count: IRangeCountMap = calcPortionRangeCounts(p.meals, ranges);
                    result.portions = getPortionRangesAsStrings(count, state);
                }
                result.item = p.item;
                result.meals = p.meals;
                total.push(result);
            }
            return total;
        });
}
