import { fs } from '../../Storage';
import firebase from 'firebase/compat/app';

import { IOrder, IOrderMap } from '../interfaces/IDayOrder';
import { IOrderActivityLog } from '../interfaces/IOrderActivityLog';
import { IAppConfig } from '../interfaces/IAppConfig';


export const orderActivityLogConverter = {
    toFirestore(order: IOrderActivityLog): firebase.firestore.DocumentData {
        return order;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): IOrderActivityLog {
        const id = snapshot.id;
        const data = snapshot.data(options);
        return {
            ...data,
            id
        } as IOrderActivityLog;
    }
};

const removeUndefined = (order: IOrder) => {
    if (order.tea) {
        if (order.tea.dessertSpecial === undefined) {
            order.tea.dessertSpecial = {};
        }
        if (order.tea.special === undefined) {
            order.tea.special = {};
        }
    }
    if (order.lunch) {
        if (order.lunch.dessertSpecial === undefined) {
            order.lunch.dessertSpecial = {};
        }
        if (order.lunch.special === undefined) {
            order.lunch.special = {};
        }
    }
    return order;
};


export const orderConverter = {
    toFirestore(o: IOrder): firebase.firestore.DocumentData {
        const order = removeUndefined(o);
        return {
            updated: order.updated,
            orderDate: order.orderDate,
            uid: order.uid,
            dateId: order.dateId,
            lunch: order.lunch,
            tea: order.tea,
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): IOrder {
        const id = snapshot.id;
        const data = snapshot.data(options);
        return {
            ...data,
            id
        } as IOrder;
    }
};

export const getMappedOrdersForRange = async (fromDate: number, toDate: number) => {
    const ref = queryOrdersBetweenDates(fromDate, toDate);
    const snap = await ref.withConverter(orderConverter).get();
    if (snap.empty) {
        return {};
    }
   
    const mapped: IOrderMap = snap.docs.map((d) => d.data()).reduce(
        (obj: IOrderMap, item: IOrder) => {
            obj[item.id] = item;
            return obj;
        },
        {}
    );

    return mapped;
}

export const queryOrdersByDate = (filterDate: number) => {
    const ref = fs.collection(`orders`).where('orderDate', '>=', filterDate);
    return ref;
};

export const queryOrdersBetweenDates = (startDate: number, endDate: number) => {
    if (startDate > endDate) {
        throw new Error(`queryOrdersBetweenDates startDate > endDate: ${startDate} > ${endDate}`);
    }
    const ref = fs.collection(`orders`).where('orderDate', '>=', startDate).where('orderDate', '<=', endDate);
    return ref;
};

export const queryOrdersByUid = (uids: ReadonlyArray<string>) => {
    if (uids.length > 10) {
        throw new Error(`queryOrdersByUid max uids is 10, current uids: ${uids.length}`);
    }
    const ref = fs.collection(`orders`).where('uid', 'in', uids);
    return ref;
};
//  needed a composite index
export const queryOrdersByUidAndDate = (uids: ReadonlyArray<string>, filterDate: number) => {
    if (uids.length > 10) {
        throw new Error(`queryOrdersByUidAndDate max uids is 10, current uids: ${uids.length}`);
    }
    const ref = fs.collection(`orders`).where('uid', 'in', uids).where('orderDate', '>=', filterDate);
    return ref;
};

export const queryOrderActivityLogsByDate = (filterDate: number) => {
    const ref = fs.collection(`orderActivity`).where('changeTime', '>=', filterDate);
    return ref;
};

export const queryOrderActivityLogsByDateLatest = (filterDate: number) => {
    const ref = fs.collection(`orderActivity`).where('changeTime', '>=', filterDate).orderBy(`changeTime`, `desc`).limit(1);
    return ref;
};

export const queryOrderActivityLogsByDateAndUids = (uids: ReadonlyArray<string>, filterDate: number) => {
    if (uids.length > 10) {
        throw new Error(`queryOrderActivityLogsByDateAndUids max uids is 10, current uids: ${uids.length}`);
    }
    const ref = fs.collection(`orderActivity`).where('uid', 'in', uids).where('changeTime', '>=', filterDate);
    return ref;
};


export const queryConfig = () => {
    const ref = fs.collection(`config`).limit(1);
    return ref;
};

export const updateConfig = async (config: IAppConfig) => {
    const ref = fs.collection(`config`).limit(1);
    const configSnap = await ref.get();
    const doc = configSnap.docs[0];
    await doc.ref.update(config);
};