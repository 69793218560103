import { IState } from "../../Store/state";

import {
    IMenuItemUsageStats,
    MenuItemUsageStat,
    IMenuItemId
} from "../../App/interfaces/IMenuItemUsageStats";
import {
    getAllMenuAllergens,
    getAllMenuDesserts,
    getAllMenuLunches,
    getAllMenuSides,
    getAllMenuTeas,
    getAllDietaryCodes,
    getMenuDataByCycleMap,
    getMenuCycleDefinition, getItemLabelForIdMap
} from "./menuSelectors";

import {
    IMenuAllergenMap,
    IMenuAllergen
} from "../../App/interfaces/IMenuAllergen";
import {
    IMenuExtraMap,
    IMenuExtra
} from "../../App/interfaces/IMenuExtra";
import { 
    IDietaryCodesMap,
    IDietaryCode,
 } from "../../App/interfaces/IDietaryCodes";
import { 
    IMenuDataByCycleMap, 
    IMenuDataMap, 
    IMenuData, 
    IMenuDataSession
} from "../../App/interfaces/IMenuData";
import { 
    getDayFromId, 
    getWeekFromId 
} from "../../App/utils/menus";
import { IMenuMealMap, IMenuMeal } from "../../App/interfaces/IMenuMeal";
import { 
    IMenuCycleDefinition 
} from "../../App/interfaces/IMenuCycle";

const getMenuCycleDefinitionName = (cycle: string, state: IState): string | undefined => {
    const definition: IMenuCycleDefinition | undefined  = getMenuCycleDefinition(cycle, state.menu);
    if (definition) {
        return definition.name;
    }

    return undefined;
};

const updateStatsForAllergens = (aId: string, menuItems: IMenuExtraMap, stat: IMenuItemUsageStats, state: IState): void => {
    for (const i in menuItems) {
        const item: IMenuExtra = menuItems[i];
        if (item && item.allergens && item.allergens.indexOf(aId) > -1) {
            stat.usedCount++;
            if (stat.usedByIds) {
                stat.usedByIds.push(item.id);
            }
            const label: string | undefined = getItemLabelForIdMap(state.menu)[item.id];
            if (label) {
                stat.usedByLabels.push(label);
            }
        }
    }
};

const calcAllergensUsageStats = (state: IState): IMenuItemUsageStats[] => {
    const allergens: IMenuAllergenMap = getAllMenuAllergens(state.menu);
    const dietaries: IDietaryCodesMap = getAllDietaryCodes(state.menu);
    const desserts: IMenuExtraMap = getAllMenuDesserts(state.menu);
    const lunches: IMenuExtraMap = getAllMenuLunches(state.menu);
    const sides: IMenuExtraMap = getAllMenuSides(state.menu);
    const teas: IMenuExtraMap = getAllMenuTeas(state.menu);

    const results: IMenuItemUsageStats[] = [];
    for (const a in allergens) {
        const all: IMenuAllergen = allergens[a];
        if (all) {
            const stat: IMenuItemUsageStats = {
                id: a,
                label: all.item,
                usedCount: 0,
                usedByIds: [],
                usedByLabels: [],
                itemType: MenuItemUsageStat.Ingredients
            }

            for (const i in dietaries) {
                const item: IDietaryCode = dietaries[i];
                if (item && item.allergens && item.allergens.indexOf(a) > -1) {
                    stat.usedCount++;
                    if (stat.usedByIds) {
                        stat.usedByIds.push(item.code);
                        stat.usedByLabels.push(item.code);
                    }
                }
            }

            updateStatsForAllergens(a, desserts, stat, state);
            updateStatsForAllergens(a, lunches, stat, state);
            updateStatsForAllergens(a, sides, stat, state);
            updateStatsForAllergens(a, teas, stat, state);
            results.push(stat);
        }
    }
    return results;
};

const calcSidesUsageStats = (state: IState): IMenuItemUsageStats[] => {
    const items: IMenuExtraMap = getAllMenuSides(state.menu);
    const menuDataByCycle: IMenuDataByCycleMap = getMenuDataByCycleMap(state.menu);
    const results: IMenuItemUsageStats[] = [];
    for (const k in items) {
        const v: IMenuExtra = items[k];
        if (v) {
            const stat: IMenuItemUsageStats = {
                id: k,
                label: v.item,
                usedCount: 0,
                usedByMenuIds: [],
                usedByLabels: [],
                itemType: MenuItemUsageStat.Sides
            }
            for (const cycle in menuDataByCycle) {
                const m: IMenuDataMap = menuDataByCycle[cycle];
                if (m) {
                    for (const i in m) {
                        const md: IMenuData = m[i];
                        if (md) {
                            if (md.lunch.sides && md.lunch.sides.indexOf(k) > -1) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Lunch Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `lunch`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                            if (md.tea.sides && md.tea.sides.indexOf(k) > -1) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Tea Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `tea`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                        }
                    }
                }
            }
            results.push(stat);
        }
    }

    return results;
};

const calcDesserstUsageStats = (state: IState): IMenuItemUsageStats[] => {
    const items: IMenuExtraMap = getAllMenuDesserts(state.menu);
    const menuDataByCycle: IMenuDataByCycleMap = getMenuDataByCycleMap(state.menu);
    const results: IMenuItemUsageStats[] = [];
    for (const k in items) {
        const v: IMenuExtra = items[k];
        if (v) {
            const stat: IMenuItemUsageStats = {
                id: k,
                label: v.item,
                usedCount: 0,
                usedByMenuIds: [],
                usedByLabels: [],
                itemType: MenuItemUsageStat.Sides
            }
            for (const cycle in menuDataByCycle) {
                const m: IMenuDataMap = menuDataByCycle[cycle];
                if (m) {
                    for (const i in m) {
                        const md: IMenuData = m[i];
                        if (md) {
                            if (md.lunch.desserts && md.lunch.desserts.indexOf(k) > -1) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Lunch Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `lunch`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                            if (md.tea.desserts && md.tea.desserts.indexOf(k) > -1) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Tea Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `tea`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                        }
                    }
                }
            }
            results.push(stat);
        }
    }

    return results;
};

const hasItemAsMeal = (item: IMenuMeal, mealSession: IMenuDataSession) : boolean => {
    return item.id === mealSession.normal;
};

const calcLunchesUsageStats = (state: IState): IMenuItemUsageStats[] => {
    const items: IMenuMealMap = getAllMenuLunches(state.menu);
    const menuDataByCycle: IMenuDataByCycleMap = getMenuDataByCycleMap(state.menu);
    const results: IMenuItemUsageStats[] = [];
    for (const k in items) {
        const v: IMenuMeal = items[k];
        if (v && v.isAlternative === false) {
            const stat: IMenuItemUsageStats = {
                id: k,
                label: v.item,
                usedCount: 0,
                usedByMenuIds: [],
                usedByLabels: [],
                itemType: MenuItemUsageStat.Sides
            }
            for (const cycle in menuDataByCycle) {
                const m: IMenuDataMap = menuDataByCycle[cycle];
                if (m) {
                    for (const i in m) {
                        const md: IMenuData = m[i];
                        if (md) {
                            if (hasItemAsMeal(v, md.lunch)) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Lunch Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `lunch`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                            if (hasItemAsMeal(v, md.tea)) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Tea Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `tea`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                        }
                    }
                }
            }
            results.push(stat);
        }
    }

    return results;
};

const calcTeasUsageStats = (state: IState): IMenuItemUsageStats[] => {
    const items: IMenuMealMap = getAllMenuTeas(state.menu);
    const menuDataByCycle: IMenuDataByCycleMap = getMenuDataByCycleMap(state.menu);
    const results: IMenuItemUsageStats[] = [];
    for (const k in items) {
        const v: IMenuMeal = items[k];
        if (v && v.isAlternative === false) {
            const stat: IMenuItemUsageStats = {
                id: k,
                label: v.item,
                usedCount: 0,
                usedByMenuIds: [],
                usedByLabels: [],
                itemType: MenuItemUsageStat.Sides
            }
            for (const cycle in menuDataByCycle) {
                const m: IMenuDataMap = menuDataByCycle[cycle];
                if (m) {
                    for (const i in m) {
                        const md: IMenuData = m[i];
                        if (md) {
                            if (hasItemAsMeal(v, md.lunch)) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Lunch Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `lunch`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                            if (hasItemAsMeal(v, md.tea)) {
                                const day: number = getDayFromId(md.id);
                                const week: number = getWeekFromId(md.id);
                                const label: string = `Tea Week ${week} Day ${day} Cycle ${getMenuCycleDefinitionName(cycle, state)}`;
                                const menuId: IMenuItemId = {
                                    cycle,
                                    index: md.id,
                                    session: `tea`,
                                    label
                                }
                                if (stat.usedByMenuIds) {
                                    stat.usedByMenuIds.push(menuId);
                                }
                                stat.usedByLabels.push(label);
                                stat.usedCount++;
                            }
                        }
                    }
                }
            }
            results.push(stat);
        }
    }

    return results;
};

export const getMenuItemStats = (itemType: MenuItemUsageStat, state: IState): IMenuItemUsageStats[] => {
    switch (itemType) {
        case MenuItemUsageStat.Ingredients: {
            return calcAllergensUsageStats(state);
        }
        case MenuItemUsageStat.Desserts: {
            return calcDesserstUsageStats(state);
        }
        case MenuItemUsageStat.Lunches: {
            return calcLunchesUsageStats(state);
        }
        case MenuItemUsageStat.Sides: {
            return calcSidesUsageStats(state);
        }
        case MenuItemUsageStat.Teas: {
            return calcTeasUsageStats(state);
        }
        default:
            return [];
    }
};