import createSagaMiddleware from 'redux-saga';

import {
    applyMiddleware,
    combineReducers,
    createStore
} from 'redux';

import {
    all,
    fork
} from 'redux-saga/effects';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { IState } from './state';

import {
    appConfirmationsAddedSaga,
    appConfirmationsUpdatedSaga,
    appCopyWeeksOrders,
    appLoadAllConfirmations,
    appLoadAllRunners,
    appLoadAllUsers,
    appLoadFeatures,
    appLoadUser,
    appLoadUserNotes,
    appSaveAmendmentOrderForDay,
    appSaveOrderForDay,
    appToggleUserOrderUpdateEvent,
    appUsersUpdatedSaga,
    appUploadCachedConfirmations,
    appSignaturesSaga,
} from '../App/sagas/appSaga';

import {
    appLoadAppConfig,
    appAllOrdersSaga,
    appUserOrdersSaga,
    appAllOrderLogsSaga,
    appUserOrderLogsSaga
} from '../App/sagas/firestoreSaga';

import {
    appCalcLunchDessertJoggers,
    appCalcLunchDessertJoggersForRange,
    appCalcTeaDessertJoggers,
    appCalcTeaDessertJoggersForRange,

    appCalcLunchSideJoggers,
    appCalcTeaSideJoggers,
    appCalcLunchSideJoggersForRange,
    appCalcTeaSideJoggersForRange,

    appCalcMainJoggers,
    appCalcTeaJoggers,
    appCalcMainJoggersForRange,
    appCalcTeaJoggersForRange,
    appCalcDietaryStatsForDateRange,
    appCalcMenuItemStatsForDateRange,
    appSignatureReportsSaga,
} from '../App/sagas/reportsSaga';

import {
    appLoadAllCheckDescribers,
    appLoadAllCheckCustomControls,
    appPrerequisiteSaga,
} from '../App/sagas/checksSaga';

import {
    appLoadAllMenus,
    appLoadAllPdfUrls,
    appSaveMenuData,
    appDietaryCodesSaga,
    appLoadAlternatives,
    appLoadAlternativeMenuMap,
    appLoadPortionRuleData,
} from '../App/sagas/menuSaga';

import {
    authConnectionStateSaga,
} from '../Auth/sagas/authSaga';

import { appInitialState } from '../App/store/appInitialState';
import appReducer from '../App/store/appBundle';

import authReducer, {
    authInitialState
} from '../Auth/store/authBundle';

import { checksInitialState } from '../App/store/checksInitialState';
import checksReducer from '../App/store/checksBundle';

import { menuInitialState } from '../App/store/menuInitialState';
import menuReducer from '../App/store/menuBundle';

import { reportsInitialState } from '../App/store/reportsInitialState';
import reportsReducer from '../App/store/reportsBundle';

import { pdfsLinksInitialState } from '../App/store/pdfsLinksInitialState';
import pdfsLinksReducer from '../App/store/pdfsLinksBundle';

import { viewInitialState } from '../App/store/viewInitialState';
import viewReducer from '../App/store/viewBundle';

function* rootSaga() {
    yield all([
        fork(appLoadAllPdfUrls),
        fork(authConnectionStateSaga),
        fork(appConfirmationsAddedSaga),
        fork(appConfirmationsUpdatedSaga),
        fork(appLoadAppConfig),
        fork(appLoadAlternatives),
        fork(appLoadAlternativeMenuMap),
        fork(appLoadAllConfirmations),
        fork(appLoadAllMenus),
        fork(appLoadAllRunners),
        fork(appLoadUser),
        fork(appLoadAllUsers),
        fork(appLoadFeatures),
        fork(appLoadPortionRuleData),
        fork(appLoadUserNotes),
        fork(appDietaryCodesSaga),
        fork(appSaveMenuData),
        fork(appSaveAmendmentOrderForDay),
        fork(appSaveOrderForDay),
        fork(appToggleUserOrderUpdateEvent),
        fork(appCopyWeeksOrders),
        fork(appUsersUpdatedSaga),
        fork(appCalcLunchDessertJoggers),
        fork(appCalcLunchDessertJoggersForRange),
        fork(appCalcTeaDessertJoggers),
        fork(appCalcTeaDessertJoggersForRange),
        fork(appCalcLunchSideJoggers),
        fork(appCalcTeaSideJoggers),
        fork(appCalcLunchSideJoggersForRange),
        fork(appCalcTeaSideJoggersForRange),
        fork(appCalcMainJoggers),
        fork(appCalcTeaJoggers),
        fork(appCalcMainJoggersForRange),
        fork(appCalcTeaJoggersForRange),
        fork(appUploadCachedConfirmations),
        fork(appLoadAllCheckDescribers),
        fork(appLoadAllCheckCustomControls),
        fork(appCalcDietaryStatsForDateRange),
        fork(appCalcMenuItemStatsForDateRange),
        fork(appSignatureReportsSaga),
        fork(appSignaturesSaga),
        fork(appPrerequisiteSaga),
        fork(appAllOrdersSaga),
        fork(appUserOrdersSaga),
        fork(appAllOrderLogsSaga),
        fork(appUserOrderLogsSaga),
    ]);
}

export const getInitialState = (): IState => {
    const state: IState = {
        app: appInitialState(),
        auth: authInitialState(),
        checks: checksInitialState(),
        menu: menuInitialState(),
        reports: reportsInitialState(),
        pdfsLinks: pdfsLinksInitialState(),
        view: viewInitialState(),
    };

    return state;
};

let store: any;

export const getState = () => {
    if (store) {
        return store.getState();
    }
    return undefined;
}

export const configure = () => {
    const sagaMiddleware = createSagaMiddleware();
    const root = combineReducers({
        app: appReducer,
        auth: authReducer,
        checks: checksReducer,
        menu: menuReducer,
        reports: reportsReducer,
        pdfsLinks: pdfsLinksReducer,
        view: viewReducer,
    });

    store = createStore(
        root,
        getInitialState(),
        composeWithDevTools(applyMiddleware(sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
};

export default configure;