import {
    eventChannel
} from 'redux-saga';

import {
    db,
} from '../../Storage';

import {
    ISentConfirmationDates,
} from '../../App/interfaces/IAppState';

import { IUser } from '../../Auth/interfaces/IUser';

import {
    TypeKeys
} from '../store';
import { ISignatureInfo } from '../../Common/interfaces/ISignatureInfo';
import { dateAsNumberN, N_DATE_TIME_STORAGE_KEY_FORMAT, parseFormat } from '../../Common/utils/dateFunctions';


//  Listen to user record updates
export function usersUpdateChannel() {
    const ref = db.child('users');
    const channel = eventChannel(emit => {
        ref.on('child_changed', updates => {
            if (updates) {
                const userKey = updates.key;
                const userChanged = updates.val();

                const user: IUser = {
                    ...userChanged,
                    uid: userKey
                };

                emit(
                    {
                        payload: {
                            uid: userKey,
                            user
                        },
                        type: TypeKeys.REG_ALL_USERS_UPDATE_RES,
                    }
                );
            }
        });

        const unsubscribe = () => {
            // tslint:disable-next-line:no-console
            // console.log(`usersUpdateChannel unsubscribe`);
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

//  Listen to user record added
export function usersAddedChannel() {

    const ref = db.child('users');
    const channel = eventChannel(emit => {
        ref.on('child_added', updates => {
            if (updates) {
                const userKey = updates.key;
                const userChanged = updates.val();
                const user: IUser = {
                    ...userChanged,
                    uid: userKey
                };
                if (user) {
                    // tslint:disable-next-line:no-console
                    console.log(`usersAddedChannel key=${userKey}`);
                }
            }
        });

        const unsubscribe = () => {
            // tslint:disable-next-line:no-console
            // console.log(`usersAddedChannel unsubscribe`);
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}


//  Listen to user record updates
export function confirmationUpdateChannel() {
    const ref = db.child('confirmations');
    const channel = eventChannel(emit => {
        ref.on('child_changed', updates => {
            if (updates) {
                const key = updates.key as string;
                const value = updates.val() as string;

                const confirmations: ISentConfirmationDates = {
                    [key]: value
                };

                emit(
                    {
                        payload: confirmations,
                        type: TypeKeys.REG_CONFIRMATION_EVENTS_RES,
                    }
                );
            }
        });

        const unsubscribe = () => {
            // tslint:disable-next-line:no-console
            // console.log(`confirmationUpdateChannel unsubscribe`);
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

export function confirmationAddedChannel() {
    return eventChannel(emit => {
        db.child('confirmations').on('child_added', updates => {
            if (updates) {

                const key = updates.key as string;
                const value = updates.val() as string;

                const confirmations: ISentConfirmationDates = {
                    [key]: value
                };

                emit(
                    {
                        payload: confirmations,
                        type: TypeKeys.REG_CONFIRMATION_EVENTS_RES,
                    }
                );
            }
        });

        const unsubscribe = () => {
            // tslint:disable-next-line:no-console
            // console.log(`confirmationAddedChannel unsubscribe`);
            db.child('confirmations').off('value');
        }
        return unsubscribe;
    });
}


export const processDeliveryConfirmations = (uid: string, dates: any): ISignatureInfo[] => {
    const all: ISignatureInfo[] = [];
    for (const date in dates) {
        if (date) {
            const info = dates[date];
            if (info) {
                const newInfo: ISignatureInfo = {
                    url: info.url,
                    uid,
                    dateTime: date,
                    dateValue: dateAsNumberN(parseFormat(date, N_DATE_TIME_STORAGE_KEY_FORMAT)),
                    data: info.data,
                }
                all.push(newInfo);
            }
        }
    }
    return all;
};


