import firebase from 'firebase/compat/app';

import 'firebase/auth';

import { IState } from '../../Store/state';

import {
    auth
} from '../../Storage';

export const getLoggedInUid = (): string | undefined => {
    const userInfo: firebase.User | null = auth.getLoggedInUser();

    if (userInfo) {
        return userInfo.uid;
    }
    
    return undefined;
};

export const getError = (state: IState): Error | undefined => {
    return state.auth.error;
};

export const isConnected = (state: IState): boolean => {
    return state.auth.connected;
};

export const isAuthorised = (state: IState): boolean => {
    return state.auth.status.authorised;
};

export const isAdmin = (state: IState): boolean => {
    return state.auth.status.isAdmin;
};