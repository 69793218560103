import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    RouteComponentProps
} from 'react-router-dom';
import { StaticContext } from 'react-router';

import 'react-day-picker/lib/style.css';

/*
FIXED CSS BUG in fomantic-css/semantic.min.css
Modified .ui.sidebar

Changed this
pushable:not(body){-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);overflow-y:hidden

To this
.pushable:not(body){-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}


*/

import './Styles/fomantic-css/semantic.min.css';

//  Groovy Icons
//  https://fomantic-ui.com/elements/icon.html

import './Styles/index.scss';

import ProtectedRoute, {
    IProtectedRouteParentProps
} from './Auth/components/ProtectedRoute';

import SpinnerWithTitle from './Common/components/SpinnerWithTitle';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { configure } from './Store/store';
import { initialiseOfflineStore } from './Storage/offlineStore';
import { userRedirect, adminRedirect, driverRedirect, kitchenRedirect, managerRedirect, printLabelsMainRedirect, printLabelsTeaRedirect, printLabelsMainSideRedirect, printLabelsTeaSideRedirect } from './redirects';
import { AuthProvider } from './Auth/hooks/useAuth';
import { DataDispatchProvider } from './Common/hooks/DataDispatchContext';
import { DataDispatch } from './Common/components/DataDispatch';

initialiseOfflineStore();

const AsyncDriverView = lazy(() => import('./App/components/DriverView'));
const AsyncKitchenView = lazy(() => import('./App/components/KitchenView'));
const AsyncManagerView = lazy(() => import('./App/components/ManagerView'));
const AsyncUserView = lazy(() => import('./App/components/UserView'));
const AsyncAdminView = lazy(() => import('./App/components/AdminView'));
const AsyncLogin = lazy(() => import('./Auth/components/Login'));

const AsyncJoggerLabelsMain = lazy(() => import('./App/components/joggers/JoggerLabelsMain'));
const AsyncJoggerLabelsTea = lazy(() => import('./App/components/joggers/JoggerLabelsTea'));
const AsyncJoggerLabelsMainSide = lazy(() => import('./App/components/joggers/JoggerLabelsMainSide'));
const AsyncJoggerLabelsTeaSide = lazy(() => import('./App/components/joggers/JoggerLabelsTeaSide'));

const store = configure();

const defaultProtectedRouteProps: IProtectedRouteParentProps = {
    authenticationPath: '/'
};

interface IAdminLoadFlag {
    loaded: boolean;
}

const adminLoadFlag: IAdminLoadFlag = {
    loaded: false,
}

export { adminLoadFlag };

// console.log(`USING FOMATIC CSS`);

const SpinnerInApp: React.FC = (): React.ReactElement => {
    return (
        <div className='app'>
            <SpinnerWithTitle mini={true} title={''} />
        </div>
    );
}

const ReloadApp: React.FC = (): React.ReactElement => {
    return (
        <div>
            <div className={'refresh-content'}>
                <div className={'refresh-content-icon'} />
                <div className={'refresh-content-text'}>{`New content is available.`}</div>
                <div className={'refresh-content-text'}>{`Close this window and restart.`}</div>
            </div>
        </div>
    );
};

const RenderLogin = (props: RouteComponentProps<any, StaticContext, any>): JSX.Element => {
    // return <SpinnerWithTitle title={'Loading...'} />;
    return (
        <AsyncLogin
            {...props}
            userPath={userRedirect}
            adminPath={adminRedirect}
            driverPath={driverRedirect}
            kitchenPath={kitchenRedirect}
            managerPath={managerRedirect}
        />
    );
};

// ReactDOM.render(<ReloadApp/>, document.getElementById('root') as HTMLElement);
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <DataDispatchProvider>
                    <DataDispatch>
                        <Router >
                            <Suspense fallback={<SpinnerInApp />}>
                                <Switch>
                                    <Route
                                        exact={true}
                                        path="/"
                                        render={RenderLogin}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={adminRedirect}
                                        component={AsyncAdminView}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={driverRedirect}
                                        component={AsyncDriverView}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={kitchenRedirect}
                                        component={AsyncKitchenView}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={managerRedirect}
                                        component={AsyncManagerView}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={userRedirect}
                                        component={AsyncUserView}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={printLabelsMainRedirect}
                                        component={AsyncJoggerLabelsMain}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={printLabelsTeaRedirect}
                                        component={AsyncJoggerLabelsTea}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={printLabelsMainSideRedirect}
                                        component={AsyncJoggerLabelsMainSide}
                                    />
                                    <ProtectedRoute
                                        {...defaultProtectedRouteProps}
                                        exact={true}
                                        path={printLabelsTeaSideRedirect}
                                        component={AsyncJoggerLabelsTeaSide}
                                    />

                                </Switch>
                            </Suspense>
                        </Router>
                    </DataDispatch>
                </DataDispatchProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
);

const onUpdate = (registration: ServiceWorkerRegistration): void => {
    registration.update()
        .then(() => {
            ReactDOM.render(<ReloadApp />, document.getElementById('root') as HTMLElement);
        })
        .catch((e: Error) => {
            console.error(`onUpdate error ${e}`);
        });
};

const onSuccess = (registration: ServiceWorkerRegistration): void => {
    console.log(`onSuccess`);
};

const config = {
    onUpdate,
    onSuccess
};

serviceWorkerRegistration.register(config);
