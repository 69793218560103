import {
    db,
} from '../../Storage';
import { IMenuExtraMap } from '../../App/interfaces/IMenuExtra';

export const MENU_DESSERTS_PATH: string = 'data/food/menu_desserts';
export const MENU_SIDES_PATH: string = 'data/food/menu_sides';

export const loadMenuExtras = (extra: string): Promise<Readonly<IMenuExtraMap>> => {
    return new Promise((resolve, reject) => {
        db.child(extra).once('value')
        .then( (snapshot) => {
            const values = snapshot.val();

            const result: IMenuExtraMap = {};
            for (const k in values) {
                if (k) {
                    result[k] = values[k];
                }
            }

            resolve(result);
        })
        .catch ( (error) => {
            console.error(`loadMenuExtras ${extra} ${JSON.stringify(error)}`);
            reject(error);
        })
    });
};