import { IEmailOptions } from "../../../Common/interfaces/IEmailOptions";
import { isUndefinedOrEmpty } from "../../../Common/utils/typeGuards";

export enum CheckType {
    Input,
    Check,
    DropDown,
    Range,
    InternalDropdown,
    ItemCheck,
    Label,
    AlertCheck,
    PrereqCheck
}

export enum ValidType {
    None,
    FreeText,
    Numeric,
    Check
}

export enum AlertRuleType {
    Standard,
    PreRequisite,
    Other
}

export interface AlertRuleTypeLabelMap {
    [type: number]: string;
}

export const AlertRuleTypeToLabel: AlertRuleTypeLabelMap = {
    [AlertRuleType.Standard]: `Standard`,
    [AlertRuleType.PreRequisite]: `Report Issue`,
} as const;

export interface IAlertRule {
    email: string;
    alertMessage: string;
    type: AlertRuleType;
}

export const getAlertRuleType = (rule: IAlertRule | undefined | null): AlertRuleType => {
    if (rule === undefined || rule === null) {
        return AlertRuleType.Standard;
    }
    
    if (rule.type === undefined) {
        return AlertRuleType.Standard;
    }
    
    return rule.type;
};

export interface ICheckDescriber {
    id: string;
    description: string;
    type: CheckType;
    customId: string;
    sortOn: number;
    validation: ValidType;
}

export interface ICheckDescriberPosition {
    id: string;
    sortOn: number;
}

export interface ICheckDescriberMap {
    [id: string]: ICheckDescriber;
}

export interface ICheckGroup {
    id: string;
    tabOrder: number;
    name: string;
    enabled: boolean;
    category?: string;
    inReport?: boolean;     //  is it in the report for the manager to see - i.e opening closing etc?
}
export const defaultForNoCategory: string = `other`;

export interface ICheckGroupCategoryMap {
    [id: string]: string;
}

export interface ICheckGroupCategoryMaxTabOrderMap {
    [id: string]: number;
}

export interface ICheckGroupsCategoryMap {
    [category: string]: ReadonlyArray<ICheckGroup>;
}

export interface ICheckGroupMap {
    [id: string]: ICheckGroup;
}

export interface ICheckDescriberGroupMap {
    [id: string]: ICheckDescriberMap;
}

export interface IRange {
    hi: number;
    lo: number;
    rule?: IAlertRule | null;
}

export interface ICustomCheckControl {
    id: string;
    name: string;
}

export interface ICustomDropDownsCheckControl extends ICustomCheckControl {
    values: ReadonlyArray<string>;
}

export interface ICustomInternalDropdownsCheckControl extends ICustomCheckControl {
    dataPath: string;
}

export interface ICustomRangesCheckControl extends ICustomCheckControl, IRange {
    protocol?: string | null;
}

export interface ICustomItemCheckControl extends ICustomCheckControl {
    checkLabel: string;
    items: ReadonlyArray<string>;
}

export interface ICustomPrereqCheckControl extends ICustomCheckControl {
    alertName: string;
    noAlertName: string;
}

export interface ICustomAlertCheckControl extends ICustomCheckControl {
    alertName: string;
    noAlertName: string;
    protocol?: string | null;
    rule?: IAlertRule | null;
}

export interface IAlertCheckResult {
    alertName: string;
    noAlertName: string;
    value: boolean;
    rule?: IAlertRule | null;
}

export interface IRangeResult extends IRange {
    value: number;
}

export interface IItemCheckCode {
    name: string;
    units: string;
    checkCode: string;
}

export type CheckResultTypes = string | boolean | Array<IItemCheckCode> | IRangeResult | IAlertCheckResult;

export interface ICheckResult {
    id: string;
    description: string;
    value: CheckResultTypes;
    sortOn: number;
    type: CheckType;
}

export interface IGroupCheckResult {
    group: string;
    name: string;
    userName: string | undefined;
    checks: ReadonlyArray<ICheckResult>;
    url: string;
    time: Date;
}

export interface ICustomDropDownsCheckControlMap {
    [id: string]: ICustomDropDownsCheckControl;
}
export interface ICustomItemCheckControlMap {
    [id: string]: ICustomItemCheckControl;
}
export interface ICustomInternalDropdownsCheckControlMap {
    [id: string]: ICustomInternalDropdownsCheckControl;
}
export interface ICustomRangesCheckControlMap {
    [id: string]: ICustomRangesCheckControl;
}
export interface ICustomAlertCheckControlMap {
    [id: string]: ICustomAlertCheckControl;
}
export interface ICustomPrereqCheckControlMap {
    [id: string]: ICustomPrereqCheckControl;
}

export interface IGroupCheckResultMap {
    [group: string]: ReadonlyArray<IGroupCheckResult>;
}

export interface IRuleExceptionsResult {
    protocols: ReadonlyArray<string>;
    emails: ReadonlyArray<IEmailOptions>;
}

export enum PrerequisiteUrgency {
    Low,
    Medium,
    High,
    Extreme
}

export interface UrgencyLabelMap {
    [urgency: number]: string;
}

export const UrgencyToLabel: UrgencyLabelMap = {
    [PrerequisiteUrgency.Low]: `Low`,
    [PrerequisiteUrgency.Medium]: `Medium`,
    [PrerequisiteUrgency.High]: `High`,
    [PrerequisiteUrgency.Extreme]: `Extreme`,
} as const;

export const UrgencyLabels = [`Low`, `Medium`, `High`, `Extreme`] as const;

export interface IPrerequisiteCheckMeta {
    checkId: string;
    checkName: string;
}

export interface IPrerequisiteMeta {
    id: string;
    urgency: PrerequisiteUrgency;
    resolved: boolean;
    description: string;
    reportedBy: string;
}
export interface IPrerequisite extends IPrerequisiteMeta, IPrerequisiteCheckMeta {
    time: Date;
    groupId: string;
    groupName: string;
}

export interface PrerequisiteMap {
    [uid: string]: IPrerequisite;
}

export interface IPrerequisiteSignatureMeta {
    issue: string;
    reportedBy: string;
    reportedTime: string;
    reportedUrgency: PrerequisiteUrgency;
    resolvedAction: string;
    resolvedBy: string;
    resolvedByLoggedInUser: string;
    resolvedTime: string;
}

export type PrerequisiteEventType = 'updated' | 'added' | 'deleted';

export const CHECK_GROUP_CONFIG: string = `config`;
export const DESCRIBER_PATH: string = `checks/describers`;

export const PREREQUISITE_ROOT_PATH: string = `prerequisite`;
export const PREREQUISITE_PENDING_PATH: string = `${PREREQUISITE_ROOT_PATH}/pending`;
export const PREREQUISITE_COMPLETE_PATH: string = `${PREREQUISITE_ROOT_PATH}/complete`;

export const CUSTOM_CONTROLS_PATH: string = `checks/custom_controls`;
export const CUSTOM_CONTROL_DROPDOWNS: string = `dropdowns`;
export const CUSTOM_CONTROL_RANGES: string = `ranges`;
export const CUSTOM_CONTROL_ITEM_CHECK: string = `item_checks`;
export const CUSTOM_CONTROL_ALERT_CHECK: string = `alert_check`;
export const CUSTOM_CONTROL_PREREQ_CHECK: string = `prereq_check`;
export const CUSTOM_CONTROL_INTERNAL_DROPDOWNS: string = `internal_dropdowns`;

export const itemCheckCodeKey = (i: IItemCheckCode): string => {
    return `${i.units}_${i.name}_${i.checkCode}`;
}

export const itemCheckCodeMatch = (a: IItemCheckCode, b: IItemCheckCode): boolean => {
    return a.name === b.name && a.checkCode === b.checkCode;
}

export const LabelForRange = (item: ICustomRangesCheckControl): string => {
    return `${item.name}: ${item.lo} to ${item.hi}`;
}

export const LabelForCheckType = (t: CheckType): string => {
    if (t === CheckType.Input) {
        return `Number/Text`;
    }
    else if (t === CheckType.Check) {
        return `Check Box`;
    }
    else if (t === CheckType.AlertCheck) {
        return `Alert Check`;
    }
    else if (t === CheckType.PrereqCheck) {
        return `Prerequisite Check`;
    }
    else if (t === CheckType.DropDown) {
        return `User DropDown`;
    }
    else if (t === CheckType.Range) {
        return `Range`;
    }
    else if (t === CheckType.ItemCheck) {
        return `Item Check`;
    }
    else if (t === CheckType.Label) {
        return `Label`;
    }
    return `Internal Dropdown`;
};

export const LabelForValidType = (t: ValidType): string => {
    if (t === ValidType.Check) {
        return `Checked`;
    }
    else if (t === ValidType.None) {
        return `None`;
    }
    else if (t === ValidType.FreeText) {
        return `Text`;
    }
    return `Number`;
};

export const compareCheckGroupsByCategory = (a: ICheckGroup, b: ICheckGroup): number => {
    if (isUndefinedOrEmpty(a.category) && isUndefinedOrEmpty(b.category)) {
        return 0;
    }

    if (isUndefinedOrEmpty(a.category) && !isUndefinedOrEmpty(b.category)) {
        return defaultForNoCategory.localeCompare(b.category);
    }

    if (isUndefinedOrEmpty(b.category) && !isUndefinedOrEmpty(a.category)) {
        return a.category.localeCompare(defaultForNoCategory);
    }

    if (!isUndefinedOrEmpty(a.category) && !isUndefinedOrEmpty(b.category)) {
        return a.category.localeCompare(b.category);
    }

    return 0;
};

export const compareCheckGroupsByTabOrder = (a: ICheckGroup, b: ICheckGroup): number => {
    return a.tabOrder - b.tabOrder;
};

export const compareCheckGroupsByName = (a: ICheckGroup, b: ICheckGroup): number => {
    return a.name.localeCompare(b.name);
};

export const compareByCheckGroupThenByName = (a: ICheckGroup, b: ICheckGroup): number => {
    return compareCheckGroupsByCategory(a, b) || compareCheckGroupsByName(a, b);
};

export const compareByCheckGroupThenByTabOrder = (a: ICheckGroup, b: ICheckGroup): number => {
    return compareCheckGroupsByCategory(a, b) || compareCheckGroupsByTabOrder(a, b);
};

export const compareGroupCheckResultsByName = (a: IGroupCheckResult, b: IGroupCheckResult): number => {
    return a.name.localeCompare(b.name);
};