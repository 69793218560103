import { IDietaryCodesOrder } from '../interfaces/IDietaryCodesOrder';

export const getTotalSpecialsCount = (s1: IDietaryCodesOrder | undefined): number => {
    let result: number = 0;

    if (s1) {
        for (const code in s1) {
            if (code) {
                const count: number = s1[code];
                result = result + count;
            }
        }
    }
    
    return result;
};

