import React, { useEffect, useContext, createContext } from "react";
import { Dispatch } from 'redux';

import { useAuth } from "../../Auth/hooks/useAuth";
import { getUserTypeId, UserTypeId } from "../../Auth/interfaces/IUser";

import {
    load as kdLoad,
    unload as kdUnload
} from '../../App/store/driverKitchenViewDataLoadUnload';
import {
    load as adLoad,
    unload as adUnload
} from '../../App/store/adminViewDataLoadUnload';
import {
    load as manLoad,
    unload as manUnLoad
} from '../../App/store/managerViewDataLoadUnload';

type LoadT = (dispatch: Dispatch<any>) => void;
type UnloadT = (dispatch: Dispatch<any>) => void;

export interface IDataDispatchContext {
    load: LoadT;
    unload: UnloadT;
}

type DefaultValue = undefined;
export type DataDispatchContextValue = IDataDispatchContext | DefaultValue;

const DataDispatchContext = createContext<DataDispatchContextValue>(undefined);
export const DataDispatchContextProvider = DataDispatchContext.Provider;
export const DataDispatchContextConsumer = DataDispatchContext.Consumer;

export interface IDataDispatchProviderProps {
    orgUid: string | undefined;
    children?: React.ReactNode;
}
export const DataDispatchProvider: React.FC = ({ children }) => {
    const authContext = useDataDispatchProvider();
    return <DataDispatchContextProvider value={authContext}>{children}</DataDispatchContextProvider>;
};

export const useDataDispatch = () => {
    const context = useContext(DataDispatchContext);
    if (context === undefined) {
        throw new Error('useDataDispatch must be used within a DataDispatchProvider')
    }
    return context
};

function useDataDispatchProvider(): IDataDispatchContext {
    const { userInfo } = useAuth();

    useEffect(() => {
        console.log(`useDataDispatchProvider.mounted`);
        return () => {
            console.log(`useDataDispatchProvider.unmounted`);
        };
    }, []);

    const load = React.useCallback((dispatch: Dispatch<any>): void => {
        if (userInfo) {
            const userType: UserTypeId = getUserTypeId(userInfo.userType);
            console.log(`useDataDispatchProvider.load.${userInfo.userType}`);
            switch (userType) {
                case UserTypeId.Administrator:
                    adLoad(dispatch);
                    break;

                case UserTypeId.Driver:
                    kdLoad(dispatch);
                    break;

                case UserTypeId.Kitchen:
                    kdLoad(dispatch);
                    break;

                case UserTypeId.Manager:
                    manLoad(dispatch);
                    break;

                default:
                    break;
            }
        }
    },[userInfo]);

    const unload = React.useCallback((dispatch: Dispatch<any>): void => {
        if (userInfo) {
            const userType: UserTypeId = getUserTypeId(userInfo.userType);
            console.log(`useDataDispatchProvider.unload.${userInfo.userType}`);
            switch (userType) {
                case UserTypeId.Administrator:
                    adUnload(dispatch);
                    break;

                case UserTypeId.Driver:
                    kdUnload(dispatch);
                    break;

                case UserTypeId.Kitchen:
                    kdUnload(dispatch);
                    break;

                case UserTypeId.Manager:
                    manUnLoad(dispatch);
                    break;

                default:
                    break;
            }
        }
    },[userInfo]);

    const result: IDataDispatchContext = {
        load,
        unload
    };

    return result;
};
