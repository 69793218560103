import memoize from 'memoize-one';

import { compareCheckGroupsByName, compareCheckGroupsByTabOrder, ICheckGroup, ICheckGroupCategoryMaxTabOrderMap } from '../../App/components/checks/interfaces';


export const getAllOtherGroupNames = memoize((allCheckGroups: ReadonlyArray<ICheckGroup>, group: ICheckGroup): ReadonlyArray<string> => {
    return allCheckGroups.filter((cg) => cg.name !== group.name).map((cg) => cg.name);
});

export const getNextCategoryTabOrder = memoize((category: string | undefined, maxTabOrderPerCategory: Readonly<ICheckGroupCategoryMaxTabOrderMap>): number => {
    if (category !== undefined && maxTabOrderPerCategory[category] !== undefined) {
        return maxTabOrderPerCategory[category] + 1;
    }
    return 0;
});

export const filterChecksForCategory = (category: string | undefined, groups: ReadonlyArray<ICheckGroup>) => {
    return groups.filter((g) => {
        if (g.category) {
            if (g.category === category) {
                return true;
            }
            else {
                return false
            }
        }
        return false;
    }).sort(compareCheckGroupsByName);
};

export const filterChecksForCategorySortOnTabIndex = (category: string | undefined, groups: ReadonlyArray<ICheckGroup>) => {
    return groups.filter((g) => {
        if (g.category) {
            if (g.category === category) {
                return true;
            }
            else {
                return false
            }
        }
        return false;
    }).sort(compareCheckGroupsByTabOrder);
};