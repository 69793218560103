import { IDietaryCodesOrder } from "../../App/interfaces/IDietaryCodesOrder";
import { IStringToNumberMap } from "../../Common/interfaces/IStringToNumberMap";
import {
    getAllUserUids,
    getNameForUid,
} from "./appSelectors";
import {
    getAllDietaryCodes,
    getAllMenuAlternativesMap
} from "./menuSelectors";

import { loadAllOrdersForDateRange } from "./loadAllOrdersForDateRange";
import { IDayOrder, IOrderMap } from "../../App/interfaces/IDayOrder";
import { IDietaryCodesMap } from "../../App/interfaces/IDietaryCodes";
import { IAlternativeMap, IAlternative } from '../../App/interfaces/IAlternatives';
import { IDietaryUsageStats } from '../../App/interfaces/IDietaryUsageStats';
import { IState } from '../../Store/state';

const getCountsAndDatesForDietaries = (uid: string, dietaries: IDietaryCodesOrder | undefined, orderDate: number, lastUser: IStringMap, count: IStringToNumberMap, dates: IStringToNumberMap): void => {
    if (dietaries !== undefined) {
        for (const dc in dietaries) {
            if (dc) {
                const value: number | undefined | null = dietaries[dc];
                if (value !== undefined && value !== null) {
                    if (count[dc]) {
                        count[dc] += value;
                    }
                    else {
                        count[dc] = value;
                    }

                    if (dates[dc] && dates[dc] < orderDate) {
                        dates[dc] = orderDate;
                        lastUser[dc] = uid;
                    }
                    else if (dates[dc] === undefined) {
                        dates[dc] = orderDate;
                        lastUser[dc] = uid;
                    }
                }
            }
        }
    }
};

interface IStringMap {
    [key: string]: string;
}

export const getDietaryStatsForDateRange = (fromDate: number, toDate: number, state: IState): IDietaryUsageStats[] => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(fromDate, toDate, state);
    const uids: ReadonlyArray<string> = getAllUserUids(state.app);
    
    const mostRecentOrder: IStringToNumberMap = {};
    const count: IStringToNumberMap = {};
    const lastUserMap: IStringMap = {};
    
    for (const uid of uids) {
        const orders = Object.values(orderMap).filter((o) => o.uid === uid);
        for (const o of orders) {
            const orderDate: number = o.orderDate;
            const lunch: IDayOrder = o.lunch;
            const tea: IDayOrder = o.tea;
            if (uid && lunch) {
                getCountsAndDatesForDietaries(uid, lunch.special, orderDate, lastUserMap, count, mostRecentOrder);
                getCountsAndDatesForDietaries(uid, lunch.dessertSpecial, orderDate, lastUserMap, count, mostRecentOrder);
            }
            if (uid && tea) {
                getCountsAndDatesForDietaries(uid, tea.special, orderDate, lastUserMap, count, mostRecentOrder);
                getCountsAndDatesForDietaries(uid, tea.dessertSpecial, orderDate, lastUserMap, count, mostRecentOrder);
            }
        }
    }

    const stats: IDietaryUsageStats[] = [];

    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);
    for (const dc in allDietaries) {
        if (dc) {
            const usedCount: number = count[dc] === undefined ? 0 : count[dc];
            const isDeleted: boolean = allDietaries[dc].deleted;
            const lastUsed: number = mostRecentOrder[dc] === undefined ? 0 : mostRecentOrder[dc];
            const lastUserUid: string | undefined = mostRecentOrder[dc] === undefined ? undefined : lastUserMap[dc];
            const lastUser: string | undefined = lastUserUid !== '' ? getNameForUid(lastUserUid, state.app) : '';

            const allMenuAlts: IAlternativeMap = getAllMenuAlternativesMap(state.menu);
            const alternativeItemIds: string[] = [];
            for (const a in allMenuAlts) {
                if (a) {
                    const alt: IAlternative = allMenuAlts[a];
                    if (alt && alt.dietaries) {
                        const dietary: string | undefined = alt.dietaries[dc];
                        if (dietary !== undefined) {
                            alternativeItemIds.push(alt.itemId);
                        }
                    }
                }
            }

            const ds: IDietaryUsageStats = {
                dc,
                label: allDietaries[dc].label,
                usedCount,
                isDeleted,
                lastUsed,
                lastUser,
                lastUserUid,
                alternativeItemIds
            };

            stats.push(ds);

            if (count[dc] === undefined && allDietaries[dc].deleted) {
                count[dc] = -1;
            }
            else if (count[dc] === undefined) {
                count[dc] = 0;
            }
        }
    }
    return stats;
};