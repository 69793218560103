import { IAllMenuCycleMap, IMenuCycleDefinitionMap } from './IMenuCycle';

import { IMenuExtraMap } from './IMenuExtra';

import { IMenuAllergenMap, IMenuAllergyMap } from './IMenuAllergen';

import { IMenuMealMap } from './IMenuMeal';

import { IMenuDataByCycleMap } from './IMenuData';

import { IMenuMapByCycle } from './IMenu';

export const DEFAULT_MENU_CYCLE = 'cycle1';

export interface IMenuItemIdToLabelMap {
    [id: string]: string;
}

export interface IMenuItemIdToIsJoggerGramsMap {
    [id: string]: boolean;
}

export interface IAllMenuData {
    currentMenuCycle: string;
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>;
    allMenuCycles: Readonly<IAllMenuCycleMap>;
    menuSides: Readonly<IMenuExtraMap>;
    menuDesserts: Readonly<IMenuExtraMap>;
    menuAllergens: Readonly<IMenuAllergenMap>;
    menuLunches: Readonly<IMenuMealMap>;
    menuTeas: Readonly<IMenuMealMap>;
    menuDataByCycle: Readonly<IMenuDataByCycleMap>;
    displayMenusByCycle: Readonly<IMenuMapByCycle>;
    menuItemIdToLabel: Readonly<IMenuItemIdToLabelMap>;
    isJoggerMultiplierGramsMap: Readonly<IMenuItemIdToIsJoggerGramsMap>;
    allergies: Readonly<IMenuAllergyMap>;
}
