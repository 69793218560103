import { IMenuExtra, defaultMenuExtra } from './IMenuExtra';


// @typescript-eslint/no-empty-interface
export interface IMenuMeal extends IMenuExtra {
}

export interface IMenuMealMap {
    [key: string]: IMenuMeal;
}

export const defaultMenuMeal: IMenuMeal = {
    ...defaultMenuExtra,
}

//  Need to check joggers and reports
export const menuItemNotSelectedId: string = `noMenuItemSelectedId`;
export const menuItemNotSelected: string = `No Item Selected`;