import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { IState } from '../../Store/state';

import { isAuthorised } from '../../Auth/store/';

interface IStateToProps {
    authorised: boolean;
}

const mapStateToProps = (state: IState): IStateToProps => {
    const result: IStateToProps = {
        authorised: isAuthorised(state),
    };
    return result;
};

export interface IProtectedRouteParentProps extends RouteProps {
    authenticationPath: string;
}

type IProps = IProtectedRouteParentProps & IStateToProps;

export const ProtectedRoute: React.FC<IProps> = ({authenticationPath, authorised, ...rest}): React.ReactElement => {
    const redirectPath: string | undefined =  authorised ? undefined : authenticationPath;
    console.log("is Authorised ", authorised);
     if (redirectPath !== undefined) {
        const RenderComponent = () => (<Redirect to={{pathname: redirectPath}}/>);
        return <Route {...rest} component={RenderComponent} render={undefined}/>;
    } else {
        return <Route {...rest}/>;
    }
};

export default connect<IStateToProps, {}, {}, IState>(mapStateToProps)(ProtectedRoute);