export interface IAppConfig {
    loggingOn: boolean;
    orderByHour: number;
    orderByOn: boolean;
    orderByWeekDay: number;
    showKitchenChecks: boolean;
    adminEmail: string;
}

export const defaultConfig: IAppConfig = {
    loggingOn: true,
    orderByHour: 15,
    orderByOn: true,
    orderByWeekDay: 5,
    showKitchenChecks: false,
    adminEmail: `admin@earlyyearscatering.co.uk`,
}
