import memoize from 'memoize-one';
import { startOfDay } from '../../Common/utils/dateFunctions';
import { DropdownItemProps } from '../../third-party-ui/Dropdown';

import { 
    DietaryCodesAllUsers, 
    DietaryCodesCD, 
} from '../../App/interfaces/IDietaryCodes';

import { IDietaryItem } from '../../App/interfaces/IDietaryItem';
import { IUser } from '../../Auth/interfaces/IUser';
import { IComboBoxOptions } from '../../Common/interfaces/IComboBoxOptions';

import { IAppConfig } from '../../App/interfaces/IAppConfig';
import { canAddModifyOrder } from '../../Common/utils/canAddModifyOrder';
import { sortDropdownItemsTextComp } from '../../Common/utils/sortDropdownItems';

export const canEditOrCreateOrder = (appConfig: IAppConfig, loggedInUser: Readonly<IUser> | undefined, orderDate: Date, today: Date = new Date()): boolean => {
    if (orderDate !== null) {
        const orderByOnForUser: boolean = loggedInUser !== undefined ? loggedInUser.orderByOn : false;
        if (appConfig.orderByOn && orderByOnForUser) {
            const orderDateStartOfDay: Date = startOfDay(orderDate);
            return canAddModifyOrder(today, orderDateStartOfDay, appConfig.orderByWeekDay, appConfig.orderByHour);
        }
        return true;
    }
    return false;
};

export const getAllNoneAdminUsersOptions = memoize((allUsers: ReadonlyArray<IUser>) => {
    
    const options: DropdownItemProps[] = allUsers.filter((u) => u.admin === false).map( (sd) => {
            
        const option: IComboBoxOptions = {
            key: sd.uid,
            text: sd.name,
            value: sd.uid
        };
        return option;
    } );

    options.sort(sortDropdownItemsTextComp);

    return options;
});

export const getUserOptions = memoize((user: Readonly<IUser> | undefined) => {
    const options: DropdownItemProps[] = [];
    if (user) {
        if (user.orgs) {
            for (const o in user.orgs) {
                if (o) {
                    const option: IComboBoxOptions = {
                        key: o,
                        text: user.orgs[o].name,
                        value: o,
                    };
                    options.push(option);
                }
            }
        }
    }
    options.sort(sortDropdownItemsTextComp);

    return options;
});

export interface IMapToCodes {
    [key: string]: IDietaryItem[];
}

export const isDietaryForUser = (dietaryUsers: string[], uid: string): boolean => {
    if (dietaryUsers.indexOf(DietaryCodesAllUsers) !== -1 || dietaryUsers.indexOf(uid) !== -1) {
        return true;
    }
    return false;
}

export const hideCD = (code: string, hideCDFlag: boolean): boolean => {
    if (code === DietaryCodesCD && hideCDFlag) {
        return true;
    }
    return false;
}
