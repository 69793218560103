import { IViewState, ITabIdxMap, TAB_NAME_ADMIN_VIEW, TAB_NAME_REPORTS_VIEW, TAB_NAME_JOGGERS_VIEW } from '../../App/interfaces/IViewState';


const tabIdxMap: ITabIdxMap = {};
tabIdxMap[`${TAB_NAME_ADMIN_VIEW}`] = 0;
tabIdxMap[`${TAB_NAME_REPORTS_VIEW}`] = 0;
tabIdxMap[`${TAB_NAME_JOGGERS_VIEW}`] = 0;

export const viewInitialState = (): IViewState => {
    const state: IViewState = {
        tabIdxMap
     };
    return state;
};
