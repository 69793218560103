import firebase from 'firebase/compat/app';
import { uploadStringData, saveUrl } from './dataUpload';

export const SIGNATURES_FOODCHECK_PATH = `signatures/foodcheck`;
export const SIGNATURES_DELIVERY_PATH = `signatures/delivery`;
export const SIGNATURES_CHECKS_PATH = `signatures/checks`;
export const SIGNATURES_PREREQUISITE_PATH = `signatures/prerequisite`;
export const SAVE_SIGNATURE_FORMAT = `image/svg+xml`;

export const getFoodCheckSignatureStoragePath = (color: string, time: string): string => {
    return `${SIGNATURES_FOODCHECK_PATH}/${color}/${time}`;
};

export const getDeliverySignatureStoragePath = (uid: string, time: string): string => {
    return `${SIGNATURES_DELIVERY_PATH}/${uid}/${time}`;
};

export const getChecksGroupSignatureStoragePath = (group: string, time: string): string => {
    return `${SIGNATURES_CHECKS_PATH}/${group}/${time}`;
};

export const getPrerequisiteSignatureStoragePath = (group: string, time: string): string => {
    return `${SIGNATURES_PREREQUISITE_PATH}/${group}/${time}`;
};


export const saveSignature = async (
    storage: firebase.storage.Reference,
    db: firebase.database.Reference,
    metaStoragePath: string,
    dateTime: string,
    data: string,
    additionalData: any | null = null): Promise<void> => {
    const metadata = {
        contentType: SAVE_SIGNATURE_FORMAT,
    };
    const url: string = await uploadStringData(storage, `data_url`, metadata, metaStoragePath, data);
    await saveUrl(db, metaStoragePath, url, dateTime, additionalData);
};
