export const defaultCostPence: number = 250;

export interface IOrg {
    name: string;
}

export interface IOrgs {
    [key: string]: IOrg;    // uid -> IOrg
}

export const AllUserTypes = ['admin', 'driver', 'kitchen', 'user', 'manager'] as const;
export const AllUserTypesDesc = ['Administrator', 'Driver', 'Kitchen', 'User', 'Manager'] as const;

export enum UserTypeId {
    Administrator,
    Driver,
    Kitchen,
    User,
    Manager
}

export interface IUser {
    accr: string;
    active: boolean;
    admin: boolean;
    email: string;
    name: string;
    orgs: IOrgs | undefined;
    orderByOn: boolean;
    uid: string;
    userName: string;
    userType: string;
    lunchCostPence: number | undefined;
    teaCostPence: number | undefined;
}

export interface IUsersMap {
    [key: string]: IUser;
}

export const getOrgsFromUser = (user: IUser): IOrgs => {
    const orgs: IOrgs = user.orgs !== undefined ? user.orgs : {};
    return orgs;
};

export const getUserTypeId = (userType: string): UserTypeId => {
    switch (userType) {
        case AllUserTypes[UserTypeId.Administrator]:
            return UserTypeId.Administrator;

        case AllUserTypes[UserTypeId.Driver]:
            return UserTypeId.Driver;

        case AllUserTypes[UserTypeId.Kitchen]:
            return UserTypeId.Kitchen;

        case AllUserTypes[UserTypeId.Manager]:
            return UserTypeId.Manager;

        default:
            return UserTypeId.User;
    }
};

export const isTypeUser = (userType: string): boolean => {
    return getUserTypeId(userType) === UserTypeId.User;
};

export type IUserInfoType = null | undefined | IUser;