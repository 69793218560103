import React, { useState, useEffect, useContext, createContext } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import firebase from 'firebase/compat/app';


import { IUser, IUserInfoType } from "../../Auth/interfaces/IUser";
import { loadUserInfo } from "../../Auth/sagas/authSaga";
import { isUndefinedOrNull } from "../../Common/utils/typeGuards";

import { auth } from '../../Storage/base';

import {
    actions,
} from '../store';

import {
    actions as appActions,
} from '../../App/store';

export type IUserType = null | undefined | firebase.User;
export type ISignInType = (email: string, password: string) => Promise<IUserInfoType>;
export type ISignOutType = () => Promise<void>;

export interface IAuthContext {
    user: IUserType;
    userInfo: IUserInfoType;
    signin: ISignInType;
    signout: ISignOutType;
}

type DefaultValue = undefined;
export type AuthContextValue = IAuthContext | DefaultValue;

const AuthContext = createContext<AuthContextValue>(undefined);
export const AuthContextProvider = AuthContext.Provider;
export const AuthContextConsumer = AuthContext.Consumer;

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider: React.FC = ({ children }) => {
    const authContext = useAuthProvider();
    return <AuthContextProvider value={authContext}>{children}</AuthContextProvider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
};

// Provider hook that creates auth object and handles state
function useAuthProvider(): IAuthContext {
    const dispatch: Dispatch<any> = useDispatch();

    const [user, setUser] = useState<null | firebase.User>(null);
    const [userInfo, setUserInfo] = useState<null | IUser>(null);

    useEffect(() => {
        console.log(`useAuthProvider.mounted`);
        return () => {
            console.log(`useAuthProvider.unmounted`);
        };
    }, []);

    const signin = async (email: string, password: string): Promise<IUserInfoType> => {
        try {
            const response = await auth.signInWithEmailAndPassword(email, password);
            setUser(response.user);
            if (response.user !== null) {
                const userInfoData: IUser = await loadUserInfo(response.user.uid);

                setUserInfo(userInfoData);
                dispatch(appActions.loadUserRes(userInfoData));
                dispatch(appActions.setLoggedInUser(userInfoData));
                dispatch(actions.signinRes({ authorised: true, isAdmin: userInfoData.admin }));
                return userInfoData;
            }
            else {
                return null;
            }
        }
        catch (err) {
            dispatch(actions.authErr(new Error(err.message)));
        }
    };

    const signout = async (): Promise<void> => {
        await auth.signOut();
        dispatch(actions.signoutRes());
        setUser(null);
        setUserInfo(null);
    };

    useEffect(() => {
        let mounted: boolean = true;
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (mounted) {
                if (isUndefinedOrNull(user)) {
                    dispatch(actions.signoutRes());
                    setUser(null);
                    setUserInfo(null);
                }
            }
        });

        return function cleanup() {
            mounted = false;
            unsubscribe();
        };
    }, [dispatch]);

    // Return the user object and auth methods
    return {
        user,
        userInfo,
        signin,
        signout,
    } as const;
};