import { Dispatch } from 'redux';

import { actions as app } from '../../App/store';
import { actions as auth } from '../../Auth/store';
import { actions as checks } from '../../App/store/checksBundle';
import { actions as menu } from '../../App/store/menuBundle';

import { loadAllRunners } from '../../App/sagas/appSaga';
import { subWeeks } from '../../Common/utils/dateFunctions';

export const unload = (dispatch: Dispatch<any>): void => {
    console.log(`manager.unload`);
    dispatch(app.uregAllOrdersReq());
    dispatch(app.uregAllUsersUpdateReq());
    dispatch(app.uregConfirmationEventsReq());
    dispatch(app.uregSignatureEventsReq());
    dispatch(app.resetStateReq());
    dispatch(menu.resetStateReq());
    dispatch(auth.signoutReq());
    dispatch(checks.uregPrerequisiteEventsReq());
};

export const load = (dispatch: Dispatch<any>): void => {
    console.log(`manager.load`);
    loadAllRunners()
    .then((runners) => {
        dispatch(app.runnersRes(runners));
    })
    .catch((error) => {
        console.error(`Failed to load runners, no food check records. ${error}`);
    });
    const filterDateOrders: number = subWeeks(new Date(), 1).getTime();
    dispatch(app.regAllOrdersReq(filterDateOrders));
    dispatch(checks.loadCheckDescribersReq());
    dispatch(app.allUsersReq());
    dispatch(app.regSignatureEventsReq());
    dispatch(app.regAllUsersUpdateReq());
    dispatch(checks.regPrerequisiteEventsReq());
};