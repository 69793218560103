import { ILogin } from '../../Auth/interfaces/ILogin';
import { IAuthState } from '../../Auth/interfaces/IAuthState';
import { ILoginResult } from '../../Auth/interfaces/ILoginResult';

export const authInitialState = (): IAuthState => {
    const state: IAuthState = {
        connected: true,
        error: undefined,
        loading: false,
        status: {
            authorised: false,
            isAdmin: false
        },
    };
    return state;
};

export enum TypeKeys {
    AUTH_ERR = 'Auth/ERR',

    SIGNIN_REQ = 'Auth/SIGNIN_REQ',
    SIGNIN_RES = 'Auth/SIGNIN_RES',

    SIGNOUT_REQ = 'Auth/SIGNOUT_REQ',
    SIGNOUT_RES = 'Auth/SIGNOUT_RES',

    REG_CONNECTION_STATE = 'Auth/REG_CONNECTION_STATE',
    UREG_CONNECTION_STATE = 'Auth/UREG_CONNECTION_STATE',
    SET_CONNECTION_STATE = 'Auth/SET_CONNECTION_STATE',
}

export interface ISetConnectionState {
    type: TypeKeys.SET_CONNECTION_STATE;
    payload: boolean;
}

export interface IRegConnectionState {
    type: TypeKeys.REG_CONNECTION_STATE;
}

export interface IURegConnectionState {
    type: TypeKeys.UREG_CONNECTION_STATE;
}

export interface IAuthErr {
    type: TypeKeys.AUTH_ERR;
    error: Error;
}

export interface ISigninReq {
    type: TypeKeys.SIGNIN_REQ;
    payload: ILogin;
}

export interface ISigninRes {
    type: TypeKeys.SIGNIN_RES;
    payload: ILoginResult;
}
export interface ISignoutReq {
    type: TypeKeys.SIGNOUT_REQ;
}

export interface ISignoutRes {
    type: TypeKeys.SIGNOUT_RES;
}

export const actions = {
    authErr(error: Error): IAuthErr {
        const action: IAuthErr = { type: TypeKeys.AUTH_ERR, error };
        return action;
    },

    signinReq(payload: ILogin): ISigninReq {
        const action: ISigninReq = { type: TypeKeys.SIGNIN_REQ, payload };
        return action;
    },

    signinRes(payload: ILoginResult): ISigninRes {
        const action: ISigninRes = { type: TypeKeys.SIGNIN_RES, payload };
        return action;
    },

    signoutReq(): ISignoutReq {
        const action: ISignoutReq = { type: TypeKeys.SIGNOUT_REQ };
        return action;
    },

    signoutRes(): ISignoutRes {
        const action: ISignoutRes = { type: TypeKeys.SIGNOUT_RES };
        return action;
    },

    regConnectionState(): IRegConnectionState {
        const action: IRegConnectionState = { type: TypeKeys.REG_CONNECTION_STATE };
        return action;
    },

    uregConnectionState(): IURegConnectionState {
        const action: IURegConnectionState = { type: TypeKeys.UREG_CONNECTION_STATE };
        return action;
    },

    setConnectionState(payload: boolean): ISetConnectionState {
        const action: ISetConnectionState = { type: TypeKeys.SET_CONNECTION_STATE, payload };
        return action;
    },
};

type TActionTypes = 
    IAuthErr    |
    ISigninReq  |
    ISigninRes  |
    ISignoutReq |
    ISignoutRes |
    ISetConnectionState|
    IRegConnectionState|
    IURegConnectionState;

const subReducers = {

    setConnectionState(state: IAuthState, action: ISetConnectionState): IAuthState {
        const result: IAuthState = {
            ...state,
            connected: action.payload
        };
        return result;
    },

    authErr(state: IAuthState, action: IAuthErr): IAuthState {
        const result: IAuthState = {
            ...state,
            error: action.error,
            loading: false,
            status: {
                authorised: false,
                isAdmin: false
            },
        };
        return result;
    },

    signinReq(state: IAuthState, action: ISigninReq): IAuthState {
        const result: IAuthState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    signinRes(state: IAuthState, action: ISigninRes): IAuthState {
        const result: IAuthState = {
            ...state,
            loading: false,
            status: {
                authorised: action.payload.authorised,
                isAdmin: action.payload.isAdmin
            },
        };
        return result;
    },

    signoutReq(state: IAuthState, action: ISignoutReq): IAuthState {
        const result: IAuthState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    signoutRes(state: IAuthState, action: ISignoutRes): IAuthState {
        return authInitialState();
    }
};

const reducer = (state: IAuthState = authInitialState(), action: TActionTypes): IAuthState => {
    switch (action.type) {
        case TypeKeys.SET_CONNECTION_STATE:
            return subReducers.setConnectionState(state, action);
    
        case TypeKeys.SIGNIN_REQ:
            return subReducers.signinReq(state, action);

        case TypeKeys.SIGNIN_RES:
            return subReducers.signinRes(state, action);

        case TypeKeys.SIGNOUT_REQ:
            return subReducers.signoutReq(state, action);

        case TypeKeys.SIGNOUT_RES:
            return subReducers.signoutRes(state, action);

        case TypeKeys.AUTH_ERR:
            return subReducers.authErr(state, action);
        
        default:
            return state;
    }
};

export default reducer;