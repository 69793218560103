import { IReportsState } from "../../App/interfaces/IReportsState";
import { MenuItemUsageStat } from "../../App/interfaces/IMenuItemUsageStats";
import { getReportQueries } from "../../App/interfaces/IReports";
import { firstWeekDayN } from "../../Common/utils/dateFunctions";

export const reportsInitialState = (): IReportsState => {
    const state: IReportsState = {
        dietaryUsageStats: [],
        allergenUsageStats: [],
        sidesUsageStats: [],
        dessertsUsageStats: [],
        lunchesUsageStats: [],
        teasUsageStats: [],

        lunchDessertJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        lunchDessertJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaDessertJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaDessertJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },

        lunchSideJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        lunchSideJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaSideJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaSideJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },

        mainJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        mainJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaJoggerEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        teaJoggerForRangeEdit: {
            alternativesByRunnerByUid: [],
            portionsByRunnerByUid: [],
        },
        portionsDate: firstWeekDayN(),
        selectedMenuItemUsageStats: MenuItemUsageStat.Ingredients,
        deliveryReportsStorageMap: {},
        foodcheckReportsStorageMap: {},
        diarycheckReportsStorageMap: {},
        prerequisiteReportsStorageMap: {},
        reportQueries: getReportQueries(),
    };
    return state;
};