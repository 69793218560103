export const getWeekFromId = (id: number): number => {
    if (id < 5) {
        return 1;
    }

    if (id < 10) {
        return 2;
    }

    if (id < 15) {
        return 3;
    }

    return 4;
};


export const getDayFromId = (id: number): number => {
    const day: number = (id % 5) + 1;
    return day;
};