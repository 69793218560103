import {
    ICheckDescriberGroupMap,
    ICheckGroup,
    ICustomDropDownsCheckControl,
    ICustomInternalDropdownsCheckControl,
    ICustomRangesCheckControl,
    ICustomItemCheckControl,
    ICustomAlertCheckControl,
    ICustomAlertCheckControlMap,
    ICustomRangesCheckControlMap,
    ICustomInternalDropdownsCheckControlMap,
    ICustomDropDownsCheckControlMap,
    ICustomItemCheckControlMap,
    ICheckGroupCategoryMap,
    IPrerequisite,
    PrerequisiteEventType,
    ICustomPrereqCheckControl,
    ICustomPrereqCheckControlMap,
    ICheckGroupCategoryMaxTabOrderMap,
} from "../../App/components/checks/interfaces";

import { IChecksState } from "../../App/interfaces/IChecksState";
import { checksInitialState } from "./checksInitialState";

export enum TypeKeys {
    LOAD_CHECK_DESCRIBERS_REQ = 'CHECKS/LOAD_CHECK_DESCRIBERS_REQ',
    LOAD_CHECK_DESCRIBERS_RES = 'CHECKS/LOAD_CHECK_DESCRIBERS_RES',

    LOAD_CHECK_CUSTOM_CONTROLS_REQ = 'CHECKS/LOAD_CHECK_CUSTOM_CONTROLS_REQ',
    LOAD_CHECK_CUSTOM_CONTROLS_RES = 'CHECKS/LOAD_CHECK_CUSTOM_CONTROLS_RES',

    SET_CURRENT_GROUP_REQ = 'CHECKS/SET_CURRENT_GROUP_REQ',

    REG_PREREQUISITES_EVENTS_REQ = 'CHECKS/REG_PREREQUISITES_EVENTS_REQ',
    REG_PREREQUISITES_EVENTS_RES = 'CHECKS/REG_PREREQUISITES_EVENTS_RES',
    UREG_PREREQUISITES_EVENTS_REQ = 'CHECKS/UREG_PREREQUISITES_EVENTS_REQ',
};

export interface IURegPrerequisiteEventsReq {
    type: TypeKeys.UREG_PREREQUISITES_EVENTS_REQ;
}

export interface IRegPrerequisiteEventsReq {
    type: TypeKeys.REG_PREREQUISITES_EVENTS_REQ;
}

export interface IRegSignatureEventsRes {
    type: TypeKeys.REG_PREREQUISITES_EVENTS_RES;
    payload: {
        uid: string;
        eventType: PrerequisiteEventType;
        data: IPrerequisite | undefined;
    };
}


interface ISetCurrentGroupReq {
    type: TypeKeys.SET_CURRENT_GROUP_REQ;
    payload: {
        currentGroupId: string
    };
}

interface ILoadCheckDescribersReq {
    type: TypeKeys.LOAD_CHECK_DESCRIBERS_REQ;
}

interface ILoadCheckDescribersRes {
    type: TypeKeys.LOAD_CHECK_DESCRIBERS_RES;
    payload: {
        groupMap: ICheckDescriberGroupMap,
        allCheckGroupCategories: ICheckGroupCategoryMap,
        maxTabOrderPerCategory: ICheckGroupCategoryMaxTabOrderMap,
        groups: ReadonlyArray<ICheckGroup>
    };
}

interface ICheckCustomControlsReq {
    type: TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_REQ;
}

interface ICheckCustomControlsRes {
    type: TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_RES;
    payload: {
        dropdowns: ReadonlyArray<ICustomDropDownsCheckControl>,
        itemChecks: ReadonlyArray<ICustomItemCheckControl>,
        internalDropdowns: ReadonlyArray<ICustomInternalDropdownsCheckControl>,
        ranges: ReadonlyArray<ICustomRangesCheckControl>,
        alertChecks: ReadonlyArray<ICustomAlertCheckControl>,
        prereqChecks: ReadonlyArray<ICustomPrereqCheckControl>,
    };
}

export const actions = {

    setCurrentGroupReq(payload: { currentGroupId: string }): ISetCurrentGroupReq {
        const action: ISetCurrentGroupReq = { type: TypeKeys.SET_CURRENT_GROUP_REQ, payload };
        return action;
    },

    loadCheckDescribersReq(): ILoadCheckDescribersReq {
        const action: ILoadCheckDescribersReq = { type: TypeKeys.LOAD_CHECK_DESCRIBERS_REQ };
        return action;
    },

    loadCheckDescribersRes(payload: { groupMap: ICheckDescriberGroupMap, groups: ReadonlyArray<ICheckGroup>, allCheckGroupCategories: ICheckGroupCategoryMap, maxTabOrderPerCategory: ICheckGroupCategoryMaxTabOrderMap }): ILoadCheckDescribersRes {
        const action: ILoadCheckDescribersRes = { type: TypeKeys.LOAD_CHECK_DESCRIBERS_RES, payload };
        return action;
    },

    loadCheckCustomControlsReq(): ICheckCustomControlsReq {
        const action: ICheckCustomControlsReq = { type: TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_REQ };
        return action;
    },

    loadCheckCustomControlsRes(payload: {
        dropdowns: ReadonlyArray<ICustomDropDownsCheckControl>,
        itemChecks: ReadonlyArray<ICustomItemCheckControl>,
        internalDropdowns: ReadonlyArray<ICustomInternalDropdownsCheckControl>,
        ranges: ReadonlyArray<ICustomRangesCheckControl>,
        alertChecks: ReadonlyArray<ICustomAlertCheckControl>,
        prereqChecks: ReadonlyArray<ICustomPrereqCheckControl>,
    }): ICheckCustomControlsRes {

        const action: ICheckCustomControlsRes = { type: TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_RES, payload };
        return action;
    },

    regPrerequisiteEventsReq(): IRegPrerequisiteEventsReq {
        const action: IRegPrerequisiteEventsReq = { type: TypeKeys.REG_PREREQUISITES_EVENTS_REQ };
        return action;
    },

    uregPrerequisiteEventsReq(): IURegPrerequisiteEventsReq {
        const action: IURegPrerequisiteEventsReq = { type: TypeKeys.UREG_PREREQUISITES_EVENTS_REQ };
        return action;
    },

    regPrerequisiteEventsRes(payload: {
        uid: string;
        eventType: PrerequisiteEventType;
        data: IPrerequisite | undefined;
    }): IRegSignatureEventsRes {
        const action: IRegSignatureEventsRes = { type: TypeKeys.REG_PREREQUISITES_EVENTS_RES, payload };
        return action;
    },
};

type TActionTypes =
    ISetCurrentGroupReq |
    ILoadCheckDescribersReq |
    ILoadCheckDescribersRes |
    ICheckCustomControlsReq |
    ICheckCustomControlsRes |
    IRegSignatureEventsRes;

const reducers = {
    setCurrentGroupReq(state: IChecksState, action: ISetCurrentGroupReq): IChecksState {
        const result: IChecksState = {
            ...state,
            currentGroupId: action.payload.currentGroupId
        };
        return result;
    },

    loadCheckDescribersRes(state: IChecksState, action: ILoadCheckDescribersRes): IChecksState {
        const result: IChecksState = {
            ...state,
            allChecksLoaded: true,
            allCheckDescribers: action.payload.groupMap,
            allCheckGroups: action.payload.groups,
            allCheckGroupCategories: action.payload.allCheckGroupCategories,
            maxTabOrderPerCategory: action.payload.maxTabOrderPerCategory,
        };
        return result;
    },

    loadCheckCustomControlsRes(state: IChecksState, action: ICheckCustomControlsRes): IChecksState {
        const result: IChecksState = {
            ...state,
            customDropdownControls: action.payload.dropdowns.reduce(
                (obj: ICustomDropDownsCheckControlMap, item: ICustomDropDownsCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
            customItemCheckControls: action.payload.itemChecks.reduce(
                (obj: ICustomItemCheckControlMap, item: ICustomItemCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
            customInternalDropdownControls: action.payload.internalDropdowns.reduce(
                (obj: ICustomInternalDropdownsCheckControlMap, item: ICustomInternalDropdownsCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
            customRangeControls: action.payload.ranges.reduce(
                (obj: ICustomRangesCheckControlMap, item: ICustomRangesCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
            customAlertCheckControls: action.payload.alertChecks.reduce(
                (obj: ICustomAlertCheckControlMap, item: ICustomAlertCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
            customPrereqCheckControls: action.payload.prereqChecks.reduce(
                (obj: ICustomPrereqCheckControlMap, item: ICustomPrereqCheckControl) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            ),
        };
        return result;
    },

    regPrerequisiteEventsRes(state: IChecksState, action: IRegSignatureEventsRes): IChecksState {
        switch (action.payload.eventType) {
            case `added`: 
            case `updated`: {
                if (action.payload.data !== undefined) {
                    const result: IChecksState = {
                        ...state,
                        prerequisiteMap: {
                            ...state.prerequisiteMap,
                            [action.payload.uid]: action.payload.data
                        }
                    };
                    return result;
                }
                return state;
            }

            case `deleted`: {
                const prerequisiteMap = { ...state.prerequisiteMap };
                delete prerequisiteMap[action.payload.uid];
                const result: IChecksState = {
                    ...state,
                    prerequisiteMap
                };
                return result;
            }

            default:
                return state;
        }
    },
};

const reducer = (state: IChecksState = checksInitialState(), action: TActionTypes): IChecksState => {
    switch (action.type) {
        case TypeKeys.SET_CURRENT_GROUP_REQ:
            return reducers.setCurrentGroupReq(state, action);

        case TypeKeys.LOAD_CHECK_DESCRIBERS_RES:
            return reducers.loadCheckDescribersRes(state, action);

        case TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_RES:
            return reducers.loadCheckCustomControlsRes(state, action);

        case TypeKeys.REG_PREREQUISITES_EVENTS_RES:
            return reducers.regPrerequisiteEventsRes(state, action);

        default:
            return state;
    }
};

export default reducer;