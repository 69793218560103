import isEqual from 'lodash/isEqual';

import { createSelector } from 'reselect';

import { IState } from '../../Store/state';

import {
    IAppState,
    ISentConfirmationDates,
} from '../../App/interfaces/IAppState';

import {
    IDayOrder, IOrder, IOrderMap
} from '../../App/interfaces/IDayOrder';

import {
    IUser,
    IUsersMap
} from '../../Auth/interfaces/IUser';

import { ILogEntryMap } from '../../App/interfaces/ILogEntry';

import {
    IRunner,
    IRunners,
    IUidToRunnerColor
} from '../../App/interfaces/IRunners';

import {
    firstWeekDayN,  startOfDateAsNumberN,
} from '../../Common/utils/dateFunctions';

import {
    compareUserName,
    getTotalCount,
} from '../../App/store/utils';

import { IAppConfig } from '../../App/interfaces/IAppConfig';

import {
    IFeature,
    IFeatureMap
} from '../interfaces/IFeature';

import {
    IUserNotes,
    IUserNotesMap,
} from '../interfaces/IUserNotes';

import {
    IKeyToSignatureInfoArray,
    ISignatureInfo
} from '../../Common/interfaces/ISignatureInfo';

import { appInitialState } from './appInitialState';
import { getDistinct } from '../../Common/utils/arrayUtils';
import { IStringToStringMap } from '../../Common/interfaces/IStringToNumberMap';
import { IOrderActivityLog, IOrderActivityLogMap } from '../interfaces/IOrderActivityLog';

export const getLocalState = (state: IState): IAppState => {
    if (state && state.app) {
        return state.app;
    }
    return appInitialState();
};

export const getOrderActivitySubscriptionMade = (state: IAppState): boolean => {
    return state.orderActivitySubscriptionMade;
}

export const getOrderActivityLogsLoading = (state: IAppState): boolean => {
    return state.allOrderActivityLogsLoading;
}

export const getUserOrderActivityLogs = (state: IAppState): Readonly<IOrderActivityLogMap> => {
    return state.allOrderActivityLogs;
}

export const getUserOrderActivityLogsArray = createSelector(
    [getUserOrderActivityLogs],
    (allOrderActivityLogs): ReadonlyArray<IOrderActivityLog> => {
        return Object.values(allOrderActivityLogs);
    }
);

export const getOrders = (state: IAppState): Readonly<IOrderMap> => {
    return state.allOrders;
}

export const getOrderForUserAndDate = (uid: string | undefined, date: number, state: IAppState): IOrder | undefined => {
    if (uid !== undefined) {
        const orders = Object.values(state.allOrders).filter((o) => o.uid === uid && o.orderDate === date);
        if (orders.length > 0) {
            return orders[0];
        }
    }
    return undefined;
};

//  which should be used - this is used in View Orders
export const getSelectedUid = (state: IAppState): Readonly<string> | undefined => {
    return state.selectedUid;
};
//  which should be used - this is used everywhere else
export const getSelectedUserUid = (state: IAppState): Readonly<string> | undefined => {
    return state.selectedUserUid;
};

export const getOrdersForSelectedUid = createSelector(
    [getSelectedUserUid, getOrders],
    (uid, orders): Readonly<IOrderMap> => {
        if (uid) {
            const om = Object.values(orders).filter((o) => o.uid === uid).reduce(
                (obj: IOrderMap, item: IOrder) => {
                    obj[item.id] = item;
                    return obj;
                },
                {}
            );
            return om;
        }
        return {};
    }
);


export const isLoading = (state: IAppState): boolean => {
    return state.loading;
};

export const getAppConfig = (state: IAppState): Readonly<IAppConfig> => {
    return state.appConfig;
};

export const getAppConfigAdminEmail = (state: IAppState): Readonly<string> => {
    return state.appConfig.adminEmail;
};

export const getUserNotes = (state: IAppState): Readonly<IUserNotesMap> => {
    return state.userNotes;
};

export const getUserNote = (uid: string, state: IAppState): Readonly<IUserNotes> | undefined => {
    if (state.userNotes && state.userNotes[uid]) {
        return state.userNotes[uid];
    }
    return undefined;
};

export const getFeatures = (state: IAppState): Readonly<IFeatureMap> => {
    return state.features;
};

export const getFeature = (uid: string | undefined, state: IAppState): Readonly<IFeature> | undefined => {
    if (uid !== undefined && state.features && state.features[uid]) {
        return state.features[uid];
    }
    return undefined;
};

export const getAppSentConfirmations = (state: IAppState): Readonly<ISentConfirmationDates> => {
    return state.confirmations;
};

export const getAppSentConfirmationForUid = (uid: string, state: IAppState): Readonly<string> | undefined => {
    return state.confirmations[uid];
};

export const getAllUsersMap = (state: IAppState): IUsersMap => {
    return state.allUsers;
};

export const getSelectedDayOrderDate = (state: IAppState): number => {
    if (state.selectedDayOrders) {
        return state.selectedDayOrders.startDate;
    }

    return startOfDateAsNumberN(firstWeekDayN());
};

export const getSelectedRunner = (state: IAppState): Readonly<string> | undefined => {
    return state.selectedRunner;
};

export const getLoggedInUser = (state: IAppState): Readonly<IUser> | undefined => {
    return state.loggedInUser;
};

export const getError = (state: IAppState): Error | undefined => {
    return state.error;
};

export const getUserOrderUpdateEvent = (state: IAppState): boolean => {
    return state.userOrderUpdateEvent;
};
export const getDayLunchOrder = (state: IAppState): Readonly<IDayOrder> => {
    return state.dayOrdersLunch;
};

export const getDayTeaOrder = (state: IAppState): Readonly<IDayOrder> => {
    return state.dayOrdersTea;
};

export const hasLunchOrderChanged = (state: IAppState): boolean => {
    return !isEqual(state.dayOrdersLunch, state.originalDayOrdersLunch);
};

export const hasTeaOrderChanged = (state: IAppState): boolean => {
    return !isEqual(state.dayOrdersTea, state.originalDayOrdersTea);
};

export const hasOrderBeenModified = createSelector(
    [hasLunchOrderChanged, hasTeaOrderChanged],
    (lunchChanged, teaChanged) => {
        return lunchChanged || teaChanged;
    }
);

export const getSpecialCountForCode = (dayOrder: Readonly<IDayOrder>, code: string): number => {
    if (dayOrder.special && dayOrder.special[code]) {
        const count: number = dayOrder.special[code];
        return count;
    }

    return 0;
};

export const getSpecialDessertCountForCode = (dayOrder: Readonly<IDayOrder>, code: string): number => {
    if (dayOrder.dessertSpecial && dayOrder.dessertSpecial[code]) {
        const count: number = dayOrder.dessertSpecial[code];
        return count;
    }

    return 0;
};

export const getSpecialLunchCountForDay = (code: string, state: IAppState): number => {
    const dayOrder: Readonly<IDayOrder> | undefined = getDayLunchOrder(state);
    if (dayOrder !== undefined) {
        return getSpecialCountForCode(dayOrder, code);
    }

    return 0;
};

export const getSpecialLunchDessertCountForDay = (code: string, state: IAppState): number => {
    const dayOrder: Readonly<IDayOrder> | undefined = getDayLunchOrder(state);
    if (dayOrder !== undefined) {
        return getSpecialDessertCountForCode(dayOrder, code);
    }

    return 0;
};

export const getSpecialTeaCountForDay = (code: string, state: IAppState): number => {
    const dayOrder: Readonly<IDayOrder> | undefined = getDayTeaOrder(state);
    if (dayOrder !== undefined) {
        return getSpecialCountForCode(dayOrder, code);
    }
    return 0;
};

export const getSpecialTeaDessertCountForDay = (code: string, state: IAppState): number => {
    const dayOrder: Readonly<IDayOrder> | undefined = getDayTeaOrder(state);
    if (dayOrder !== undefined) {
        return getSpecialDessertCountForCode(dayOrder, code);
    }
    return 0;
};

export const getNameForLoggedInUserUid = (uid: string | undefined, state: IAppState): string | undefined => {
    if (!uid) {
        return undefined;
    }
    const user: IUser | undefined = getLoggedInUser(state);
    if (user && user.orgs) {
        return user.orgs[uid].name;
    }
    return undefined;
};

export const getAllOrgsUidsForUid = (uid: string | undefined, state: IAppState): string[] => {
    if (!uid) {
        return [];
    }
    const user: IUser | undefined = state.allUsers[uid];
    if (user && user.orgs) {
        return Array.from(Object.keys(user.orgs));
    }
    return [];
};

export const getAllUidsForLoggedInUser = createSelector(
    [getLoggedInUser],
    (loggedInUser) => {
        if (loggedInUser && loggedInUser.orgs) {
            const uids: ReadonlyArray<string> = Array.from(Object.keys(loggedInUser.orgs));
            return uids;
        }
        return [];
    }
);

export const getAllUsers = createSelector(
    [getAllUsersMap, getLoggedInUser],
    (userMap, loggedInUser) => {
        if (loggedInUser) {
            return Object.values(userMap).filter((u) => u.uid !== loggedInUser.uid).sort(compareUserName);
        }
        return [];
    }
);

export const getAllUsersUids = createSelector(
    [getAllUsersMap, getLoggedInUser],
    (userMap, loggedInUser) => {
        if (loggedInUser) {
            return Object.values(userMap).filter((u) => u.uid !== loggedInUser.uid).sort(compareUserName).map((u) => u.uid);
        }
        return [];
    }
);

export const getAllUsersUidsToNameMap = createSelector(
    [getAllUsers],
    (users) => {
        const result: IStringToStringMap = {};
        users.forEach((u) => {
            result[u.uid] = u.name;
        });
        return result;
    }
);

export const getAllUsersUidsSortedByName = (state: IAppState): ReadonlyArray<string> => {
    const array: ReadonlyArray<string> = Array.from(Object.keys(state.allUsers)).sort((a: string, b: string) => {
        const nameA: string = state.allUsers[a].name;
        const nameB: string = state.allUsers[b].name;
        return nameA.localeCompare(nameB);
    });
    return array;
};

export const getUserForKnownUid = (uid: string, state: IAppState): IUser => {
    return state.allUsers[uid];
};

export const getUserForUid = (uid: string | undefined, state: IAppState): IUser | undefined => {
    if (!uid) {
        return undefined;
    }
    const user: IUser | undefined = state.allUsers[uid];
    return user;
};

export const getNameForUid = (uid: string | undefined, state: IAppState): string | undefined => {
    if (uid === undefined) {
        return undefined;
    }

    const user: IUser | undefined = getUserForUid(uid, state);
    if (user) {
        return user.name
    }
    return undefined;
};

export const getUserNameForLoggedInUser = (state: IAppState): string | undefined => {
    const user: IUser | undefined = getLoggedInUser(state);
    if (user) {
        return user.userName;
    }
    return undefined;
};
export const getUserHasOrders = (uid: string, state: IAppState): boolean => {
    const orders = Object.values(state.allOrders).filter((o) => o.uid === uid);
    return orders.length !== 0;
};

export const getAllOrderDatesForUser = (uid: string, state: IAppState): ReadonlyArray<number> => {
    const orders = Object.values(state.allOrders).filter((o) => o.uid === uid);
    const dates = orders.map((o) => o.orderDate);
    return dates.sort();
};

export function makeGetAllOrderDatesForUserId() {
    const idPassThru = (state: IAppState, id: string | undefined) => id;
    return createSelector(
        [getOrders, idPassThru],
        (orderMap, id) => {
            if (id) {
                const orders = Object.values(orderMap).filter((o) => o.uid === id);
                const dates = orders.map((o) => o.dateId);
                return dates.sort();
            }
            return [];
        }
    );
}

export const getAllUsersOrdersLogs = (state: IAppState): Readonly<ILogEntryMap> => {
    return state.allUserOrderLogs;
};

export const getAllUserUids = (state: IAppState): ReadonlyArray<string> => state.allUids;

export const hasAmmendedOrderForSelectedUserAndDate = createSelector(
    [getSelectedUserUid, getOrders, getSelectedDayOrderDate],
    (uid, orderMap, orderDate) => {
        if (uid !== undefined) {
            const orders = Object.values(orderMap).filter((o) => o.uid === uid && o.orderDate === orderDate);
            if (orders.length > 0) {
                if (orders[0].lunch.ammendDate || orders[0].tea.ammendDate){
                    return true;
                }
            }
        }
        return false;
    }
);

export const getOrderForUserAndStartDate = (uid: string, startDate: number, state: IAppState): Readonly<IOrder> | undefined => {
    const o = Object.values(getOrders(state)).filter((o) => o.uid === uid && o.orderDate === startDate);
    if (o.length > 0) {
        if (o.length > 1) {
            console.error(`getNewOrderForUserAndStartDate multiple orders for ${uid} ${startDate}`);
        }
        return o[0];
    }
    return undefined;
};


export const getRunnerColors = (state: IAppState): ReadonlyArray<string> => {
    return state.runnerColors;
};

export const getUidRunner = (state: IAppState): IUidToRunnerColor => {
    return state.uidToRunner;
};

export const getRunnerForUid = (uid: string, state: IAppState): string | undefined => {
    return state.uidToRunner[uid];
};

export const getRunners = (state: IAppState): IRunners => {
    return state.runners;
};

export const getRunnerStartDate = (state: IAppState): Date | null => {
    return state.runnerStartDate;
};

export const getRunnerEndDate = (state: IAppState): Date | null => {
    return state.runnerEndDate;
};

export const getAllUidsForRunner = (color: string, state: IAppState): ReadonlyArray<string> => {
    const runner: IRunner = state.runners[color];
    if (runner && runner.members) {
        const uids: ReadonlyArray<string> = Object.keys(runner.members);
        const users: ReadonlyArray<IUser> = uids.map((u) => {
            return state.allUsers[u];
        }).sort(compareUserName).filter(u => u !== undefined);

        return users.map((u) => u.uid);
    }

    return [];
};

export const getAllUidsForSelectedRunner = (state: IAppState): ReadonlyArray<string> => {
    if (state.selectedRunner) {
        return getAllUidsForRunner(state.selectedRunner, state);
    }

    return [];
};

export const getUsersForSelectedRunner = (state: IState): ReadonlyArray<IUser> => {
    const allUsers: ReadonlyArray<IUser> = getAllUsers(state.app);
    const selectedRunner: Readonly<string> | undefined = getSelectedRunner(state.app);
    if (selectedRunner) {
        const map: IUidToRunnerColor = getUidRunner(state.app);
        return allUsers.filter((u) => map[u.uid] === selectedRunner);
    }
    return allUsers;
};

export const getAllMembersForSelectedRunner = (state: IAppState): ReadonlyArray<string> => {
    if (state.selectedRunner) {
        const uids: ReadonlyArray<string> = getAllUidsForRunner(state.selectedRunner, state);

        const members: string[] = [];
        for (const u of uids) {
            const user: IUser | undefined = state.allUsers[u];
            if (user) {
                members.push(user.name);
            }
        }

        return members.sort();
    }
    return [];
};

export const getAllOrderDatesForRunner = createSelector(
    [getAllUidsForSelectedRunner, getOrders],
    (uids, orderMap) => {
        const orderDates: number[] = [];
        for (const uid of uids) {
            const dates = Object.values(orderMap).filter((o) => o.uid === uid).map((o) => o.orderDate);
            if (dates.length > 0) {
                orderDates.push(...dates);
            }
        }
        const result: ReadonlyArray<number> = getDistinct(orderDates).slice().sort() as ReadonlyArray<number>;
        return result;
    }
);


export const hasLunchOrderForUserAndDate = (uid: string, startDate: number, state: IAppState): boolean => {
    const orders = Object.values(getOrders(state)).filter((o) => o.uid === uid && o.orderDate === startDate);
    if (orders.length > 0) {
        const count: number = getTotalCount(orders[0].lunch, {});
        if (count > 0) {
            return true;
        }
    }
    
    return false;
};

export const hasTeaOrderForUserAndDate = (uid: string, startDate: number, state: IAppState): boolean => {
    const orders = Object.values(getOrders(state)).filter((o) => o.uid === uid && o.orderDate === startDate);
    if (orders.length > 0) {
        const count: number = getTotalCount(orders[0].tea, {});
        if (count > 0) {
            return true;
        }
    }
    
    return false;
};

export const getFoodCheckConfirmations = (state: IAppState): IKeyToSignatureInfoArray => {
    return state.foodSignatureInfo;
};

export const getDiaryCheckConfirmations = (state: IAppState): IKeyToSignatureInfoArray => {
    return state.diarySignatureInfo;
}

export const getDeliveryCheckConfirmations = (state: IAppState): IKeyToSignatureInfoArray => {
    return state.deliverySignatureInfo;
}

export const getAllFoodCheckConfirmations = createSelector(
    [getFoodCheckConfirmations],
    (confirmations) => {
        const all: ISignatureInfo[] = [];
        if (confirmations !== undefined) {
            for (const uid in confirmations) {
                if (uid) {
                    const dates = confirmations[uid];
                    all.push(...dates);
                }
            }
        }
        return all;
    }
);

export const getPrerequisiteConfirmations = (state: IAppState): IKeyToSignatureInfoArray => {
    return state.prerequisiteSignatureInfo;
}

export const prerequisiteConfirmationsForId = (state: IAppState, id: string): ISignatureInfo[] => {
    return state.prerequisiteSignatureInfo[id];
}

export function makeGetPrerequisiteConfirmationsForId() {
    const idPassThru = (state: IAppState, id: string) => id;
    return createSelector(
        [getPrerequisiteConfirmations, idPassThru],
        (confirmations, id) => {
            if (confirmations !== undefined && confirmations[id] !== undefined) {
                return confirmations[id];
            }
            return [];
        }
    );
}
