import { defaultConfig } from '../../App/interfaces/IAppConfig';
import { IAppState } from '../../App/interfaces/IAppState';
import { defaultDayOrder } from '../../App/interfaces/IDayOrder';
import { firstWeekDayN } from '../../Common/utils/dateFunctions';

export const appInitialState = (): IAppState => {
    const state: IAppState = {
        orderActivitySubscriptionMade: false,
        allOrders: {},
        allOrderActivityLogs: {},
        allOrderActivityLogsLoading: false,
        allUids: [],
        allUserOrderLogs: {},
        allUsers: {},
        appConfig: { ...defaultConfig },
        confirmations: {},
        dayOrdersLunch: { ...defaultDayOrder },
        dayOrdersTea: { ...defaultDayOrder },
        dietaryCodes: {},
        dietaryCodesIds: [],
        error: undefined,
        features: {},
        loading: false,
        loggedInUser: undefined,
        originalDayOrdersLunch: { ...defaultDayOrder },
        originalDayOrdersTea: { ...defaultDayOrder },
        runnerColors: [],
        runners: {},
        runnerStartDate: firstWeekDayN(),
        runnerEndDate: firstWeekDayN(),
        saved: false,
        savedOrder: undefined,
        selectedDayOrders: undefined,
        selectedRunner: undefined,
        selectedUid: undefined,
        selectedUserUid: undefined,
        uidToRunner: {},
        userNotes: {},
        userOrderSavedEvent: false,
        userOrderUpdateEvent: false,
        foodSignatureInfo: {},
        diarySignatureInfo: {},
        deliverySignatureInfo: {},
        prerequisiteSignatureInfo: {},
    };
    return state;
};
