export interface IDietaryMap {
    [code: string]: string;
}

export interface IAlternative {
    itemId: string;
    dietaries: IDietaryMap
}

export enum AlternativeUpdate {
    Add,
    Modify,
    Delete
}

export interface IAlternativeMap {
    [id: string]: IAlternative;
}

export const AlternativePath = `data/food/menu_alternatives`;