import { MealSession } from "./MealSession";

export enum MenuItemUsageStat {
    Ingredients,
    Desserts,
    Lunches,
    Sides,
    Teas
}
export interface IMenuItemId {
    cycle: string;
    index: number;
    label: string;
    session: MealSession;
}
export interface IMenuItemUsageStats {
    id: string;
    label: string;
    usedCount: number;
    usedByIds?: Array<string>;
    usedByMenuIds?: Array<IMenuItemId>;
    itemType: MenuItemUsageStat;
    usedByLabels: Array<string>;
}