import { IViewState } from '../../App/interfaces/IViewState';
import { viewInitialState } from './viewInitialState';

export enum TypeKeys {
    VIEW_SET_TAB_SELECTED_IDX = 'VIEW/VIEW_SET_TAB_SELECTED_IDX',
    RESET_STATE_REQ = 'VIEW/RESET_STATE_REQ',
};

export interface IViewSetTabSelectedIdx {
    type: TypeKeys.VIEW_SET_TAB_SELECTED_IDX;
    payload: {
        tabName: string;
        idx: number;
    }
}

export interface IAppResetStateReq {
    type: TypeKeys.RESET_STATE_REQ;
}

export const actions = {
    setTabSelectedIdx(payload: {
        tabName: string,
        idx: number
    }): IViewSetTabSelectedIdx {
        const action: IViewSetTabSelectedIdx = { type: TypeKeys.VIEW_SET_TAB_SELECTED_IDX, payload };
        return action;
    },

    resetStateReq(): IAppResetStateReq {
        const action: IAppResetStateReq = { type: TypeKeys.RESET_STATE_REQ };
        return action;
    },
};

type TActionTypes =
    IViewSetTabSelectedIdx |
    IAppResetStateReq
;

const reducers = {
    setTabSelectedIdx(state: IViewState, action: IViewSetTabSelectedIdx): IViewState {
        const result: IViewState = {
            ...state,
            tabIdxMap: {
                ...state.tabIdxMap,
                [action.payload.tabName]: action.payload.idx
            }
        };
        return result;
    },

    resetStateReq(state: IViewState, action: IAppResetStateReq): IViewState {
        const result: IViewState = viewInitialState();
        return result;
    },
};


const reducer = (state: IViewState = viewInitialState(), action: TActionTypes): IViewState => {
    switch (action.type) {
        case TypeKeys.VIEW_SET_TAB_SELECTED_IDX:
            return reducers.setTabSelectedIdx(state, action);

        case TypeKeys.RESET_STATE_REQ:
            return reducers.resetStateReq(state, action);
        default:
            return state;
    }
};

export default reducer;