export interface ITabIdxMap {
    [key: string]: number;
}

export interface IViewState {
    tabIdxMap: ITabIdxMap;
}

export const TAB_NAME_ADMIN_VIEW: string = `AdminViewTab`;
export const TAB_NAME_REPORTS_VIEW: string = `ReportsViewTab`;
export const TAB_NAME_JOGGERS_VIEW: string = `JoggersViewTab`;