import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { config } from './config';

if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
        // tslint:disable-next-line:no-console
        console.log(`firebase.auth().setPersistence SUCCESS`);
        // firebase.database.enableLogging(true);
    })
    .catch( (error) => {
        // tslint:disable-next-line:no-console
        console.error(`firebase.auth().setPersistence ERROR ${error.message}`);
    });
}


export const storage = firebase.storage().ref();
export const db = firebase.database().ref();
export const fs = firebase.firestore();
export const auth = firebase.auth();
export const func = firebase.functions();