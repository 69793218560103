import React from 'react';

interface ISpinnerWithTitle {
    title: string;
    mini?: boolean;
}

const getContentClassName = (mini?: boolean): string => {
    if (mini !== undefined && mini === true) {
        return `please-wait-content-mini`;
    }
    return `please-wait-content`;;
};

const SpinnerWithTitle: React.FC<ISpinnerWithTitle> = (props): React.ReactElement => {
    return (
        <div>
            <div className={'centered-fixed'}>
                <div className={getContentClassName(props.mini)}>{props.title}</div>
                <div className={'please-wait'}/>
            </div>
            
            {/* <div className={'wander-bee-container'}>
                <div className={'wander-bee'}/>
            </div> */}
            {/* <div className={'plane-container'}>
                <div className={'plane'}/>
            </div> */}
            {/* <div className={'flier'}>
                <div className={'bee'}/>
            </div> */}
        </div>
    );
};

export default SpinnerWithTitle;