import firebase from 'firebase/compat/app';

export const uploadStringData = (
    storage: firebase.storage.Reference,
    format: firebase.storage.StringFormat,
    metadata: firebase.storage.UploadMetadata,
    storagePath: string,
    data: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const toStore = storage.child(storagePath);
        toStore.putString(data, format, metadata)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL()
                    .then((url) => {
                        resolve(url)
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const saveUrl = async (db: firebase.database.Reference, storagePath: string, url: string, time: string, data: any | null = null): Promise<void> => {
    console.log(`saveUrl ${storagePath} ${time} ${url}`);
    await db.child(storagePath).update({
        time,
        url,
        data
    });
};