export enum MenuItemType {
    Ingredients,
    Dessert,
    Lunch,
    Side,
    Tea,
}

export interface IMenuExtra {
    canMultiply: boolean;
    isAlternative: boolean;
    id: string;
    item: string;
    joggerMultiplier: number;
    isJoggerMultiplierGrams: boolean;
    multiplier: number;
    allergens: string[];
}

export interface IMenuExtraMap {
    [key: string]: IMenuExtra;
}

export const defaultMenuExtra: IMenuExtra = {
    allergens: [],
    canMultiply: false,
    isAlternative: false,
    id: ``,
    item: ``,
    joggerMultiplier: 1,
    isJoggerMultiplierGrams: false,
    multiplier: 1,
};
