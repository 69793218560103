const oldVersion: number = 1;
const oldStore: string = `delivery`;


export class IDBManager {
    private dbName: string;
    private dbStore: string;
    private dbVersion: number;

    private iDb: IDBDatabase | null = null;
    constructor(dbName: string, dbStore: string, dbVersion: number) {
        this.dbName = dbName;
        this.dbStore = dbStore;
        this.dbVersion = dbVersion;
    }

    public initialise = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (window.indexedDB) {
                const request: IDBOpenDBRequest = window.indexedDB.open(this.dbName, this.dbVersion);
                request.onsuccess = (_event) => {
                    this.iDb = request.result;
                    resolve();
                };
                request.onupgradeneeded  = (e) => {
                    if (e.oldVersion < 1) {
                        request.result.createObjectStore(this.dbStore);
                    }

                    if (e.oldVersion === oldVersion) {
                        try {
                            request.result.deleteObjectStore (oldStore);
                        }
                        catch (error) {
                            console.log(`error trying to delete old object store ${oldStore}: ${error}`);
                        }

                        try {
                            request.result.createObjectStore(this.dbStore);
                        }
                        catch (error) {
                            console.log(`error trying to create object store ${this.dbStore}: ${error}`);
                        }
                    }
                };
                request.onerror  = (_event) => {
                    reject(request.error);
                };
            }
            else {
                console.log(`error indexedDB not present. offline storage will not work`);
            }
        });
    } 

    public isInitialised = (): boolean => {
        return this.iDb !== null;
    }

    public getDbName = (): string => {
        return this.dbName;
    }

    public getDbStore = (): string => {
        return this.dbStore;
    }

    public exsitsInStore = (key: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore]);
                const store: IDBObjectStore = trans.objectStore(this.dbStore);
                const req: IDBRequest<number> = store.count(key);
                req.onsuccess = (e) => {
                    const hasKey: boolean = req.result !== undefined ? req.result !== 0 ? true : false : false;
                    resolve(hasKey);
                };
                req.onerror = (e) => {
                    resolve(false);
                };
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });
    }

    public saveToStore = (id: string, data: any): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readwrite');
                const addReq: IDBRequest<IDBValidKey> = trans.objectStore(this.dbStore).add(data, id);
                addReq.onerror = (e) => {
                    reject(e);
                }
                trans.oncomplete = (e) => {
                    resolve();
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }

    public clearStore = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readwrite');
                const clearReq: IDBRequest<undefined> = trans.objectStore(this.dbStore).clear();
                clearReq.onerror = (e) => {
                    reject(e);
                }
                trans.oncomplete = (e) => {
                    resolve();
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }

    public getFromStore = (id: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readonly');
                const store: IDBObjectStore = trans.objectStore(this.dbStore);
                const read = store.get(id);
                read.onerror = (e) => {
                    reject(e);
                }
                read.onsuccess = (e) => {
                    if (read.result) {
                        resolve(read.result);
                    }
                    else {
                        reject(new Error(`IDBManager::getFromStore - nothing found for ${id}`));
                    }
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }

    public saveBlobToStore = (id: string, blob: Blob): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readwrite');
                const addReq: IDBRequest<IDBValidKey> = trans.objectStore(this.dbStore).add(blob, id);
                addReq.onerror = (e) => {
                    reject(e);
                }
                trans.oncomplete = (e) => {
                    resolve();
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }

    public getBlobFromStore = (id: string): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readonly');
                const store: IDBObjectStore = trans.objectStore(this.dbStore);
                const read = store.get(id);
                read.onerror = (e) => {
                    reject(e);
                }
                read.onsuccess = (e) => {
                    if (read.result) {
                        resolve(read.result);
                    }
                    else {
                        reject(new Error(`IDBManager::getBlobFromStore - nothing found for ${id}`));
                    }
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }

    public getKeysFromStore = (): Promise<IDBValidKey[]> => {
        return new Promise((resolve, reject) => {
            if (this.iDb) {
                const trans: IDBTransaction = this.iDb.transaction([this.dbStore], 'readonly');
                const store: IDBObjectStore = trans.objectStore(this.dbStore);
                const read: IDBRequest<IDBValidKey[]> = store.getAllKeys()
                read.onerror = (e) => {
                    reject(e);
                }
                read.onsuccess = (e) => {
                    if (read.result) {
                        resolve(read.result);
                    }
                    else {
                        resolve([]);
                    }
                }
            }
            else {
                reject(new Error(`No Local DB`));
            }
        });  
    }
};