import React, {  useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { useDataDispatch } from '../../Common/hooks/DataDispatchContext';

export const DataDispatch: React.FC = ({children}): React.ReactElement => {
    const dispatch: Dispatch<any> = useDispatch();
    const { load, unload } = useDataDispatch();
    
    useEffect(() => {
        console.log(`DataDispatch.mounted`);
        load(dispatch);
        return () => {
            console.log(`DataDispatch.unmounted`);
            unload(dispatch);
        };
    }, [dispatch, load, unload]);

    return (
        <React.Fragment>{children}</React.Fragment>
    );
};
