import { MealSession } from '../interfaces/MealSession';

import {
    IAllMenuCycleMap,
    IMenuCycle,
    IMenuCycleDefinition,
    IMenuCycleDefinitionMap,
    IMenuCycleMap,
    CurrentCyclePastMenus,
} from '../interfaces/IMenuCycle';

import {
    IMenuData,
    IMenuDataByCycleMap,
    IMenuDataMap,
    IMenuDataSession,
} from '../interfaces/IMenuData';

import { IMenuMealMap, menuItemNotSelectedId, menuItemNotSelected } from '../interfaces/IMenuMeal';

import {
    IMenuExtra,
    IMenuExtraMap
} from '../interfaces/IMenuExtra';

import { IUidToRunnerColor, RUNNER_NONE } from '../interfaces/IRunners';

import {
    IAltPortionMap,
    IKitchenMenuAlternativePortions,
    IKitchenMenuPortions,
    IPortionsWithAlternatives
} from '../interfaces/IKitchenMenuPortions';

import { IDayOrder, IOrder, IOrderMap } from '../interfaces/IDayOrder';

import { getTotalSpecialsCount } from '../utils/getTotalSpecialsCount';

import {
    getAllDietaryCodes,
    getAllMenuAlternativesMap,
    getAllMenuCycleMap,
    getAllMenuDesserts,
    getAllMenuLunches,
    getAllMenuSides,
    getAllMenuTeas,
    getMenuCycleDefinitionMap,
    getMenuDataByCycleMap,
} from './menuSelectors';

import {
    getFeature,
    getUidRunner,
    getSelectedRunner
} from './appSelectors';

import {
    IAlternative,
    IAlternativeMap,
} from '../interfaces/IAlternatives';

import { loadAllOrdersForDateRange } from './loadAllOrdersForDateRange';

import {
    sumSidesByDate
} from '../utils/sumSides';

import { IDietaryCodesMap } from '../interfaces/IDietaryCodes';
import { IDietaryCode } from '../interfaces/IDietaryCodes';
import { IDietaryCodesOrder } from '../interfaces/IDietaryCodesOrder';

import {
    ALL_FEATURES,
    FeatureId,
    IFeature
} from '../interfaces/IFeature';

import { getFeatureFlag } from '../utils/getFeatureFlag';
import { IState } from '../../Store/state';
import { N_DATE_FORMAT, parseFormat, startOfDateAsNumberN, endOfDateAsNumberN } from '../../Common/utils/dateFunctions';


export const isDateInMenuCycle = (dateKey: string, cycle: IMenuCycleDefinition): boolean => {
    const cycleDateStart: number = startOfDateAsNumberN(parseFormat(cycle.startDate, N_DATE_FORMAT));
    const cycleDateEnd: number = endOfDateAsNumberN(parseFormat(cycle.endDate, N_DATE_FORMAT));
    const theDate: number = startOfDateAsNumberN(parseFormat(dateKey, N_DATE_FORMAT));
    return theDate <= cycleDateEnd && theDate >= cycleDateStart;
}

export const getMenuDataForDateKey = (
    dateKey: string,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
): IMenuData | undefined => {

    for (const cId in menuCycleDefinitionMap) {
        if (cId && isDateInMenuCycle(dateKey, menuCycleDefinitionMap[cId])) {
            const menuCycleMap: IMenuCycleMap | undefined = allMenuCycles[cId];
            const pastMenus: IMenuCycleMap | undefined = allMenuCycles[CurrentCyclePastMenus];
            if (menuCycleMap) {
                const cycle: IMenuCycle | undefined = menuCycleMap[dateKey];
                if (cycle) {
                    const menuDataMap: IMenuDataMap = menuDataByCycle[cId];
                    if (menuDataMap) {
                        const menuData: IMenuData | undefined = menuDataMap[cycle.menuId];
                        return menuData;
                    }
                    else {
                        // tslint:disable-next-line:no-console
                        console.log(`getMenuDataForDateKey could not find menuDataMap for : ${cId} ${dateKey}`);
                    }
                }
                else if (pastMenus !== undefined) {
                    const cycle: IMenuCycle | undefined = pastMenus[dateKey];
                    if (cycle) {
                        const menuDataMap: IMenuDataMap = menuDataByCycle[cId];
                        if (menuDataMap) {
                            const menuData: IMenuData | undefined = menuDataMap[cycle.menuId];
                            return menuData;
                        }
                        else {
                            // tslint:disable-next-line:no-console
                            console.log(`getMenuDataForDateKey could not find menuDataMap for : ${CurrentCyclePastMenus} ${dateKey}`);
                        }
                    }
                }
                else {
                    // tslint:disable-next-line:no-console
                    console.log(`getMenuDataForDateKey could not find IMenuCycle for : ${cId} ${dateKey}`);
                }
            }
            else {
                // tslint:disable-next-line:no-console
                console.log(`getMenuDataForDateKey could not find menuCycleMap for : ${cId} ${dateKey}`);
            }
        }
    }

    return undefined;
}

const extractActiveDietaries = (
    special: IDietaryCodesOrder | undefined,
    allDietaries: Readonly<IDietaryCodesMap>): IDietaryCodesOrder => {
    const result: IDietaryCodesOrder = {};
    if (special !== undefined) {
        for (const code in special) {
            const dietary: IDietaryCode = allDietaries[code];
            if (dietary !== undefined && dietary.deleted === false) {
                result[code] = special[code];
            }
        }
    }
    return result;
};

const calculatePortionsForAlternatives = (
    item: Readonly<IMenuExtra>,
    special: IDietaryCodesOrder,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>): number => {

    let result: number = 0;
    const alternative: IAlternative | undefined = menuAlternatives[item.id];

    if (alternative !== undefined &&
        alternative.dietaries &&
        Object.keys(alternative.dietaries).length > 0) {

        for (const code in special) {
            const altId: string | undefined = alternative.dietaries[code];
            const dietary: IDietaryCode = allDietaries[code];
            if (altId !== undefined && dietary !== undefined) {
                if (containsAnAllergen(item.allergens, dietary.allergens)) {
                    const numberDietaries: number = special[code];
                    result += numberDietaries;
                }
            }
        }
    }

    return result;
};

const updateAlternativePortionMap = (
    uid: string,
    runner: string,
    date: number,
    itemId: string,
    special: IDietaryCodesOrder,
    menuAlternatives: Readonly<IAlternativeMap>,
    items: Readonly<IMenuMealMap | IMenuExtraMap>,
    map: IAltPortionMap,
): IAltPortionMap => {

    const alternative: IAlternative | undefined = menuAlternatives[itemId];

    if (alternative !== undefined &&
        alternative.dietaries &&
        Object.keys(alternative.dietaries).length > 0) {

        for (const code in special) {
            const altId: string | undefined = alternative.dietaries[code];
            if (altId !== undefined) {
                const altItem: Readonly<IMenuExtra> | undefined = items[altId];
                if (altItem === undefined) {
                    console.error(`getAlternativePortionMap could not find menu main for main altId : ${altId}`);
                }
                else {
                    const numberDietaries: number = special[code];
                    let altCount = numberDietaries;
                    const key: string = `${code}_${altItem.id}`;
                    if (map[key] !== undefined) {
                        altCount = 0;
                    }
                    const altPortion: IKitchenMenuAlternativePortions = {
                        code,
                        date,
                        id: altId,
                        item: altItem.item,
                        meals: Math.ceil(altCount * altItem.joggerMultiplier),
                        runner,
                        total: Math.ceil(altCount * altItem.joggerMultiplier),
                        uid,
                    };

                    if (map[key]) {
                        map[key].meals += altCount;
                        map[key].total += altCount;
                    }
                    else {
                        map[key] = altPortion;
                    }
                }
            }
        }
    }

    return map;
};

export const getSessionNumbers = (
    uid: string,
    sdo: IOrder,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuItems: Readonly<IMenuMealMap>,
    menuSides: Readonly<IMenuExtraMap>,
    menuDesserts: Readonly<IMenuExtraMap>,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>,
    runnerMap: Readonly<IUidToRunnerColor>): IKitchenMenuPortions[] => {

    const results: IKitchenMenuPortions[] = [];
    const order: IDayOrder = mealSession === 'tea' ? sdo.tea : sdo.lunch;
    const special: IDietaryCodesOrder = extractActiveDietaries(order.special, allDietaries);
    const dessertSpecial: IDietaryCodesOrder = extractActiveDietaries(order.dessertSpecial, allDietaries);

    const sessionNumbers: number = order.normalMeals + getTotalSpecialsCount(special);
    const sessionDessertNumbers: number = order.dessert + getTotalSpecialsCount(dessertSpecial);

    if (sessionNumbers) {
        const date: number = sdo.orderDate
        const orderDate: string = sdo.dateId;
        const menu: IMenuData | undefined = getMenuDataForDateKey(orderDate, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle);
        if (menu) {
            const runner: string = runnerMap[uid] !== undefined ? runnerMap[uid] : RUNNER_NONE;
            const session: IMenuDataSession = menu[mealSession];
            let newMainPortions: number = sessionNumbers;
            const normalPortion: IMenuExtra = menuItems[session.normal];
            if (normalPortion && normalPortion.canMultiply) {
                const allergensMatch: boolean = orderAllergensMatchDietaryAllergens(normalPortion.allergens, special, allDietaries);

                const portionsToRemove = allergensMatch ? calculatePortionsForAlternatives(normalPortion, special, menuAlternatives, allDietaries) : 0;
                newMainPortions = sessionNumbers - portionsToRemove;

                const portion: IKitchenMenuPortions = {
                    date,
                    id: normalPortion.id,
                    item: normalPortion.item,
                    meals: newMainPortions,
                    runner,
                    total: normalPortion.multiplier * newMainPortions,
                    uid,
                }
                results.push(portion);
            }


            const sides: string[] = session.sides;
            for (const side of sides) {
                const menuSide: IMenuExtra | undefined = menuSides[side];
                if (menuSide && menuSide.canMultiply) {
                    const allergensMatch: boolean = orderAllergensMatchDietaryAllergens(menuSide.allergens, special, allDietaries);

                    const portionsToRemove = allergensMatch ? calculatePortionsForAlternatives(menuSide, special, menuAlternatives, allDietaries) : 0;
                    const newPortions: number = sessionNumbers - portionsToRemove;

                    const portion: IKitchenMenuPortions = {
                        date,
                        id: menuSide.id,
                        item: menuSide.item,
                        meals: newPortions,
                        runner,
                        total: menuSide.multiplier * newPortions,
                        uid,
                    }
                    results.push(portion);
                }
                else if (menuSide === undefined) {
                    // tslint:disable-next-line:no-console
                    console.error(`getSessionNumbers ${mealSession} could not find menu side for side id : ${side}`);
                }
            }


            const desserts: string[] = session.desserts;
            for (const desert of desserts) {
                const menuDessert: IMenuExtra | undefined = menuDesserts[desert];
                if (menuDessert && menuDessert.canMultiply) {
                    const allergensMatch: boolean = orderAllergensMatchDietaryAllergens(menuDessert.allergens, dessertSpecial, allDietaries);

                    const portionsToRemove = allergensMatch ? calculatePortionsForAlternatives(menuDessert, dessertSpecial, menuAlternatives, allDietaries) : 0;
                    const newPortions: number = sessionDessertNumbers - portionsToRemove;

                    const portion: IKitchenMenuPortions = {
                        date,
                        id: menuDessert.id,
                        item: menuDessert.item,
                        meals: newPortions,
                        runner,
                        total: menuDessert.multiplier * newPortions,
                        uid,
                    }
                    results.push(portion);
                }
                else if (menuDessert === undefined) {
                    // tslint:disable-next-line:no-console
                    console.error(`getSessionNumbers ${mealSession} could not find menu dessert for dessert id : ${desert}`);
                }
            }
        }
        else {
            // tslint:disable-next-line:no-console
            console.error(`getSessionNumbers no menu for ${orderDate}`);
        }
    }

    return results;
};


const containsAnAllergen = (allergensToSearch: string[], allergensToFind: string[]): boolean => {
    return allergensToFind.some(item => allergensToSearch.includes(item));
};


const orderAllergensMatchDietaryAllergens = (
    allergensToSearch: string[],
    specials: IDietaryCodesOrder,
    allDietaries: Readonly<IDietaryCodesMap>): boolean => {

    if (specials && allergensToSearch) {
        for (const code in specials) {
            if (code) {
                const dietary: IDietaryCode = allDietaries[code];
                if (dietary !== undefined && dietary.allergens && containsAnAllergen(allergensToSearch, dietary.allergens)) {
                    return true;
                }
            }
        }
    }

    return false;
};

const reduceDuplicatedAlternatives = (
    portions: IKitchenMenuPortions[],
    alternativePortions: IKitchenMenuAlternativePortions[]): void => {
    for (const a of alternativePortions) {
        const p = portions.find(f => f.id === a.id);
        if (p) {
            p.meals = p.meals - a.meals;
            p.total = p.meals;
        }
    }
}

export const getDesserts = (
    orderDate: string,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuDesserts: Readonly<IMenuExtraMap>,
): IMenuExtra[] => {

    const result: IMenuExtra[] = [];
    const menu: IMenuData | undefined = getMenuDataForDateKey(orderDate, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle);
    if (menu) {
        const session: IMenuDataSession = menu[mealSession];
        const desserts: string[] = session.desserts;
        for (const dessert of desserts) {
            const menuDessert: IMenuExtra | undefined = menuDesserts[dessert];
            if (!menuDessert) {
                // tslint:disable-next-line:no-console
                console.error(`getDesserts ${mealSession} could not find menu dessert for dessert id : ${dessert}`);
                continue;
            }
            result.push(menuDessert);
        }

    }

    return result;
};

export const getSides = (
    orderDate: string,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuSides: Readonly<IMenuExtraMap>,
): IMenuExtra[] => {

    const result: IMenuExtra[] = [];
    const menu: IMenuData | undefined = getMenuDataForDateKey(orderDate, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle);
    if (menu) {
        const session: IMenuDataSession = menu[mealSession];
        const sides: string[] = session.sides;
        for (const side of sides) {
            const menuSide: IMenuExtra | undefined = menuSides[side];
            if (!menuSide) {
                // tslint:disable-next-line:no-console
                console.error(`getSides ${mealSession} could not find menu side for side id : ${side}`);
                continue;
            }
            result.push(menuSide);
        }

    }

    return result;
};

//  All values are rounded up
//  Do we only want to calculate for order meals that we have decided to 'canMultiply'????
export const getMainNumbers = (
    uid: string,
    sdo: IOrder,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuItems: Readonly<IMenuMealMap>,
    menuSides: Readonly<IMenuExtraMap>,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>,
    runnerMap: Readonly<IUidToRunnerColor>): IPortionsWithAlternatives => {

    const portions: IKitchenMenuPortions[] = [];
    let altPortMap: IAltPortionMap = {};

    const order: IDayOrder = mealSession === 'tea' ? sdo.tea : sdo.lunch;
    const special: IDietaryCodesOrder = extractActiveDietaries(order.special, allDietaries);
    const totalDietaryCount: number = getTotalSpecialsCount(special);
    const sessionNumbers: number = order.normalMeals + totalDietaryCount;
    const hasMeal: boolean = sessionNumbers !== 0;
    const orderDate: string = sdo.dateId;
    const menu: IMenuData | undefined = hasMeal ? getMenuDataForDateKey(orderDate, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle) : undefined;

    if (menu) {
        const runner: string = runnerMap[uid] !== undefined ? runnerMap[uid] : RUNNER_NONE;
        const date: number = sdo.orderDate;
        const session: IMenuDataSession = menu[mealSession];
        const normalPortion: IMenuExtra | undefined = menuItems[session.normal];
        // console.log(uid)
        // if (uid === `8vkL3m0cdTRfOHuayGYv1yNGAG13`) {
        //     console.log(`break`)   ;
        // }
        if (normalPortion) {
            const sidesInOrder: IMenuExtra[] = getSides(orderDate, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuSides);
            const allergensInSides: string[] = [];
            // console.log(JSON.stringify(sidesInOrder))
            sidesInOrder.forEach((s) => allergensInSides.push(...s.allergens));
            const allAllergens: string[] = [...normalPortion.allergens, ...allergensInSides];
            const uniqueAllergens = allAllergens.filter((x, i, a) => a.indexOf(x) === i);
            const allergensMatch: boolean = orderAllergensMatchDietaryAllergens(uniqueAllergens, special, allDietaries);
            if (allergensMatch) {
                const portionsToRemove = calculatePortionsForAlternatives(normalPortion, special, menuAlternatives, allDietaries);
                
                altPortMap = updateAlternativePortionMap(
                    uid,
                    runner,
                    date,
                    normalPortion.id,
                    special,
                    menuAlternatives,
                    menuItems, altPortMap);

                const newPortions: number = sessionNumbers - portionsToRemove;

                const portion: IKitchenMenuPortions = {
                    date,
                    id: normalPortion.id,
                    item: normalPortion.item,
                    meals: Math.ceil(normalPortion.joggerMultiplier * newPortions),
                    runner,
                    total: Math.ceil(normalPortion.joggerMultiplier * newPortions),
                    uid,
                }
                portions.push(portion);
            }
            else {
                const portion: IKitchenMenuPortions = {
                    date,
                    id: normalPortion.id,
                    item: normalPortion.item,
                    meals: Math.ceil(normalPortion.joggerMultiplier * sessionNumbers),
                    runner,
                    total: Math.ceil(normalPortion.joggerMultiplier * sessionNumbers),
                    uid,
                }
                portions.push(portion);
            }
        }
        else {
            console.error(`getMainNumbers session has no normal lunch item: ${JSON.stringify(session)}`);
            const portion: IKitchenMenuPortions = {
                date,
                id: menuItemNotSelectedId,
                item: menuItemNotSelected,
                meals: 1,
                runner,
                total: 1,
                uid,
            }
            portions.push(portion);
        }
    }

    const alternativePortions: IKitchenMenuAlternativePortions[] = Object.values(altPortMap);

    reduceDuplicatedAlternatives(portions, alternativePortions);

    const mainPortions: IPortionsWithAlternatives = {
        alternativePortions,
        portions,
    };
    return mainPortions;
};

//  All values are rounded up
export const getDessertNumbers = (
    noDessert: boolean,
    uid: string,
    sdo: IOrder,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuDesserts: Readonly<IMenuExtraMap>,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>,
    runnerMap: Readonly<IUidToRunnerColor>): IPortionsWithAlternatives => {

    const portions: IKitchenMenuPortions[] = [];
    let altPortMap: IAltPortionMap = {};
    const order: IDayOrder = mealSession === 'tea' ? sdo.tea : sdo.lunch;

    const dessertSpecial: IDietaryCodesOrder = extractActiveDietaries(order.dessertSpecial, allDietaries);
    const totalDietaryCount: number = getTotalSpecialsCount(dessertSpecial);
    const sessionNumbers: number = order.dessert + totalDietaryCount;

    const orderDate: string = sdo.dateId;
    const dessertsInOrder: IMenuExtra[] = getDesserts(orderDate, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuDesserts);
    const runner: string = runnerMap[uid] !== undefined ? runnerMap[uid] : RUNNER_NONE;
    const date: number = sdo.orderDate;

    if ((order.dessert === 0 && totalDietaryCount === 0) || noDessert) {
        if (mealSession === `lunch`) {
            for (const menuDessert of dessertsInOrder) {
                const portion: IKitchenMenuPortions = {
                    date,
                    id: menuDessert.id,
                    item: menuDessert.item,
                    meals: 0,
                    runner,
                    total: 0,
                    uid,
                }
                portions.push(portion);
            }
        }
        else {
            if (noDessert && order.normalMeals > 0) {
                for (const menuDessert of dessertsInOrder) {
                    const portion: IKitchenMenuPortions = {
                        date,
                        id: menuDessert.id,
                        item: menuDessert.item,
                        meals: 0,
                        runner,
                        total: 0,
                        uid,
                    }
                    portions.push(portion);
                }
            }
        }
    }
    else {
        for (const menuDessert of dessertsInOrder) {
            if (orderAllergensMatchDietaryAllergens(menuDessert.allergens, dessertSpecial, allDietaries)) {

                const portionsToRemove = calculatePortionsForAlternatives(menuDessert, dessertSpecial, menuAlternatives, allDietaries);
                altPortMap = updateAlternativePortionMap(
                    uid,
                    runner,
                    date,
                    menuDessert.id,
                    dessertSpecial,
                    menuAlternatives,
                    menuDesserts, altPortMap);

                const newPortions: number = sessionNumbers - portionsToRemove;

                const portion: IKitchenMenuPortions = {
                    date,
                    id: menuDessert.id,
                    item: menuDessert.item,
                    meals: Math.ceil(menuDessert.joggerMultiplier * newPortions),
                    runner,
                    total: Math.ceil(menuDessert.joggerMultiplier * newPortions),
                    uid,
                }
                portions.push(portion);
            }
            else {
                const portion: IKitchenMenuPortions = {
                    date,
                    id: menuDessert.id,
                    item: menuDessert.item,
                    meals: Math.ceil(menuDessert.joggerMultiplier * sessionNumbers),
                    runner,
                    total: Math.ceil(menuDessert.joggerMultiplier * sessionNumbers),
                    uid,
                }
                portions.push(portion);
            }
        }
    }

    const alternativePortions: IKitchenMenuAlternativePortions[] = Object.values(altPortMap);

    reduceDuplicatedAlternatives(portions, alternativePortions);

    const dessertPortions: IPortionsWithAlternatives = {
        alternativePortions,
        portions,
    };

    return dessertPortions;
};


export const getSideNumbers = (
    uid: string,
    sdo: IOrder,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuSides: Readonly<IMenuExtraMap>,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>,
    runnerMap: Readonly<IUidToRunnerColor>): IPortionsWithAlternatives => {

    const portions: IKitchenMenuPortions[] = [];
    let altPortMap: IAltPortionMap = {};
    const order: IDayOrder = mealSession === 'tea' ? sdo.tea : sdo.lunch;

    const specials: IDietaryCodesOrder = extractActiveDietaries(order.special, allDietaries);
    const totalDietaryCount: number = getTotalSpecialsCount(specials);
    const sessionNumbers: number = order.dessert + totalDietaryCount;

    const orderDate: string = sdo.dateId;
    const sidesInOrder: IMenuExtra[] = getSides(orderDate, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuSides);
    const runner: string = runnerMap[uid] !== undefined ? runnerMap[uid] : RUNNER_NONE;
    const date: number = sdo.orderDate;

    if ((order.dessert === 0 && totalDietaryCount === 0)) {
        if (mealSession === `lunch`) {
            // for (const menuDessert of sidesInOrder) {
            //     const portion: IKitchenMenuPortions = {
            //         date,
            //         id: menuDessert.id,
            //         item: menuDessert.item,
            //         meals: 0,
            //         runner,
            //         total: 0,
            //         uid,
            //     }
            //     portions.push(portion);
            // }
        }
        else {
            if (order.normalMeals > 0) {
                // for (const menuDessert of sidesInOrder) {
                //     const portion: IKitchenMenuPortions = {
                //         date,
                //         id: menuDessert.id,
                //         item: menuDessert.item,
                //         meals: 0,
                //         runner,
                //         total: 0,
                //         uid,
                //     }
                //     portions.push(portion);
                // }
            }
        }
    }
    else {
        for (const menuSide of sidesInOrder) {
            if (orderAllergensMatchDietaryAllergens(menuSide.allergens, specials, allDietaries)) {

                const portionsToRemove = calculatePortionsForAlternatives(menuSide, specials, menuAlternatives, allDietaries);
                altPortMap = updateAlternativePortionMap(
                    uid,
                    runner,
                    date,
                    menuSide.id,
                    specials,
                    menuAlternatives,
                    menuSides, altPortMap);

                const newPortions: number = sessionNumbers - portionsToRemove;

                const portion: IKitchenMenuPortions = {
                    date,
                    id: menuSide.id,
                    item: menuSide.item,
                    meals: Math.ceil(menuSide.joggerMultiplier * newPortions),
                    runner,
                    total: Math.ceil(menuSide.joggerMultiplier * newPortions),
                    uid,
                }
                portions.push(portion);
            }
            else {
                const portion: IKitchenMenuPortions = {
                    date,
                    id: menuSide.id,
                    item: menuSide.item,
                    meals: Math.ceil(menuSide.joggerMultiplier * sessionNumbers),
                    runner,
                    total: Math.ceil(menuSide.joggerMultiplier * sessionNumbers),
                    uid,
                }
                portions.push(portion);
            }
        }
    }

    const alternativePortions: IKitchenMenuAlternativePortions[] = Object.values(altPortMap);

    reduceDuplicatedAlternatives(portions, alternativePortions);

    const dessertPortions: IPortionsWithAlternatives = {
        alternativePortions,
        portions,
    };

    return dessertPortions;
};


export interface IPortions {
    lunches: IKitchenMenuPortions[];
    teas: IKitchenMenuPortions[];
    allLunches: number;
    allTeas: number;
}

export const getPortionNumbersForOrders = (orderMap: Readonly<IOrderMap>, state: IState): IPortions => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuSides: Readonly<IMenuExtraMap> = getAllMenuSides(state.menu);
    const menuLunches: Readonly<IMenuMealMap> = getAllMenuLunches(state.menu);
    const menuDesserts: Readonly<IMenuExtraMap> = getAllMenuDesserts(state.menu);
    const runnerMap: Readonly<IUidToRunnerColor> = getUidRunner(state.app);
    const menuTeas: Readonly<IMenuMealMap> = getAllMenuTeas(state.menu);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);

    const result: IPortions = {
        lunches: [],
        teas: [],
        allLunches: 0,
        allTeas: 0
    };

    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            const specialTea: IDietaryCodesOrder = extractActiveDietaries(order.tea.special, allDietaries);
            const sessionNumbersTea: number = order.tea.normalMeals + getTotalSpecialsCount(specialTea);
            result.allTeas += sessionNumbersTea;

            const specialLunch: IDietaryCodesOrder = extractActiveDietaries(order.lunch.special, allDietaries);
            const sessionNumbersLunch: number = order.lunch.normalMeals + getTotalSpecialsCount(specialLunch);
            result.allLunches += sessionNumbersLunch;
            
            result.lunches = result.lunches.concat(
                getSessionNumbers(order.uid, order, 'lunch', menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuLunches, menuSides, menuDesserts, menuAlternatives, allDietaries, runnerMap)
            );
            result.teas = result.teas.concat(
                getSessionNumbers(order.uid, order, 'tea', menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuTeas, menuSides, menuDesserts, menuAlternatives, allDietaries, runnerMap)
            );
        }
    }
    return result;
};

export interface IPortionsForDate {
    lunchPortionsByCustomer: ReadonlyArray<IKitchenMenuPortions>;
    lunchPortionsByTotals: ReadonlyArray<IKitchenMenuPortions>;
    teaPortionsByCustomer: ReadonlyArray<IKitchenMenuPortions>;
    teaPortionsByTotals: ReadonlyArray<IKitchenMenuPortions>;
    allLunches: number;
    allTeas: number;
};

export const getPortionsForDate = (theDate: number, state: IState): IPortionsForDate => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(theDate, theDate, state);
    const portions: IPortions = getPortionNumbersForOrders(orderMap, state);
    const results: Readonly<IPortionsForDate> = {
        lunchPortionsByCustomer: portions.lunches,
        lunchPortionsByTotals: sumSidesByDate(portions.lunches),
        teaPortionsByCustomer: portions.teas,
        teaPortionsByTotals: sumSidesByDate(portions.teas),
        allLunches: portions.allLunches,
        allTeas: portions.allTeas
    }

    return results;
};

export const getJoggerDessertsForOrders = (mealSession: MealSession, orderMap: Readonly<IOrderMap>, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuDesserts: Readonly<IMenuExtraMap> = getAllMenuDesserts(state.menu);
    const runnerMap: Readonly<IUidToRunnerColor> = getUidRunner(state.app);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);
    const selectedRunner: Readonly<string> | undefined = getSelectedRunner(state.app);

    const result: IPortionsWithAlternatives[] = [];
    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            if (filterOnSelectedRunner && selectedRunner) { //  only calc Joggers for selected runner
                if (runnerMap[order.uid] !== selectedRunner) {
                    continue;
                }
            }

            const features: Readonly<IFeature> | undefined = getFeature(order.uid, state.app);
            const noJogger: boolean = features !== undefined ? getFeatureFlag(features, ALL_FEATURES[FeatureId.NoJogger]) : false;
            const noDessert: boolean = features !== undefined ? getFeatureFlag(features, ALL_FEATURES[FeatureId.NoDessert]) : false;
            if (noJogger === false) {
                const portions: IPortionsWithAlternatives = getDessertNumbers(noDessert, order.uid, order, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuDesserts, menuAlternatives, allDietaries, runnerMap);
                if (portions.alternativePortions.length || portions.portions.length) {
                    result.push(portions);
                }
            }
        }
    }
    return result;
};

export const getJoggerSidesForOrders = (mealSession: MealSession, orderMap: Readonly<IOrderMap>, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuSides: Readonly<IMenuExtraMap> = getAllMenuSides(state.menu);
    const runnerMap: Readonly<IUidToRunnerColor> = getUidRunner(state.app);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);
    const selectedRunner: Readonly<string> | undefined = getSelectedRunner(state.app);

    const result: IPortionsWithAlternatives[] = [];
    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            if (filterOnSelectedRunner && selectedRunner) { //  only calc Joggers for selected runner
                if (runnerMap[order.uid] !== selectedRunner) {
                    continue;
                }
            }
           
            const features: Readonly<IFeature> | undefined = getFeature(order.uid, state.app);
            const noJogger: boolean = features !== undefined ? getFeatureFlag(features, ALL_FEATURES[FeatureId.NoJogger]) : false;
            if (noJogger === false) {
                const portions: IPortionsWithAlternatives = getSideNumbers(order.uid, order, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuSides, menuAlternatives, allDietaries, runnerMap);
                if (portions.alternativePortions.length || portions.portions.length) {
                    result.push(portions);
                }
            }
        }
    }
    return result;
};

export const getSessionJoggerDessertsForDate = (mealSession: MealSession, startDate: number, endDate: number, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(startDate, endDate, state);
    const portions: IPortionsWithAlternatives[] = getJoggerDessertsForOrders(mealSession, orderMap, filterOnSelectedRunner, state);
    return portions;
};

export const getSessionJoggerSidesForDate = (mealSession: MealSession, startDate: number, endDate: number, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(startDate, endDate, state);
    const portions: IPortionsWithAlternatives[] = getJoggerSidesForOrders(mealSession, orderMap, filterOnSelectedRunner, state);
    return portions;
};

//  All values are rounded up
export const getDessertCount = (
    noDessert: boolean,
    item: string,
    sdo: IOrder,
    mealSession: MealSession,
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>,
    allMenuCycles: Readonly<IAllMenuCycleMap>,
    menuDataByCycle: Readonly<IMenuDataByCycleMap>,
    menuDesserts: Readonly<IMenuExtraMap>,
    menuAlternatives: Readonly<IAlternativeMap>,
    allDietaries: Readonly<IDietaryCodesMap>,
): number => {

    const altPortMap: IAltPortionMap = {};
    const order: IDayOrder = mealSession === 'tea' ? sdo.tea : sdo.lunch;
    const dessertSpecial: IDietaryCodesOrder = order.dessertSpecial !== undefined ? order.dessertSpecial : {};
    const totalDietaryCount: number = getTotalSpecialsCount(dessertSpecial);
    const sessionNumbers: number = order.dessert + totalDietaryCount;
    const orderDate: string = sdo.dateId;
    const dessertsInOrder: IMenuExtra[] = getDesserts(orderDate, mealSession, menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuDesserts);

    let count: number = 0;

    if ((order.dessert === 0 && totalDietaryCount === 0) || noDessert) {
        return count;
    }
    else {
        for (const menuDessert of dessertsInOrder) {
            if (orderAllergensMatchDietaryAllergens(menuDessert.allergens, order.dessertSpecial, allDietaries)) {
                let portionsToRemove: number = 0;
                const alternative: IAlternative | undefined = menuAlternatives[menuDessert.id];
                if (alternative) {
                    for (const code in dessertSpecial) {
                        if (code) {
                            if (alternative.dietaries) {
                                const altId: string | undefined = alternative.dietaries[code];
                                if (altId) {
                                    const numberDietaries: number = dessertSpecial[code];
                                    const altDessert: IMenuExtra | undefined = menuDesserts[altId];
                                    if (!altDessert) {
                                        // tslint:disable-next-line:no-console
                                        console.error(`getDessertCount ${mealSession} could not find menu dessert for dessert altId : ${altId}`);
                                        continue;
                                    }

                                    //  Check if we have double counted an alternative with a previous dessert on this order
                                    if (altPortMap[code] !== undefined) {
                                        continue;
                                    }

                                    if (altDessert.id !== item) {
                                        continue;
                                    }
                                    const altPortion: IKitchenMenuAlternativePortions = {
                                        code,
                                        date: 0,
                                        id: altId,
                                        item: altDessert.item,
                                        meals: 0,
                                        runner: '',
                                        total: 0,
                                        uid: '',
                                    };

                                    altPortMap[code] = altPortion;

                                    count += Math.ceil(numberDietaries * altDessert.multiplier);
                                    portionsToRemove += numberDietaries;

                                    // tslint:disable-next-line: no-console
                                    // console.log(`alternative1=${code} ${numberDietaries * altDessert.multiplier} ${portionsToRemove} ${count}`);
                                }
                            }
                        }
                    }

                    if (menuDessert.id !== item) {
                        continue;
                    }
                    const newPortions: number = sessionNumbers - portionsToRemove;
                    count += Math.ceil(newPortions * menuDessert.multiplier);

                    // tslint:disable-next-line: no-console
                    // console.log(`alternative2=${newPortions} ${newPortions * menuDessert.multiplier} ${portionsToRemove} ${count}`);
                }
                else {
                    // tslint:disable-next-line:no-console
                    // console.error(`getDessertCount ${mealSession} orderHasAllergen ${order} but no alternative record for dessert : ${menuDessert.item}`);
                    //  stick an order on any way
                    if (menuDessert.id !== item) {
                        continue;
                    }
                    const newPortions: number = sessionNumbers - portionsToRemove;
                    count += Math.ceil(newPortions * menuDessert.multiplier);

                    // tslint:disable-next-line: no-console
                    // console.log(`alternative not found ${newPortions * menuDessert.multiplier} ${portionsToRemove} ${count}`);

                    continue;
                }
            }
            else {
                if (menuDessert.id !== item) {
                    continue;
                }
                count += Math.ceil(menuDessert.multiplier * sessionNumbers);
                // tslint:disable-next-line: no-console
                // console.log(`no allergen ${sessionNumbers * menuDessert.multiplier} ${count}`);
            }
        }
    }

    // tslint:disable-next-line: no-console
    // console.log(`count for order  ${count}`);

    return count;
};


export const getLunchDessertItemsOrderedForDateRange = (fromDate: number, toDate: number, itemId: string, state: IState): number => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(fromDate, toDate, state);
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuDesserts: Readonly<IMenuExtraMap> = getAllMenuDesserts(state.menu);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);

    let count: number = 0;
    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            const features: Readonly<IFeature> | undefined = getFeature(order.uid, state.app);
            const noJogger: boolean = features !== undefined ? getFeatureFlag(features, ALL_FEATURES[FeatureId.NoJogger]) : false;
            const noDessert: boolean = features !== undefined ? getFeatureFlag(features, ALL_FEATURES[FeatureId.NoDessert]) : false;
            if (noJogger === false) {
                count += getDessertCount(noDessert, itemId, order, 'lunch', menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuDesserts, menuAlternatives, allDietaries);
            }
        }
    }

    return count;
};

export const getLunchJoggerMainsForDate = (startDate: number, endDate: number, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(startDate, endDate, state);
    const portions: IPortionsWithAlternatives[] = getLunchMainsAndAlternativesForOrders(orderMap, filterOnSelectedRunner, state);
    return portions;
};


export const getLunchMainsAndAlternativesForOrders = (orderMap: Readonly<IOrderMap>, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuLunches: Readonly<IMenuMealMap> = getAllMenuLunches(state.menu);
    const menuSides: Readonly<IMenuExtraMap> = getAllMenuSides(state.menu);
    const runnerMap: Readonly<IUidToRunnerColor> = getUidRunner(state.app);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);
    const selectedRunner: Readonly<string> | undefined = getSelectedRunner(state.app);

    const result: IPortionsWithAlternatives[] = [];
    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            if (filterOnSelectedRunner && selectedRunner) { //  only calc Mains for selected runner
                if (runnerMap[order.uid] !== selectedRunner) {
                    continue;
                }
            }
            
            const portions: IPortionsWithAlternatives = getMainNumbers(order.uid, order, 'lunch', menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuLunches, menuSides, menuAlternatives, allDietaries, runnerMap);
            if (portions.alternativePortions.length || portions.portions.length) {
                result.push(portions);
            }
        }
    }
    return result;
};

export const getTeaJoggerMainsForDate = (startDate: number, endDate: number, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const orderMap: Readonly<IOrderMap> = loadAllOrdersForDateRange(startDate, endDate, state);
    const portions: IPortionsWithAlternatives[] = getTeaMainsAndAlternativesForOrders(orderMap, filterOnSelectedRunner, state);
    return portions;
};

export const getTeaMainsAndAlternativesForOrders = (orderMap: Readonly<IOrderMap>, filterOnSelectedRunner: boolean, state: IState): IPortionsWithAlternatives[] => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = getAllMenuCycleMap(state.menu);
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = getMenuDataByCycleMap(state.menu);
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = getMenuCycleDefinitionMap(state.menu);
    const menuTeas: Readonly<IMenuMealMap> = getAllMenuTeas(state.menu);
    const menuSides: Readonly<IMenuExtraMap> = getAllMenuSides(state.menu);
    const runnerMap: Readonly<IUidToRunnerColor> = getUidRunner(state.app);
    const menuAlternatives: Readonly<IAlternativeMap> = getAllMenuAlternativesMap(state.menu);
    const allDietaries: Readonly<IDietaryCodesMap> = getAllDietaryCodes(state.menu);
    const selectedRunner: Readonly<string> | undefined = getSelectedRunner(state.app);

    const result: IPortionsWithAlternatives[] = [];

    for (const k in orderMap) {
        const order = orderMap[k];
        if (order.uid) {
            if (filterOnSelectedRunner && selectedRunner) { //  only calc Mains for selected runner
                if (runnerMap[order.uid] !== selectedRunner) {
                    continue;
                }
            }
            const portions: IPortionsWithAlternatives = getMainNumbers(order.uid, order, 'tea', menuCycleDefinitionMap, allMenuCycles, menuDataByCycle, menuTeas, menuSides, menuAlternatives, allDietaries, runnerMap);
            if (portions.alternativePortions.length || portions.portions.length) {
                result.push(portions);
            }
        }
    }

    return result;
};
