export interface IDietaryCode {
    code: string;
    label: string;
    allergens: string[];
    deleted: boolean;
    users: string[];
}

export interface IDietaryCodesMap {
    [code: string]: IDietaryCode;
}

export interface IDeletedCodesMap {
    [code: string]: boolean;
}

export const DietaryCodesCD: string = `CD`;
export const DietaryCodesAllUsers: string = `AllUsers`;
export const DietaryCodesDataPath: string = `data/food/dietary_codes`;
export const DietaryCodesUserDataPath: string = `data/food/user/dietary_codes`;

