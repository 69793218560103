import { startOfDay, getISODay, addHours, addWeeks, isAfter, getHours, getMinutes, differenceInDays } from '../../Common/utils/dateFunctions';
import { nextWeekDayN, ISO_WEEK_DAY_MONDAY, ISO_WEEK_DAY_FRIDAY, isSameOrAfter } from '../../Common/utils/dateFunctions';


export const getNextCutoff = (today: Date, weekday: number, hour: number, addWeek: boolean = false): Date => {
    let comingWeekDay: Date = startOfDay(today);
    let thisWeekday: number = getISODay(comingWeekDay);

    while (thisWeekday !== weekday) {
        comingWeekDay = nextWeekDayN(comingWeekDay);
        thisWeekday = getISODay(comingWeekDay);
    }
    comingWeekDay = addHours(comingWeekDay, hour);
    
    if (addWeek) {
        comingWeekDay = addWeeks(comingWeekDay, 1);
    }
    return comingWeekDay;
};

export const geFollowingMonday = (today: Date): Date => {
    let comingWeekDay: Date = startOfDay(today);
    let thisWeekday: number = getISODay(comingWeekDay);
    while (thisWeekday !== ISO_WEEK_DAY_MONDAY) {
        comingWeekDay = nextWeekDayN(comingWeekDay);
        thisWeekday = getISODay(comingWeekDay);
    }
    return comingWeekDay;
};

const calcSeconds = (hour: number, minutes: number): number => {
    return (60 * minutes) + (3600 * hour);
};

//  Cut off rules are by 23pm the Thursday before the following weeks orders
export const canAddModifyOrder = (today: Date, orderDate: Date, weekday: number = 4, hour: number = 23): boolean => {
    if (isAfter(today, orderDate)) {
        return false
    }
    const startofToday:  Date = startOfDay(today);
    const orderDateWeekday: number = getISODay(orderDate);
    const todaysWeekday: number = getISODay(today);
    const todaysHour: number = getHours(today);
    const todaysMins: number = getMinutes(today);
    const todaySeconds = calcSeconds(todaysHour, todaysMins);
    const cutoffSeconds = calcSeconds(hour, 0);
    if (todaysWeekday > weekday || (todaysWeekday === weekday && todaySeconds > cutoffSeconds)) {
        const mondayAfterNextCutoff: Date = geFollowingMonday(getNextCutoff(startofToday, weekday, 0, todaysWeekday === weekday));
        // console.log(`today=${today.format()} orderDate=${orderDate.format()} mondayAfterNextCutoff=${mondayAfterNextCutoff.format()}`);
        if (isSameOrAfter(orderDate, mondayAfterNextCutoff)) {
            return true;
        }
        return false;
    }
    else {
        const comingCutoff: Date = getNextCutoff(startofToday, weekday, hour);
        const diff = differenceInDays(orderDate, comingCutoff);

        if (orderDateWeekday === ISO_WEEK_DAY_FRIDAY) {
            if (diff > 1) {
                return true;
            }
        }
        else if (isAfter(orderDate, comingCutoff)) {
            return true;
        }
        return false;
    }
};