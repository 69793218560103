import firebase from 'firebase/compat/app';
import 'firebase/auth';

import { auth } from './base';
/// https://firebase.google.com/docs/auth/web/manage-users
/// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/
/// https://firebase.google.com/docs/auth/web/auth-state-persistence
export const doCreateUserWithEmailAndPassword = (email: string, password: string) => auth.createUserWithEmailAndPassword(email, password);
export const doSignInWithEmailAndPassword = (email: string, password: string) => auth.signInWithEmailAndPassword(email, password);
export const doSignOut = () => auth.signOut();
export const getLoggedInUser = (): firebase.User | null => auth.currentUser;

export const getLoggedInUserUid = (): string | undefined => {
    if (auth.currentUser) {
        return auth.currentUser.uid;
    }
    return undefined;
}


// Password Reset
export const doPasswordReset = (email: string) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password: string): Promise<any> => { 
    return new Promise<any>((resolve, reject) => {
        if (auth.currentUser !== undefined && auth.currentUser !== null) {
            auth.currentUser.updatePassword(password)
            .then ((result) => {
                resolve(result);
            })
            .catch ((error) => {
                reject(error)
            });
        }
        else {
            reject(new Error('No current signed in user'));
        }
    });
};

// auth.doCreateUserWithEmailAndPassword('p_lfc_morgan@hotmail.com', 'password123')
// .then ( (result) => {
//     // tslint:disable-next-line:no-console
//     console.log(`created user=${JSON.stringify(result)}`);
// })
// .catch ( (error) => {
//     // tslint:disable-next-line:no-console
//     console.log(`error createing user=${JSON.stringify(error)}`);
// });

// auth.doSignInWithEmailAndPassword('p_lfc_morgan@hotmail.com', 'password123')
// .then ( (result) => {
//     // tslint:disable-next-line:no-console
//     console.log(`signed in user=${JSON.stringify(result)}`);
// })
// .catch ( (error) => {
//     // tslint:disable-next-line:no-console
//     console.log(`error signing in user=${JSON.stringify(error)}`);
// });

  