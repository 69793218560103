import firebase from 'firebase/compat/app';
import {
    call,
    put,
    take,
    select,
    race,
    fork,
    PutEffect,
} from 'redux-saga/effects';

import {
    eventChannel
} from 'redux-saga';

import 'firebase/auth';

import {
    actions,
    TypeKeys
} from '../store/reportsBundle';

import {
    actions as app
} from '../store/appBundle';

import {
    IAppState,
} from '../../App/interfaces/IAppState';

import {
    getLocalState,
    getRunnerColors,
} from '../../App/store/appSelectors';

import { isAdmin } from '../../Auth/store/selectors';

import {
    getSessionJoggerDessertsForDate,
    getLunchJoggerMainsForDate,
    getTeaJoggerMainsForDate,
    getSessionJoggerSidesForDate
} from '../../App/store/getSessionNumbers';

import {
    IAlternativePortionsByRunnerByUid,
    IPortionsByRunnerByUid,
    IPortionsWithAlternatives
} from '../../App/interfaces/IKitchenMenuPortions';

import {
    getAltPortionsByRunnerByUid,
    getPortionsByRunnerByUid
} from '../../App/utils/joggerTable';

import { getDietaryStatsForDateRange } from '../../App/store/getDietaryStatsForDateRange';
import { IDietaryUsageStats } from '../../App/interfaces/IDietaryUsageStats';

import { getState } from '../../Store/store';
import { IState } from '../../Store/state';

import { colorsWithoutNone } from '../../App/utils/runnersFiltered';
import { IMenuItemUsageStats } from '../../App/interfaces/IMenuItemUsageStats';
import { getMenuItemStats } from '../../App/store/getMenuItemStats';

import {
    db,
} from '../../Storage';

import { 
    DELIVERY_REPORTS, 
    FOODCHECK_REPORTS, 
    DIARYCHECK_REPORTS, 
    PREREQUISITE_REPORTS
} from '../../App/interfaces/IReports';

function* processChannelAction(action: any):  Generator<PutEffect<any>, void, unknown> {
    yield put(action);
}

function* getPortionsAndAlternativesByUid(portions: IPortionsWithAlternatives[]) {
    const admin: boolean = yield select(isAdmin);
    const appState: IAppState = yield select(getLocalState);
    const runnerColors: ReadonlyArray<string> = colorsWithoutNone(getRunnerColors(appState));
    const alternativesByRunnerByUid: IAlternativePortionsByRunnerByUid[] = getAltPortionsByRunnerByUid(admin, runnerColors, portions);
    const portionsByRunnerByUid: IPortionsByRunnerByUid[] = getPortionsByRunnerByUid(admin, runnerColors, portions);
    return {
        alternativesByRunnerByUid,
        portionsByRunnerByUid
    }
}

export function* appCalcLunchDessertJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerDessertsForDate, `lunch`, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcLunchDessertJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function* appCalcTeaDessertJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_DESSERT_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerDessertsForDate, `tea`, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaDessertJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function* appCalcLunchSideJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_LUNCH_SIDE_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerSidesForDate, `lunch`, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcLunchSideJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function* appCalcTeaSideJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_SIDE_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerSidesForDate, `tea`, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaSideJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcMainJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_MAIN_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getLunchJoggerMainsForDate, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcMainJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcTeaJoggers() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_JOGGERS_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getTeaJoggerMainsForDate, payload.theStartDate, payload.theStartDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaJoggersRes(alternativesByRunnerByUid, portionsByRunnerByUid));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcLunchDessertJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_LUNCH_DESSERT_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerDessertsForDate, `lunch`, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcLunchDessertJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcTeaDessertJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_DESSERT_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerDessertsForDate, `tea`, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaDessertJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function* appCalcLunchSideJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_LUNCH_SIDE_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerSidesForDate, `lunch`, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcLunchSideJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcTeaSideJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_SIDE_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getSessionJoggerSidesForDate, `tea`, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaSideJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function* appCalcMainJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_MAIN_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getLunchJoggerMainsForDate, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcMainJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcTeaJoggersForRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_TEA_JOGGERS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const portions: IPortionsWithAlternatives[] = yield call(getTeaJoggerMainsForDate, payload.theStartDate, payload.theEndDate, payload.filterOnSelectedRunner, state);
            const {alternativesByRunnerByUid, portionsByRunnerByUid} = yield getPortionsAndAlternativesByUid(portions);
            yield put(actions.calcTeaJoggersForRangeRes(alternativesByRunnerByUid, portionsByRunnerByUid));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcDietaryStatsForDateRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_DIETARY_STATS_FOR_RANGE_REQ);
        try {
            const state: IState = yield select(getState);
            const stats: IDietaryUsageStats[] = yield call(getDietaryStatsForDateRange, payload.theStartDate, payload.theEndDate, state);
            yield put(actions.calcDietaryStatsForDateRangeRes(stats));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appCalcMenuItemStatsForDateRange() {
    while (true) {
        const { payload } = yield take(TypeKeys.CALC_MENU_ITEM_STATS_REQ);
        try {
            const { 
                itemType,
            } = payload

            const state: IState = yield select(getState);
            const stats: IMenuItemUsageStats[] = yield call(getMenuItemStats, itemType, state);
            yield put(actions.calcMenuItemStatsRes(itemType, stats));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}


export function deliveryReportsChannel(refId: string) {
    const ref = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${DELIVERY_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_DELIVERY_REPORTS_EVENTS_RES,
                        }
                    );
               }
            }
        });

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${DELIVERY_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_DELIVERY_REPORTS_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

export function foodcheckReportsChannel(refId: string) {
    const ref = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${FOODCHECK_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_FOODCHECK_REPORTS_EVENTS_RES,
                        }
                    );
               }
            }
        });

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${FOODCHECK_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_FOODCHECK_REPORTS_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

export function diariesReportsChannel(refId: string) {
    const ref = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${DIARYCHECK_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_DIARYCHECK_REPORTS_EVENTS_RES,
                        }
                    );
               }
            }
        });

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${DIARYCHECK_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_DIARYCHECK_REPORTS_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}

export function prerequisiteReportsChannel(refId: string) {
    const ref: firebase.database.Reference = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${PREREQUISITE_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_PREREQUISITE_REPORTS_EVENTS_RES,
                        }
                    );
               }
            }
        });

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                key: event.key,
                                storage: `${PREREQUISITE_REPORTS}/${event.key}`
                            },
                            type: TypeKeys.REG_PREREQUISITE_REPORTS_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
}


export function* appSignatureReportsSaga() {
    while (true) {

        yield take(TypeKeys.REG_SIGNATURE_REPORTS_EVENTS_REQ);

        const delivery = yield call(deliveryReportsChannel, DELIVERY_REPORTS);
        const foodcheck = yield call(foodcheckReportsChannel, FOODCHECK_REPORTS);
        const diarycheck = yield call(diariesReportsChannel, DIARYCHECK_REPORTS);
        const prerequisite = yield call(prerequisiteReportsChannel, PREREQUISITE_REPORTS);

        try {
            while (true) {
                const { cancel, actionDelivery, actionFoodcheck, actionDiarycheck, actionPrerequisites } = yield race({
                    actionDelivery: take(delivery),
                    actionFoodcheck: take(foodcheck),
                    actionDiarycheck: take(diarycheck),
                    actionPrerequisites: take(prerequisite),
                    cancel: take(TypeKeys.UREG_SIGNATURE_REPORTS_EVENTS_REQ)
                });
                if (cancel) {
                    delivery.close();
                    foodcheck.close();
                    diarycheck.close();
                    prerequisite.close();
                    break;
                }
                else if (actionDelivery) {
                    yield fork(processChannelAction, actionDelivery);
                }
                else if (actionFoodcheck) {
                    yield fork(processChannelAction, actionFoodcheck);
                }
                else if (actionDiarycheck) {
                    yield fork(processChannelAction, actionDiarycheck);
                }
                else if (actionPrerequisites) {
                    yield fork(processChannelAction, actionPrerequisites);
                }
            }
        }
        catch (error) {
            yield put(app.appErr(error))
        }
    }
}