import React from 'react';
import memoize from 'memoize-one';
import * as material from 'material-colors'

import { SwatchesPicker, ColorResult, RGBColor } from "react-color";

const names: string[] = [
    `red`,
    `pink`,
    `purple`,
    `deepPurple`,
    `indigo`,
    `blue`,
    `lightBlue`,
    `cyan`,
    `teal`,
    `green`,
    `lightGreen`,
    `lime`,
    `yellow`,
    `amber`,
    `orange`,
    `deepOrange`,
    `brown`,
    `blueGrey`,
];

const depth: string[] = [
    `900`,
    `800`,
    `700`,
    `600`,
    `500`,
    `400`,
    `300`,
    `200`,
    `100`,
    `50`,
];

const getColors = memoize((names: string[], depth: string[]): any[][] => {
    const result: any[] = [];
    for (const c of names) {
        const name: any[] = [];
        for (const d of depth) {
            name.push(material[c][d]);
        }
        result.push(name);
    }
    return result;
});

interface IPopupColorSwatchProps {
    show: boolean;
    color: RGBColor | undefined;
    setShow: (show: boolean) => void;
    colorChangeComplete: (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const swatch: React.CSSProperties = {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
};

const cover: React.CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
};

const popover: React.CSSProperties = {
    position: 'absolute',
    zIndex: 2,
};

const container: React.CSSProperties = {
    marginTop: '10px',
};

const getColorStyle = (color: RGBColor | undefined): React.CSSProperties => {
    const background: string = color ? `rgba(${color.r}, ${color.g}, ${color.b})` : `initial`;
    const style: React.CSSProperties = {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background,
    };
    return style;
}

export const rgbStringToRgb = (rgb: string): RGBColor => {
    // Choose correct separator
    const sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    const rgbParts = rgb.substr(5).split(")")[0].split(sep);

    if (rgbParts.length < 4) {
        return {
            r: 255, g: 255, b: 255, a: 1 
        }
    }

    const result: RGBColor = {
        r: parseInt(rgbParts[0], 10),
        g: parseInt(rgbParts[1], 10),
        b: parseInt(rgbParts[2], 10),
        a: parseInt(rgbParts[3], 10),
    }
   
    return result;
};

export const rgbToRgbString = (rgb: RGBColor | undefined): string | undefined => {
    if (rgb === undefined) {
        return undefined;
    }
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
};

export const PopupColorSwatch: React.FC<IPopupColorSwatchProps> = ({ show, color, setShow, colorChangeComplete }): React.ReactElement => {
    const close = (): void => {
        setShow(false);
    };

    const toggle = (): void => {
        setShow(!show);
    };

    return (
        <div style={container}>
            <div style={swatch} onClick={toggle}>
                <div style={getColorStyle(color)} />
            </div>
            {show ? <div style={popover}>
                <div style={cover} onClick={close} />
                <SwatchesPicker colors={getColors(names, depth)} color={color} onChangeComplete={colorChangeComplete} />
            </div> : null}
        </div>
    );
};