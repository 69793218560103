import { Dispatch } from 'redux';

import { actions as app } from '../../App/store';
import { actions as auth } from '../../Auth/store';
import { actions as checks } from '../../App/store/checksBundle';
import { actions as menu } from '../../App/store/menuBundle';
import { actions as view } from '../../App/store/viewBundle';
import { actions as reports } from '../../App/store/reportsBundle';
import { actions as pdfsLinks } from '../../App/store/pdfsLinksBundle';
import { loadAllRunners } from '../../App/sagas/appSaga';
import { subWeeks } from '../../Common/utils/dateFunctions';

export const unload = (dispatch: Dispatch<any>): void => {
    console.log(`admin.unload`);
    
    dispatch(app.uregAllOrderLogsReq());
    dispatch(app.uregAllOrdersReq());

    dispatch(app.uregAllUsersUpdateReq());
    dispatch(app.uregConfirmationEventsReq());
    dispatch(app.uregSignatureEventsReq());
    dispatch(reports.uregSignatureReportsEventsReq());

    dispatch(app.resetStateReq());
    dispatch(menu.resetStateReq());
    dispatch(view.resetStateReq());
    dispatch(auth.signoutReq());
    dispatch(auth.uregConnectionState());
    dispatch(checks.uregPrerequisiteEventsReq());
};

export const load = (dispatch: Dispatch<any>): void => {
    console.log(`admin.load`);

    dispatch(pdfsLinks.loadAllPdfUrlsReq());
    dispatch(reports.regSignatureReportsEventsReq());

    const filterDateOrders: number = subWeeks(new Date(), 4).getTime();
    dispatch(app.regAllOrdersReq(filterDateOrders));
    
    dispatch(app.appConfigReq());
    dispatch(app.featuresReq());
    dispatch(app.userNotesReq());
    dispatch(app.appConfirmationReq());
    dispatch(app.allUsersReq());

    dispatch(app.regAllUsersUpdateReq());
    dispatch(app.regConfirmationEventsReq());
    dispatch(app.regSignatureEventsReq());

    loadAllRunners()
        .then((runners) => {
            const getFirstRunner = runners.find((r) => r.order === 1);
            const runColor = getFirstRunner ? getFirstRunner.color : `gold`;
            dispatch(app.runnersRes(runners));
            dispatch(app.selectRunner(runColor));
        })
        .catch((error) => {
            dispatch(app.selectRunner(`gold`));
            console.error(`Failed to load runners, no food check records. ${error}`);
        });
    dispatch(menu.menusReq());
    dispatch(menu.portionRuleDataReq());
    dispatch(menu.alternativesReq());
    dispatch(menu.dietaryCodesReq());
    dispatch(menu.alternativeMealMapReq());

    dispatch(checks.loadCheckDescribersReq());
    dispatch(checks.loadCheckCustomControlsReq());
    dispatch(checks.regPrerequisiteEventsReq());
};