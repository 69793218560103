import {
    defaultDayOrder,
    IDayOrder,
    IOrder,
    IOrderPayload
} from '../../App/interfaces/IDayOrder';

import { ISelectOrdersForUidAndDate } from '../../App/interfaces/ISelectOrdersForUidAndDate';


import { MealSession } from '../../App/interfaces/MealSession';
import { IUser, IUsersMap } from '../../Auth/interfaces/IUser';

import {
    IRunner,
    IRunners,
    IUidToRunnerColor
} from '../../App/interfaces/IRunners';

import { appInitialState } from '../../App/store/appInitialState';
import { IAppConfig } from '../../App/interfaces/IAppConfig';

import {
    IAppState,
    IMealOrder,
    ISaveOrder,
    ISentConfirmationDates,
    ISpecialMealOrder,
} from '../../App/interfaces/IAppState';

import { IFeatureMap } from '../interfaces/IFeature';

import {
    getOrderForUserAndStartDate,
} from './appSelectors';

import { IUserNotesMap } from '../interfaces/IUserNotes';

import {
    ISignatureInfo,
    SignatureEventType,
    SignatureEventName
} from '../../Common/interfaces/ISignatureInfo';
import { mergeToUniqueArray } from '../../Common/utils/arrayUtils';
import { getTotalCount } from './utils';
import { IOrderActivityLog, IOrderActivityLogMap, IOrderActivityLogPayload } from '../interfaces/IOrderActivityLog';

export enum TypeKeys {
    APP_ERR = 'APP/ERR',
    RESET_STATE_REQ = 'APP/RESET_STATE_REQ',
    
    APP_CONFIG_REQ = 'APP/APP_CONFIG_REQ',
    APP_CONFIG_RES = 'APP/APP_CONFIG_RES',

    LOAD_CONFIRMATION_REQ = 'APP/LOAD_CONFIRMATION_REQ',
    LOAD_CONFIRMATION_RES = 'APP/LOAD_CONFIRMATION_RES',

    REG_CONFIRMATION_EVENTS_REQ = 'APP/REG_CONFIRMATION_EVENTS_REQ',
    REG_CONFIRMATION_EVENTS_RES = 'APP/REG_CONFIRMATION_EVENTS_RES',
    UREG_CONFIRMATION_EVENTS_REQ = 'APP/UREG_CONFIRMATION_EVENTS_REQ',

    FEATURES_REQ = 'APP/FEATURES_REQ',
    FEATURES_RES = 'APP/FEATURES_RES',

    USER_NOTES_REQ = 'APP/USER_NOTES_REQ',
    USER_NOTES_RES = 'APP/USER_NOTES_RES',

    CLEAR_CURRENT_EDIT_MEALS = 'APP/CLEAR_CURRENT_EDIT_MEALS',
    CLEAR_CURRENT_DESSERT_EDIT_MEALS = 'APP/CLEAR_CURRENT_DESSERT_EDIT_MEALS',

    SET_NORMAL_MEALS = 'APP/SET_NORMAL_MEALS',
    SET_NORMAL_DESSERT_MEALS = 'APP/SET_NORMAL_DESSERT_MEALS',
    SET_SPECIAL_MEALS = 'APP/SET_SPECIAL_MEALS',
    SET_SPECIAL_DESSERT_MEALS = 'APP/SET_SPECIAL_DESSERT_MEALS',
    SET_NOTE_FOR_MEALS = 'APP/SET_NOTE_FOR_MEALS',
    SET_AMMEND_REASON = 'APP/SET_AMMEND_REASON',

    SAVE_ORDER_FOR_DAY_REQ = 'APP/SAVE_ORDER_FOR_DAY_REQ',
    SAVE_AMMEND_ORDER_FOR_DAY_REQ = 'APP/SAVE_AMMEND_ORDER_FOR_DAY_REQ',
    UPDATE_CURRENT_ORDERS_AND_TIMESTAMP = 'APP/UPDATE_CURRENT_ORDERS_AND_TIMESTAMP',

    LOAD_ALL_USERS_REQ = 'APP/LOAD_ALL_USERS_REQ',
    LOAD_ALL_USERS_RES = 'APP/LOAD_ALL_USERS_RES',

    SET_LOGGED_IN_USER = 'APP/SET_LOGGED_IN_USER',
    LOAD_USER_REQ = 'APP/LOAD_USER_REQ',
    LOAD_USER_RES = 'APP/LOAD_USER_RES',

    SELECT_RUNNER = 'APP/SELECT_RUNNER',
    SELECT_RUNNER_DATES = 'APP/SELECT_RUNNER_DATES',

    SELECT_USER_UID = 'APP/SELECT_USER_UID',

    SELECT_ORDERS_FOR_UID_AND_DATE = 'APP/SELECT_ORDERS_FOR_UID_AND_DATE',

    REG_ALL_USERS_UPDATE_REQ = 'APP/REG_ALL_USERS_UPDATE_REQ',
    REG_ALL_USERS_UPDATE_RES = 'APP/REG_ALL_USERS_UPDATE_RES',
    UREG_ALL_USERS_UPDATE_REQ = 'APP/UREG_ALL_USERS_UPDATE_REQ',

    REG_SIGNATURE_EVENTS_REQ = 'APP/REG_SIGNATURE_EVENTS_REQ',
    REG_SIGNATURE_EVENTS_RES = 'APP/REG_SIGNATURE_EVENTS_RES',
    UREG_SIGNATURE_EVENTS_REQ = 'APP/UREG_SIGNATURE_EVENTS_REQ',

    TOGGLE_USER_ORDER_UPDATE_EVENT = 'APP/TOGGLE_USER_ORDER_UPDATE_EVENT',
    TOGGLE_USER_ORDER_SAVED_EVENT = 'APP/TOGGLE_USER_ORDER_SAVED_EVENT',

    LOAD_ALL_RUNNERS_REQ = 'APP/LOAD_ALL_RUNNERS_REQ',
    LOAD_ALL_RUNNERS_RES = 'APP/LOAD_ALL_RUNNERS_RES',

    COPY_WEEK_ORDERS_REQ = 'APP/COPY_WEEK_ORDERS_REQ',
    COPY_WEEK_ORDERS_RES = 'APP/COPY_WEEK_ORDERS_RES',

    REG_ALL_ORDERS_REQ = 'APP/REG_ALL_ORDERS_REQ',
    REG_ALL_ORDERS_RES = 'APP/REG_ALL_ORDERS_RES',
    UREG_ALL_ORDERS_REQ = 'APP/UREG_ALL_ORDERS_REQ',

    REG_USER_ORDERS_REQ = 'APP/REG_USER_ORDERS_REQ',
    UREG_USER_ORDERS_REQ = 'APP/UREG_USER_ORDERS_REQ',

    REG_ALL_ORDER_LOGS_REQ = 'APP/REG_ALL_ORDER_LOGS_REQ',
    REG_ALL_ORDER_LOGS_RES = 'APP/REG_ALL_ORDER_LOGS_RES',
    REG_ALL_ORDER_LOGS_SNAPSHOT_RES = 'APP/REG_ALL_ORDER_LOGS_SNAPSHOT_RES',
    UREG_ALL_ORDER_LOGS_REQ = 'APP/UREG_ALL_ORDER_LOGS_REQ',

    REG_USER_ORDER_LOGS_REQ = 'APP/REG_USER_ORDER_LOGS_REQ',
    UREG_USER_ORDER_LOGS_REQ = 'APP/UREG_USER_ORDER_LOGS_REQ',

    ORDER_LOGS_SUBSCRIBED = 'APP/ORDER_LOGS_SUBSCRIBED',
};

export interface IOrderLogsSubscribedReq {
    type: TypeKeys.ORDER_LOGS_SUBSCRIBED;
}

export interface IUnRegUserOrderLogsReq {
    type: TypeKeys.UREG_USER_ORDER_LOGS_REQ;
}
export interface IRegUserOrderLogsReq {
    type: TypeKeys.REG_USER_ORDER_LOGS_REQ;
    uids: string[];
    fromDate: number;
}

export interface IUnRegAllOrderLogsReq {
    type: TypeKeys.UREG_ALL_ORDER_LOGS_REQ;
}
export interface IRegAllOrderLogsReq {
    type: TypeKeys.REG_ALL_ORDER_LOGS_REQ;
    fromDate: number;
}
export interface IRegAllOrderLogsRes {
    type: TypeKeys.REG_ALL_ORDER_LOGS_RES;
    payload: IOrderActivityLogPayload;
}

export interface IRegAllOrderLogsSnapshotRes {
    type: TypeKeys.REG_ALL_ORDER_LOGS_SNAPSHOT_RES;
    payload: IOrderActivityLog[];
}


export interface IUnRegUserOrdersReq {
    type: TypeKeys.UREG_USER_ORDERS_REQ;
}
export interface IRegUserOrdersReq {
    type: TypeKeys.REG_USER_ORDERS_REQ;
    uids: string[];
    fromDate: number;
}

export interface IUnRegAllOrdersReq {
    type: TypeKeys.UREG_ALL_ORDERS_REQ;
}
export interface IRegAllOrdersReq {
    type: TypeKeys.REG_ALL_ORDERS_REQ;
    fromDate: number;
}
export interface IRegAllOrdersRes {
    type: TypeKeys.REG_ALL_ORDERS_RES;
    payload: IOrderPayload;
}

export interface IConfirmationsReq {
    type: TypeKeys.LOAD_CONFIRMATION_REQ;
}

export interface IConfirmationsRes {
    type: TypeKeys.LOAD_CONFIRMATION_RES;
    payload: ISentConfirmationDates;
}

export interface IUnRegConfirmationEventsReq {
    type: TypeKeys.UREG_CONFIRMATION_EVENTS_REQ;
}

export interface IRegConfirmationEventsReq {
    type: TypeKeys.REG_CONFIRMATION_EVENTS_REQ;
}

export interface IRegConfirmationEventsRes {
    type: TypeKeys.REG_CONFIRMATION_EVENTS_RES;
    payload: ISentConfirmationDates;
}

export interface IUnRegAllUsersUpdateReq {
    type: TypeKeys.UREG_ALL_USERS_UPDATE_REQ;
}

export interface IRegAllUsersUpdateReq {
    type: TypeKeys.REG_ALL_USERS_UPDATE_REQ;
}

export interface IRegAllUsersUpdateRes {
    type: TypeKeys.REG_ALL_USERS_UPDATE_RES;
    payload: {
        user: IUser,
        uid: string
    };
}

export interface ICopyWeeksOrdersReq {
    type: TypeKeys.COPY_WEEK_ORDERS_REQ;
    payload: {
        fromDate: Date;
        toDate: Date;
        days: number;
    }
}

export interface ICopyWeeksOrdersRes {
    type: TypeKeys.COPY_WEEK_ORDERS_RES;
}

export interface IAppResetStateReq {
    type: TypeKeys.RESET_STATE_REQ;
}

export interface IAppConfigReq {
    type: TypeKeys.APP_CONFIG_REQ;
}

export interface IAppConfigRes {
    type: TypeKeys.APP_CONFIG_RES;
    payload: IAppConfig;
}

export interface IRunnersReq {
    type: TypeKeys.LOAD_ALL_RUNNERS_REQ;
}

export interface IRunnersRes {
    type: TypeKeys.LOAD_ALL_RUNNERS_RES;
    payload: ReadonlyArray<IRunner>;
}

export interface IToggleUserOrderSavedEvent {
    type: TypeKeys.TOGGLE_USER_ORDER_SAVED_EVENT;
    payload: boolean;
}

export interface IToggleUserOrderUpdateEvent {
    type: TypeKeys.TOGGLE_USER_ORDER_UPDATE_EVENT;
    payload: boolean;
}

export interface IUnRegSignatureEventsReq {
    type: TypeKeys.UREG_SIGNATURE_EVENTS_REQ;
}

export interface IRegSignatureEventsReq {
    type: TypeKeys.REG_SIGNATURE_EVENTS_REQ;
}


export interface IRegSignatureEventsRes {
    type: TypeKeys.REG_SIGNATURE_EVENTS_RES;
    payload: {
        key: string;
        eventName: SignatureEventName;
        eventType: SignatureEventType;
        signatures: ISignatureInfo[];
    };
}

export interface ISelectUserUid {
    type: TypeKeys.SELECT_USER_UID;
    payload: string;
}
export interface ISelectRunner {
    type: TypeKeys.SELECT_RUNNER;
    payload: string;
}

export interface ISelectRunnerDates {
    type: TypeKeys.SELECT_RUNNER_DATES;
    payload: {
        start: Date | null;
        end: Date | null;
    };
}

export interface ISelectOrderForUidAndDate {
    type: TypeKeys.SELECT_ORDERS_FOR_UID_AND_DATE;
    payload: ISelectOrdersForUidAndDate;
}

export interface ILoadUserReq {
    type: TypeKeys.LOAD_USER_REQ;
}

export interface ISetLoggedInUser {
    type: TypeKeys.SET_LOGGED_IN_USER;
    payload: Readonly<IUser>;
}

export interface ILoadUserRes {
    type: TypeKeys.LOAD_USER_RES;
    payload: Readonly<IUser>;
}

export interface IAllUsersReq {
    type: TypeKeys.LOAD_ALL_USERS_REQ;
}

export interface IAllUsersRes {
    type: TypeKeys.LOAD_ALL_USERS_RES;
    payload: ReadonlyArray<IUser>;
}

export interface IAmmendOrderForDayReq {
    type: TypeKeys.SAVE_AMMEND_ORDER_FOR_DAY_REQ;
    payload: ISaveOrder;
}

export interface ISaveOrderForDayReq {
    type: TypeKeys.SAVE_ORDER_FOR_DAY_REQ;
    payload: ISaveOrder;
}

export interface IUpdateCurrentOrdersAndTimeStamp {
    type: TypeKeys.UPDATE_CURRENT_ORDERS_AND_TIMESTAMP;
    payload: {
        uid: string;
        startDate: Date;
        dayOrderLunch: Readonly<IDayOrder> | undefined;
        dayOrderTea: Readonly<IDayOrder> | undefined
    }
}

export interface ISetAmmendOrderReason {
    type: TypeKeys.SET_AMMEND_REASON;
    payload: string | undefined;
}

export interface IClearCurrentEditMeals {
    type: TypeKeys.CLEAR_CURRENT_EDIT_MEALS;
}

export interface IClearCurrentDessertEditMeals {
    type: TypeKeys.CLEAR_CURRENT_DESSERT_EDIT_MEALS;
}

export interface ISetNormalMeals {
    type: TypeKeys.SET_NORMAL_MEALS;
    payload: IMealOrder;
}

export interface ISetNormalDessertMeals {
    type: TypeKeys.SET_NORMAL_DESSERT_MEALS;
    payload: IMealOrder;
}


export interface ISetSpecialDessertMeals {
    type: TypeKeys.SET_SPECIAL_DESSERT_MEALS;
    payload: ISpecialMealOrder;
}

export interface ISetSpecialMeals {
    type: TypeKeys.SET_SPECIAL_MEALS;
    payload: ISpecialMealOrder;
}

export interface ISetNoteForMeals {
    type: TypeKeys.SET_NOTE_FOR_MEALS;
    payload: {
        session: MealSession,
        note: string;
    };
}

export interface IAppErr {
    type: TypeKeys.APP_ERR;
    error: Error | undefined;
}

export interface IFeaturesReq {
    type: TypeKeys.FEATURES_REQ;
}

export interface IFeaturesRes {
    type: TypeKeys.FEATURES_RES;
    payload: Readonly<IFeatureMap>;
}

export interface IUserNotesReq {
    type: TypeKeys.USER_NOTES_REQ;
}

export interface IUserNotesRes {
    type: TypeKeys.USER_NOTES_RES;
    payload: Readonly<IUserNotesMap>;
}

export const actions = {

    orderLogsSubscribedReq(): IOrderLogsSubscribedReq {
        const action: IOrderLogsSubscribedReq = { type: TypeKeys.ORDER_LOGS_SUBSCRIBED };
        return action;
    },

    regUserOrderLogsReq(fromDate: number, uids: string[]): IRegUserOrderLogsReq {
        const action: IRegUserOrderLogsReq = { type: TypeKeys.REG_USER_ORDER_LOGS_REQ, uids, fromDate };
        return action;
    },
    uregUserOrderLogsReq(): IUnRegUserOrderLogsReq {
        const action: IUnRegUserOrderLogsReq = { type: TypeKeys.UREG_USER_ORDER_LOGS_REQ };
        return action;
    },

    regAllOrderLogsReq(fromDate: number): IRegAllOrderLogsReq {
        const action: IRegAllOrderLogsReq = { type: TypeKeys.REG_ALL_ORDER_LOGS_REQ, fromDate };
        return action;
    },
    regAllOrderLogsRes(payload: IOrderActivityLogPayload): IRegAllOrderLogsRes {
        const action: IRegAllOrderLogsRes = { type: TypeKeys.REG_ALL_ORDER_LOGS_RES, payload };
        return action;
    },
    regAllOrderLogsSnapshotRes(payload: IOrderActivityLog[]): IRegAllOrderLogsSnapshotRes {
        const action: IRegAllOrderLogsSnapshotRes = { type: TypeKeys.REG_ALL_ORDER_LOGS_SNAPSHOT_RES, payload };
        return action;
    },
    uregAllOrderLogsReq(): IUnRegAllOrderLogsReq {
        const action: IUnRegAllOrderLogsReq = { type: TypeKeys.UREG_ALL_ORDER_LOGS_REQ };
        return action;
    },

    regAllOrdersReq(fromDate: number): IRegAllOrdersReq {
        const action: IRegAllOrdersReq = { type: TypeKeys.REG_ALL_ORDERS_REQ, fromDate };
        return action;
    },
    regAllOrdersRes(payload: IOrderPayload): IRegAllOrdersRes {
        const action: IRegAllOrdersRes = { type: TypeKeys.REG_ALL_ORDERS_RES, payload };
        return action;
    },
    uregAllOrdersReq(): IUnRegAllOrdersReq {
        const action: IUnRegAllOrdersReq = { type: TypeKeys.UREG_ALL_ORDERS_REQ };
        return action;
    },

    regUserOrdersReq(fromDate: number, uids: string[]): IRegUserOrdersReq {
        const action: IRegUserOrdersReq = { type: TypeKeys.REG_USER_ORDERS_REQ, uids, fromDate };
        return action;
    },
    uregUserOrdersReq(): IUnRegUserOrdersReq {
        const action: IUnRegUserOrdersReq = { type: TypeKeys.UREG_USER_ORDERS_REQ };
        return action;
    },

    appErr(error: Error | undefined): IAppErr {
        const action: IAppErr = { type: TypeKeys.APP_ERR, error };
        return action;
    },

    resetStateReq(): IAppResetStateReq {
        const action: IAppResetStateReq = { type: TypeKeys.RESET_STATE_REQ };
        return action;
    },

    appConfigReq(): IAppConfigReq {
        const action: IAppConfigReq = { type: TypeKeys.APP_CONFIG_REQ };
        return action;
    },

    appConfigRes(payload: IAppConfig): IAppConfigRes {
        const action: IAppConfigRes = { type: TypeKeys.APP_CONFIG_RES, payload };
        return action;
    },

    appConfirmationReq(): IConfirmationsReq {
        const action: IConfirmationsReq = { type: TypeKeys.LOAD_CONFIRMATION_REQ };
        return action;
    },

    appConfirmationRes(payload: ISentConfirmationDates): IConfirmationsRes {
        const action: IConfirmationsRes = { type: TypeKeys.LOAD_CONFIRMATION_RES, payload };
        return action;
    },

    uregConfirmationEventsReq(): IUnRegConfirmationEventsReq {
        const action: IUnRegConfirmationEventsReq = { type: TypeKeys.UREG_CONFIRMATION_EVENTS_REQ };
        return action;
    },

    regConfirmationEventsReq(): IRegConfirmationEventsReq {
        const action: IRegConfirmationEventsReq = { type: TypeKeys.REG_CONFIRMATION_EVENTS_REQ };
        return action;
    },

    regConfirmationEventsRes(payload: ISentConfirmationDates): IRegConfirmationEventsRes {
        const action: IRegConfirmationEventsRes = { type: TypeKeys.REG_CONFIRMATION_EVENTS_RES, payload };
        return action;
    },

    uregSignatureEventsReq(): IUnRegSignatureEventsReq {
        const action: IUnRegSignatureEventsReq = { type: TypeKeys.UREG_SIGNATURE_EVENTS_REQ };
        return action;
    },

    regSignatureEventsReq(): IRegSignatureEventsReq {
        const action: IRegSignatureEventsReq = { type: TypeKeys.REG_SIGNATURE_EVENTS_REQ };
        return action;
    },

    regSignatureEventsRes(payload: {
        key: string;
        eventName: SignatureEventName;
        eventType: SignatureEventType;
        signatures: ISignatureInfo[];
    }): IRegSignatureEventsRes {
        const action: IRegSignatureEventsRes = { type: TypeKeys.REG_SIGNATURE_EVENTS_RES, payload };
        return action;
    },

    uregAllUsersUpdateReq(): IUnRegAllUsersUpdateReq {
        const action: IUnRegAllUsersUpdateReq = { type: TypeKeys.UREG_ALL_USERS_UPDATE_REQ };
        return action;
    },

    regAllUsersUpdateReq(): IRegAllUsersUpdateReq {
        const action: IRegAllUsersUpdateReq = { type: TypeKeys.REG_ALL_USERS_UPDATE_REQ };
        return action;
    },

    regAllUsersUpdateRes(payload: {
        user: IUser,
        uid: string
    }): IRegAllUsersUpdateRes {
        const action: IRegAllUsersUpdateRes = { type: TypeKeys.REG_ALL_USERS_UPDATE_RES, payload };
        return action;
    },

    copyWeeksOrdersReq(payload:
        {
            fromDate: Date;
            toDate: Date;
            days: number;
        }
    ): ICopyWeeksOrdersReq {
        const action: ICopyWeeksOrdersReq = { type: TypeKeys.COPY_WEEK_ORDERS_REQ, payload };
        return action;
    },

    copyWeeksOrdersRes(): ICopyWeeksOrdersRes {
        const action: ICopyWeeksOrdersRes = { type: TypeKeys.COPY_WEEK_ORDERS_RES };
        return action;
    },

    selectUserUid(payload: string): ISelectUserUid {
        const action: ISelectUserUid = { type: TypeKeys.SELECT_USER_UID, payload };
        return action;
    },

    selectRunner(payload: string): ISelectRunner {
        const action: ISelectRunner = { type: TypeKeys.SELECT_RUNNER, payload };
        return action;
    },

    selectRunnerDates(payload: {
        start: Date | null;
        end: Date | null;
    }): ISelectRunnerDates {
        const action: ISelectRunnerDates = { type: TypeKeys.SELECT_RUNNER_DATES, payload };
        return action;
    },

    selectDayOrders(payload: ISelectOrdersForUidAndDate): ISelectOrderForUidAndDate {
        const action: ISelectOrderForUidAndDate = { type: TypeKeys.SELECT_ORDERS_FOR_UID_AND_DATE, payload };
        return action;
    },

    featuresReq(): IFeaturesReq {
        const action: IFeaturesReq = { type: TypeKeys.FEATURES_REQ };
        return action;
    },

    featuresRes(payload: Readonly<IFeatureMap>): IFeaturesRes {
        const action: IFeaturesRes = { type: TypeKeys.FEATURES_RES, payload };
        return action;
    },

    userNotesReq(): IUserNotesReq {
        const action: IUserNotesReq = { type: TypeKeys.USER_NOTES_REQ };
        return action;
    },

    userNotesRes(payload: Readonly<IUserNotesMap>): IUserNotesRes {
        const action: IUserNotesRes = { type: TypeKeys.USER_NOTES_RES, payload };
        return action;
    },

    setAmmendOrderReason(payload: string | undefined): ISetAmmendOrderReason {
        const action: ISetAmmendOrderReason = { type: TypeKeys.SET_AMMEND_REASON, payload };
        return action;
    },

    clearCurrentEditMeals(): IClearCurrentEditMeals {
        const action: IClearCurrentEditMeals = { type: TypeKeys.CLEAR_CURRENT_EDIT_MEALS };
        return action;
    },

    clearCurrentDessertEditMeals(): IClearCurrentDessertEditMeals {
        const action: IClearCurrentDessertEditMeals = { type: TypeKeys.CLEAR_CURRENT_DESSERT_EDIT_MEALS };
        return action;
    },

    setNormalMealOrder(payload: Readonly<IMealOrder>): ISetNormalMeals {
        const action: ISetNormalMeals = { type: TypeKeys.SET_NORMAL_MEALS, payload };
        return action;
    },

    setNormalDessertMealOrder(payload: Readonly<IMealOrder>): ISetNormalDessertMeals {
        const action: ISetNormalDessertMeals = { type: TypeKeys.SET_NORMAL_DESSERT_MEALS, payload };
        return action;
    },

    setSpecialMealOrder(payload: Readonly<ISpecialMealOrder>): ISetSpecialMeals {
        const action: ISetSpecialMeals = { type: TypeKeys.SET_SPECIAL_MEALS, payload };
        return action;
    },

    setSpecialDessertMealOrder(payload: Readonly<ISpecialMealOrder>): ISetSpecialDessertMeals {
        const action: ISetSpecialDessertMeals = { type: TypeKeys.SET_SPECIAL_DESSERT_MEALS, payload };
        return action;
    },

    setNoteForMealOrder(payload: { session: MealSession, note: string }): ISetNoteForMeals {
        if (payload.note === undefined || payload.note === null) {
            payload.note = '';
        }
        const action: ISetNoteForMeals = { type: TypeKeys.SET_NOTE_FOR_MEALS, payload };
        return action;
    },

    ammendOrderForDayReq(payload: Readonly<ISaveOrder>): IAmmendOrderForDayReq {
        const action: IAmmendOrderForDayReq = { type: TypeKeys.SAVE_AMMEND_ORDER_FOR_DAY_REQ, payload };
        return action;
    },

    saveOrderForDayReq(payload: Readonly<ISaveOrder>): ISaveOrderForDayReq {
        const action: ISaveOrderForDayReq = { type: TypeKeys.SAVE_ORDER_FOR_DAY_REQ, payload };
        return action;
    },

    updateCurrentDayOrdersAndTimeStamp(payload: {
        uid: string;
        startDate: Date;
        dayOrderLunch: Readonly<IDayOrder> | undefined;
        dayOrderTea: Readonly<IDayOrder> | undefined
    }): IUpdateCurrentOrdersAndTimeStamp {
        const action: IUpdateCurrentOrdersAndTimeStamp = { type: TypeKeys.UPDATE_CURRENT_ORDERS_AND_TIMESTAMP, payload };
        return action;
    },

    allUsersReq(): IAllUsersReq {
        const action: IAllUsersReq = { type: TypeKeys.LOAD_ALL_USERS_REQ };
        return action;
    },

    allUsersRes(payload: ReadonlyArray<IUser>): IAllUsersRes {
        const action: IAllUsersRes = { type: TypeKeys.LOAD_ALL_USERS_RES, payload };
        return action;
    },

    loadUserReq(): ILoadUserReq {
        const action: ILoadUserReq = { type: TypeKeys.LOAD_USER_REQ };
        return action;
    },

    loadUserRes(payload: Readonly<IUser>): ILoadUserRes {
        const action: ILoadUserRes = { type: TypeKeys.LOAD_USER_RES, payload };
        return action;
    },

    setLoggedInUser(payload: Readonly<IUser>): ISetLoggedInUser {
        const action: ISetLoggedInUser = { type: TypeKeys.SET_LOGGED_IN_USER, payload };
        return action;
    },

    toggleUserOrderUpdateEvent(payload: boolean): IToggleUserOrderUpdateEvent {
        const action: IToggleUserOrderUpdateEvent = { type: TypeKeys.TOGGLE_USER_ORDER_UPDATE_EVENT, payload };
        return action;
    },

    toggleUserOrderSavedEvent(payload: boolean): IToggleUserOrderSavedEvent {
        const action: IToggleUserOrderSavedEvent = { type: TypeKeys.TOGGLE_USER_ORDER_SAVED_EVENT, payload };
        return action;
    },

    runnersReq(): IRunnersReq {
        const action: IRunnersReq = { type: TypeKeys.LOAD_ALL_RUNNERS_REQ };
        return action;
    },

    runnersRes(payload: ReadonlyArray<IRunner>): IRunnersRes {
        const action: IRunnersRes = { type: TypeKeys.LOAD_ALL_RUNNERS_RES, payload };
        return action;
    },
};

type TActionTypes =
    IOrderLogsSubscribedReq |
    IRegAllOrderLogsRes |
    IRegAllOrderLogsSnapshotRes |
    IRegAllOrdersRes |

    IAppErr |
    IAppResetStateReq |
    IAppConfigReq |
    IAppConfigRes |
    IConfirmationsReq |
    IConfirmationsRes |

    IUnRegConfirmationEventsReq |
    IRegConfirmationEventsReq |
    IRegConfirmationEventsRes |

    IFeaturesReq |
    IFeaturesRes |
    IUserNotesReq |
    IUserNotesRes |

    ISetAmmendOrderReason |
    IClearCurrentEditMeals |
    IClearCurrentDessertEditMeals |
    ISetNormalMeals |
    ISetNormalDessertMeals |
    ISetSpecialMeals |
    ISetSpecialDessertMeals |
    ISetNoteForMeals |
    IAmmendOrderForDayReq |
    ISaveOrderForDayReq |
    IUpdateCurrentOrdersAndTimeStamp |
    ISelectOrderForUidAndDate |
    ISelectUserUid |
    ISelectRunner |
    ISelectRunnerDates |
    IAllUsersReq |
    IAllUsersRes |
    ILoadUserReq |
    ILoadUserRes |
    ISetLoggedInUser |
    IToggleUserOrderUpdateEvent |
    IToggleUserOrderSavedEvent |
    IRunnersReq |
    IRunnersRes |
    ICopyWeeksOrdersReq |
    ICopyWeeksOrdersRes |
    IUnRegAllUsersUpdateReq |
    IRegAllUsersUpdateReq |
    IRegAllUsersUpdateRes |
    IRegSignatureEventsRes
    ;


const updateSelectedDayOrder = (state: IAppState, update: IOrder, uid: string): IAppState => {
    if (state.selectedDayOrders !== undefined) {
        if (state.selectedDayOrders.startDate === update.orderDate && state.selectedDayOrders.uid === uid) {
            const result: IAppState = {
                ...state,
                dayOrdersLunch: update.lunch,
                dayOrdersTea: update.tea,
                originalDayOrdersLunch: update.lunch,
                originalDayOrdersTea: update.tea,
                error: undefined,
            };
            return result;
        }
    }
    return state;
};

const handleOrderEvent = (state: IAppState, payload: IOrderPayload) => {
    if (payload.type === 'removed') {
        const orders = { ...state.allOrders };
        if (orders[payload.order.id] !== undefined) {
            delete orders[payload.order.id];
            const result: IAppState = {
                ...state,
                allOrders: orders,
            };
            return result;
        }
        return state;
    }

    const result: IAppState = {
        ...state,
        allOrders: {
            ...state.allOrders,
            [payload.order.id]: payload.order,
        }
    };
    return updateSelectedDayOrder(result, payload.order, payload.order.uid);
};

const handleSelectedUidEvent = (state: IAppState, uid: string) => {
    let dayOrder: IOrder | undefined;

    if (state.selectedDayOrders && state.selectedDayOrders.uid === uid) {
        dayOrder = getOrderForUserAndStartDate(state.selectedDayOrders.uid, state.selectedDayOrders.startDate, state);
    }

    const order = dayOrder !== undefined ? {
        dayOrdersLunch: dayOrder.lunch,
        dayOrdersTea: dayOrder.tea
    }
        : {
            dayOrdersLunch: { ...defaultDayOrder },
            dayOrdersTea: { ...defaultDayOrder }
        }

    const result: IAppState = {
        ...state,
        dayOrdersLunch: { ...order.dayOrdersLunch },
        dayOrdersTea: { ...order.dayOrdersTea },
        originalDayOrdersLunch: { ...order.dayOrdersLunch },
        originalDayOrdersTea: { ...order.dayOrdersTea },
        selectedUserUid: uid
    };
    return result;
};

const reducers = {

    appResetStateReq(state: IAppState, action: IAppResetStateReq): IAppState {
        const result: IAppState = appInitialState();
        return result;
    },

    orderLogsSubscribedReq(state: IAppState, action: IOrderLogsSubscribedReq): IAppState {
        const result: IAppState = {
            ...state,
            orderActivitySubscriptionMade: true,
            allOrderActivityLogsLoading: true,
        };

        return result;
    },

    regAllOrderLogsRes(state: IAppState, action: IRegAllOrderLogsRes): IAppState {
        const { payload } = action;
        if (payload.type === 'removed') {
            return state;
        }

        const result: IAppState = {
            ...state,
            allOrderActivityLogsLoading: false,
            allOrderActivityLogs: {
                ...state.allOrderActivityLogs,
                [payload.orderLog.id]: payload.orderLog,
            }
        };

        return result;
    },

    regAllOrderLogsSnapshotRes(state: IAppState, action: IRegAllOrderLogsSnapshotRes): IAppState {
        const { payload } = action;
        const allOrderActivityLogs = payload.reduce(
            (obj: IOrderActivityLogMap, item: IOrderActivityLog) => {
                obj[item.id] = item;
                return obj;
            },
            {}
        );
        const result: IAppState = {
            ...state,
            allOrderActivityLogsLoading: false,
            allOrderActivityLogs
        };

        return result;
    },


    regAllOrdersRes(state: IAppState, action: IRegAllOrdersRes): IAppState {
        return handleOrderEvent(state, action.payload);
    },

    appConfigRes(state: IAppState, action: IAppConfigRes): IAppState {
        const result: IAppState = {
            ...state,
            appConfig: action.payload
        };
        return result;
    },

    appConfirmationRes(state: IAppState, action: IConfirmationsRes): IAppState {
        const result: IAppState = {
            ...state,
            confirmations: action.payload
        };
        return result;
    },

    appConfirmationEventsRes(state: IAppState, action: IRegConfirmationEventsRes): IAppState {
        const confirmations: ISentConfirmationDates = {
            ...state.confirmations,
            ...action.payload
        };

        const result: IAppState = {
            ...state,
            confirmations
        };
        return result;
    },


    selectUserUid(state: IAppState, action: ISelectUserUid): IAppState {
        return handleSelectedUidEvent(state, action.payload);
    },

    selectRunner(state: IAppState, action: ISelectRunner): IAppState {
        const result: IAppState = {
            ...state,
            selectedRunner: action.payload
        };
        return result;
    },

    selectRunnerDates(state: IAppState, action: ISelectRunnerDates): IAppState {
        const result: IAppState = {
            ...state,
            runnerStartDate: action.payload.start,
            runnerEndDate: action.payload.end,
        };
        return result;
    },

    selectDayOrders(state: IAppState, action: ISelectOrderForUidAndDate): IAppState {
        const dayOrder: IOrder | undefined = getOrderForUserAndStartDate(action.payload.uid, action.payload.startDate, state);
        const order = dayOrder !== undefined ? {
            dayOrdersLunch: dayOrder.lunch,
            dayOrdersTea: dayOrder.tea
        }
            : {
                dayOrdersLunch: { ...defaultDayOrder },
                dayOrdersTea: { ...defaultDayOrder }
            }

        const result: IAppState = {
            ...state,
            dayOrdersLunch: { ...order.dayOrdersLunch },
            dayOrdersTea: { ...order.dayOrdersTea },
            originalDayOrdersLunch: { ...order.dayOrdersLunch },
            originalDayOrdersTea: { ...order.dayOrdersTea },
            selectedDayOrders: action.payload
        };
        return result;
    },

    appErr(state: IAppState, action: IAppErr): IAppState {
        if (action.error) console.error(`app.error=${action.error}`);
        const result: IAppState = {
            ...state,
            error: action.error,
            loading: false,
            saved: false
        };
        return result;
    },

    featuresReq(state: IAppState, action: IFeaturesReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    featuresRes(state: IAppState, action: IFeaturesRes): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            features: action.payload,
            loading: false
        };
        return result;
    },

    userNotesReq(state: IAppState, action: IUserNotesReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    userNotesRes(state: IAppState, action: IUserNotesRes): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: false,
            userNotes: action.payload,
        };
        return result;
    },

    setAmmendOrderReason(state: IAppState, action: ISetAmmendOrderReason): IAppState {
        return processAmmendOrderReason(state, action);
    },

    clearCurrentEditMeals(state: IAppState, action: IClearCurrentEditMeals): IAppState {
        const lunchCount = getTotalCount(state.dayOrdersLunch, {});
        const teaCount = getTotalCount(state.dayOrdersTea, {});

        if (lunchCount !== 0 || teaCount !== 0) {
            if (lunchCount !== 0 && teaCount === 0) {
                const dayOrdersLunch: IDayOrder = { ...defaultDayOrder };
                const result: IAppState = {
                    ...state,
                    dayOrdersLunch,
                };

                return result;
            }
            else if (lunchCount === 0 && teaCount !== 0) {
                const dayOrdersTea: IDayOrder = { ...defaultDayOrder };
                const result: IAppState = {
                    ...state,
                    dayOrdersTea
                };

                return result;
            }
            else {
                const dayOrdersLunch: IDayOrder = { ...defaultDayOrder };
                const dayOrdersTea: IDayOrder = { ...defaultDayOrder };

                const result: IAppState = {
                    ...state,
                    dayOrdersLunch,
                    dayOrdersTea
                };

                return result;
            }
        }
        else {

            return state;
        }
    },

    clearCurrentDessertEditMeals(state: IAppState, action: IClearCurrentDessertEditMeals): IAppState {
        const lunchCount = getTotalCount(state.dayOrdersLunch, {});
        const teaCount = getTotalCount(state.dayOrdersTea, {});

        if (lunchCount !== 0 || teaCount !== 0) {
            if (lunchCount !== 0 && teaCount === 0) {
                const dayOrdersLunch: IDayOrder = { ...state.dayOrdersLunch };
                dayOrdersLunch.dessert = 0;
                dayOrdersLunch.dessertSpecial = {};
                const result: IAppState = {
                    ...state,
                    dayOrdersLunch
                };

                return result;
            }
            else if (lunchCount === 0 && teaCount !== 0) {
                const dayOrdersTea: IDayOrder = { ...state.dayOrdersTea };
                dayOrdersTea.dessert = 0;
                dayOrdersTea.dessertSpecial = {};
                const result: IAppState = {
                    ...state,
                    dayOrdersTea
                };
                return result;
            }
            else {
                const dayOrdersLunch: IDayOrder = { ...state.dayOrdersLunch };
                const dayOrdersTea: IDayOrder = { ...state.dayOrdersTea };
                dayOrdersLunch.dessert = 0;
                dayOrdersTea.dessert = 0;
                dayOrdersTea.dessertSpecial = {};
                dayOrdersLunch.dessertSpecial = {};
                const result: IAppState = {
                    ...state,
                    dayOrdersLunch,
                    dayOrdersTea
                };

                return result;
            }
        }
        else {
            return state;
        }
    },

    setNormalMealOrder(state: IAppState, action: ISetNormalMeals): IAppState {
        const session: MealSession = action.payload.session;
        if (session === 'lunch') {
            return processNormalMealLunchOrder(state, action);
        }
        return processNormalMealTeaOrder(state, action);
    },

    setNormalDessertMealOrder(state: IAppState, action: ISetNormalDessertMeals): IAppState {
        const session: MealSession = action.payload.session;
        if (session === 'lunch') {
            return processNormalDessertMealLunchOrder(state, action);
        }
        return processNormalDessertMealTeaOrder(state, action);
    },

    setSpecialMealOrder(state: IAppState, action: ISetSpecialMeals): IAppState {
        const session: MealSession = action.payload.session;
        if (session === 'lunch') {
            return processSpecialMealLunchOrder(state, action);
        }
        return processSpecialMealTeaOrder(state, action);
    },

    setSpecialDessertMealOrder(state: IAppState, action: ISetSpecialDessertMeals): IAppState {
        const session: MealSession = action.payload.session;
        if (session === 'lunch') {
            return processSpecialDessertMealLunchOrder(state, action);
        }
        return processSpecialDessertMealTeaOrder(state, action);
    },

    setNoteForMealOrder(state: IAppState, action: ISetNoteForMeals): IAppState {
        const session: MealSession = action.payload.session;
        if (session === 'lunch') {
            return processNoteForMealLunchOrder(state, action);
        }
        return processNoteForMealTeaOrder(state, action);
    },

    ammendOrderForTheDayReq(state: IAppState, action: IAmmendOrderForDayReq): IAppState {

        const result: IAppState = {
            ...state,
            error: undefined,
            saved: false,
            savedOrder: action.payload,
        };
        return result;
    },

    saveOrderForTheDayReq(state: IAppState, action: ISaveOrderForDayReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            saved: false,
            savedOrder: action.payload,
        };
        return result;
    },

    updateCurrentDayOrdersAndTimeStamp(state: IAppState, action: IUpdateCurrentOrdersAndTimeStamp): IAppState {
        const dayOrderLunch: Readonly<IDayOrder> = action.payload.dayOrderLunch !== undefined ? action.payload.dayOrderLunch : { ...defaultDayOrder };
        const dayOrderTea: Readonly<IDayOrder> = action.payload.dayOrderTea !== undefined ? action.payload.dayOrderTea : { ...defaultDayOrder };

        const result: IAppState = {
            ...state,
            dayOrdersLunch: dayOrderLunch,
            dayOrdersTea: dayOrderTea,
            originalDayOrdersLunch: { ...dayOrderLunch },
            originalDayOrdersTea: { ...dayOrderTea },
        };

        return result;
    },

    loadAllUsersReq(state: IAppState, action: IAllUsersReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    loadAllUsersRes(state: IAppState, action: IAllUsersRes): IAppState {
        const allUids: string[] = action.payload.filter((u) => u.admin === false).map((u) => { return u.uid });
        const result: IAppState = {
            ...state,
            allUsers: action.payload.reduce(
                (obj: IUsersMap, item: IUser) => {
                    obj[item.uid] = item;
                    return obj;
                },
                {}
            ),
            allUids,
            error: undefined,
            loading: false
        };
        return result;
    },

    loadUserReq(state: IAppState, action: ILoadUserReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    loadUserRes(state: IAppState, action: ILoadUserRes): IAppState {
        if (action.payload.admin === false) {
            const orgsUids: string[] = action.payload.orgs !== undefined ? Object.keys(action.payload.orgs) : [action.payload.uid];
            const allUids: ReadonlyArray<string> = mergeToUniqueArray(orgsUids, state.allUids) as ReadonlyArray<string>;

            const result: IAppState = {
                ...state,
                allUids,
                allUsers: {
                    ...state.allUsers,
                    [action.payload.uid]: action.payload,
                },
                error: undefined,
                loading: false
            };
            return result;
        }
        else {
            const result: IAppState = {
                ...state,
                error: undefined,
                loading: false
            };
            return result;
        }
    },

    setLoggedInUser(state: IAppState, action: ISetLoggedInUser): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: false,
            loggedInUser: action.payload
        };
        return result;
    },

    toggleUserOrderUpdateEvent(state: IAppState, action: IToggleUserOrderUpdateEvent): IAppState {
        const result: IAppState = {
            ...state,
            userOrderUpdateEvent: action.payload
        };

        return result;
    },

    toggleUserOrderSavedEvent(state: IAppState, action: IToggleUserOrderSavedEvent): IAppState {
        const result: IAppState = {
            ...state,
            userOrderSavedEvent: action.payload
        };

        return result;
    },

    runnersReq(state: IAppState, action: IRunnersReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            loading: true
        };
        return result;
    },

    runnersRes(state: IAppState, action: IRunnersRes): IAppState {

        const runners: ReadonlyArray<IRunner> = action.payload.slice().sort((a: IRunner, b: IRunner) => {
            if (a.order === b.order) {
                return 0;
            }

            if (a.order < b.order) {
                return -1
            }

            return 1;
        });

        const uidToRunner: IUidToRunnerColor = {};
        for (const runner of runners) {
            const members = runner.members;
            if (members) {
                for (const id in members) {
                    if (id) {
                        uidToRunner[id] = runner.color
                    }
                }
            }
        }

        const result: IAppState = {
            ...state,
            error: undefined,
            loading: false,
            runnerColors: runners.map((r) => r.color),
            runners: runners.reduce(
                (obj: IRunners, item) => {
                    obj[item.color] = item;
                    return obj;
                },
                {}
            ),
            uidToRunner
        };
        return result;
    },

    copyWeeksOrdersReq(state: IAppState, action: ICopyWeeksOrdersReq): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            saved: false,
        };
        return result;
    },

    copyWeeksOrdersRes(state: IAppState, action: ICopyWeeksOrdersRes): IAppState {
        const result: IAppState = {
            ...state,
            error: undefined,
            saved: true,
        };
        return result;
    },

    regUsersUpdateRes(state: IAppState, action: IRegAllUsersUpdateRes): IAppState {

        const result: IAppState = {
            ...state,
            allUsers: {
                ...state.allUsers,
                [action.payload.uid]: action.payload.user
            },
            error: undefined,
        };

        return result;
    },

    appSignatureEventsRes(state: IAppState, action: IRegSignatureEventsRes): IAppState {
        switch (action.payload.eventName) {
            case `foodcheck`: {
                const result: IAppState = {
                    ...state,
                    foodSignatureInfo: {
                        ...state.foodSignatureInfo,
                        [action.payload.key]: action.payload.signatures,
                    },
                };
                return result;
            }

            case `diarycheck`: {
                const result: IAppState = {
                    ...state,
                    diarySignatureInfo: {
                        ...state.diarySignatureInfo,
                        [action.payload.key]: action.payload.signatures,
                    },
                };
                return result;
            }

            case `deliverycheck`: {
                const result: IAppState = {
                    ...state,
                    deliverySignatureInfo: {
                        ...state.deliverySignatureInfo,
                        [action.payload.key]: action.payload.signatures,
                    },
                };
                return result;
            }

            case `prerequisitecheck`: {
                const result: IAppState = {
                    ...state,
                    prerequisiteSignatureInfo: {
                        ...state.prerequisiteSignatureInfo,
                        [action.payload.key]: action.payload.signatures,
                    },
                };
                return result;
            }

            default:
                return state;
        }
    },
};


const processNormalMealLunchOrder = (state: IAppState, action: ISetNormalMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersLunch };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            normalMeals: action.payload.count,
        };

        const result: IAppState = {
            ...state,
            dayOrdersLunch: newDayOrder
        };

        return result;
    }
    else if (dayOrder.normalMeals !== action.payload.count) {
        const result: IAppState = {
            ...state,
            dayOrdersLunch: {
                ...dayOrder,
                normalMeals: action.payload.count
            }
        };

        return result;
    }

    return state;
};

const processNormalDessertMealLunchOrder = (state: IAppState, action: ISetNormalDessertMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersLunch };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            dessert: action.payload.count,
        };

        const result: IAppState = {
            ...state,
            dayOrdersLunch: newDayOrder
        };

        return result;
    }
    else if (dayOrder.dessert !== action.payload.count) {
        const result: IAppState = {
            ...state,
            dayOrdersLunch: {
                ...dayOrder,
                dessert: action.payload.count
            }
        };

        return result;
    }

    return state;
};

const processAmmendOrderReason = (state: IAppState, action: ISetAmmendOrderReason): IAppState => {
    // need both with reason

    const dayOrderLunch: IDayOrder = { ...state.dayOrdersLunch };
    const dayOrderTea: IDayOrder = { ...state.dayOrdersTea };

    const newDayOrderLunch: IDayOrder = {
        ...dayOrderLunch,
        ammendReason: action.payload,
    };

    const newDayOrderTea: IDayOrder = {
        ...dayOrderTea,
        ammendReason: action.payload,
    };

    const result: IAppState = {
        ...state,
        dayOrdersLunch: {
            ...newDayOrderLunch
        },
        dayOrdersTea: {
            ...newDayOrderTea
        }
    };

    return result;
};

const processNormalMealTeaOrder = (state: IAppState, action: ISetNormalMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersTea };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            normalMeals: action.payload.count,
        };

        const result: IAppState = {
            ...state,
            dayOrdersTea: newDayOrder
        };

        return result;
    }
    else if (dayOrder.normalMeals !== action.payload.count) {
        const result: IAppState = {
            ...state,
            dayOrdersTea: {
                ...dayOrder,
                normalMeals: action.payload.count
            }
        };

        return result;
    }

    return state;
};

const processNormalDessertMealTeaOrder = (state: IAppState, action: ISetNormalDessertMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersTea };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            dessert: action.payload.count,
        };

        const result: IAppState = {
            ...state,
            dayOrdersTea: newDayOrder
        };

        return result;
    }
    else if (dayOrder.dessert !== action.payload.count) {
        const result: IAppState = {
            ...state,
            dayOrdersTea: {
                ...dayOrder,
                dessert: action.payload.count
            }
        };

        return result;
    }

    return state;
};

const processNoteForMealLunchOrder = (state: IAppState, action: ISetNoteForMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersLunch };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            note: action.payload.note,
        };

        const result: IAppState = {
            ...state,
            dayOrdersLunch: newDayOrder
        };

        return result;
    }
    else {
        const result: IAppState = {
            ...state,
            dayOrdersLunch: {
                ...dayOrder,
                note: action.payload.note,
            }
        };

        return result;
    }
};

const processNoteForMealTeaOrder = (state: IAppState, action: ISetNoteForMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersTea };

    if (dayOrder === undefined) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            note: action.payload.note,
        };

        const result: IAppState = {
            ...state,
            dayOrdersTea: newDayOrder
        };

        return result;
    }
    else {
        const result: IAppState = {
            ...state,
            dayOrdersTea: {
                ...dayOrder,
                note: action.payload.note,
            }
        };

        return result;
    }
};

const processSpecialMealLunchOrder = (state: IAppState, action: ISetSpecialMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersLunch };

    if (dayOrder === undefined && action.payload.count !== 0) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            special: {
                [action.payload.code]: action.payload.count
            },
        };

        const result: IAppState = {
            ...state,
            dayOrdersLunch: newDayOrder
        };

        return result;
    }
    else {
        const special = { ...dayOrder.special };

        if (action.payload.count === 0) {
            delete special[action.payload.code];
        }
        else {
            special[action.payload.code] = action.payload.count;
        }

        const result: IAppState = {
            ...state,
            dayOrdersLunch: {
                ...dayOrder,
                special: {
                    ...special
                }
            }
        };
        return result;
    }
};

const processSpecialDessertMealLunchOrder = (state: IAppState, action: ISetSpecialDessertMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersLunch };
    if (dayOrder === undefined && action.payload.count !== 0) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            dessertSpecial: {
                [action.payload.code]: action.payload.count
            },
        };
        const result: IAppState = {
            ...state,
            dayOrdersLunch: newDayOrder
        };

        return result;
    }
    else {
        const dessertSpecial = { ...dayOrder.dessertSpecial };

        if (action.payload.count === 0) {
            delete dessertSpecial[action.payload.code];
        }
        else {
            dessertSpecial[action.payload.code] = action.payload.count;
        }

        const result: IAppState = {
            ...state,
            dayOrdersLunch: {
                ...dayOrder,
                dessertSpecial: {
                    ...dessertSpecial
                }
            }
        };
        return result;
    }
};

const processSpecialMealTeaOrder = (state: IAppState, action: ISetSpecialMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersTea };

    if (dayOrder === undefined && action.payload.count !== 0) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            special: {
                [action.payload.code]: action.payload.count
            },
        };

        const result: IAppState = {
            ...state,
            dayOrdersTea: newDayOrder
        };

        return result;
    }
    else {
        const special = { ...dayOrder.special };

        if (action.payload.count === 0) {
            delete special[action.payload.code];
        }
        else {
            special[action.payload.code] = action.payload.count;
        }

        const result: IAppState = {
            ...state,
            dayOrdersTea: {
                ...dayOrder,
                special: {
                    ...special
                }
            }
        };

        return result;
    }
};

const processSpecialDessertMealTeaOrder = (state: IAppState, action: ISetSpecialDessertMeals): IAppState => {
    const dayOrder: IDayOrder = { ...state.dayOrdersTea };

    if (dayOrder === undefined && action.payload.count !== 0) {
        const newDayOrder: IDayOrder = {
            ...defaultDayOrder,
            dessertSpecial: {
                [action.payload.code]: action.payload.count
            },
        };

        const result: IAppState = {
            ...state,
            dayOrdersTea: newDayOrder
        };

        return result;
    }
    else {
        const dessertSpecial = { ...dayOrder.dessertSpecial };

        if (action.payload.count === 0) {
            delete dessertSpecial[action.payload.code];
        }
        else {
            dessertSpecial[action.payload.code] = action.payload.count;
        }

        const result: IAppState = {
            ...state,
            dayOrdersTea: {
                ...dayOrder,
                dessertSpecial: {
                    ...dessertSpecial
                }
            }
        };

        return result;
    }
};

const reducer = (state: IAppState = appInitialState(), action: TActionTypes): IAppState => {
    switch (action.type) {

        case TypeKeys.RESET_STATE_REQ:
            return reducers.appResetStateReq(state, action);

        case TypeKeys.APP_CONFIG_RES:
            return reducers.appConfigRes(state, action);

        case TypeKeys.FEATURES_REQ:
            return reducers.featuresReq(state, action);

        case TypeKeys.FEATURES_RES:
            return reducers.featuresRes(state, action);

        case TypeKeys.USER_NOTES_REQ:
            return reducers.userNotesReq(state, action);

        case TypeKeys.USER_NOTES_RES:
            return reducers.userNotesRes(state, action);

        case TypeKeys.LOAD_CONFIRMATION_RES:
            return reducers.appConfirmationRes(state, action);

        case TypeKeys.REG_CONFIRMATION_EVENTS_RES:
            return reducers.appConfirmationEventsRes(state, action);

        case TypeKeys.SELECT_USER_UID:
            return reducers.selectUserUid(state, action);

        case TypeKeys.SELECT_RUNNER:
            return reducers.selectRunner(state, action);

        case TypeKeys.SELECT_RUNNER_DATES:
            return reducers.selectRunnerDates(state, action);

        case TypeKeys.SELECT_ORDERS_FOR_UID_AND_DATE:
            return reducers.selectDayOrders(state, action);

        case TypeKeys.APP_ERR:
            return reducers.appErr(state, action);

        case TypeKeys.SET_AMMEND_REASON:
            return reducers.setAmmendOrderReason(state, action);

        case TypeKeys.CLEAR_CURRENT_EDIT_MEALS:
            return reducers.clearCurrentEditMeals(state, action);

        case TypeKeys.CLEAR_CURRENT_DESSERT_EDIT_MEALS:
            return reducers.clearCurrentDessertEditMeals(state, action);

        case TypeKeys.SET_NORMAL_MEALS:
            return reducers.setNormalMealOrder(state, action);

        case TypeKeys.SET_NORMAL_DESSERT_MEALS:
            return reducers.setNormalDessertMealOrder(state, action);

        case TypeKeys.SET_SPECIAL_MEALS:
            return reducers.setSpecialMealOrder(state, action);

        case TypeKeys.SET_SPECIAL_DESSERT_MEALS:
            return reducers.setSpecialDessertMealOrder(state, action);

        case TypeKeys.SET_NOTE_FOR_MEALS:
            return reducers.setNoteForMealOrder(state, action);

        case TypeKeys.SAVE_AMMEND_ORDER_FOR_DAY_REQ:
            return reducers.ammendOrderForTheDayReq(state, action);

        case TypeKeys.SAVE_ORDER_FOR_DAY_REQ:
            return reducers.saveOrderForTheDayReq(state, action);

        case TypeKeys.UPDATE_CURRENT_ORDERS_AND_TIMESTAMP:
            return reducers.updateCurrentDayOrdersAndTimeStamp(state, action);

        case TypeKeys.LOAD_ALL_USERS_REQ:
            return reducers.loadAllUsersReq(state, action);

        case TypeKeys.LOAD_ALL_USERS_RES:
            return reducers.loadAllUsersRes(state, action);

        case TypeKeys.LOAD_USER_REQ:
            return reducers.loadUserReq(state, action);

        case TypeKeys.LOAD_USER_RES:
            return reducers.loadUserRes(state, action);

        case TypeKeys.SET_LOGGED_IN_USER:
            return reducers.setLoggedInUser(state, action);

        case TypeKeys.TOGGLE_USER_ORDER_UPDATE_EVENT:
            return reducers.toggleUserOrderUpdateEvent(state, action);

        case TypeKeys.TOGGLE_USER_ORDER_SAVED_EVENT:
            return reducers.toggleUserOrderSavedEvent(state, action);

        case TypeKeys.LOAD_ALL_RUNNERS_REQ:
            return reducers.runnersReq(state, action);

        case TypeKeys.LOAD_ALL_RUNNERS_RES:
            return reducers.runnersRes(state, action);

        case TypeKeys.COPY_WEEK_ORDERS_REQ:
            return reducers.copyWeeksOrdersReq(state, action);

        case TypeKeys.COPY_WEEK_ORDERS_RES:
            return reducers.copyWeeksOrdersRes(state, action);

        case TypeKeys.REG_ALL_USERS_UPDATE_RES:
            return reducers.regUsersUpdateRes(state, action);

        case TypeKeys.REG_SIGNATURE_EVENTS_RES:
            return reducers.appSignatureEventsRes(state, action);

        case TypeKeys.REG_ALL_ORDERS_RES:
            return reducers.regAllOrdersRes(state, action);

        case TypeKeys.REG_ALL_ORDER_LOGS_RES:
            return reducers.regAllOrderLogsRes(state, action);

        case TypeKeys.REG_ALL_ORDER_LOGS_SNAPSHOT_RES:
            return reducers.regAllOrderLogsSnapshotRes(state, action);

        case TypeKeys.ORDER_LOGS_SUBSCRIBED:
            return reducers.orderLogsSubscribedReq(state, action);

        default:
            return state;
    }
};

export default reducer;