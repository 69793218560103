import cloneDeep  from 'lodash/cloneDeep';
import { format } from '../../Common/utils/dateFunctions';

import { IMenuState } from "../../App/interfaces/IMenuState";
import { menuInitialState } from "./menuInitialState";
import { Cycle } from "../../App/interfaces/IMenuCycle";
import { MealSession } from "../../App/interfaces/MealSession";
import { MenuField } from "../../App/interfaces/MenuField";
import {
    IMenuData,
    IMenuDataMap
} from "../../App/interfaces/IMenuData";
import { IAllMenuData, IMenuItemIdToIsJoggerGramsMap } from "../../App/interfaces/IAllMenuData";
import { IDeletedCodesMap, IDietaryCode, IDietaryCodesMap } from "../../App/interfaces/IDietaryCodes";
import { AlternativeUpdate, IAlternative, IAlternativeMap } from "../../App/interfaces/IAlternatives";
import {
    IPortionRangeMap,
    IPortionRuleMap
} from "../../App/interfaces/IPortionRules";
import {
    N_DATE_FORMAT,
} from "../../Common/utils/dateFunctions";
import { IAlternativeMealDataMap } from "../../App/interfaces/IAlternativeMealData";
import { isItemInAnyRules } from './menuSelectors';


export enum TypeKeys {
    RESET_STATE_REQ = 'MENU/RESET_STATE_REQ',

    EDIT_MENU_CYCLE_DEFINITION = 'MENU/EDIT_MENU_CYCLE_DEFINITION',

    SET_MENU_EDIT_CYCLE = 'MENU/SET_MENU_EDIT_CYCLE',

    SET_MENU_EDIT_DATA = 'MENU/SET_MENU_EDIT_DATA',

    SET_MENU_EDIT_DATA_FIELD = 'MENU/SET_MENU_EDIT_DATA_FIELD',

    SAVE_MENU_EDIT_DATA_REQ = 'MENU/SAVE_MENU_EDIT_DATA_REQ',
    SAVE_MENU_EDIT_DATA_RES = 'MENU/SAVE_MENU_EDIT_DATA_RES',

    ALTERNATIVES_REQ = 'MENU/ALTERNATIVES_REQ',
    ALTERNATIVES_RES = 'MENU/ALTERNATIVES_RES',
    UPDATE_ALTERNATIVES_RES = 'MENU/UPDATE_ALTERNATIVES_RES',
    
    ALTERNATIVE_MEAL_MAP_REQ = 'MENU/ALTERNATIVE_MEAL_MAP_REQ',
    ALTERNATIVE_MEAL_MAP_RES = 'MENU/ALTERNATIVE_MEAL_MAP_RES',

    DIETARY_CODES_REQ = 'MENU/DIETARY_CODES_REQ',
    DIETARY_CODES_RES = 'MENU/DIETARY_CODES_RES',

    PORTION_RULE_REQ = 'MENU/PORTION_RULE_REQ',
    PORTION_RULE_RES = 'MENU/PORTION_RULE_RES',

    LOAD_ALL_MENUS_REQ = 'MENU/LOAD_ALL_MENUS_REQ',
    LOAD_ALL_MENUS_RES = 'MENU/LOAD_ALL_MENUS_RES',
};

export interface IMenuResetStateReq {
    type: TypeKeys.RESET_STATE_REQ;
}

export interface ISetMenuEditCycle {
    type: TypeKeys.SET_MENU_EDIT_CYCLE;
    payload: string;
}

export interface IEditMenuEditCycle {
    type: TypeKeys.EDIT_MENU_CYCLE_DEFINITION;
    payload: {
        cycle: Cycle;
        endDate: Date;
        startDate: Date;
        label: string;
    };
}

export interface ISetMenuEditData {
    type: TypeKeys.SET_MENU_EDIT_DATA;
    payload: number;
}

export interface ISetMenuEditDataField {
    type: TypeKeys.SET_MENU_EDIT_DATA_FIELD;
    payload: {
        session: MealSession;
        field: MenuField;
        value: string | string[];
    }
}

export interface ISaveMenuEditData {
    type: TypeKeys.SAVE_MENU_EDIT_DATA_REQ;
    payload: IMenuData;
}

export interface IMenusReq {
    type: TypeKeys.LOAD_ALL_MENUS_REQ;
    doNotDelay?: boolean;
}

export interface IMenusRes {
    type: TypeKeys.LOAD_ALL_MENUS_RES;
    payload: IAllMenuData;
}


export interface IDietaryCodesReq {
    type: TypeKeys.DIETARY_CODES_REQ;
}

export interface IDietaryCodesRes {
    type: TypeKeys.DIETARY_CODES_RES;
    payload: ReadonlyArray<IDietaryCode>;
}

export interface IAlternativesReq {
    type: TypeKeys.ALTERNATIVES_REQ;
}

export interface IAlternativesRes {
    type: TypeKeys.ALTERNATIVES_RES;
    payload: Readonly<IAlternativeMap>;
}

export interface IUpdateAlternativesRes {
    type: TypeKeys.UPDATE_ALTERNATIVES_RES;
    payload: {
        id: string;
        type: AlternativeUpdate;
        alternative: IAlternative | undefined;
    }
}

export interface IAlternativeMealMapReq {
    type: TypeKeys.ALTERNATIVE_MEAL_MAP_REQ;
}

export interface IAlternativeMealMapRes {
    type: TypeKeys.ALTERNATIVE_MEAL_MAP_RES;
    payload: Readonly<IAlternativeMealDataMap>;
}

export interface IPortionRuleDataReq {
    type: TypeKeys.PORTION_RULE_REQ;
}

export interface IPortionRuleDataRes {
    type: TypeKeys.PORTION_RULE_RES;
    payload: {
        ranges: IPortionRangeMap;
        rules: IPortionRuleMap;
    };
}

//////////////////////  ACTIONS
export const actions = {

    resetStateReq(): IMenuResetStateReq {
        const action: IMenuResetStateReq = { type: TypeKeys.RESET_STATE_REQ };
        return action;
    },

    editMenuCycleDefinition(payload: {
        cycle: Cycle;
        endDate: Date;
        startDate: Date;
        label: string;
    }): IEditMenuEditCycle {
        const action: IEditMenuEditCycle = { type: TypeKeys.EDIT_MENU_CYCLE_DEFINITION, payload };
        return action;
    },

    setMenuEditCycle(payload: string): ISetMenuEditCycle {
        const action: ISetMenuEditCycle = { type: TypeKeys.SET_MENU_EDIT_CYCLE, payload };
        return action;
    },

    setMenuEditData(payload: number): ISetMenuEditData {
        const action: ISetMenuEditData = { type: TypeKeys.SET_MENU_EDIT_DATA, payload };
        return action;
    },

    setMenuEditDataField(payload: {
        session: MealSession;
        field: MenuField;
        value: string | string[];
    }): ISetMenuEditDataField {
        const action: ISetMenuEditDataField = { type: TypeKeys.SET_MENU_EDIT_DATA_FIELD, payload };
        return action;
    },

    saveMenuEditData(payload: IMenuData): ISaveMenuEditData {
        const action: ISaveMenuEditData = { type: TypeKeys.SAVE_MENU_EDIT_DATA_REQ, payload };
        return action;
    },

    dietaryCodesReq(): IDietaryCodesReq {
        const action: IDietaryCodesReq = { type: TypeKeys.DIETARY_CODES_REQ };
        return action;
    },

    dietaryCodesRes(payload: ReadonlyArray<IDietaryCode>): IDietaryCodesRes {
        const action: IDietaryCodesRes = { type: TypeKeys.DIETARY_CODES_RES, payload };
        return action;
    },

    alternativesReq(): IAlternativesReq {
        const action: IAlternativesReq = { type: TypeKeys.ALTERNATIVES_REQ };
        return action;
    },

    alternativesRes(payload: Readonly<IAlternativeMap>): IAlternativesRes {
        const action: IAlternativesRes = { type: TypeKeys.ALTERNATIVES_RES, payload };
        return action;
    },

    updateAlternativeRes(payload: {
        id: string;
        type: AlternativeUpdate;
        alternative: IAlternative | undefined;
    }): IUpdateAlternativesRes {
        const action: IUpdateAlternativesRes = { type: TypeKeys.UPDATE_ALTERNATIVES_RES, payload };
        return action;
    },

    alternativeMealMapReq(): IAlternativeMealMapReq {
        const action: IAlternativeMealMapReq = { type: TypeKeys.ALTERNATIVE_MEAL_MAP_REQ };
        return action;
    },

    alternativeMealMapRes(payload: Readonly<IAlternativeMealDataMap>): IAlternativeMealMapRes {
        const action: IAlternativeMealMapRes = { type: TypeKeys.ALTERNATIVE_MEAL_MAP_RES, payload };
        return action;
    },

    menusReq(doNotDelay?: boolean): IMenusReq {
        const action: IMenusReq = { type: TypeKeys.LOAD_ALL_MENUS_REQ, doNotDelay };
        return action;
    },

    menusRes(payload: IAllMenuData): IMenusRes {
        const action: IMenusRes = { type: TypeKeys.LOAD_ALL_MENUS_RES, payload };
        return action;
    },

    portionRuleDataReq(): IPortionRuleDataReq {
        const action: IPortionRuleDataReq = { type: TypeKeys.PORTION_RULE_REQ };
        return action;
    },

    portionRuleDataRes(payload: {
        ranges: IPortionRangeMap;
        rules: IPortionRuleMap;
    }): IPortionRuleDataRes {
        const action: IPortionRuleDataRes = { type: TypeKeys.PORTION_RULE_RES, payload };
        return action;
    },

};

//////////////////////  ACTION TYPES
type TActionTypes =
    IMenuResetStateReq |
    IDietaryCodesRes |
    IAlternativesRes |
    IUpdateAlternativesRes |
    IAlternativeMealMapRes |
    IPortionRuleDataRes |
    ISetMenuEditData |
    ISetMenuEditDataField |
    IEditMenuEditCycle |
    ISetMenuEditCycle |
    ISaveMenuEditData |
    IMenusReq |
    IMenusRes
    ;
//////////////////////  ACTION REDUCERS
const reducers = {

    menuResetStateReq(state: IMenuState, action: IMenuResetStateReq): IMenuState {
        const result: IMenuState = menuInitialState();
        return result;
    },

    editMenuCycleDefinition(state: IMenuState, action: IEditMenuEditCycle): IMenuState {

        const startDate: string = format(action.payload.startDate, N_DATE_FORMAT);
        const endDate: string = format(action.payload.endDate, N_DATE_FORMAT);
        
        const result: IMenuState = {
            ...state,
            menuCycleDefinitionMapEdit: {
                ...state.menuCycleDefinitionMapEdit,
                [action.payload.cycle]: {
                    ...state.menuCycleDefinitionMapEdit[action.payload.cycle],
                    endDate,
                    name: action.payload.label,
                    startDate
                }
            },
        };

        return result;
    },

    setMenuEditCycle(state: IMenuState, action: ISetMenuEditCycle): IMenuState {
        const result: IMenuState = {
            ...state,
            editMenuCycle: action.payload
        };
        return result;
    },

    setMenuEditData(state: IMenuState, action: ISetMenuEditData): IMenuState {
        const cycle: string = state.editMenuCycle !== undefined ? state.editMenuCycle : state.currentMenuCycle;
        const menuData: IMenuDataMap | undefined = state.menuDataByCycle[cycle];

        let menuEditData: IMenuData | undefined;

        if (menuData) {
            menuEditData = menuData[action.payload];
        }

        const result: IMenuState = {
            ...state,
            menuEditData: menuEditData !== undefined ? cloneDeep(menuEditData) : undefined
        };
        return result;
    },

    setMenuEditDataField(state: IMenuState, action: ISetMenuEditDataField): IMenuState {
        const menuEditData: IMenuData | undefined = state.menuEditData;

        if (menuEditData) {
            const cloned: IMenuData = cloneDeep(menuEditData);
            
            if (action.payload.session === `lunch`) {
                switch (action.payload.field)  {
                    case `desserts`:
                        cloned[`lunch`][`desserts`] = action.payload.value as string[];  
                        break;   
                    case `normal`:
                        cloned[`lunch`][`normal`] = action.payload.value as string;  
                        break;   
                    case `sides`:
                        cloned[`lunch`][`sides`] = action.payload.value as string[];  
                        break;   
                    
                }
            }
            else if (action.payload.session === `tea`) {
                switch (action.payload.field)  {
                    case `desserts`:
                        cloned[`tea`][`desserts`] = action.payload.value as string[];  
                        break;   
                    case `normal`:
                        cloned[`tea`][`normal`] = action.payload.value as string;  
                        break;   
                    case `sides`:
                        cloned[`tea`][`sides`] = action.payload.value as string[];  
                        break;   
                    
                }
            }
            
            const result: IMenuState = {
                ...state,
                menuEditData: cloned
            };
            return result;
        }

        return state;
    },

    dietaryCodesRes(state: IMenuState, action: IDietaryCodesRes): IMenuState {
        const dietaryCodes: IDietaryCodesMap = action.payload.reduce(
            (obj: IDietaryCodesMap, item: IDietaryCode) => {
                obj[item.code] = item;
                return obj;
            },
            {}
        );

        const deletedDietary: IDeletedCodesMap = {};
        for (const code in dietaryCodes) {
            const dc = dietaryCodes[code];
            if (dc && dc.deleted) {
                deletedDietary[code] = true;
            }
        }

        const dietaryCodesIds: ReadonlyArray<string> = action.payload.map((dc) => dc.code);

        const result: IMenuState = {
            ...state,
            dietaryCodes,
            dietaryCodesIds,
            deletedDietary,
        };
        return result;
    },

    portionRuleDataRes(state: IMenuState, action: IPortionRuleDataRes): IMenuState {

        const rules: IPortionRuleMap = action.payload.rules;
        const isJoggerMultiplierGramsMap: IMenuItemIdToIsJoggerGramsMap = { ...state.isJoggerMultiplierGramsMap };

        for (const k in isJoggerMultiplierGramsMap) {
            isJoggerMultiplierGramsMap[k] = !isItemInAnyRules(k, rules);
        }
        
        const result: IMenuState = {
            ...state,
            portionRanges: action.payload.ranges,
            portionRules: rules,
            isJoggerMultiplierGramsMap,
        };
        return result;
    },

    alternativesRes(state: IMenuState, action: IAlternativesRes): IMenuState {
        const result: IMenuState = {
            ...state,
            alternatives: action.payload,
        };
        return result;
    },

    updateAlternativeRes(state: IMenuState, action: IUpdateAlternativesRes): IMenuState {
        if (action.payload.type === AlternativeUpdate.Delete) {
            const alternatives = { ...state.alternatives };
            delete alternatives[action.payload.id];
            const result: IMenuState = {
                ...state,
                alternatives,
            };
            return result;
        }
        else if (action.payload.alternative !== undefined) {
            const result: IMenuState = {
                ...state,
                alternatives: {
                    ...state.alternatives,
                    [action.payload.id]: action.payload.alternative,
                }
            };
            return result;
        }

        return state;
    },

    alternativeMealMapRes(state: IMenuState, action: IAlternativeMealMapRes): IMenuState {
        const result: IMenuState = {
            ...state,
            alternativeMealData: action.payload,
        };
        return result;
    },

    menusReq(state: IMenuState, _action: IMenusReq): IMenuState {
        const result: IMenuState = {
            ...state,
            menuLoaded: false,
        };
        return result;
    },

    menusRes(state: IMenuState, action: IMenusRes): IMenuState {

        const result: IMenuState = {
            ...state,
            allMenuCycles: action.payload.allMenuCycles,
            currentMenuCycle: action.payload.currentMenuCycle,
            menuCycleDefinitionMap: action.payload.menuCycleDefinitionMap,
            menuCycleDefinitionMapEdit: action.payload.menuCycleDefinitionMap,
            menuDataByCycle: action.payload.menuDataByCycle,
            menuDesserts: action.payload.menuDesserts,
            menuAllergens: action.payload.menuAllergens,
            menuLoaded: true,
            menuAtStartupLoaded: true,
            menuLunches: action.payload.menuLunches,
            menuSides: action.payload.menuSides,
            menuTeas: action.payload.menuTeas,
            displayMenusByCycle: action.payload.displayMenusByCycle,
            menuItemIdToLabel: action.payload.menuItemIdToLabel,
            isJoggerMultiplierGramsMap: action.payload.isJoggerMultiplierGramsMap,
        };

        return result;
    },

};

const reducer = (state: IMenuState = menuInitialState(), action: TActionTypes): IMenuState => {
    // tslint:disable-next-line:no-console
    // console.log(`menu.reducer ${action.type}`);
    switch (action.type) {

        case TypeKeys.RESET_STATE_REQ:
            return reducers.menuResetStateReq(state, action);

        case TypeKeys.ALTERNATIVES_RES:
            return reducers.alternativesRes(state, action);

        case TypeKeys.UPDATE_ALTERNATIVES_RES:
            return reducers.updateAlternativeRes(state, action);

        case TypeKeys.ALTERNATIVE_MEAL_MAP_RES:
            return reducers.alternativeMealMapRes(state, action);

        case TypeKeys.DIETARY_CODES_RES:
            return reducers.dietaryCodesRes(state, action);

        case TypeKeys.PORTION_RULE_RES:
            return reducers.portionRuleDataRes(state, action);

        case TypeKeys.LOAD_ALL_MENUS_REQ:
            return reducers.menusReq(state, action);

        case TypeKeys.LOAD_ALL_MENUS_RES:
            return reducers.menusRes(state, action);

        case TypeKeys.EDIT_MENU_CYCLE_DEFINITION:
            return reducers.editMenuCycleDefinition(state, action);

        case TypeKeys.SET_MENU_EDIT_CYCLE:
            return reducers.setMenuEditCycle(state, action);

        case TypeKeys.SET_MENU_EDIT_DATA:
            return reducers.setMenuEditData(state, action);

        case TypeKeys.SET_MENU_EDIT_DATA_FIELD:
            return reducers.setMenuEditDataField(state, action);
        default:
            return state;
    }
};

export default reducer;