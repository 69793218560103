import { DEFAULT_MENU_CYCLE } from '../interfaces/IAllMenuData';
import { IMenuState } from '../../App/interfaces/IMenuState';

export const menuInitialState = (): IMenuState => {
    const state: IMenuState = {
        alternativeMealData: {},
        alternatives: {},
        allMenuCycles: {},
        currentMenuCycle: DEFAULT_MENU_CYCLE,
        dietaryCodes: {},
        deletedDietary: {},
        dietaryCodesIds: [],
        editMenuCycle: undefined,
        menuAllergens: {},
        menuAtStartupLoaded: false,
        menuCycleDefinitionMap: {},
        menuCycleDefinitionMapEdit: {},
        menuDataByCycle: {},
        menuDesserts: {},
        menuEditData: undefined,
        menuLoaded: false,
        menuLunches: {},
        menuSides: {},
        menuTeas: {},
        displayMenusByCycle: {},
        portionRanges: {},
        portionRules: {},
        menuItemIdToLabel: {},
        isJoggerMultiplierGramsMap: {},
        allergies: {}
    };
    return state;
};
