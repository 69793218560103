export const LUNCH: string = 'lunch';
export const TEA: string = 'tea';
export const SIDES: string = 'sides';
export const DESERTS: string = 'desserts';
export const ALLERGENS: string = 'allergens';

export const CurrentCyclePastMenus: string = `currentCyclePastMenus`;
export type Cycle = 'cycle1' | 'cycle2' | 'cycle3';

export interface IMenuCycle {
    cycleId: string;
    menuId: number;
    date: string;
    dateStr: string;
    dateVal: number;
    offset: number;
}

export interface IMenuCycleMap {
    [key: string]: IMenuCycle;
}

export interface IAllMenuCycleMap {
    [key: string]: IMenuCycleMap;
}

export interface IMenuCycleDefinition {
    id: string;
    name:string;
    startDate: string;
    endDate: string;
}

export interface IMenuCycleDefinitionMap {
    [key: string]: IMenuCycleDefinition;
}
