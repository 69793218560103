import firebase from 'firebase/compat/app';
import { RGBColor } from 'react-color';

import {
    db,
} from '../../Storage';
import { IAlternativeMealDataMap, IAlternativeMealData } from '../../App/interfaces/IAlternativeMealData';
import { rgbStringToRgb } from '../../Common/components/PopupColorSwatch';

export const ALTERNATIVE_DATA_MEAL_MAP_PATH: string = 'data/food/alternative_data/meal_map';
// export const ALTERNATIVE_DATA_COLOUR_MAP_PATH: string = 'data/food/alternative_data/colour_map';
// export const ALTERNATIVE_DATA_COLOUR_MAP_PRINT_PATH: string = `${ALTERNATIVE_DATA_COLOUR_MAP_PATH}/print-labels`;
// export const ALTERNATIVE_DATA_COLOUR_MAP_DISPLAY_PATH: string = `${ALTERNATIVE_DATA_COLOUR_MAP_PATH}/display-labels`;

export const loadAlternativeMealMap = async (): Promise<Readonly<IAlternativeMealDataMap>> => {
    const snapshot: firebase.database.DataSnapshot = await db.child(ALTERNATIVE_DATA_MEAL_MAP_PATH).once('value');
    const data = snapshot.val();
    const result: IAlternativeMealDataMap = {};
    for (const k in data) {
        if (k) {
            result[k] = {
                ...data[k],
            };
        }
    }
    return result;
};

const getTextColorToContrastWithBackground = (rgb: RGBColor): string => {
    const brightness: number = Math.round(((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000);
    const textColour: string = (brightness > 125) ? 'black' : 'white';
    return textColour;
};

export const createAlternativeMealMap = (id: string, name: string, rgbBackground: string): IAlternativeMealData => {
    const rgb: RGBColor = rgbStringToRgb(rgbBackground);
    const result: IAlternativeMealData = {
        display: {
            background: rgbBackground,
            color: getTextColorToContrastWithBackground(rgb),
        },
        id,
        print: {
            background: `initial`,
            color: rgbBackground,
        },
        name,
    };
    return result;
};

export const updateAlternativeMealMap = async (data: IAlternativeMealData): Promise<void> => {
    return await db.child(`${ALTERNATIVE_DATA_MEAL_MAP_PATH}/${data.id}`).update(data);
};

// const loadAlternativeMealColourMap = async (map: string): Promise<Readonly<IAlternativeMealColourMap>> => {
//     const snapshot: firebase.database.DataSnapshot = await db.child(map).once('value');
//     const data = snapshot.val();
//     const result: IAlternativeMealColourMap = {};
//     for (const k in data) {
//         if (k) {
//             result[k] = {
//                 id: k,
//                 color: data[k].color,
//                 background: data[k].background,
//             };
//         }
//     }
//     return result;
// };
// export const loadAlternativeMealDisplayColourMap = async (): Promise<Readonly<IAlternativeMealColourMap>> => {
//     return await loadAlternativeMealColourMap(ALTERNATIVE_DATA_COLOUR_MAP_DISPLAY_PATH);
// };
// export const loadAlternativeMealPrintColourMap = async (): Promise<Readonly<IAlternativeMealColourMap>> => {
//     return await loadAlternativeMealColourMap(ALTERNATIVE_DATA_COLOUR_MAP_PRINT_PATH);
// };

// const updateAlternativeMealColourMap = async (map: string, data: IColourData): Promise<void> => {
//     return await db.child(`${map}/${data.id}`).update(data);
// };
// export const updateAlternativeMealDisplayColourMap = async (data: IColourData): Promise<void> => {
//     return await updateAlternativeMealColourMap(ALTERNATIVE_DATA_COLOUR_MAP_DISPLAY_PATH, data);
// };
// export const updateAlternativeMealPrintColourMap = async (data: IColourData): Promise<void> => {
//     return await updateAlternativeMealColourMap(ALTERNATIVE_DATA_COLOUR_MAP_PRINT_PATH, data);
// };

/*
"alternative_data": {
      "meal_map": {
        "8624fbc8b42589d3ae91bcaafd6415df": {
          "name": "vegetarian_meal",
          "print": {
            "color": "rgb(36, 177, 31)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(36, 177, 31)",
            "color": "rgb(255, 255, 255)"
          }
        },
        "725da15674a2d576327a47e5772c4779": {
          "name": "main_dietary",
          "print": {
            "color": "rgb(221, 67, 67)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(221, 67, 67)",
            "color": "initial"
          }
        },
        "5ec4210b29052b2377ccc6f0ca5d0e1d": {
          "name": "vegan_dietary",
          "print": {
            "color": "rgb(243, 236, 169)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(243, 236, 169)",
            "color": "initial"
          }
        },
        "536a9a5cf401d974a323031bfb1915c1": {
          "name": "halal_dietary",
          "print": {
            "color": "rgb(84, 177, 240)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(84, 177, 240)",
            "color": "rgb(255, 255, 255)"
          }
        },
        "c6552b7e15ae84d608ea5649022e68dd": {
          "name": "special_dietary",
          "print": {
            "color": "rgb(145, 69, 148)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(145, 69, 148)",
            "color": "rgb(255, 255, 255)"
          }
        },
        "4d6b7e8ed9ed4a06d0777df51d624e92": {
          "name": "side_alternative",
          "print": {
            "color": "rgb(221, 161, 71)",
            "background": "initial"
          },
          "display": {
            "background": "rgb(221, 161, 71)",
            "color": "initial"
          }
        }
      }
    },
*/