import { IChecksState } from '../../App/interfaces/IChecksState';

export const checksInitialState = (): IChecksState => {
    const state: IChecksState = {
        allChecksLoaded: false,
        allCheckDescribers: {},
        allCheckGroups: [],
        allCheckGroupCategories: {},
        maxTabOrderPerCategory: {},
        customDropdownControls: {},
        customItemCheckControls: {},
        customInternalDropdownControls: {},
        customRangeControls: {},
        customAlertCheckControls: {},
        customPrereqCheckControls: {},
        currentGroupId: ``,
        prerequisiteMap: {},
    };
    return state;
};
