import { Dispatch } from 'redux';

import { actions as app } from '../../App/store';
import { actions as auth } from '../../Auth/store';
import { actions as checks } from '../../App/store/checksBundle';
import { actions as menu } from '../../App/store/menuBundle';

import { loadAllRunners } from '../../App/sagas/appSaga';
import { subWeeks } from '../../Common/utils/dateFunctions';

export const unload = (dispatch: Dispatch<any>): void => {
    console.log(`driver.kitchen.unload`);
    dispatch(app.uregAllOrdersReq());
    dispatch(app.uregAllUsersUpdateReq());
    dispatch(app.uregSignatureEventsReq())
    dispatch(app.resetStateReq());
    dispatch(menu.resetStateReq());
    dispatch(auth.signoutReq());
    dispatch(checks.uregPrerequisiteEventsReq());
};

export const load = (dispatch: Dispatch<any>): void => {
    console.log(`driver.kitchen.load`);
    loadAllRunners()
        .then((runners) => {
            const getFirstRunner = runners.find((r) => r.order === 1);
            const runColor = getFirstRunner ? getFirstRunner.color : `gold`;
            dispatch(app.runnersRes(runners));
            dispatch(app.selectRunner(runColor));
        })
        .catch((error) => {
            dispatch(app.selectRunner(`gold`));
            console.error(`Failed to load runners, no food check records. ${error}`);
        });
    const filterDateOrders: number = subWeeks(new Date(), 1).getTime();
    dispatch(app.regAllOrdersReq(filterDateOrders));
    dispatch(app.appConfigReq());
    dispatch(app.featuresReq());
    dispatch(app.loadUserReq());
    dispatch(app.userNotesReq());
    dispatch(app.regAllUsersUpdateReq());
    dispatch(app.regSignatureEventsReq());

    dispatch(app.allUsersReq());
    dispatch(menu.menusReq());
    dispatch(menu.portionRuleDataReq());
    dispatch(menu.alternativesReq());
    dispatch(menu.dietaryCodesReq());
    dispatch(menu.alternativeMealMapReq());

    dispatch(checks.loadCheckDescribersReq());
    dispatch(checks.loadCheckCustomControlsReq());
    dispatch(checks.regPrerequisiteEventsReq());
};