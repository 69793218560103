import { getUid } from '../../Common/utils/getUid';

export interface IPortionRule {
    id: string;
    name: string;
    itemIds: string[];
    rangeIds: string[]
}

export interface IPortionRuleMap {
    [id: string]: IPortionRule;
}
 
export interface IPortionRange {
    id: string;
    from: number;
    to: number;
    portionAsString: string[];
    portion: number[];
}

export interface IPortionRangeMap {
    [id: string]: IPortionRange;
}

export interface IRangeCountMap {
    [rangeId: string]: number;
}

export interface IItemToRangeCountMap {
    [itemId: number]: IRangeCountMap;
}

export const getNextIdForRanges = (): string => {
    return getUid(20);
}
export const getNextIdForRules = (): string => {
    return getUid(20);
}

export const PortionRulePath: string = 'data/food/portion_rules';
export const PortionRangePath: string = 'data/food/portion_ranges';

export const sortPortionAsStrings = (portionAsString: string[]): string[] => {
    
    const dN = (dn: string): {n: number, d: number} => {
        const i: number = dn.indexOf('/');
        if (i !== -1) {
            const N: number = parseInt(dn.substr(0, i), 10);
            const D: number = parseInt(dn.substr(i+1, dn.length - i), 10);
            return {d: D, n: N};
        }
        const n: number = parseInt(dn, 10);
        return {d: 1, n};
    };
    
    const compare = (a: string, b: string): number => {
        const dnA: {n: number, d: number} = dN(a);
        const dnB: {n: number, d: number} = dN(b)
        const aN: number = dnA.n / dnA.d;
        const bN: number = dnB.n / dnB.d;

        return aN-bN;
    };

    try {
        return portionAsString.sort(compare);  
    }
    catch (error) {
        // tslint:disable-next-line:no-console
        console.error(`${JSON.stringify(error)} sorting ${JSON.stringify(portionAsString)}`);
    }

    return portionAsString;
};

export const sortPortionRanges = (ranges: IPortionRangeMap): IPortionRangeMap => {
    for (const rK in ranges) {
        if (rK) {
            const r: IPortionRange = ranges[rK];
            r.portionAsString = sortPortionAsStrings(r.portionAsString);
            r.portion.sort();
        }
    } 
    return ranges;
}

