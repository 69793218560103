import {
    loadAllMenuCycles,
    getCurrentCycle,
    loadCycleMap
} from './loadMenuCycle';
import { loadMenuDataByCycle } from './loadMenuData';
import {
    loadMenuExtras,
    MENU_SIDES_PATH,
    MENU_DESSERTS_PATH
} from './loadMenuExtras';
import { loadMenuAllergens } from './loadMenuAllergens';
import {
    loadMenuSession,
    MENU_LUNCHES_PATH,
    MENU_TEAS_PATH
} from './loadMenuSession';
import {
    IMenuExtraMap,
    IMenuExtra
} from '../../App/interfaces/IMenuExtra';
import { IMenuAllergenMap, IMenuAllergyMap } from '../../App/interfaces/IMenuAllergen';
import { IMenuMealMap } from '../../App/interfaces/IMenuMeal';
import {
    IAllMenuCycleMap,
    IMenuCycleDefinitionMap
} from '../../App/interfaces/IMenuCycle';
import { IMenuDataByCycleMap } from '../../App/interfaces/IMenuData';
import { loadAllergies } from './loadAllergies';

const addJoggerMultiplier = (data: Readonly<IMenuExtraMap>): void => {
    for (const k in data) {
        if (k) {
            const item: IMenuExtra = data[k];
            if (item && item.joggerMultiplier === undefined) {
                item.joggerMultiplier = 1;
            }
        }
    }
};

const addIsAlternative = (data: Readonly<IMenuExtraMap>): void => {
    for (const k in data) {
        if (k) {
            const item: IMenuExtra = data[k];
            if (item && item.isAlternative === undefined) {
                item.isAlternative = false;
            }
        }
    }
};

const addIsJoggerMultiplierGrams = (data: Readonly<IMenuExtraMap>): void => {
    for (const k in data) {
        if (k) {
            const item: IMenuExtra = data[k];
            if (item && item.isJoggerMultiplierGrams === undefined) {
                item.isJoggerMultiplierGrams = false;
            }
        }
    }
};

const addEmptyAllergens = (data: Readonly<IMenuExtraMap>): void => {
    for (const k in data) {
        if (k) {
            const item: IMenuExtra = data[k];
            if (item && item.allergens === undefined) {
                item.allergens = [];
            }
        }
    }
};

export interface IAllMenuDataRecords {
    currentMenuCycle: string;
    menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap>;
    allMenuCycles: Readonly<IAllMenuCycleMap>;
    menuSides: Readonly<IMenuExtraMap>;
    menuDesserts: Readonly<IMenuExtraMap>;
    menuAllergens: Readonly<IMenuAllergenMap>;
    menuLunches: Readonly<IMenuMealMap>;
    menuTeas: Readonly<IMenuMealMap>;
    menuDataByCycle: Readonly<IMenuDataByCycleMap>;
    allergies: Readonly<IMenuAllergyMap>;
}

export const loadAllMenuDataRecords = async (): Promise<Readonly<IAllMenuDataRecords>> => {
    const allMenuCycles: Readonly<IAllMenuCycleMap> = await loadAllMenuCycles();
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = await loadMenuDataByCycle();
    const menuSides: Readonly<IMenuExtraMap> = await loadMenuExtras(MENU_SIDES_PATH);
    const menuDesserts: Readonly<IMenuExtraMap> = await loadMenuExtras(MENU_DESSERTS_PATH);
    const menuAllergens: Readonly<IMenuAllergenMap> = await loadMenuAllergens();
    const menuLunches: Readonly<IMenuMealMap> = await loadMenuSession(MENU_LUNCHES_PATH);
    const menuTeas: Readonly<IMenuMealMap> = await loadMenuSession(MENU_TEAS_PATH);
    const currentMenuCycle: string = await getCurrentCycle();
    const menuCycleDefinitionMap: IMenuCycleDefinitionMap = await loadCycleMap();
    const allergies: IMenuAllergyMap = await loadAllergies();

    addJoggerMultiplier(menuLunches);
    addJoggerMultiplier(menuDesserts);
    addJoggerMultiplier(menuSides);
    addJoggerMultiplier(menuTeas);

    addIsAlternative(menuLunches);
    addIsAlternative(menuDesserts);
    addIsAlternative(menuSides);
    addIsAlternative(menuTeas);

    addIsJoggerMultiplierGrams(menuLunches);
    addIsJoggerMultiplierGrams(menuDesserts);
    addIsJoggerMultiplierGrams(menuSides);
    addIsJoggerMultiplierGrams(menuTeas);

    addEmptyAllergens(menuLunches);
    addEmptyAllergens(menuDesserts);
    addEmptyAllergens(menuSides);
    addEmptyAllergens(menuTeas);
    
    const result: IAllMenuDataRecords = {
        allMenuCycles,
        currentMenuCycle,
        menuCycleDefinitionMap,
        menuDataByCycle,
        menuSides,
        menuDesserts,
        menuAllergens,
        menuLunches,
        menuTeas,
        allergies
    };

    return result;
};