import {
    db,
} from '../../Storage';
import { IMenuAllergyMap } from '../../App/interfaces/IMenuAllergen';

export const ALLERGIES_PATH: string = `data/food/menu_allergy`;

export const loadAllergies = (): Promise<Readonly<IMenuAllergyMap>> => {
    return new Promise((resolve, reject) => {
        db.child(ALLERGIES_PATH).once('value')
        .then( (snapshot) => {
            const values = snapshot.val();
            const result: IMenuAllergyMap = {};
            for (const k in values) {
                if (k) {
                    result[k] = values[k];
                }
            }

            resolve(result);
        })
        .catch ( (error) => {
            console.error(`loadAllergies ${JSON.stringify(error)}`);
            reject(error);
        })
    });
};