import { getYear, getMonth, getMonthsBetweenDates, subYears } from '../../Common/utils/dateFunctions';

export const DELIVERY_REPORTS: string = `reports/delivery`;
export const FOODCHECK_REPORTS: string = `reports/foodcheck`;
export const DIARYCHECK_REPORTS: string = `reports/checks`;
export const PREREQUISITE_REPORTS: string = `reports/prerequisite`;

export const mapMonth = (month: number): string => {
    switch (month) {
        case 0:
            return `Jan`;
        case 1:
            return `Feb`;
        case 2:
            return `Mar`;
        case 3:
            return `Apr`;
        case 4:
            return `May`;
        case 5:
            return `Jun`;
        case 6:
            return `Jul`;
        case 7:
            return `Aug`;
        case 8:
            return `Sep`;
        case 9:
            return `Oct`;
        case 10:
            return `Nov`;
    }

    return `Dec`;
};

export const getReportQueryLabel = (m: number, y: number): string => {
    return  `${mapMonth(m)}-${y}`;
};

export interface IReportQuery {
    month: number;
    year: number;
}
// needs to be done correctly will work for one year
export const getReportQueries = (): IReportQuery[] => {
    const today: Date = new Date();
    const start: Date = subYears(today, 1);

    const months: Date[] = getMonthsBetweenDates(start, today);
    const queries: IReportQuery[] = [];
    for (const m of months) {
        const nowYear: number = getYear(m);
        const nowMonth: number = getMonth(m);
        queries.push({
            month: nowMonth,
            year: nowYear
        });
    }

    
    return queries;
};

export interface IReportsStorageMap {
    [key: string]: string;
}