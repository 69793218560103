import {
    db,
} from '../../Storage';
import { IMenuAllergenMap } from '../../App/interfaces/IMenuAllergen';

export const MENU_ALLERGENS_PATH: string = `data/food/menu_allergens`;

export const loadMenuAllergens = (): Promise<Readonly<IMenuAllergenMap>> => {
    return new Promise((resolve, reject) => {
        db.child(MENU_ALLERGENS_PATH).once('value')
        .then( (snapshot) => {
            const values = snapshot.val();
            const result: IMenuAllergenMap = {};
            for (const k in values) {
                if (k) {
                    result[k] = values[k];
                }
            }

            resolve(result);
        })
        .catch ( (error) => {
            console.error(`loadMenuAllergens ${JSON.stringify(error)}`);
            reject(error);
        })
    });
};