import {
    call,
    put,
    take,
    delay,
    select,
} from 'redux-saga/effects';

import 'firebase/auth';

import {
    actions,
    TypeKeys
} from '../store/menuBundle';

import {
    actions as app,
} from '../../App/store/appBundle';
import { 
    getLoggedInUser,
    getLocalState as appLocalState
} from '../../App/store/appSelectors';

import {
    actions as pdfsLinks,
    TypeKeys as PdfsLinksTypeKeys
} from '../../App/store/pdfsLinksBundle';

import {
    db,
} from '../../Storage';

import {
    IMeal,
    IMealAllergens
} from '../../App/interfaces/IMeal';
import { 
    IMenuAllergyMap
} from '../../App/interfaces/IMenuAllergen';
import {
    IMenu,
    IMenuMap,
    IMenuMapByCycle
} from '../../App/interfaces/IMenu';
import { IMenuAllergenMap } from '../../App/interfaces/IMenuAllergen';
import {
    IMenuData,
    IMenuDataByCycleMap,
    IMenuDataMap,
    IMenuDataSession
} from '../../App/interfaces/IMenuData';

import {
    IMenuExtra,
    IMenuExtraMap
} from '../../App/interfaces/IMenuExtra';

import {
    IMenuMeal,
    IMenuMealMap,
    menuItemNotSelected
} from '../interfaces/IMenuMeal';

import {
    IAllMenuCycleMap,
    IMenuCycleDefinitionMap,
    CurrentCyclePastMenus,
} from '../../App/interfaces/IMenuCycle';

import { IAllMenuData, IMenuItemIdToIsJoggerGramsMap, IMenuItemIdToLabelMap } from '../interfaces/IAllMenuData';
import { 
    getEditMenuCycleId,
    isItemInAnyRules,
    getLocalState as menuLocalState
} from '../store/menuSelectors';

import {
    IUrlData,
    IUrlDataMap
} from '../../Common/interfaces/IUrlData';

import { getAllergensFromMenu } from '../../Common/utils/getAllergensFromMenu';
import { loadAllMenuDataRecords } from '../../App/dataservices/loadAllMenuDataRecords';
import { MENU_DATA_BY_CYCLE_PATH } from '../../App/dataservices/loadMenuData';
import {
    IDietaryCode,
    DietaryCodesDataPath,
    DietaryCodesAllUsers
} from '../../App/interfaces/IDietaryCodes';

import { IUser } from '../../Auth/interfaces/IUser';
import {
    IAlternativeMap,
    AlternativePath, IAlternative
} from '../../App/interfaces/IAlternatives';
import {
    IPortionRangeMap,
    IPortionRuleMap,
    PortionRangePath,
    PortionRulePath,
    sortPortionRanges
} from '../../App/interfaces/IPortionRules';
import { loadAlternativeMealMap } from '../../App/dataservices/alternativeMenuData';
import { IAppState } from '../../App/interfaces/IAppState';
import { IMenuState } from '../../App/interfaces/IMenuState';
import { IAlternativeMealDataMap } from '../interfaces/IAlternativeMealData';

const loadPortionRuleData = async (): Promise<Readonly<IPortionRuleMap>> => {
    const rulesSnap = await db.child(PortionRulePath).once('value');
    const rules: IPortionRuleMap = rulesSnap.val();
    return rules;
};

const loadPortionRangeData = async (): Promise<Readonly<IPortionRangeMap>> => {
    const rangesSnap = await db.child(PortionRangePath).once('value');
    const ranges: IPortionRangeMap = sortPortionRanges(rangesSnap.val());
    return ranges;
};

export function* appLoadPortionRuleData() {
    while (true) {
        yield take(TypeKeys.PORTION_RULE_REQ);
        try {
            const portionRuleData:Readonly<IPortionRuleMap> = yield call(loadPortionRuleData);
            const portionRangeData: Readonly<IPortionRangeMap> = yield call(loadPortionRangeData);
            yield put(actions.portionRuleDataRes({rules: portionRuleData, ranges: portionRangeData}));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

const dietaryForUser = (user: Readonly<IUser>, allowedUsers: string[]): boolean => {
    if (user.admin) {
        return true;
    }
    if (allowedUsers && allowedUsers.length) {
        for (const uid of allowedUsers) {
            const orgs: string[] = user.orgs !== undefined ? Object.keys(user.orgs) : [uid];
            if (uid === DietaryCodesAllUsers || orgs.indexOf(uid) !== -1) {
                return true;
            }
        }
    }

    return false;
};

const getDietaryCodes = async (state: IAppState): Promise<ReadonlyArray<IDietaryCode>> => {
    const snapshot = await db.child(DietaryCodesDataPath).once('value');
    const items = snapshot.val();
    const codes: IDietaryCode[] = [];
    const user: Readonly<IUser> | undefined = getLoggedInUser(state);
    if (user) {
        for (const code in items) {
            if (code) {
                const item: IDietaryCode = {
                    code,
                    ...items[code]
                }
                if (dietaryForUser(user, item.users)) {
                    codes.push(item);
                }
            }
        }
    }
    return codes;
};

export function* appDietaryCodesSaga() {
    while (true) {
        yield take(TypeKeys.DIETARY_CODES_REQ);
        try {
            const state: IAppState = yield select(appLocalState);
            const codes: ReadonlyArray<IDietaryCode> = yield call(getDietaryCodes, state);
            yield put(actions.dietaryCodesRes(codes));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

const loadAlternatives = async (): Promise<Readonly<IAlternativeMap>> => {
    const snapshot = await db.child(AlternativePath).once('value');
    const alternatives = snapshot.val();
    return alternatives;
};

export function* appLoadAlternatives() {
    while (true) {
        yield take(TypeKeys.ALTERNATIVES_REQ);
        try {
            const alternatives:Readonly<IAlternativeMap> = yield call(loadAlternatives);
            for (const k in alternatives) {
                const alt: IAlternative = alternatives[k];
                if (alt && alt.dietaries === undefined) {
                    alt.dietaries = {};
                }
            }
            yield put(actions.alternativesRes(alternatives));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

export function* appLoadAlternativeMenuMap() {
    while (true) {
        yield take(TypeKeys.ALTERNATIVE_MEAL_MAP_REQ);
        try {
            const alternativeMenuMap: Readonly<IAlternativeMealDataMap> = yield call(loadAlternativeMealMap);
            yield put(actions.alternativeMealMapRes(alternativeMenuMap));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

const writeMenuItem = async (payload: IMenuData, state: IMenuState): Promise<void> => {
    const cycle: string = getEditMenuCycleId(state);
    await db.child(`${MENU_DATA_BY_CYCLE_PATH}/${cycle}/${payload.id}`).update(payload);
};

export function* appSaveMenuData() {
    while (true) {
        const { payload } = yield take(TypeKeys.SAVE_MENU_EDIT_DATA_REQ);
        try {
            const state: IMenuState = yield(select(menuLocalState));
            yield call(writeMenuItem, payload, state);
            yield put(actions.menusReq(true));
        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}

const getSidesFromSession = (session: IMenuDataSession, menuSides: Readonly<IMenuExtraMap>): string[] => {
    const sides = session.sides;
    const result: string[] = [];
    if (sides) {
        for (const side of sides) {
            const menuSide: IMenuExtra | undefined = menuSides[side];
            if (menuSide) {
                result.push(menuSide.item);
            }
        }
    }

    return result;
};

const getDessertsFromSession = (session: IMenuDataSession, menuDesserts: Readonly<IMenuExtraMap>): string[] => {
    const desserts = session.desserts;
    const result: string[] = [];
    if (desserts) {
        for (const dessert of desserts) {
            const menuDessert: IMenuExtra | undefined = menuDesserts[dessert];
            if (menuDessert) {
                result.push(menuDessert.item);
            }
        }
    }

    return result;
};

const getDisplayMenusFromData = (menuData: Readonly<IMenuDataMap>, menuLunches: Readonly<IMenuMealMap>, menuTeas: Readonly<IMenuMealMap>, menuSides: Readonly<IMenuExtraMap>, menuDesserts: Readonly<IMenuExtraMap>, menuAllergens: Readonly<IMenuAllergenMap>): IMenuMap => {

    const menus: IMenuMap = {};

    Object.keys(menuData).forEach((k) => {
        
        const m: IMenuData = menuData[parseInt(k)];
        const lunch: IMenuDataSession = m.lunch;

        const ln: IMenuMeal | undefined = menuLunches[lunch.normal];
        
        const allergensLunch: IMealAllergens = getAllergensFromMenu(ln, lunch.sides, lunch.desserts, menuSides, menuDesserts, menuAllergens);

        const lunchMeal: IMeal = {
            allergens: allergensLunch,
            desserts: getDessertsFromSession(lunch, menuDesserts),
            normal: ln !== undefined ? ln.item : menuItemNotSelected,
            session: 'lunch',
            sides: getSidesFromSession(lunch, menuSides),
        };

        const tea = m.tea;
        const tn: IMenuMeal | undefined = menuTeas[tea.normal];
        
        const allergensTea: IMealAllergens = getAllergensFromMenu(tn, tea.sides, tea.desserts, menuSides, menuDesserts, menuAllergens);

        const teaMeal: IMeal = {
            allergens: allergensTea,
            desserts: getDessertsFromSession(tea, menuDesserts),
            normal: tn !== undefined ? tn.item : menuItemNotSelected,
            session: 'tea',
            sides: getSidesFromSession(tea, menuSides),
        };

        const menu: IMenu = {
            id: m.id,
            lunch: lunchMeal,
            tea: teaMeal,
        };

        menus[m.id] = menu;

    });
    return menus;
};

const loadAllMenus = async (): Promise<IAllMenuData> => {

    const result = await loadAllMenuDataRecords();
    const portionRuleData = await loadPortionRuleData();
    const currentMenuCycle: string = result.currentMenuCycle;
    const allMenuCycles: Readonly<IAllMenuCycleMap> = result.allMenuCycles;
    const menuDataByCycle: Readonly<IMenuDataByCycleMap> = result.menuDataByCycle;
    const menuSides: Readonly<IMenuExtraMap> = result.menuSides;
    const menuDesserts: Readonly<IMenuExtraMap> = result.menuDesserts;
    const menuAllergens: Readonly<IMenuAllergenMap> = result.menuAllergens;
    const menuLunches: Readonly<IMenuMealMap> = result.menuLunches;
    const allergies: Readonly<IMenuAllergyMap> = result.allergies;
    const menuTeas: Readonly<IMenuMealMap> = result.menuTeas;
    const menuCycleDefinitionMap: Readonly<IMenuCycleDefinitionMap> = result.menuCycleDefinitionMap;

    const displayMenusByCycle: IMenuMapByCycle = {};
    for (const c in allMenuCycles) {
        if (c) {
            if (c === CurrentCyclePastMenus) {
                displayMenusByCycle[c] = getDisplayMenusFromData(menuDataByCycle[currentMenuCycle], menuLunches, menuTeas, menuSides, menuDesserts, menuAllergens);
            }
            else {
                displayMenusByCycle[c] = getDisplayMenusFromData(menuDataByCycle[c], menuLunches, menuTeas, menuSides, menuDesserts, menuAllergens);
            }
            
        }
    }

    const menuItemIdToLabel: IMenuItemIdToLabelMap = {};
    for (const k in menuSides) {
        menuItemIdToLabel[k] = menuSides[k].item;
    }
    for (const k in menuDesserts) {
        menuItemIdToLabel[k] = menuDesserts[k].item;
    }
    for (const k in menuLunches) {
        menuItemIdToLabel[k] = menuLunches[k].item;
    }
    for (const k in menuTeas) {
        menuItemIdToLabel[k] = menuTeas[k].item;
    }
    for (const k in menuAllergens) {
        menuItemIdToLabel[k] = menuAllergens[k].item;
    }

    const isJoggerMultiplierGramsMap: IMenuItemIdToIsJoggerGramsMap = {};
    for (const k in menuSides) {
        isJoggerMultiplierGramsMap[k] = isItemInAnyRules(k, portionRuleData) ? false : menuSides[k].isJoggerMultiplierGrams;
    }
    for (const k in menuDesserts) {
        isJoggerMultiplierGramsMap[k] = isItemInAnyRules(k, portionRuleData) ? false : menuDesserts[k].isJoggerMultiplierGrams;
    }
    for (const k in menuLunches) {
        isJoggerMultiplierGramsMap[k] = isItemInAnyRules(k, portionRuleData) ? false : menuLunches[k].isJoggerMultiplierGrams;
    }
    for (const k in menuTeas) {
        isJoggerMultiplierGramsMap[k] = isItemInAnyRules(k, portionRuleData) ? false : menuTeas[k].isJoggerMultiplierGrams;
    }

    const payload: IAllMenuData = {
        allMenuCycles,
        currentMenuCycle,
        menuAllergens,
        menuCycleDefinitionMap,
        menuDataByCycle,
        menuDesserts,
        menuLunches,
        menuSides,
        menuTeas,
        displayMenusByCycle,
        menuItemIdToLabel,
        isJoggerMultiplierGramsMap,
        allergies
    };

    return payload;
};


export function* appLoadAllMenus() {
    while (true) {
        try {
            const { doNotDelay } = yield take(TypeKeys.LOAD_ALL_MENUS_REQ);
            if (doNotDelay) {
                const menus: IAllMenuData = yield call(loadAllMenus);
                yield put(actions.menusRes(menus));
            }
            else {
                const menus: IAllMenuData = yield call(loadAllMenus);
                yield delay(4000);
                yield put(actions.menusRes(menus));
            }
        }
        catch (error) {
            console.error(error);
            yield put(app.appErr(error));
        }
    }
}


//  should be in it's own vertical saga
const loadAllPdfUrls = async (): Promise<IUrlDataMap> => {
    const menuUrlsSnap = await db.child('menu_urls').once('value');
    const urls = menuUrlsSnap.val();
    const menuPdfUrls: IUrlDataMap = {};
    for (const c in urls) {
        if (c) {
            const cycleUrls = urls[c];
            if (cycleUrls) {
                for (const wm in cycleUrls) {
                    if (wm) {
                        const urlData: IUrlData | undefined = cycleUrls[wm];
                        if (urlData) {
                            const path: string = `${c}/${wm}`;
                            menuPdfUrls[path] = urlData;
                        }
                    }
                }
            }
        }
    }
    return menuPdfUrls;
};

export function* appLoadAllPdfUrls() {
    while (true) {
        yield take(PdfsLinksTypeKeys.LOAD_ALL_PDF_URLS_REQ);

        try {
            const urls: IUrlDataMap = yield call(loadAllPdfUrls);
            yield put(pdfsLinks.loadAllPdfUrlsRes(urls));

        } catch (error) {
            //  Failure
            yield put(app.appErr(error));
        }
    }
}
