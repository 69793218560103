import {
    call,
    fork,
    put,
    race,
    take,
    select,
    PutEffect
} from 'redux-saga/effects';

import {
    EventChannel,
    eventChannel
} from 'redux-saga';

import { orderActivityLogConverter, orderConverter, queryConfig, queryOrderActivityLogsByDate, queryOrderActivityLogsByDateAndUids, queryOrderActivityLogsByDateLatest, queryOrdersByDate, queryOrdersByUidAndDate } from '../dataservices/firestore';

import {
    actions,
    TypeKeys
} from '../store';
import { IOrderPayload } from '../interfaces/IDayOrder';
import { IRegAllOrderLogsReq, IRegAllOrdersReq, IRegUserOrderLogsReq, IRegUserOrdersReq } from '../store/appBundle';
import { IOrderActivityLog, IOrderActivityLogPayload } from '../interfaces/IOrderActivityLog';
import { IAppState } from '../interfaces/IAppState';
import { getLocalState, getOrderActivitySubscriptionMade } from '../store/appSelectors';
import { IAppConfig } from '../interfaces/IAppConfig';


function* processChannelAction(action: any): Generator<PutEffect<any>, void, unknown> {
    yield put(action);
}

const loadAppConfig = async (): Promise<Readonly<IAppConfig>> => {
    const ref = queryConfig();
    const snapshot = await ref.get();
    const config = snapshot.docs[0].data() as IAppConfig;
    return config;
};

export function* appLoadAppConfig() {
    while (true) {
        yield take(TypeKeys.APP_CONFIG_REQ);

        try {
            const config: Readonly<IAppConfig> = yield call(loadAppConfig);
            yield put(actions.appConfigRes(config));

        } catch (error) {
            //  Failure
            yield put(actions.appErr(error));
        }
    }
}


export function userOrderLogsChannel(filterDate: number, uids: string[]): EventChannel<unknown> {
    const ref = queryOrderActivityLogsByDateAndUids(uids, filterDate);
    let cleanUp: Function | undefined = undefined;
    const channel = eventChannel(emit => {
        cleanUp = ref.withConverter(orderActivityLogConverter).onSnapshot((snap) => {
            snap.docChanges().forEach((change) => {
                if (change.type !== `removed`) {
                    const payload: IOrderActivityLogPayload = {
                        orderLog: change.doc.data(),
                        type: change.type
                    };
                    emit({
                        payload,
                        type: TypeKeys.REG_ALL_ORDER_LOGS_RES,
                    });
                }
            });
        });

        const unsubscribe = () => {
            if (cleanUp) {
                cleanUp();
            }
        }

        return unsubscribe;
    });

    return channel;
};

export function* appUserOrderLogsSaga() {
    while (true) {
        const action: IRegUserOrderLogsReq = yield take(TypeKeys.REG_USER_ORDER_LOGS_REQ);

        const state: IAppState = yield select(getLocalState);
        const alreadySubscribed: boolean = getOrderActivitySubscriptionMade(state);

        if (alreadySubscribed) {
            continue;
        }
        console.log(`Subscribing to order activity logs`);
        yield put(actions.orderLogsSubscribedReq());

        const channel: EventChannel<unknown> = yield call(userOrderLogsChannel, action.fromDate, action.uids);
        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_USER_ORDER_LOGS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
    }
}

export function allOrderLogsChannel(filterDate: number): EventChannel<unknown> {
    const ref = queryOrderActivityLogsByDateLatest(filterDate);
    let cleanUp: Function | undefined = undefined;
    const channel = eventChannel(emit => {
        cleanUp = ref.withConverter(orderActivityLogConverter).onSnapshot((snap) => {
            snap.docChanges().forEach((change) => {
                if (change.type !== `removed`) {
                    const payload: IOrderActivityLogPayload = {
                        orderLog: change.doc.data(),
                        type: change.type
                    };
                    console.log(`allOrderLogsChannel emitting ${JSON.stringify(payload)}`);
                    emit({
                        payload,
                        type: TypeKeys.REG_ALL_ORDER_LOGS_RES,
                    });
                }
            });
        });

        const unsubscribe = () => {
            if (cleanUp) {
                cleanUp();
            }
        }

        return unsubscribe;
    });

    return channel;
};

const getLogsSnapshot = async (fromDate: number) => {
    const ref = queryOrderActivityLogsByDate(fromDate);
    const logs = await ref.withConverter(orderActivityLogConverter).get();
    return logs.docs.map((d) => d.data());
};

export function* appAllOrderLogsSaga() {
    while (true) {
        const action: IRegAllOrderLogsReq = yield take(TypeKeys.REG_ALL_ORDER_LOGS_REQ);

        const state: IAppState = yield select(getLocalState);
        const alreadySubscribed: boolean = getOrderActivitySubscriptionMade(state);

        if (alreadySubscribed) {
            continue;
        }
        console.log(`Subscribing to order activity logs`);
        yield put(actions.orderLogsSubscribedReq());

        //  get snapshot of all logs....
        const logs: IOrderActivityLog[] = yield call(getLogsSnapshot, action.fromDate);
        yield put(actions.regAllOrderLogsSnapshotRes(logs));

        //  listen only to the latest logs
        const channel: EventChannel<unknown> = yield call(allOrderLogsChannel, action.fromDate);
        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_ALL_ORDER_LOGS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
    }
}

export function allOrdersChannel(filterDate: number): EventChannel<unknown> {
    const ref = queryOrdersByDate(filterDate);
    let cleanUp: Function | undefined = undefined;
    const channel = eventChannel(emit => {
        cleanUp = ref.withConverter(orderConverter).onSnapshot((snap) => {
            snap.docChanges().forEach((change) => {
                const payload: IOrderPayload = {
                    order: change.doc.data(),
                    type: change.type
                };
                emit({
                    payload,
                    type: TypeKeys.REG_ALL_ORDERS_RES,
                });
            });
        });

        const unsubscribe = () => {
            if (cleanUp) {
                cleanUp();
            }
        }

        return unsubscribe;
    });

    return channel;
};

export function* appAllOrdersSaga() {
    while (true) {
        const action: IRegAllOrdersReq = yield take(TypeKeys.REG_ALL_ORDERS_REQ);
        const channel: EventChannel<unknown> = yield call(allOrdersChannel, action.fromDate);
        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_ALL_ORDERS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
    }
}

export function userOrdersChannel(filterDate: number, uids: string[]): EventChannel<unknown> {
    //  should be a parameter the date    
    const ref = queryOrdersByUidAndDate(uids, filterDate);
    let cleanUp: Function | undefined = undefined;
    const channel = eventChannel(emit => {
        cleanUp = ref.withConverter(orderConverter).onSnapshot((snap) => {
            snap.docChanges().forEach((change) => {
                const payload: IOrderPayload = {
                    order: change.doc.data(),
                    type: change.type
                };
                emit({
                    payload,
                    type: TypeKeys.REG_ALL_ORDERS_RES,
                });
            });
        });

        const unsubscribe = () => {
            if (cleanUp) {
                cleanUp();
            }
        }

        return unsubscribe;
    });

    return channel;
};
export function* appUserOrdersSaga() {
    while (true) {
        const action: IRegUserOrdersReq = yield take(TypeKeys.REG_USER_ORDERS_REQ);
        const channel: EventChannel<unknown> = yield call(userOrdersChannel, action.fromDate, action.uids);
        try {
            while (true) {
                const { cancel, action } = yield race({
                    action: take(channel),
                    cancel: take(TypeKeys.UREG_USER_ORDERS_REQ)
                });
                if (cancel) {
                    channel.close();
                    break;
                }
                else if (action) {
                    yield fork(processChannelAction, action);
                }
            }
        }
        catch (error) {
            yield put(actions.appErr(error))
        }
    }
}


