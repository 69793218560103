import { IKitchenMenuPortions } from '../interfaces/IKitchenMenuPortions';


export const sumSidesByCustomer = (sides: ReadonlyArray<IKitchenMenuPortions>): ReadonlyArray<IKitchenMenuPortions> => {
    const sum: ReadonlyArray<IKitchenMenuPortions> = sides.reduce((total: IKitchenMenuPortions[], current: IKitchenMenuPortions) => {
        const side: IKitchenMenuPortions | undefined = total.find( (s) => s.id === current.id && s.date === current.date && s.uid === current.uid );
        if (side) {
            side.total = current.total + side.total;
            side.meals = current.meals + side.meals;
        }
        else {
            const copySide: IKitchenMenuPortions = {
                ...current
            }
            total.push(copySide);
        }
        return total;
    }, []);

    return sum;
};

export const sumSidesByDate = (sides: ReadonlyArray<IKitchenMenuPortions>): ReadonlyArray<IKitchenMenuPortions> => {
    const sum: ReadonlyArray<IKitchenMenuPortions> = sides.reduce((total: IKitchenMenuPortions[], current: IKitchenMenuPortions) => {
        const side: IKitchenMenuPortions | undefined = total.find( (s) => s.id === current.id && s.date === current.date);
        if (side) {
            side.total = current.total + side.total;
            side.meals = current.meals + side.meals;
        }
        else {
            const copySide: IKitchenMenuPortions = {
                ...current
            }
            total.push(copySide);
        }
        return total;
    }, []);

    return sum;
};