import {
    db,
} from '../../Storage';
import { IMenuMealMap } from '../../App/interfaces/IMenuMeal';

export const MENU_LUNCHES_PATH: string = 'data/food/menu_lunches';
export const MENU_TEAS_PATH: string = 'data/food/menu_teas';

export const loadMenuSession = (path: string): Promise<Readonly<IMenuMealMap>> => {
    return new Promise((resolve, reject) => {
        db.child(path).once('value')
        .then( (snapshot) => {
            const values = snapshot.val();
            const result: IMenuMealMap = {};
            for (const k in values) {
                if (k) {
                    result[k] = values[k];
                }
            }

            resolve(result);
        })
        .catch ( (error) => {
            console.error(`loadMenuSession ${path} ${JSON.stringify(error)}`);
            reject(error);
        })
    });
};