import { IPdfsLinksState } from '../../App/interfaces/IPdfsLinksState';
import { IUrlDataMap } from '../../Common/interfaces/IUrlData';
import { pdfsLinksInitialState } from './pdfsLinksInitialState';

export enum TypeKeys {
    LOAD_ALL_PDF_URLS_REQ = 'APP/LOAD_ALL_PDF_URLS_REQ',
    LOAD_ALL_PDF_URLS_RES = 'APP/LOAD_ALL_PDF_URLS_RES',
};

export interface ILoadAllPdfUrlsReq {
    type: TypeKeys.LOAD_ALL_PDF_URLS_REQ;
}

export interface ILoadAllPdfUrlsRes {
    type: TypeKeys.LOAD_ALL_PDF_URLS_RES;
    menuPdfUrls: IUrlDataMap;
}

export const actions = {

    loadAllPdfUrlsReq(): ILoadAllPdfUrlsReq {
        const action: ILoadAllPdfUrlsReq = { type: TypeKeys.LOAD_ALL_PDF_URLS_REQ };
        return action;
    },

    loadAllPdfUrlsRes(menuPdfUrls: IUrlDataMap): ILoadAllPdfUrlsRes {
        const action: ILoadAllPdfUrlsRes = { type: TypeKeys.LOAD_ALL_PDF_URLS_RES, menuPdfUrls };
        return action;
    },
};

type TActionTypes =
    ILoadAllPdfUrlsRes
;

const subReducers = {

    loadAllPdfUrlsRes(state: IPdfsLinksState, action: ILoadAllPdfUrlsRes): IPdfsLinksState {

        const result: IPdfsLinksState = {
            ...state,
            allMenuPdfUrls: action.menuPdfUrls,
        };

        return result;
    },
};

const reducer = (state: IPdfsLinksState = pdfsLinksInitialState(), action: TActionTypes): IPdfsLinksState => {
    switch (action.type) {
        case TypeKeys.LOAD_ALL_PDF_URLS_RES:
            return subReducers.loadAllPdfUrlsRes(state, action);
        default:
            return state;
    }
};

export default reducer;