import firebase from 'firebase/compat/app';
import {
    call,
    put,
    take,
    fork,
    race,
    select,
    PutEffect
} from 'redux-saga/effects';

import {
    eventChannel
} from 'redux-saga';

import 'firebase/auth';

import {
    actions,
    TypeKeys
} from '../store/checksBundle';

import {
    actions as appActions
} from '../store/appBundle';

import { 
    getLoggedInUser,
    getLocalState as appLocalState
} from '../../App/store/appSelectors';

import {
    db,
} from '../../Storage';

import {
    ICheckDescriberGroupMap,
    ICheckDescriber,
    ICheckGroup,
    CHECK_GROUP_CONFIG,
    DESCRIBER_PATH,
    CUSTOM_CONTROLS_PATH,
    ICustomDropDownsCheckControl,
    CUSTOM_CONTROL_DROPDOWNS,
    CUSTOM_CONTROL_RANGES,
    CUSTOM_CONTROL_INTERNAL_DROPDOWNS,
    ICustomInternalDropdownsCheckControl,
    ICustomRangesCheckControl,
    IAlertRule,
    ICustomItemCheckControl,
    CUSTOM_CONTROL_ITEM_CHECK,
    ICustomAlertCheckControl,
    CUSTOM_CONTROL_ALERT_CHECK,
    IPrerequisite,
    PREREQUISITE_PENDING_PATH,
    ICustomPrereqCheckControl,
    CUSTOM_CONTROL_PREREQ_CHECK,
    getAlertRuleType,
    ICheckGroupCategoryMap,
    defaultForNoCategory,
    ICheckGroupCategoryMaxTabOrderMap,
    compareByCheckGroupThenByTabOrder,
} from '../../App/components/checks/interfaces';
import { isUndefinedOrEmpty } from '../../Common/utils/typeGuards';
import { filterChecksForCategory } from '../../App/utils/checkSortUtils';
import { IAppState } from '../../App/interfaces/IAppState';
import { getUserTypeId, IUser, UserTypeId } from '../../Auth/interfaces/IUser';

function* processChannelAction(action: any):  Generator<PutEffect<any>, void, unknown> {
    yield put(action);
}

const loadAllCheckDescribers = async (user: Readonly<IUser>): Promise<Readonly<{ 
    groupMap: ICheckDescriberGroupMap, 
    groups: ReadonlyArray<ICheckGroup>, 
    allCheckGroupCategories: ICheckGroupCategoryMap,
    maxTabOrderPerCategory: ICheckGroupCategoryMaxTabOrderMap}>> => {

    const snapshot = await db.child(`${DESCRIBER_PATH}`).once('value');
    const groups = snapshot.val();
    const allCheckDescribers: ICheckDescriberGroupMap = {};
    const groupData: Array<ICheckGroup> = [];
    
    const userType = getUserTypeId(user.userType);
    
    for (const g in groups) {   //   g is the diary name e.g. 'opening', 'closing', 'other'
        const group = groups[g];
        for (const id in group) {   //  check id ...
            const category: string | undefined = group[CHECK_GROUP_CONFIG].category;
            const inReport: boolean = group[CHECK_GROUP_CONFIG].inReport !== undefined ? group[CHECK_GROUP_CONFIG].inReport : false;
            const enabled: boolean = group[CHECK_GROUP_CONFIG].enabled !== undefined ? group[CHECK_GROUP_CONFIG].enabled : true;

            if (enabled === true || userType === UserTypeId.Administrator) {
                if (id === CHECK_GROUP_CONFIG) {
                    groupData.push({
                        id: g,
                        name: group[CHECK_GROUP_CONFIG].name,
                        tabOrder: group[CHECK_GROUP_CONFIG].tabOrder,
                        category,
                        inReport,
                        enabled,
                    });
                    continue;
                }
            }
            
            const checkDescriber: ICheckDescriber = group[id];
            if (checkDescriber) {
                if (allCheckDescribers[g]) {
                    allCheckDescribers[g][id] = checkDescriber;
                }
                else {
                    allCheckDescribers[g] = {};
                    allCheckDescribers[g][id] = checkDescriber;
                }
            }
        }
    }

    groupData.sort(compareByCheckGroupThenByTabOrder);

    const allCheckGroupCategories: ICheckGroupCategoryMap = {};
    for (const g of groupData) {
        const cat: string = isUndefinedOrEmpty(g.category) ? defaultForNoCategory : g.category;
        if (cat === defaultForNoCategory) {
            g.category = defaultForNoCategory;
        }

        if (allCheckGroupCategories[cat] === undefined) {
            const catDisplay = cat[0].toLocaleUpperCase() + cat.slice(1);
            allCheckGroupCategories[cat] = catDisplay;
        }
    }
    
    
    // For production reorder them once here. then disable this code
    // for (const c in allCheckGroupCategories) {
    //     const groups: ReadonlyArray<ICheckGroup> = filterChecksForCategory(c, groupData).sort((a: ICheckGroup, b:ICheckGroup) => a.tabOrder - b.tabOrder);
    //     groups.forEach((g, index) => {
    //         g.tabOrder = index;
    //     });

    //     for (const g of groups) {
    //         await updateGroupConfig(g);
    //     }
    // }

    const maxTabOrderPerCategory: ICheckGroupCategoryMaxTabOrderMap = {};
    for (const c in allCheckGroupCategories) {
        const groups: ReadonlyArray<ICheckGroup> = filterChecksForCategory(c, groupData).sort((a: ICheckGroup, b:ICheckGroup) => a.tabOrder - b.tabOrder);
        if (groups.length > 0) {
            maxTabOrderPerCategory[c] = groups[groups.length - 1].tabOrder;
        }
        else {
            maxTabOrderPerCategory[c] = 0;
        }

        // console.log(`groupsPerCategory: ${c} ${groups.map((g) => `'${g.name}' : ${g.tabOrder}`)}`);
    }

    // console.log(`maxTabOrderPerCategory: ${JSON.stringify(maxTabOrderPerCategory)}`);

    return { groupMap: allCheckDescribers, groups: groupData, allCheckGroupCategories, maxTabOrderPerCategory };
};

export function* appLoadAllCheckDescribers() {
    while (true) {
        try {
            yield take(TypeKeys.LOAD_CHECK_DESCRIBERS_REQ);

            const state: IAppState = yield select(appLocalState);
            const user: Readonly<IUser> | undefined = getLoggedInUser(state);
            if (user !== undefined) {
                const result: Readonly<{ groupMap: ICheckDescriberGroupMap, groups: ReadonlyArray<ICheckGroup>, allCheckGroupCategories: ICheckGroupCategoryMap, maxTabOrderPerCategory: ICheckGroupCategoryMaxTabOrderMap }> = yield call(loadAllCheckDescribers, user);
                yield put(actions.loadCheckDescribersRes(result))
            }
            else {
                throw new Error(`appLoadAllCheckDescribers: Logged in user is null`);
            }

            
        } catch (error) {
            //  Failure
            yield put(appActions.appErr(error));
        }
    }
}


const getCustomControlDropdowns = (data: any): Array<ICustomDropDownsCheckControl> => {
    const result: Array<ICustomDropDownsCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const values: string[] = [];
            for (const v in control.values) {
                values.push(control.values[v]);
            }
            const item: ICustomDropDownsCheckControl = {
                id: c,
                name: control.name,
                values
            };

            result.push(item);
        }
    }
    return result;
};


const getCustomControlItemChecks = (data: any): Array<ICustomItemCheckControl> => {
    const result: Array<ICustomItemCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const items: string[] = [];
            for (const v in control.items) {
                items.push(control.items[v]);
            }
            const item: ICustomItemCheckControl = {
                id: c,
                name: control.name,
                checkLabel: control.checkLabel,
                items
            };

            result.push(item);
        }
    }
    return result;
};

const getCustomControlInternalDropdowns = (data: any): Array<ICustomInternalDropdownsCheckControl> => {
    const result: Array<ICustomInternalDropdownsCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const item: ICustomInternalDropdownsCheckControl = {
                id: c,
                name: control.name,
                dataPath: control.dataPath
            };

            result.push(item);
        }
    }
    return result;
};

const getCustomControlRanges = (data: any): Array<ICustomRangesCheckControl> => {
    const result: Array<ICustomRangesCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const rule: IAlertRule | undefined = control.rule !== undefined ? control.rule : undefined;
            if (rule !== undefined) {
                rule.type = getAlertRuleType(rule);
            }

            const protocol: string | undefined = control.protocol !== undefined ? control.protocol : undefined;
            const item: ICustomRangesCheckControl = {
                id: c,
                name: control.name,
                hi: control.hi,
                lo: control.lo,
                rule,
                protocol
            };

            result.push(item);
        }
    }
    return result;
};

const getCustomControlAlertChecks = (data: any): Array<ICustomAlertCheckControl> => {
    const result: Array<ICustomAlertCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const rule: IAlertRule | undefined = control.rule !== undefined ? control.rule : undefined;
            if (rule !== undefined) {
                rule.type = getAlertRuleType(rule);
            }
            const protocol: string | undefined = control.protocol !== undefined ? control.protocol : undefined;
            const item: ICustomAlertCheckControl = {
                id: c,
                name: control.name,
                alertName: control.alertName,
                noAlertName: control.noAlertName,
                rule,
                protocol,
            };

            result.push(item);
        }
    }
    return result;
};

const getCustomControlPrereqChecks = (data: any): Array<ICustomPrereqCheckControl> => {
    const result: Array<ICustomPrereqCheckControl> = [];
    for (const c in data) {
        const control = data[c];
        if (control) {
            const item: ICustomPrereqCheckControl = {
                id: c,
                name: control.name,
                alertName: control.alertName,
                noAlertName: control.noAlertName,
            };

            result.push(item);
        }
    }
    return result;
};

const loadAllCustomDropdowns = async (): Promise<ReadonlyArray<ICustomDropDownsCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_DROPDOWNS}`).once('value');
    const controls = snapshot.val();
    const dropdowns: ICustomDropDownsCheckControl[] = getCustomControlDropdowns(controls);
    return dropdowns;
};

const loadAllCustomItemChecks = async (): Promise<ReadonlyArray<ICustomItemCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_ITEM_CHECK}`).once('value');
    const controls = snapshot.val();
    const deliveries: ICustomItemCheckControl[] = getCustomControlItemChecks(controls);
    return deliveries;
};

const loadAllCustomInternalDropdowns = async (): Promise<ReadonlyArray<ICustomInternalDropdownsCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_INTERNAL_DROPDOWNS}`).once('value');
    const controls = snapshot.val();
    const dropdowns: ICustomInternalDropdownsCheckControl[] = getCustomControlInternalDropdowns(controls);
    return dropdowns;
};

const loadAllCustomRanges = async (): Promise<ReadonlyArray<ICustomRangesCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_RANGES}`).once('value');
    const controls = snapshot.val();
    const ranges: ICustomRangesCheckControl[] = getCustomControlRanges(controls);
    return ranges;
};

const loadAllCustomAlertChecks = async (): Promise<ReadonlyArray<ICustomAlertCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_ALERT_CHECK}`).once('value');
    const controls = snapshot.val();
    const ranges: ICustomAlertCheckControl[] = getCustomControlAlertChecks(controls);
    return ranges;
};

const loadAllCustomPrereqChecks = async (): Promise<ReadonlyArray<ICustomPrereqCheckControl>> => {
    const snapshot = await db.child(`${CUSTOM_CONTROLS_PATH}/${CUSTOM_CONTROL_PREREQ_CHECK}`).once('value');
    const controls = snapshot.val();
    const ranges: ICustomPrereqCheckControl[] = getCustomControlPrereqChecks(controls);
    return ranges;
};

export function* appLoadAllCheckCustomControls() {
    while (true) {
        try {
            yield take(TypeKeys.LOAD_CHECK_CUSTOM_CONTROLS_REQ);
            const dropdowns: ICustomDropDownsCheckControl[] = yield call(loadAllCustomDropdowns);
            const itemChecks: ICustomItemCheckControl[] = yield call(loadAllCustomItemChecks);
            const ranges: ICustomRangesCheckControl[] = yield call(loadAllCustomRanges);
            const internalDropdowns: ICustomInternalDropdownsCheckControl[] = yield call(loadAllCustomInternalDropdowns);
            const alertChecks: ICustomAlertCheckControl[] = yield call(loadAllCustomAlertChecks);
            const prereqChecks: ICustomPrereqCheckControl[] = yield call(loadAllCustomPrereqChecks);
            yield put(actions.loadCheckCustomControlsRes({ dropdowns, itemChecks, internalDropdowns, ranges, alertChecks, prereqChecks }));
        } catch (error) {
            //  Failure
            yield put(appActions.appErr(error));
        }
    }
}

const getPrerequisite = (value: any): IPrerequisite => {
    const diary: any = value.diary !== undefined ? value.diary : {};
    const time: Date = new Date(value.time);
    const result: IPrerequisite = {
        ...value,
        diary,
        time,
    };

    return result;
}

export function prerequisiteReportsChannel(refId: string) {
    const ref: firebase.database.Reference = db.child(refId);
    const channel = eventChannel(emit => {

        ref.on('child_added', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                uid: event.key,
                                eventType: `added`,
                                data: getPrerequisite(event.val()),
                            },
                            type: TypeKeys.REG_PREREQUISITES_EVENTS_RES,
                        }
                    );
                }
            }
        });

        ref.on('child_changed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                uid: event.key,
                                eventType: `updated`,
                                data: getPrerequisite(event.val()),
                            },
                            type: TypeKeys.REG_PREREQUISITES_EVENTS_RES,
                        }
                    );
                }
            }
        });

        ref.on('child_removed', event => {
            if (event) {
                if (event.key) {
                    emit(
                        {
                            payload: {
                                uid: event.key,
                                eventType: `deleted`,
                                data: {},
                            },
                            type: TypeKeys.REG_PREREQUISITES_EVENTS_RES,
                        }
                    );
                }
            }
        });

        const unsubscribe = () => {
            ref.off('value');
        }
        return unsubscribe;
    });
    return channel;
};


export function* appPrerequisiteSaga() {
    while (true) {

        yield take(TypeKeys.REG_PREREQUISITES_EVENTS_REQ);

        const prerequisite = yield call(prerequisiteReportsChannel, PREREQUISITE_PENDING_PATH);

        try {
            while (true) {
                const { cancel, actionPrerequisites } = yield race({
                    actionPrerequisites: take(prerequisite),
                    cancel: take(TypeKeys.UREG_PREREQUISITES_EVENTS_REQ)
                });
                if (cancel) {
                    prerequisite.close();
                    break;
                }
                else if (actionPrerequisites) {
                    yield fork(processChannelAction, actionPrerequisites);
                }
            }
        }
        catch (error) {
            yield put(appActions.appErr(error))
        }
    }
}

