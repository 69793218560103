import { IDBManager } from "../Common/utils/IDBManager";
import { format } from '../Common/utils/dateFunctions';

import {
    db,
    storage,
} from '../Storage';

import { N_DATE_TIME_STORAGE_KEY_FORMAT } from '../Common/utils/dateFunctions';
import {
    SAVE_SIGNATURE_FORMAT,
    getDeliverySignatureStoragePath,
    getFoodCheckSignatureStoragePath,
    getChecksGroupSignatureStoragePath,
    getPrerequisiteSignatureStoragePath
} from "../App/utils/saveSignature";

import {
    uploadStringData,
    saveUrl
} from "../App/utils/dataUpload";

const offlineStore: IDBManager = new IDBManager('earlyyears-portal', 'offlineStore', 2);

export const initialiseOfflineStore = (): void => {
    offlineStore.initialise()
        .then(() => {
            console.log(`initialiseOfflineStore: Created DB ${offlineStore.getDbName()} Store ${offlineStore.getDbStore()}`);
        })
        .catch((error) => {
            alert("Local Database error: " + JSON.stringify(error));
        });
}

export interface IConfirmStorageData {
    dateTime: string;
    data: string;
    additionalData: any | undefined;
}

export const uploadFromCache = async (): Promise<void> => {
    const keys: IDBValidKey[] = await offlineStore.getKeysFromStore();
    console.log(`offlineStore.uploadFromCache: Keys found:  ${JSON.stringify(keys)}`);
    if (keys.length > 0) {
        const promises: Promise<void>[] = [];
        keys.forEach((k) => promises.push(uploadCachedConfirmation(k.toString())));
        await Promise.all(promises);
        offlineStore.clearStore();
    }
}

export const saveToOfflineStore = async (dateTime: string, storagePath: string, data: string, additionalData: any | null = null): Promise<string> => {
    const dataToStore: IConfirmStorageData = {
        dateTime,
        additionalData,
        data
    };
    await offlineStore.saveToStore(storagePath, dataToStore);
    return dateTime;
};

export const saveDeliveryConfirmationOffline = async (uid: string, data: string, additionalData: any | null = null): Promise<string> => {
    const dateTime: string = format(new Date(), N_DATE_TIME_STORAGE_KEY_FORMAT);
    const storagePath: string = getDeliverySignatureStoragePath(uid, dateTime);
    await saveToOfflineStore(dateTime, storagePath, data, additionalData);
    return dateTime;
};

export const saveFoodCheckConfirmationOffline = async (uid: string, data: string, additionalData: any | null = null): Promise<string> => {
    const dateTime: string = format(new Date(), N_DATE_TIME_STORAGE_KEY_FORMAT);
    const storagePath: string = getFoodCheckSignatureStoragePath(uid, dateTime);
    await saveToOfflineStore(dateTime, storagePath, data, additionalData);
    return dateTime;
};

export const saveDiaryCheckConfirmationOffline = async (uid: string, data: string, additionalData: any | null = null): Promise<string> => {
    const dateTime: string = format(new Date(), N_DATE_TIME_STORAGE_KEY_FORMAT);
    const storagePath: string = getChecksGroupSignatureStoragePath(uid, dateTime);
    await saveToOfflineStore(dateTime, storagePath, data, additionalData);
    return dateTime;
};

export const savePrerequisteConfirmationOffline = async (uid: string, data: string, additionalData: any | null = null): Promise<string> => {
    const dateTime: string = format(new Date(), N_DATE_TIME_STORAGE_KEY_FORMAT);
    const storagePath: string = getPrerequisiteSignatureStoragePath(uid, dateTime);
    await saveToOfflineStore(dateTime, storagePath, data, additionalData);
    return dateTime;
};

export const uploadCachedConfirmation = async (storagePath: string): Promise<void> => {
    const data: any = await offlineStore.getFromStore(storagePath);
    const dataStore: IConfirmStorageData = data;
    const metadata = {
        contentType: SAVE_SIGNATURE_FORMAT,
    };
    const url: string = await uploadStringData(storage, `data_url`, metadata, storagePath, dataStore.data);
    await saveUrl(db, storagePath, url, dataStore.dateTime, dataStore.additionalData);
};